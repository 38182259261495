import React, { Fragment, useState, useRef, useEffect } from 'react';
//Importaciones
import Header from '../../dashboard/Header'
import PaginaCarga from './../../../config/PaginaCarga'

import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Modal, Stack } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';

//Material UI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Snackbar, Alert, AlertTitle, Tooltip } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import ButtonMUI from '@mui/material/Button';
import { DataGrid ,esES} from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { ObtenerUsuario, ValidarUsuario, config,listarPermisos,ValidarPermisos,LIST_SCREENS } from '../../../config/utils';

import moment from 'moment-timezone';
//Autocomplete

//Mapa Leaflet
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { Pin1, Pin2 } from '../../../config/iconos'
import 'leaflet/dist/leaflet.css';

function CalculoDetalleMicrobalance() {
    const navigate = useNavigate();

    //toast y progress Bar
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [mostrarBarraProgreso, setmostrarBarraProgreso] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const [objetoMenu, setobjetoMenu] = React.useState(null);


    //Params
    const { idbalance, idmicrobalance } = useParams();

    const [mostrarResumenMicrobalance, setmostrarResumenMicrobalance] = useState(false);

    //Variables
    const [dataResumenMicrobalance, setdataResumenMicrobalance] = useState({
        fechaLectura1: '',
        fechaLectura2: '',
        diferenciaFechas: '',
        periodoComercial: '',
        diasfacturados: '',
        sed: '',
        calculomicrobalance: {
            consumoTotalTotalizadores: '',
            consumoTotalUsuarios: '',
            dif: '',
            porcPerdida: '',
            cantSum: '',
            kwh_sum: '',
        },
        proyectado: {
            consumoTotalTotalizadores: '',
            consumoTotalUsuarios: '',
            dif: '',
            porcPerdida: '',
            kwh_sum: '',
        },
        facturacion: {
            consumoTotalTotalizadores: '',
            consumoTotalUsuarios: '',
            dif: '',
            porcPerdida: '',
            kwh_sum: '',
        }
    });
    const [dataMatricesMicrobalances, setdataMatricesMicrobalances] = useState([]);
    const [listaTotalizadores, setlistaTotalizadores] = useState([]);
    const [listaSuministros, setlistaSuministros] = useState([]);
    const [listaTotalizadoresTemporales, setlistaTotalizadoresTemporales] = useState([]);
    //Variables - DetalleSuministro    
    const [detalleSuministroMicrobalance, setdetalleSuministroMicrobalance] = useState(null);
    const [listaLectura1Suministro, setlistaLectura1Suministro] = useState(null);
    const [listaLectura2Suministro, setlistaLectura2Suministro] = useState(null);
    const [listaTotalizadoresSuministro, setlistaTotalizadoresSuministro] = useState([]);
    const [accionModificarFactorMedicion, setaccionModificarFactorMedicion] = useState(false);
    const [accionModificarLectura1, setaccionModificarLectura1] = useState(false);
    const [accionModificarLectura2, setaccionModificarLectura2] = useState(false);

    //Modales
    const [mostrarModalObservacion, setmostrarModalObservacion] = useState(false);
    const [mostrarModalProgramarMicrobalance, setmostrarModalProgramarMicrobalance] = useState(false);
    const [mostrarModalModificarSuministro, setmostrarModalModificarSuministro] = useState(false);
    const [mostrarModalAgregarTotalizador, setmostrarModalAgregarTotalizador] = useState(false);
    const [mostrarModalHabilitarLectura, setmostrarModalHabilitarLectura] = useState(false);
    const [mostrarModalObservaciones, setmostrarModalObservaciones] = useState(false);

    //Referencias
    const selectVitaBalance = useRef();
    const inputFechaProgramacionLectura = useRef();
    const inputTipoProgramacionLectura = useRef();
    const inputSuministroAgregarTemporal = useRef();
    const inputFactorMedicionAgregarTemporal = useRef();
    //Referencias SuministroDetalle
    const inputFactorMedicion = useRef();
    const selectTotalizadorAsociado1 = useRef();
    const inputLectura1 = useRef();
    const inputObservacion1 = useRef();
    const selectTotalizadorAsociado2 = useRef();
    const inputLectura2 = useRef();
    const inputObservacion2 = useRef();

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    const rows = [
        { id: 1, accion: '', item: '1', suministro: '459455', factormedicion: '1', tarifa: 'BT5C-AP', lec1: '19268', lec2: '20156', dif: '888', obs: '', proyeccion: '3933', facturacion: '410', desviacion: '1.93%' },
        { id: 2, accion: '', item: '2', suministro: '480603', factormedicion: '1', tarifa: 'BT5B', lec1: '10875', lec2: '10882', dif: '7', obs: '', proyeccion: '31', facturacion: '33', desviacion: '6.06%' },
        { id: 3, accion: '', item: '3', suministro: '460604', factormedicion: '1', tarifa: 'BT5B', lec1: '4640', lec2: '4610', dif: '-30', obs: '', proyeccion: '-133', facturacion: '10', desviacion: '1428.57%' },
        { id: 4, accion: '', item: '4', suministro: '460605', factormedicion: '1', tarifa: 'BT5B', lec1: '22', lec2: '46', dif: '24', obs: '', proyeccion: '106', facturacion: '112', desviacion: '5.10%' },
        { id: 5, accion: '', item: '5', suministro: '460606', factormedicion: '1', tarifa: 'BT5B', lec1: '29371', lec2: '29444', dif: '73', obs: '', proyeccion: '323', facturacion: '320', desviacion: '-1.03%' },
        { id: 6, accion: '', item: '6', suministro: '460607', factormedicion: '1', tarifa: 'BT5B', lec1: '256', lec2: '259', dif: '3', obs: '', proyeccion: '13', facturacion: '12', desviacion: '-10.71%' },
        { id: 7, accion: '', item: '7', suministro: '460809', factormedicion: '1', tarifa: 'BT5B', lec1: '2563', lec2: '2619', dif: '56', obs: '', proyeccion: '248', facturacion: '250', desviacion: '0.80%' },
        { id: 8, accion: '', item: '8', suministro: '480603', factormedicion: '1', tarifa: 'BT5B', lec1: '254', lec2: '258', dif: '4', obs: '', proyeccion: '18', facturacion: '18', desviacion: '1.59%' },
        { id: 9, accion: '', item: '9', suministro: '460604', factormedicion: '1', tarifa: 'BT5B', lec1: '845', lec2: '935', dif: '90', obs: '', proyeccion: '399', facturacion: '399', desviacion: '0.11%' },
        { id: 10, accion: '', item: '10', suministro: '460605', factormedicion: '1', tarifa: 'BT5B', lec1: '369', lec2: '425', dif: '56', obs: '', proyeccion: '248', facturacion: '263', desviacion: '5.7%' },
        { id: 11, accion: '', item: '11', suministro: '460606', factormedicion: '1', tarifa: 'BT5B', lec1: '1257', lec2: '1301', dif: '44', obs: '', proyeccion: '195', facturacion: '206', desviacion: '5.41%' },
        { id: 12, accion: '', item: '12', suministro: '460607', factormedicion: '1', tarifa: 'BT5B', lec1: '2628', lec2: '2896', dif: '268', obs: '', proyeccion: '1187', facturacion: '1194', desviacion: '0.06%' },
        { id: 13, accion: '', item: '13', suministro: '460809', factormedicion: '1', tarifa: 'BT5B', lec1: '1876', lec2: '19002', dif: '17126', obs: '', proyeccion: '75844', facturacion: '112', desviacion: '-67617.6%' },
        { id: 14, accion: '', item: '14', suministro: '480603', factormedicion: '1', tarifa: 'BT5B', lec1: '2632', lec2: '2589', dif: '-43', obs: '', proyeccion: '-190', facturacion: '260', desviacion: '173.24%' },
        { id: 15, accion: '', item: '15', suministro: '460604', factormedicion: '1', tarifa: 'BT5B', lec1: '264', lec2: '286', dif: '22', obs: '', proyeccion: '97', facturacion: '100', desviacion: '2.57%' },
        { id: 16, accion: '', item: '16', suministro: '460605', factormedicion: '1', tarifa: 'BT5B', lec1: '125', lec2: '131', dif: '6', obs: '', proyeccion: '27', facturacion: '28', desviacion: '5.10%' },
        { id: 17, accion: '', item: '17', suministro: '460606', factormedicion: '1', tarifa: 'BT5B', lec1: '352', lec2: '339', dif: '-13', obs: '', proyeccion: '-58', facturacion: '260', desviacion: '122.14%' },
        { id: 18, accion: '', item: '18', suministro: '460607', factormedicion: '1', tarifa: 'BT5B', lec1: '7851', lec2: '7936', dif: '85', obs: '', proyeccion: '376', facturacion: '381', desviacion: '1.20%' },
        { id: 19, accion: '', item: '19', suministro: '460809', factormedicion: '1', tarifa: 'BT5B', lec1: '231', lec2: '246', dif: '15', obs: '', proyeccion: '66', facturacion: '69', desviacion: '3.73%' },
    ];

    const columns = [
        {
            field: 'accion', headerName: '*', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Programacion Microbalance */}
                        <IconButton onClick={() => {                            
                            MostrarModalDetalleSuministroMicrobalance(cellValues.row.id)
                        }}>
                            <EditIcon fontSize='small'></EditIcon>
                        </IconButton>
                    </>
                );
            }
        },
        {
            field: 'item', headerName: 'ITEM', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <ButtonMUI className='text-decoration-underline text-primary'>{cellValues.row.item}</ButtonMUI>
                )
            }
        },
        { field: 'suministro', headerName: 'Suministro', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'factormedicion', headerName: 'Fac. Med.', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'tarifa', headerName: 'Tarifa', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'lec1', headerName: 'Lect 1', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'lec2', headerName: 'Lect 2', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'dif', headerName: 'Diferencia', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'obs', headerName: 'Obs.', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'proyeccion', headerName: 'Proyección', minWidth: 100, flex: 1, headerClassName: 'header-colum-green' },
        { field: 'facturacion', headerName: 'Facturacion', minWidth: 100, flex: 1, headerClassName: 'header-colum-green' },
        { field: 'desviacion', headerName: 'Desviación', minWidth: 100, flex: 1, headerClassName: 'header-colum-red',renderCell:(cellValues)=>{
            let valorDesviacion=cellValues.row.desviacion;
            if(isNaN(cellValues.row.desviacion)){
                valorDesviacion=0;
            }else{
                valorDesviacion=cellValues.row.desviacion;
            }
            return(                
                <span style={{}}>{valorDesviacion}</span>
            )
        } },
    ];

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_MICROBALANCE_CALCULO, permisos)) {
                    BuscarResumenMicrobalanceGeneral()
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }       
    }
    const AbrirMenu = (event) => {
        setobjetoMenu(event.currentTarget);
    };
    const CerrarMenu = () => {
        setobjetoMenu(null);
    };
    const CambiarVistaBalance = (value) => {
        console.log(selectVitaBalance.current.value);

        if (selectVitaBalance.current.value === 'balance') {
            navigate(`/balance/detalle/${idbalance}`);
        }
        else {
            navigate(`/balance/detalle/${idbalance}/${idmicrobalance}`);
        }
    }
    //Programacion de Lectura
    const MostrarModalHabilitarLectura = async (tipo) => {
        await setmostrarModalHabilitarLectura(true);
        inputTipoProgramacionLectura.current.value = tipo;
    }
    const ValidarProgramacionLectura = () => {
        let error = 0;
        if (inputFechaProgramacionLectura.current.value == '') {
            inputFechaProgramacionLectura.current.classList.add('is-invalid');
            error++;
        } else {
            inputFechaProgramacionLectura.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const ProgramarLectura = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarProgramacionLectura()) {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let url = `${config.endPoint}api/Microbalance/HabilitarLectura`;
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idmicrobalance: idmicrobalance,
                        fechaprogramacion: inputFechaProgramacionLectura.current.value,
                        tipo: inputTipoProgramacionLectura.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    // console.log(data);
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        setmostrarModalHabilitarLectura(false);
                        BuscarResumenMicrobalanceGeneral();

                    } else {
                        settituloToast("Alerta");
                        settipoToast("warning");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    // console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    //Microbalance Detalle
    const BuscarResumenMicrobalanceGeneral = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let url = `${config.endPoint}api/Microbalance/ResumenMicrobalanceGeneral/${idmicrobalance}`;
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data.content);
                if (data.success) {
                    setdataMatricesMicrobalances(data.content);
                    CalcularMicrobalance(data.content);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(data.message)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                // console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(error.tostring())
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const CalcularMicrobalance = async (matrices) => {
        // console.log(matrices);
        //
        let _dataMicrobalances = {
            fechaLectura1: '',
            fechaLectura2: '',
            diferenciaFechas: '',
            periodoComercial: '',
            diasfacturados: '',
            sed: '',
            calculomicrobalance: {
                consumoTotalTotalizadores: '',
                consumoTotalUsuarios: '',
                dif: '',
                porcPerdida: '',
                cantSum: '',
                kwh_sum: '',
            },
            proyectado: {
                consumoTotalTotalizadores: '',
                consumoTotalUsuarios: '',
                dif: '',
                porcPerdida: '',
                kwh_sum: '',
            },
            facturacion: {
                consumoTotalTotalizadores: '',
                consumoTotalUsuarios: '',
                dif: '',
                porcPerdida: '',
                kwh_sum: '',
            }
        };

        //Volcar Informacion
        let matrizFechasProgramadas = matrices.tablaFechasProgramadas;
        let matrizAgrupaciones = matrices.tablaAgrupaciones;
        let matrizResumenMicrobalance = matrices.tablaResumenMicrobalance;
        let matrizDetalleMicrobalance = matrices.tablaDetalleMicrobalance;

        //llenar datos de mcirobalance
        if (matrizDetalleMicrobalance.length > 0) {
            _dataMicrobalances.periodoComercial = matrizDetalleMicrobalance[0].mes_proceso;
            _dataMicrobalances.diasfacturados = parseFloat(matrizDetalleMicrobalance[0].dias_facturados);
            _dataMicrobalances.sed = matrizDetalleMicrobalance[0].sed_codigo;
        }

        //Buscar Fechas Programadas
        if (matrizFechasProgramadas) {
            let fechaLectura1 = await matrizFechasProgramadas.find(x => x.tipo === 'LECTURA_1');
            let fechaLectura2 = await matrizFechasProgramadas.find(x => x.tipo === 'LECTURA_2');

            if (fechaLectura1) {
                _dataMicrobalances.fechaLectura1 = moment(fechaLectura1.fecha_programacion).format("YYYY-MM-DD");
            }

            if (fechaLectura2) {
                _dataMicrobalances.fechaLectura2 = moment(fechaLectura2.fecha_programacion).format("YYYY-MM-DD");
            }

            if (fechaLectura1 && fechaLectura2) {
                let fecha1 = moment(fechaLectura1.fecha_programacion);
                let fecha2 = moment(fechaLectura2.fecha_programacion);

                let diferenciaFechas = fecha1.diff(fecha2, 'days', false);
                _dataMicrobalances.diferenciaFechas = Math.abs(diferenciaFechas) + 1;
            }
        }

        //Buscar Lista Totalizadores 
        let arrayTotalizadores = [];
        let arraySuministros = [];
        // console.log(matrizResumenMicrobalance);
        let _totalizador = matrizAgrupaciones.find(x => x.nombre_agrupacion == 'TOTALIZADOR');
        // console.log(_totalizador);
        if (_totalizador) {
            let splitElementosTotalizador = _totalizador.elementos.split(',');

            //Filtrar elementos que son TOTALIZADOR            
            splitElementosTotalizador.forEach(splitElementos => {
                let elemetosEncontrados = matrizResumenMicrobalance.filter(y => y.tarifa == splitElementos);
                arrayTotalizadores = elemetosEncontrados;
            });
            let sumaConsumoTota = 0;
            let sumaConsumoTotaFacturacion = 0;
            for (let x = 0; x < arrayTotalizadores.length; x++) {
                if (arrayTotalizadores[x].tipo == "TEMPORAL") {
                    arrayTotalizadores[x].item = 'TOT-TEMP';
                } else {
                    arrayTotalizadores[x].item = 'TOT';
                }

                let lectura_1 = parseFloat(arrayTotalizadores[x].lectura_lectura1);
                let lectura_2 = parseFloat(arrayTotalizadores[x].lectura_lectura2);
                let factorMedicion = parseFloat(arrayTotalizadores[x].fact_medicion);
                // let diasFacturados = parseFloat(arrayTotalizadores[x].dias_facturados);
                let diasFacturados=parseFloat(_dataMicrobalances.diasfacturados);
                let CEA_consumo = parseFloat(arrayTotalizadores[x].cea_consumo_fact);

                if (!isNaN(lectura_1) && !isNaN(lectura_2) && !isNaN(factorMedicion)) {
                    //Calcular Diferencia => (Lectura_1 - Lectura_2) X Factor_Medicion
                    let diferencia = (lectura_2 - lectura_1)*factorMedicion;
                    //Calcular Proyeccion=> (DiferenciaLecturas * DiasFacturados)/PeriodoLectura
                    // console.log(diferencia);
                    // console.log(diasFacturados);
                    // console.log(_dataMicrobalances.diferenciaFechas);

                    let proyeccion = (diferencia * diasFacturados) / _dataMicrobalances.diferenciaFechas;
                    arrayTotalizadores[x].diferenciaLecturas = diferencia;
                    arrayTotalizadores[x].proyeccion = proyeccion;
                    sumaConsumoTota = sumaConsumoTota + diferencia;
                    arrayTotalizadores[x].desviacion = (CEA_consumo - proyeccion) / CEA_consumo;
                } else {
                    sumaConsumoTota = 0;
                }
                if (Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura1).length === 0) {
                    arrayTotalizadores[x].lectura_lectura1 = '';
                }
                if (Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura2).length === 0) {
                    arrayTotalizadores[x].lectura_lectura2 = '';
                }
                if (Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura1).length === 0 && Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura2).length) {
                    arrayTotalizadores[x].diferenciaLecturas = '';
                }

                if (Object.entries(arrayTotalizadores[x].observacionlectura1_lectura1).length === 0) {
                    arrayTotalizadores[x].observacionlectura1_lectura1 = '';
                }

                if (Object.entries(arrayTotalizadores[x].observacionlectura1_lectura2).length === 0) {
                    arrayTotalizadores[x].observacionlectura1_lectura2 = '';
                }


                //Calculo consumo Facturacion
                sumaConsumoTotaFacturacion = sumaConsumoTotaFacturacion + CEA_consumo;

            }
            _dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores = sumaConsumoTota;
            _dataMicrobalances.facturacion.consumoTotalTotalizadores = sumaConsumoTotaFacturacion;
            setlistaTotalizadores(arrayTotalizadores);
            // console.log(arrayTotalizadores);

            //Filtrar elementos que no son TOTALIZADOR            
            splitElementosTotalizador.forEach(splitElementos => {
                let elemetosEncontrados = matrizResumenMicrobalance.filter(y => y.tarifa !== splitElementos);
                arraySuministros = elemetosEncontrados;
            });
            let sumaConsumoSum = 0;
            let sumaConsumoSumFacturacion = 0;
            let arrayData = [];
            for (let x = 0; x < arraySuministros.length; x++) {
                arraySuministros[x].item = x + 1;
                let lectura_1 = parseFloat(arraySuministros[x].lectura_lectura1);
                let lectura_2 = parseFloat(arraySuministros[x].lectura_lectura2);
                let factorMedicion = parseFloat(arraySuministros[x].fact_medicion);
                // let diasFacturados = parseFloat(arraySuministros[x].dias_facturados);

                let diasFacturados=parseFloat(_dataMicrobalances.diasfacturados);
                let CEA_consumo = parseFloat(arraySuministros[x].cea_consumo_fact);

                if (!isNaN(lectura_1) && !isNaN(lectura_2) && !isNaN(factorMedicion)) {
                    //Calcular Diferencia => (Lectura_1 - Lectura_2) X Factor_Medicion
                    let diferencia = (lectura_2 - lectura_1)*factorMedicion;
                    //Calcular Proyeccion=> (DiferenciaLecturas * DiasFacturados)/PeriodoLectura
                    // console.log(diferencia);
                    // console.log(diasFacturados);
                    // console.log(_dataMicrobalances.diferenciaFechas);

                    let proyeccion = (diferencia * diasFacturados) / parseFloat(_dataMicrobalances.diferenciaFechas);
                    
                    arraySuministros[x].diferenciaLecturas = diferencia;
                    arraySuministros[x].proyeccion = proyeccion;
                    sumaConsumoSum = sumaConsumoSum + diferencia;
                    arraySuministros[x].desviacion = (((CEA_consumo - proyeccion) / CEA_consumo)*100).toFixed(0);

                } else {
                    sumaConsumoSum = 0;
                }

                if (Object.entries(arraySuministros[x].fecha_ejecucion_lectura1).length === 0) {
                    arraySuministros[x].lectura_lectura1 = '';
                }
                if (Object.entries(arraySuministros[x].fecha_ejecucion_lectura2).length === 0) {
                    arraySuministros[x].lectura_lectura2 = '';
                }

                if (Object.entries(arraySuministros[x].observacionlectura1_lectura1).length === 0) {
                    arraySuministros[x].observacionlectura1_lectura1 = '';
                }

                if (Object.entries(arraySuministros[x].observacionlectura1_lectura2).length === 0) {
                    arraySuministros[x].observacionlectura1_lectura2 = '';
                }

                sumaConsumoSumFacturacion = sumaConsumoSumFacturacion + CEA_consumo;

                //Crear Nuevo array de Objetos
                let nElemento = {
                    id: arraySuministros[x].id_suministro_microbalance,
                    accion: '',
                    item: x + 1,
                    suministro: arraySuministros[x].codigo_suministro,
                    factormedicion: arraySuministros[x].fact_medicion,
                    tarifa: arraySuministros[x].tarifa,
                    lec1: arraySuministros[x].lectura_lectura1,
                    lec2: arraySuministros[x].lectura_lectura2,
                    dif: arraySuministros[x].diferenciaLecturas,
                    obs: `${arraySuministros[x].observacionlectura1_lectura1};${arraySuministros[x].observacionlectura1_lectura2}`,
                    proyeccion: arraySuministros[x].proyeccion,
                    facturacion: arraySuministros[x].cea_consumo_fact,
                    desviacion: arraySuministros[x].desviacion
                };
                arrayData.push(nElemento);
            }


            setlistaSuministros(arrayData);
            _dataMicrobalances.calculomicrobalance.consumoTotalUsuarios = sumaConsumoSum;
            _dataMicrobalances.facturacion.consumoTotalUsuarios = sumaConsumoSumFacturacion;

            //********************************************************************* */
            //* CALCULO MICROBALANCE Y PROYECCIONES
            //********************************************************************* */
            //1.calculomicrobalance:

            _dataMicrobalances.calculomicrobalance.dif = _dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores - _dataMicrobalances.calculomicrobalance.consumoTotalUsuarios;
            _dataMicrobalances.calculomicrobalance.porcPerdida = ((_dataMicrobalances.calculomicrobalance.dif / _dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores) * 100).toFixed(2);
            _dataMicrobalances.calculomicrobalance.cantSum = arrayData.length;
            _dataMicrobalances.calculomicrobalance.kwh_sum = (_dataMicrobalances.calculomicrobalance.dif / _dataMicrobalances.calculomicrobalance.cantSum).toFixed(2);
            //2.Proyectado
            _dataMicrobalances.proyectado.consumoTotalTotalizadores = Math.round((_dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores * _dataMicrobalances.diasfacturados) / _dataMicrobalances.diferenciaFechas);
            _dataMicrobalances.proyectado.consumoTotalUsuarios = Math.round((_dataMicrobalances.calculomicrobalance.consumoTotalUsuarios * _dataMicrobalances.diasfacturados) / _dataMicrobalances.diferenciaFechas);
            _dataMicrobalances.proyectado.dif = Math.round(_dataMicrobalances.proyectado.consumoTotalTotalizadores - _dataMicrobalances.proyectado.consumoTotalUsuarios);
            _dataMicrobalances.proyectado.porcPerdida = ((_dataMicrobalances.proyectado.dif / _dataMicrobalances.proyectado.consumoTotalTotalizadores) * 100).toFixed(2);
            _dataMicrobalances.proyectado.kwh_sum = (_dataMicrobalances.proyectado.dif / _dataMicrobalances.calculomicrobalance.cantSum).toFixed(0);

            //3.Facturacion
            _dataMicrobalances.facturacion.consumoTotalTotalizadores = Math.round(_dataMicrobalances.facturacion.consumoTotalTotalizadores);
            _dataMicrobalances.facturacion.consumoTotalUsuarios = Math.round(_dataMicrobalances.facturacion.consumoTotalUsuarios);
            _dataMicrobalances.facturacion.dif = Math.round(_dataMicrobalances.facturacion.consumoTotalTotalizadores - _dataMicrobalances.facturacion.consumoTotalUsuarios);
            _dataMicrobalances.facturacion.porcPerdida = ((_dataMicrobalances.facturacion.dif / _dataMicrobalances.facturacion.consumoTotalTotalizadores) * 100).toFixed(2);
            _dataMicrobalances.facturacion.kwh_sum = (_dataMicrobalances.facturacion.dif / _dataMicrobalances.calculomicrobalance.cantSum).toFixed(0);


        } else {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast("No se encontró elemento de agrupacion para realizar el calculo.")
            setabrirToast(true);
        }
        //Realizar Calculo de Microbalance
        console.log(_dataMicrobalances);
        setdataResumenMicrobalance(_dataMicrobalances);
    }
    //Agregar Totalizador Temporal
    const AbrirModalAgregarTotalizadorTemporal = () => {
        BuscarListaTotalizadoresTemporales();
        setmostrarModalAgregarTotalizador(true)
    }
    const ValidarControlesAgregarTemporal = () => {
        let error = 0;
        if (inputSuministroAgregarTemporal.current.value.trim() == '') {
            inputSuministroAgregarTemporal.current.classList.add('is-invalid');
            error++;
        } else {
            inputSuministroAgregarTemporal.current.classList.remove('is-invalid');
        }
        let _factorMedicion = parseFloat(inputFactorMedicionAgregarTemporal.current.value);
        if (isNaN(_factorMedicion)) {
            inputFactorMedicionAgregarTemporal.current.classList.add('is-invalid');
            error++;
        } else {
            inputFactorMedicionAgregarTemporal.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const AgregarTotalizadorTemporal = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarControlesAgregarTemporal()) {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/AgregarTotalizadorTemporal`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idmicrobalance: idmicrobalance,
                        codigosuministro: inputSuministroAgregarTemporal.current.value,
                        factormedicion: inputFactorMedicionAgregarTemporal.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        inputSuministroAgregarTemporal.current.value = "";
                        inputFactorMedicionAgregarTemporal.current.value = "";
                        settituloToast("Correcto");
                        settipoToast("success");
                        BuscarResumenMicrobalanceGeneral();
                        BuscarListaTotalizadoresTemporales();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const BuscarListaTotalizadoresTemporales = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/TotalizadorTemporal/listar/${idmicrobalance}`;

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                console.log(data);
                if (data.success) {
                    setlistaTotalizadoresTemporales(data.content);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(data.message)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        }
    }
    const EliminarSuministroMicrobalance = (idsuministromicrobalance) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (window.confirm("Esta trando de eliminar un suministro TOTALIZADOR (temporal), esta accion no puede revertirse.\n¿Esta seguro de eliminar este registro?")) {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/SuministroMicrobalance/eliminar`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idsuministromicrobalance: idsuministromicrobalance
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        BuscarListaTotalizadoresTemporales();
                        BuscarResumenMicrobalanceGeneral();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }

    }
    const ActualzarEstadoSuministroMicrobalance = (idsuministromicrobalance, estado) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/SuministroMicrobalance/actualizarEstado`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idsuministromicrobalance: idsuministromicrobalance,
                    estado: estado,
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    settituloToast("Correcto");
                    settipoToast("success");
                    BuscarListaTotalizadoresTemporales();
                    BuscarResumenMicrobalanceGeneral();
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                }
                setmensajeToast(data.message)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        }

    }

    //Detalle Suministro
    const MostrarModalDetalleSuministroMicrobalance = async (idsuministromicrobalance) => {
        try {
            //Deshabilitando Controles
            setaccionModificarFactorMedicion(false);
            setaccionModificarLectura1(false);
            setaccionModificarLectura2(false);

            //Reiniciar Valores        
            setdetalleSuministroMicrobalance(null);
            setlistaLectura1Suministro(null);
            setlistaLectura2Suministro(null);

            setmostrarModalModificarSuministro(true);
            BuscarDetalleSuministro(idsuministromicrobalance);

        } catch (error) {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(error.tostring())
            setabrirToast(true);

        }
    }
    const BuscarDetalleSuministro = (idsuministromicrobalance) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/DetalleSuministroMicrobalance/${idsuministromicrobalance}/${idmicrobalance}`;
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {

                if (data.success) {

                    let lectura1 = null;
                    let lectura2 = null;
                    let _lecturas = data.content.lecturas;
                    let _suministro = data.content.suministro;
                    let _totalizadores = data.content.totalizadores;

                    setdetalleSuministroMicrobalance(_suministro);
                    setlistaTotalizadoresSuministro(_totalizadores);

                    for (let x = 0; x < _lecturas.length; x++) {
                        switch (_lecturas[x].tipo) {
                            case 'LECTURA_1':
                                lectura1 = _lecturas[x];
                                break;
                            case 'LECTURA_2':
                                lectura2 = _lecturas[x];
                                break;
                        }
                    }
                    setlistaLectura1Suministro(lectura1);
                    setlistaLectura2Suministro(lectura2);

                    if (lectura1) {
                        console.log(lectura1.id_suministro_dependiente);
                        selectTotalizadorAsociado1.current.value = lectura1.id_suministro_dependiente
                        inputLectura1.current.value = lectura1.lectura;
                        inputObservacion1.current.value = lectura1.observacion;
                    }

                    if (lectura2) {
                        selectTotalizadorAsociado2.current.value = lectura2.id_suministro_dependiente;
                        inputLectura2.current.value = lectura2.lectura;
                        inputObservacion2.current.value = lectura2.observacion;
                    }

                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(data.message)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);

            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(error.tostring())
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const ValidarControlesDetalleSuministro = () => {
        let error = 0;
        if (inputFactorMedicion.current.value == '') {
            inputFactorMedicion.current.classList.add('is-invalid');
            error++;
        } else {
            inputFactorMedicion.current.classList.false('is-invalid');
        }

        if (error > 0) {
            return false
        }
        return true
    }
    const ActualizarFactorMedicionSuministro = async () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarControlesDetalleSuministro()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Microbalance/ActualizarFactorMedicionSuministroMicrobalance`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idsuministromicrobalance: detalleSuministroMicrobalance.id_suministro_microbalance,
                        factormedicion: inputFactorMedicion.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        setaccionModificarFactorMedicion(false);

                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const ValidarControlesLectura1 = () => {
        let error = 0;

        if (selectTotalizadorAsociado1.current.value.trim() == '') {
            selectTotalizadorAsociado1.current.classList.add('is-invalid');
            error++;
        } else {
            selectTotalizadorAsociado1.current.classList.remove('is-invalid');
        }

        if (inputLectura1.current.value.trim() == '') {
            inputLectura1.current.classList.add('is-invalid');
            error++;
        } else {
            inputLectura1.current.classList.remove('is-invalid');
        }

        // if (inputObservacion1.current.value.trim() == '') {
        //     inputObservacion1.current.classList.add('is-invalid');
        // }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const ActualizarLectura1 = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            if (ValidarControlesLectura1()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Microbalance/ActualizarLecturaMicrobalanceSuministro_v1`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idlecturamicrobalancesuministro: listaLectura1Suministro.id_lectura_microbalance_suministro,
                        idsuministrototalizador: selectTotalizadorAsociado1.current.value,
                        lectura: inputLectura1.current.value,
                        observacion: inputObservacion1.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        setaccionModificarLectura1(false);
                        BuscarDetalleSuministro(detalleSuministroMicrobalance.id_suministro_microbalance);
                        BuscarResumenMicrobalanceGeneral();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const ValidarControlesLectura2 = () => {
        let error = 0;
        if (selectTotalizadorAsociado2.current.value.trim() == '') {
            selectTotalizadorAsociado2.current.classList.add('is-invalid');
            error++;
        } else {
            selectTotalizadorAsociado2.current.classList.remove('is-invalid');
        }

        if (inputLectura2.current.value.trim() == '') {
            inputLectura2.current.classList.add('is-invalid');
            error++;
        } else {
            inputLectura2.current.classList.remove('is-invalid');
        }

        // if (inputObservacion2.current.value.trim() == '') {
        //     inputObservacion2.current.classList.add('is-invalid');
        // }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const ActualizarLectura2 = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarControlesLectura2()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Microbalance/ActualizarLecturaMicrobalanceSuministro_v1`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idlecturamicrobalancesuministro: listaLectura2Suministro.id_lectura_microbalance_suministro,
                        idsuministrototalizador: selectTotalizadorAsociado2.current.value,
                        lectura: inputLectura2.current.value,
                        observacion: inputObservacion2.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        setaccionModificarLectura1(false);
                        BuscarDetalleSuministro(detalleSuministroMicrobalance.id_suministro_microbalance);
                        BuscarResumenMicrobalanceGeneral();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Balance / Microbalance / Calculo'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Balance / Microbalance / Calculo</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate(`/balance/detalle/${idbalance}`) }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            {/* Tabla de Datos Suministros */}
            <div className='container border rounded mt-2'>
                <Container className='mt-2'>
                    <Row >
                        <Col >
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <Stack direction='horizontal' gap={3}>
                                            <Form.Label>{`SED : ${dataResumenMicrobalance.sed}`}</Form.Label>
                                            {/* <Tooltip title="Observaciones Microbalance">
                                                <IconButton onClick={() => { setmostrarModalObservaciones(true) }}>
                                                    <Badge badgeContent={2} color="secondary">
                                                        <SmsIcon></SmsIcon>
                                                    </Badge >
                                                </IconButton>
                                            </Tooltip> */}
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col className='d-grid'>
                                        <Button onClick={() => { BuscarResumenMicrobalanceGeneral() }} variant='outline-primary' size='sm'>Modificar</Button>
                                    </Col>
                                </Row>

                                {/* <Row className='my-1'>
                                    <Col>
                                        <Form.Select ref={selectVitaBalance} size='sm' onChange={(value) => { CambiarVistaBalance(value) }} defaultValue="microbalance_1">
                                            <option value="balance">BALANCE</option>
                                            <option value="microbalance_1" >Microbalance_1</option>
                                        </Form.Select>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col className='mt-1'>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td className='tabletd ' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px', fontWeight: 'bold' }}>Fecha Lec. 1</td>
                                                    <td className='tabletd'>{dataResumenMicrobalance.fechaLectura1}</td>
                                                </tr>
                                                <tr>
                                                    <td className='tabletd' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px', fontWeight: 'bold' }}>Fecha Lec. 2</td>
                                                    <td className='tabletd'>{dataResumenMicrobalance.fechaLectura2}</td>

                                                </tr>
                                                <tr>

                                                    <td className='tabletd' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px', fontWeight: 'bold' }}>Periodo</td>
                                                    <td className='tabletd text-center' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px' }}>
                                                        {dataResumenMicrobalance.diferenciaFechas}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td className='tabletd'>CONS TOT</td>
                                        <td style={{ minWidth: 50 }} className='tabletd'>{dataResumenMicrobalance.calculomicrobalance.consumoTotalTotalizadores}</td>
                                        <td className='fw-bold tabletd'>KWH</td>
                                    </tr>
                                    <tr>
                                        <td className='tabletd'>CONS USU</td>
                                        <td className='tabletd'>{dataResumenMicrobalance.calculomicrobalance.consumoTotalUsuarios}</td>
                                        <td className='fw-bold tabletd'>KWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>DIF</td>
                                        <td className='tabletd'>{dataResumenMicrobalance.calculomicrobalance.dif}</td>
                                        <td className='fw-bold tabletd'>KWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>% PERD</td>
                                        <td style={{ color: 'red' }} className='tabletd'>{isNaN(dataResumenMicrobalance.calculomicrobalance.porcPerdida) ? '' : dataResumenMicrobalance.calculomicrobalance.porcPerdida}</td>
                                        <td className='fw-bold tabletd'>%</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>#SUM</td>
                                        <td className='tabletd'>{dataResumenMicrobalance.calculomicrobalance.cantSum}</td>
                                        <td className='fw-bold tabletd'>Sum</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>% Perd./ Usuario</td>
                                        {/* <td className='tabletd' style={{ backgroundColor: 'rgb(132,205,92)' }}>{dataResumenMicrobalance.calculomicrobalance.kwh_sum}</td> */}
                                        <td className='tabletd' >{isNaN(dataResumenMicrobalance.calculomicrobalance.kwh_sum) ? '' : dataResumenMicrobalance.calculomicrobalance.kwh_sum}</td>
                                        <td className='fw-bold tabletd'>Kwh/Sum</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>

                        <Col>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td className='tabletd-black'>PROYECTADO</td>
                                        <td style={{ minWidth: 50 }} className='tabletd-black'>{dataResumenMicrobalance.diasfacturados}</td>
                                        <td className='fw-bold tabletd-black'>Dias</td>
                                    </tr>
                                    <tr>
                                        <td className='tabletd-black'>CONS. TOT</td>
                                        <td className='tabletd-black'>{isNaN(dataResumenMicrobalance.proyectado.consumoTotalTotalizadores) ? '' : dataResumenMicrobalance.proyectado.consumoTotalTotalizadores}</td>
                                        <td className='fw-bold tabletd-black'>KWH</td>
                                    </tr>
                                    <tr >
                                        <td className='tabletd-black'>CONS. USU</td>
                                        <td className='tabletd-black'>{isNaN(dataResumenMicrobalance.proyectado.consumoTotalUsuarios) ? '' : dataResumenMicrobalance.proyectado.consumoTotalUsuarios}</td>
                                        <td className='fw-bold tabletd-black'>KWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd-black'>DIF</td>
                                        <td style={{ color: 'red' }} className='tabletd-black'>{isNaN(dataResumenMicrobalance.proyectado.dif) ? '' : dataResumenMicrobalance.proyectado.dif}</td>
                                        <td className='fw-bold tabletd-black'>kWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd-black'>% Perdida</td>
                                        <td className='tabletd-black'>{isNaN(dataResumenMicrobalance.proyectado.porcPerdida) ? '' : dataResumenMicrobalance.proyectado.porcPerdida}</td>
                                        <td className='fw-bold tabletd-black'>%</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd-black'>% Perd./ Usuario</td>
                                        {/* <td className='tabletd-black' style={{ backgroundColor: 'rgb(244,173,156)', color: 'red' }}>{dataResumenMicrobalance.proyectado.kwh_sum}</td> */}
                                        <td className='tabletd-black' >{isNaN(dataResumenMicrobalance.proyectado.kwh_sum) ? '' : dataResumenMicrobalance.proyectado.kwh_sum}</td>
                                        <td className='fw-bold tabletd-black'>Kwh/Sum</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>

                        <Col>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td className='tabletd-black'>FACTURACION</td>
                                        <td style={{ minWidth: 50 }} className='tabletd-black'>{dataResumenMicrobalance.diasfacturados}</td>
                                        <td className='fw-bold tabletd-black'>Dias</td>
                                    </tr>
                                    <tr>
                                        <td className='tabletd-black'>CONS TOT</td>
                                        <td className='tabletd-black'>{dataResumenMicrobalance.facturacion.consumoTotalTotalizadores}</td>
                                        <td className='fw-bold tabletd-black'>KWH</td>
                                    </tr>
                                    <tr >
                                        <td className='tabletd-black'>CONS USU</td>
                                        <td className='tabletd-black'>{dataResumenMicrobalance.facturacion.consumoTotalUsuarios}</td>
                                        <td className='fw-bold tabletd-black'>KWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd-black'>DIF</td>
                                        <td style={{ color: 'blue' }} className='tabletd-black'>{dataResumenMicrobalance.facturacion.dif}</td>
                                        <td className='fw-bold tabletd-black'>KWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd-black'>% PERD</td>
                                        <td className='tabletd-black'>{dataResumenMicrobalance.facturacion.porcPerdida}</td>
                                        <td className='fw-bold tabletd-black'>%</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd-black'>% PERD / USU</td>
                                        {/* <td className='tabletd-black' style={{ backgroundColor: 'rgb(132,205,92)' }}>{dataResumenMicrobalance.facturacion.kwh_sum}</td> */}
                                        <td className='tabletd-black' >{dataResumenMicrobalance.facturacion.kwh_sum}</td>
                                        <td className='fw-bold tabletd-black'>Kwh/Sum</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        {/* <Col md={5} className='align-self-center'>

                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr className='header-colum-table'>
                                        <th className='tabletd'>*</th>
                                        <th className='tabletd'>Obs.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='tabletd'>
                                            <IconButton onClick={() => { setmostrarModalObservacion(true) }}>
                                                <EditIcon fontSize='small'></EditIcon>
                                            </IconButton></td>
                                        <td className='tabletd'>
                                            Observacion General 1
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='tabletd'>
                                            <IconButton onClick={() => { setmostrarModalObservacion(true) }}>
                                                <EditIcon fontSize='small'></EditIcon>
                                            </IconButton></td>
                                        <td className='tabletd'>
                                            Observacion General 2
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col> */}

                        <Col md={1} className='text-end'>
                            {/* <IconButton color='primary' onClick={() => { navigate(`/balance/detalle/${idbalance}`) }} >
                                <ArrowBackIcon ></ArrowBackIcon>
                            </IconButton> */}
                        </Col>
                    </Row>

                    <Row>
                        <Col className='text-end'>
                            <ButtonMUI variant="text"
                                onClick={AbrirMenu}>
                                Opciones
                                <KeyboardArrowDownIcon />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={objetoMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(objetoMenu)}
                                onClose={CerrarMenu}
                            >
                                <MenuItem onClick={() => { CerrarMenu(); MostrarModalHabilitarLectura('LECTURA_1') }} disabled={dataResumenMicrobalance.fechaLectura1 == '' ? false : true}>Habilitar Lectura 1</MenuItem>
                                <MenuItem onClick={() => { CerrarMenu(); MostrarModalHabilitarLectura('LECTURA_2') }} disabled={dataResumenMicrobalance.fechaLectura2 == '' && dataResumenMicrobalance.fechaLectura1==''? true : false}>Habilitar Lectura 2</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => { CerrarMenu(); AbrirModalAgregarTotalizadorTemporal() }} >Agregar Totalizador(s) Temporal(es)</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => { CerrarMenu(); setmostrarModalProgramarMicrobalance(true) }}>Finalizar</MenuItem>
                                {/* <MenuItem onClick={() => { CerrarMenu(); setmostrarModalProgramarMicrobalance(true) }}>Aprobar y Actualizar</MenuItem> */}
                            </Menu>
                        </Col>
                    </Row>

                </Container>

                <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650, minWidth: '100%' }}>
                    <Table size='small' >
                        <TableHead>
                            <TableRow >
                                <TableCell style={{ width: 100 }} className="header-colum-table">*</TableCell>
                                <TableCell style={{ width: 50 }} className="header-colum-table">ITEM</TableCell>
                                <TableCell style={{ width: 100 }} className="header-colum-table">Suministro</TableCell>
                                {/* <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Prim.</TableCell>
                                <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Sec.</TableCell> */}
                                <TableCell className="header-colum-table">Fac. Med.</TableCell>
                                <TableCell className="header-colum-table">Tarifa</TableCell>
                                <TableCell className="header-colum-table">Lect_1</TableCell>
                                <TableCell className="header-colum-table">Lect_2</TableCell>
                                <TableCell className="header-colum-table">Dif.</TableCell>
                                <TableCell className="header-colum-table">Obs.</TableCell>
                                <TableCell className="header-colum-table" style={{ backgroundColor: 'rgb(146,211,110)' }}>Proyección</TableCell>
                                <TableCell className="header-colum-table" style={{ backgroundColor: 'rgb(146,211,110)' }}>{dataResumenMicrobalance.periodoComercial}</TableCell>
                                <TableCell style={{ width: 80 }} className="header-colum-table" ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                listaTotalizadores.map((row, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                <Stack direction='horizontal'>
                                                    <IconButton onClick={() => { MostrarModalDetalleSuministroMicrobalance(row.id_suministro_microbalance) }}>
                                                        {/* <IconButton onClick={() => { setmostrarModalModificarSuministro(true) }}> */}
                                                        <EditIcon fontSize='small'></EditIcon>
                                                    </IconButton>
                                                    <IconButton onClick={() => { setmostrarResumenMicrobalance(true) }}>
                                                        <InsertChartIcon fontSize='small'></InsertChartIcon>
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                <ButtonMUI className='text-decoration-underline text-primary' >{row.item}</ButtonMUI>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>{row.codigo_suministro}</TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>{row.fact_medicion}</TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>{row.tarifa}</TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>{row.lectura_lectura1}</TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>{row.lectura_lectura2}</TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>{row.diferenciaLecturas}</TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>{`${row.observacionlectura1_lectura1};${row.observacionlectura1_lectura2}`}</TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>{row.proyeccion}</TableCell>
                                            <TableCell style={{ fontSize: '0.75em' }}>{row.cea_consumo_fact}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className='my-1 mt-3'>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={listaSuministros}
                            columns={columns} 
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            getCellClassName={(params) => {
                                if (params.colDef.field == "desviacion") {
                                    let porcentajePerdida = parseFloat(params.row.desviacion)
                                    if (!isNaN(porcentajePerdida)) {
                                        if (porcentajePerdida < 0) {
                                            return 'MUI-Cells-red'                                            
                                        } else{
                                            return 'MUI-Cells-black-normal'
                                        }
                                    }else{
                                        return 'MUI-Cells-red'
                                    }
                                }
                            }}
                            />
                    </div>
                </div>
            </div>



            {/* Modal Modificar Suministro */}
            <Modal
                show={mostrarModalModificarSuministro}
                onHide={() => { setmostrarModalModificarSuministro(false) }}
                keyboard={false}
                size='lg'
            >
                <Modal.Header>
                    <Modal.Title>
                        Detalle
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        {
                            detalleSuministroMicrobalance ?
                                <>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Suministro</small>
                                            <Form.Control defaultValue={detalleSuministroMicrobalance.codigo_suministro} disabled size='sm'></Form.Control>
                                        </Col>
                                        <Col >
                                            <small className='text-primary'>Tarifa</small>
                                            <Form.Control defaultValue={detalleSuministroMicrobalance.tarifa} disabled size='sm'></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Cliente</small>
                                            <Form.Control defaultValue={detalleSuministroMicrobalance.nombre_suministro} disabled size='sm'></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Direccion</small>
                                            <Form.Control defaultValue={detalleSuministroMicrobalance.direccion_suministro} disabled size='sm'></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col>
                                            <small className='text-primary'>Factor de Medicion</small>
                                            <Stack direction='horizontal' gap={2}>
                                                <Form.Control type='number' ref={inputFactorMedicion} size='sm' disabled={!accionModificarFactorMedicion} defaultValue={detalleSuministroMicrobalance.fact_medicion}></Form.Control>
                                                {
                                                    accionModificarFactorMedicion ?
                                                        <IconButton onClick={() => { ActualizarFactorMedicionSuministro() }}>
                                                            <SaveIcon fontSize='small' color='primary'></SaveIcon>
                                                        </IconButton>
                                                        :
                                                        <IconButton onClick={() => { setaccionModificarFactorMedicion(true) }}>
                                                            <EditIcon fontSize='small'></EditIcon>
                                                        </IconButton>
                                                }
                                            </Stack>
                                        </Col>

                                    </Row>
                                    <hr></hr>
                                </>
                                : <></>
                        }

                        {/* Lectura */}

                        <Row>
                            <Col sm={12} md={6} className="mb-2">
                                <Container >
                                    <Row>
                                        <Col>
                                            <Stack>
                                                <span className='text-primary'>Lectura 1</span>
                                                <small className='text-secondary' style={{ fontSize: '0.6em' }}>{
                                                    listaLectura1Suministro ? listaLectura1Suministro.estado : 'SIN PROGRAMAR'
                                                }</small>
                                            </Stack>
                                        </Col>
                                        <Col className='text-end'>
                                            {
                                                accionModificarLectura1 ?
                                                    <IconButton onClick={() => { }} disabled={listaLectura1Suministro ? false : true}>
                                                        <SaveIcon onClick={() => { ActualizarLectura1() }} fontSize='small' color='primary'></SaveIcon>
                                                    </IconButton>
                                                    :
                                                    <IconButton onClick={() => { setaccionModificarLectura1(true) }} disabled={listaLectura1Suministro ? false : true}>
                                                        <EditIcon fontSize='small'></EditIcon>
                                                    </IconButton>
                                            }
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col>
                                            <small className='text-primary'>Totalizador Asociado</small>
                                            <Form.Select ref={selectTotalizadorAsociado1} size="sm" defaultValue="" disabled={!accionModificarLectura1}>
                                                <option value="">Seleccione un Elemento</option>
                                                {
                                                    listaTotalizadoresSuministro.map((row, index) => {
                                                        return (
                                                            <option key={index} value={row.id_suministro_microbalance}>{row.nombre_suministro}</option>
                                                        )
                                                    })

                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col>
                                            <small className='text-primary'>Lectura</small>
                                            <Form.Control ref={inputLectura1} size='sm' disabled={!accionModificarLectura1}></Form.Control>
                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col>
                                            <small className='text-primary'>Observacion</small>
                                            <Form.Control ref={inputObservacion1} size='sm' disabled={!accionModificarLectura1}></Form.Control>
                                        </Col>
                                    </Row>

                                </Container>

                            </Col>
                            <Col sm={12} md={6}>
                                <Container >
                                    <Row>
                                        <Col>
                                            <Stack>
                                                <span className='text-primary'>Lectura 2</span>
                                                <small className='text-secondary' style={{ fontSize: '0.6em' }}>{
                                                    listaLectura2Suministro ? listaLectura2Suministro.estado : 'SIN PROGRAMAR'
                                                }</small>
                                            </Stack>
                                        </Col>
                                        <Col className='text-end'>
                                            {
                                                accionModificarLectura2 ?
                                                    <IconButton onClick={() => { ActualizarLectura2() }} disabled={listaLectura2Suministro ? false : true}>
                                                        <SaveIcon fontSize='small' color='primary'></SaveIcon>
                                                    </IconButton>
                                                    :
                                                    <IconButton onClick={() => { setaccionModificarLectura2(true) }} disabled={listaLectura2Suministro ? false : true}>
                                                        <EditIcon fontSize='small'></EditIcon>
                                                    </IconButton>
                                            }
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col>
                                            <small className='text-primary'>Totalizador Asociado</small>
                                            <Form.Select ref={selectTotalizadorAsociado2} size="sm" defaultValue="" disabled={!accionModificarLectura2}>
                                                <option value="">Seleccione un Elemento</option>
                                                {
                                                    listaTotalizadoresSuministro.map((row, index) => {
                                                        return (
                                                            <option key={index} value={row.id_suministro_microbalance}>{row.nombre_suministro}</option>
                                                        )
                                                    })

                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col>
                                            <small className='text-primary'>Lectura 2</small>
                                            <Form.Control ref={inputLectura2} size='sm' disabled={!accionModificarLectura2}></Form.Control>
                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col>
                                            <small className='text-primary'>Observacion</small>
                                            <Form.Control ref={inputObservacion2} size='sm' disabled={!accionModificarLectura2}></Form.Control>
                                        </Col>
                                    </Row>

                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Row className='mt-4'>
                        <Col className='text-end'>
                            <Button variant='outline-primary' onClick={() => { setmostrarModalModificarSuministro(false) }}>Cerrar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            {/* Modal Resumen Microbalance */}
            <Modal
                show={mostrarResumenMicrobalance}
                onHide={() => { setmostrarResumenMicrobalance(false) }}
                keyboard={false}
                size='xl'
            >
                <Modal.Header>
                    <Modal.Title>
                        Resumen SED 1199
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Tabs defaultActiveKey="mapa" className="mb-3">
                        {/* Resumen */}
                        <Tab eventKey="resumen" title="Resumen">
                            <Container>
                                <Row >
                                    <Col >
                                        <Container fluid>
                                            <Row className='mt-3'>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className='tabletd ' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px' }}>Fecha Lec. 1</td>
                                                            <td className='tabletd'></td>

                                                        </tr>
                                                        <tr>
                                                            <td className='tabletd' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px' }}>Fecha Lec. 2</td>
                                                            <td className='tabletd'></td>

                                                        </tr>
                                                        <tr >
                                                            <td className='tabletd' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px' }}>Periodo</td>
                                                            <td className='tabletd' style={{ backgroundColor: 'rgb(240,240,240)' }}></td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Row>
                                        </Container>
                                    </Col>
                                    <Col>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td className='tabletd'>CONS TOT</td>
                                                    <td className='tabletd'>25080</td>
                                                    <td className='fw-bold tabletd'>KWH</td>
                                                </tr>
                                                <tr>
                                                    <td className='tabletd'>CONS USU</td>
                                                    <td className='tabletd'>15950</td>
                                                    <td className='fw-bold tabletd'>KWH</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd'>DIF</td>
                                                    <td className='tabletd'>9130</td>
                                                    <td className='fw-bold tabletd'>KWH</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd'>% PERD</td>
                                                    <td style={{ color: 'red' }} className='tabletd'>36.41</td>
                                                    <td className='fw-bold tabletd'>%</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd'>#SUM</td>
                                                    <td className='tabletd'>540</td>
                                                    <td className='fw-bold tabletd'>Sum</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd'>%</td>
                                                    <td className='tabletd'>17</td>
                                                    <td className='fw-bold tabletd'>Kwh/Sum</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col md={5} className='align-self-center'>

                                        <table style={{ width: '100%' }}>
                                            <thead>
                                                <tr className='header-colum-table'>
                                                    <th className='tabletd'>*</th>
                                                    <th className='tabletd'>Obs.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='tabletd'>
                                                        <IconButton onClick={() => { setmostrarModalObservacion(true) }}>
                                                            <EditIcon fontSize='small'></EditIcon>
                                                        </IconButton></td>
                                                    <td className='tabletd'>
                                                        Observacion General 1
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='tabletd'>
                                                        <IconButton onClick={() => { setmostrarModalObservacion(true) }}>
                                                            <EditIcon fontSize='small'></EditIcon>
                                                        </IconButton></td>
                                                    <td className='tabletd'>
                                                        Observacion General 2
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        {/* Tabla totalizadores */}
                                        <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650, minWidth: '100%' }}>
                                            <Table size='small' >
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell style={{ width: 100 }} className="header-colum-table">*</TableCell>
                                                        <TableCell style={{ width: 50 }} className="header-colum-table">ITEM</TableCell>
                                                        <TableCell style={{ width: 100 }} className="header-colum-table">Suministro</TableCell>
                                                        <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Prim.</TableCell>
                                                        <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Sec.</TableCell>
                                                        <TableCell className="header-colum-table">Fac. Med.</TableCell>
                                                        <TableCell className="header-colum-table">Tarifa</TableCell>
                                                        <TableCell className="header-colum-table">Lect_1</TableCell>
                                                        <TableCell className="header-colum-table">Lect_2</TableCell>
                                                        <TableCell className="header-colum-table">Dif.</TableCell>
                                                        <TableCell className="header-colum-table">Obs.</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow >
                                                        <TableCell style={{ fontSize: '0.75em' }}>
                                                            <Stack direction='horizontal'>
                                                                <IconButton onClick={() => { setmostrarModalModificarSuministro(true) }}>
                                                                    <EditIcon fontSize='small'></EditIcon>
                                                                </IconButton>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}>
                                                            <ButtonMUI className='text-decoration-underline text-primary' >TOT</ButtonMUI>
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}>469365</TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}></TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}></TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}></TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}>BT5H</TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}></TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}></TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}></TableCell>
                                                        <TableCell style={{ fontSize: '0.75em' }}></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Tabla Suministros asociados a SED y Totalizador */}
                                        <div className='my-1 mt-3'>
                                            <div style={{ height: 400, width: '100%' }}>
                                                <DataGrid density='compact' rows={rows} columns={columns} />
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        {/* Mapa */}
                        <Tab eventKey="mapa" title="Mapa">
                            <MapContainer center={{
                                lat: -16.398821,
                                lng: -71.536862
                            }} zoom={17} style={{ width: "100%", height: "70vh" }}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker title="" position={[-16.399505, -71.536597]} icon={Pin2}></Marker>
                                <Marker title="" position={[-16.399542, -71.536466]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399609, -71.536419]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399629164465058, -71.53635848225495]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399662614576382, -71.5362646049461]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.39969220504745, -71.53615262269898]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399737234031424, -71.53604466380524]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399741093631032, -71.53594408097196]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399757175438825, -71.53589580119926]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399836940994387, -71.53578650117552]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399843373713647, -71.53572816314227]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.39985688235894, -71.5356409913367]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399878110294267, -71.53564568518858]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399883256404987, -71.53557460661682]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.39983794600611, -71.5355462769364]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.39976450750399, -71.5355367077917]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.39970789867012, -71.53551916438592]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399652819811312, -71.5354840776073]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.39960845073475, -71.53547610334839]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399548781976456, -71.53544420630834]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399515122679507, -71.5354282577974]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399415674750575, -71.53538360200044]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399366715781238, -71.53538360203183]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.39925043818675, -71.53532778234619]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399195359319748, -71.5353070493454]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399121920813847, -71.53527515242493]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.399069901860656, -71.53525282459287]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.39895362417668, -71.53521135865884]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.398912314968957, -71.53518743598376]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.39890313508256, -71.53515234933494]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.398863355857735, -71.53514278030056]} icon={Pin1}></Marker>
                                <Marker title="" position={[-16.398896376884057, -71.53509468108119]} icon={Pin1}></Marker>
                            </MapContainer>
                        </Tab>
                    </Tabs>
                    <Row className='mt-2 text-end'>
                        <Col>
                            <Button variant='primary'>Cerrar</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Totalizador */}
            <Modal
                show={mostrarModalAgregarTotalizador}
                onHide={() => { setmostrarModalAgregarTotalizador(false) }}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Totalizador Temporal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row >
                            <Col>
                                <small className='text-primary'>Codigo Suministro</small>
                                <Form.Control ref={inputSuministroAgregarTemporal} size='sm' placeholder='Ingrese el Suministro' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 !=''? true : false}></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className='text-primary'>Factor de Medicion</small>
                                <Stack direction='horizontal' gap={2}>
                                    <Form.Control ref={inputFactorMedicionAgregarTemporal} size='sm' placeholder='Ingrese el Factor de Medicion' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 !=''? true : false}></Form.Control>
                                    <Tooltip title="Agregar">
                                        <IconButton onClick={() => { AgregarTotalizadorTemporal() }} disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 !=''? true : false}>
                                            <AddIcon></AddIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row className='mt-2'>
                            <Col>
                                <TableContainer style={{ maxHeight: 200, minWidth: '100%' }} >
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell className="header-colum-table">Accion</TableCell>
                                                <TableCell className="header-colum-table">Totalizador</TableCell>
                                                <TableCell className="header-colum-table">Obs.</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                listaTotalizadoresTemporales.map((row) => {
                                                    return (
                                                        <TableRow key={row.id_suministro_microbalance}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <Stack direction='horizontal'>
                                                                    {
                                                                        row.estado == "ACTIVO" ?
                                                                            <Form.Check onClick={() => { ActualzarEstadoSuministroMicrobalance(row.id_suministro_microbalance, 'INACTIVO') }} defaultChecked type="switch" className='mx-2' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 !=''? true : false}/>
                                                                            : <Form.Check onClick={() => { ActualzarEstadoSuministroMicrobalance(row.id_suministro_microbalance, 'ACTIVO') }} type="switch" className='mx-2' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 !=''? true : false}/>

                                                                    }
                                                                    <Tooltip title="Eliminar Elemento">
                                                                        <IconButton onClick={() => { EliminarSuministroMicrobalance(row.id_suministro_microbalance) }} className='mx-2' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 !=''? true : false}>
                                                                            <DeleteIcon fontSize='small'></DeleteIcon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.codigo_suministro}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.tipo}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>                                
                            </Col>
                            {
                                dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 !=''? 
                                <Row>
                                    <Col>
                                        <small className='text-muted'>
                                            No se puede agregar y/o modificar los datos registrados si ya se encuentra generado una programacion de lectura.
                                        </small>
                                    </Col>
                                </Row>
                                 : <></>
                            }
                        </Row>
                        <Row className='mt-4'>
                            <Col className='text-end'>
                                <Button size='sm' variant='outline-primary' onClick={() => { setmostrarModalAgregarTotalizador(false) }}>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Habilitar Lecturas */}
            <Modal
                show={mostrarModalHabilitarLectura}
                onHide={() => { setmostrarModalHabilitarLectura(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Habilitar Lectura
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <small className='text-primary'>Tipo Lectura</small>
                                <Form.Control ref={inputTipoProgramacionLectura} size='sm' disabled></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className='text-primary'>Elegir Fecha Lectura</small>
                                <Form.Control ref={inputFechaProgramacionLectura} size='sm' type='date'></Form.Control>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button variant='outline-primary' size='sm' onClick={() => { setmostrarModalHabilitarLectura(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button variant='primary' size='sm' onClick={() => { ProgramarLectura() }}>Aceptar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Observaciones */}
            <Modal
                show={mostrarModalObservaciones}
                onHide={() => { setmostrarModalObservaciones(false) }}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Observaciones
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row >
                            <Col>
                                <small className='text-primary'>Observación</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Stack direction='horizontal' gap={2}>
                                    <Form.Control size='sm' placeholder='Observación'></Form.Control>
                                    <IconButton>
                                        <AddIcon></AddIcon>
                                    </IconButton>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <TableContainer style={{ maxHeight: 200, minWidth: '100%' }} >
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell className="" style={{ width: '80px' }}>Accion</TableCell>
                                                <TableCell className="">Observación</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell style={{ fontSize: '0.75em' }}>
                                                    <Stack direction='horizontal'>

                                                        <IconButton className='mx-2'>
                                                            <EditIcon fontSize='small'></EditIcon>
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '0.75em' }}>Observacion General 1</TableCell>

                                            </TableRow>
                                            <TableRow >
                                                <TableCell style={{ fontSize: '0.75em' }}>
                                                    <Stack direction='horizontal'>
                                                        <IconButton className='mx-2'>
                                                            <EditIcon fontSize='small'></EditIcon>
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '0.75em' }}>Observacion General 1</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button size='sm' variant='outline-primary' onClick={() => { setmostrarModalObservaciones(false) }}>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


        </Fragment>
    );
}

export default CalculoDetalleMicrobalance;