import React, { Fragment, useEffect, useState, useRef } from 'react';
import Header from '../../dashboard/Header'

import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Modal, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle,Tooltip, Badge } from '@mui/material';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EngineeringIcon from '@mui/icons-material/Engineering';

//Autocomplete
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//Mapa Leaflet
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Pin1, PinRojo, PinVerde, PinAmarillo, PinAzul, PinNego } from '../../../config/iconos'
import 'leaflet/dist/leaflet.css';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

//Importaciones
import PaginaCarga from '../../../config/PaginaCarga'
import { ValidarUsuario, ObtenerUsuario, config, useWindowSize, GuardarEnStorage, ObtenerFiltros, ValidarPermisos, LIST_SCREENS, listarPermisos } from '../../../config/utils'


function ListaBalancesMapa(props) {

    const navigate = useNavigate();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    //Modal
    const [mostrarModalFiltro, setmostrarModalFiltro] = useState(false);
    const [mostrarModalAgregarSuministros, setmostrarModalAgregarSuministros] = useState(false);
    const [mostrarModalMensajeExcedente, setmostrarModalMensajeExcedente] = useState(false);

    //toast y progress Bar
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [mostrarBarraProgreso, setmostrarBarraProgreso] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const size = useWindowSize();
    //
    const [dataBalances, setdataBalances] = useState([]);
    const [cantidadMaximaRegistros, setcantidadMaximaRegistros] = useState(1000);

    //matrices Filtros Seleccionados
    const [matrizCriterios, setmatrizCriterios] = useState([]);
    const [matrizPeriodosSeleccionados, setmatrizPeriodosSeleccionados] = useState([]);
    const [matrizSetSeleccionados, setmatrizSetSeleccionados] = useState([]);
    const [matrizAlimentadorSeleccionados, setmatrizAlimentadorSeleccionados] = useState([]);
    const [matrizSedSeleccionados, setmatrizSedSeleccionados] = useState([]);
    const [cambiarMicrobalancesProgramados, setcambiarMicrobalancesProgramados] = useState(false);

    //
    const [cadenaPeriodosSeleccionados, setcadenaPeriodosSeleccionados] = useState([]);
    const [cadenaSetSeleccionados, setcadenaSetSeleccionados] = useState([]);
    const [cadenaAlimenadorSeleccionados, setcadenAlimentadorSeleccionados] = useState([]);
    const [cadenaSedSeleccionados, setcadenaSedSeleccionados] = useState([]);

    //Activar/Desactivar Filtros
    const [mostrarFiltroPeriodo, setMostrarFiltroPeriodo] = useState(false);
    const [mostrarFiltroSET, setMostrarFiltroSET] = useState(false);
    const [mostrarFiltroAlimentador, setMostrarFiltroAlimentador] = useState(false);
    const [mostrarFiltroSed, setMostrarFiltroSed] = useState();
    const [mostrarFiltroPorcentajePerdida, setMostrarFiltroPorcentajePerdida] = useState(false);
    const [mostrarFiltroKwhPerdida, setMostrarFiltroKwhPerdida] = useState(false);

    //filtros Activos
    const [filtroPeriodoActivo, setfiltroPeriodoActivo] = useState(false);
    const [filtroSetActivo, setfiltroSetActivo] = useState(false);
    const [filtroAlimnentadorActivo, setfiltroAlimnentadorActivo] = useState(false);
    const [filtroSedActivo, setfiltroSedActivo] = useState(false);
    const [filtroPorcentajePerdidaActivo, setfiltroPorcentajePerdidaActivo] = useState(false);
    const [filtroKwhPerdidaActivo, setfiltroKwhPerdidaActivo] = useState(false);

    //
    const [valorFiltroPeriodo, setvalorFiltroPeriodo] = useState([]);
    const [valorFiltroSet, setvalorFiltroSet] = useState([]);
    const [valorFiltroAlimentador, setvalorFiltroAlimentador] = useState([]);
    const [valorFiltroSED, setvalorFiltroSED] = useState([]);
    const [habilitarMensajeSed, sethabilitarMensajeSed] = useState(false);

    const [valorSelectPorcentajePerdida, setvalorSelectPorcentajePerdida] = useState('');
    const [valorSelectKwhPerdida, setvalorSelectKwhPerdida] = useState('');
    const [valorInputPorcntajePerdida, setvalorInputPorcntajePerdida] = useState(null);
    const [valorInputKwhPerdida, setvalorInputKwhPerdida] = useState(null);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const inputBuscar = useRef();
    const inputListaBuscar = useRef();

    const selectFiltroPorcentajePerdida = useRef();
    const selectFiltrokwhPerdida = useRef();
    const inputValorPorcentajePerdida = useRef();
    const inputValorKwhPerdida = useRef();

    useEffect(() => {
        ValidarAccesos();
    }, []);
    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then(async (permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_LISTA_MAPA, permisos)) {
                    await ValidarFiltroExistente().then(async (respuesta) => {
                        // console.log(respuesta);

                        await ValidarCriterios(null, respuesta.filtro);
                        // BuscarBalances('');
                        ListarBalances(respuesta);
                        BuscarListaPeriodosComercialesBalance();
                    });

                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }


    const ListarBalances = (listaArrayFiltros) => {
        let PeriodoActivoTemp = false;
        let SetActivoTemp = false;
        let AlimentadorActivoTemp = false;
        let SedActivoTemp = false;
        let PorcentajePerdidaActivoTemp = false;
        let KwhPerdidaActivo = false;

        let valorInputPorcntajePerdidaTemp = '';
        let valorSelectPorcentajePerdidaTemp = '';
        let valorInputKwhPerdidaTemp = '';
        let valorSelectKwhPerdidaTemp = '';
        let valorMostrarMicrobalanceTemp = false;
        //Validar estado de Filtros
        if (listaArrayFiltros) {
            PeriodoActivoTemp = listaArrayFiltros.periodo.length > 0 ? true : false;
            SetActivoTemp = listaArrayFiltros.set.length > 0 ? true : false;
            AlimentadorActivoTemp = listaArrayFiltros.alimentador.length > 0 ? true : false;
            SedActivoTemp = listaArrayFiltros.sed.length > 0 ? true : false;
            PorcentajePerdidaActivoTemp = listaArrayFiltros.filtro_porc_perdida !== '' ? true : false;
            KwhPerdidaActivo = listaArrayFiltros.filtro_kwh_perdida !== '' ? true : false;

            valorInputPorcntajePerdidaTemp = listaArrayFiltros.valor_por_perdida;
            valorSelectPorcentajePerdidaTemp = listaArrayFiltros.filtro_porc_perdida;
            valorInputKwhPerdidaTemp = listaArrayFiltros.valor_kwh_perdida;
            valorSelectKwhPerdidaTemp = listaArrayFiltros.filtro_kwh_perdida;
            valorMostrarMicrobalanceTemp = listaArrayFiltros.valor_mostrar_microbalances;
        } else {
            PeriodoActivoTemp = filtroPeriodoActivo;
            SetActivoTemp = filtroSetActivo;
            AlimentadorActivoTemp = filtroAlimnentadorActivo;
            SedActivoTemp = filtroSedActivo;
            PorcentajePerdidaActivoTemp = filtroPorcentajePerdidaActivo;
            KwhPerdidaActivo = filtroKwhPerdidaActivo;
            valorInputPorcntajePerdidaTemp = valorInputPorcntajePerdida;
            valorSelectPorcentajePerdidaTemp = valorSelectPorcentajePerdida;
            valorInputKwhPerdidaTemp = valorInputKwhPerdida;
            valorSelectKwhPerdidaTemp = valorSelectKwhPerdida;
            valorMostrarMicrobalanceTemp = cambiarMicrobalancesProgramados;
        }

        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/balance/ListarBalance`;

            let listaPeriodos = "";
            let listaSet = "";
            let listaAlimentador = "";
            let listaSed = "";

            if (PeriodoActivoTemp) {

                let arrayPeriodos = [];
                if (listaArrayFiltros) {
                    listaArrayFiltros.periodo.forEach(element => {
                        arrayPeriodos.push(element.mes_proceso)
                    });
                } else {
                    matrizPeriodosSeleccionados.forEach(element => {
                        arrayPeriodos.push(element.mes_proceso)
                    });
                }
                listaPeriodos = arrayPeriodos.toString();
            }

            if (SetActivoTemp) {

                let arraySet = [];
                if (listaArrayFiltros) {
                    listaArrayFiltros.set.forEach(element => {
                        arraySet.push(element.set_nombre)
                    });
                } else {
                    matrizSetSeleccionados.forEach(element => {
                        arraySet.push(element.set_nombre)
                    });
                }
                listaSet = arraySet.toString();
            }

            if (AlimentadorActivoTemp) {

                let arrayAlimentador = [];
                if (listaArrayFiltros) {
                    listaArrayFiltros.alimentador.forEach(element => {
                        arrayAlimentador.push(element.all_nombre)
                    });
                } else {
                    matrizAlimentadorSeleccionados.forEach(element => {
                        arrayAlimentador.push(element.all_nombre)
                    });
                }
                listaAlimentador = arrayAlimentador.toString();
            }

            if (SedActivoTemp) {

                let arraySed = [];
                if (listaArrayFiltros) {
                    listaArrayFiltros.sed.forEach(element => {
                        arraySed.push(element.sed_codigo)
                    });
                } else {
                    matrizSedSeleccionados.forEach(element => {
                        arraySed.push(element.sed_codigo)
                    });
                }
                listaSed = arraySed.toString();
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    listaPeriodos: listaPeriodos,
                    listaSet: listaSet,
                    listaAlimentador: listaAlimentador,
                    listaSed: listaSed,
                    mostrarmicrobalance: valorMostrarMicrobalanceTemp ? 1 : 0
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                let arrayData = [];
                let arrayRespuesta = data.content;
                let respuestaFiltro = [];
                let filtroEfectuado = false;

                //Si Modal Filtro esta Activo
                if (mostrarModalFiltro) {
                    //Filtrar % Perdida o Kw Perdida
                    if (PorcentajePerdidaActivoTemp) {
                        // console.log(valorSelectPorcentajePerdida);
                        // console.log(selectFiltroPorcentajePerdida.current.value);
                        let valorPorcentajeperdida = parseFloat(inputValorPorcentajePerdida.current.value);

                        if (!isNaN(valorPorcentajeperdida)) {
                            switch (valorSelectPorcentajePerdida) {
                                case 'MAYOR_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x > valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });
                                    filtroEfectuado = true;
                                    break;
                                case 'MAYOR_IGUAL_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x >= valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });
                                    filtroEfectuado = true;

                                    break;
                                case 'MENOR_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x < valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        } else {
                                            respuestaFiltro.push(element);
                                        }
                                    });
                                    filtroEfectuado = true;

                                    break;
                                case 'MENOR_IGUAL_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x <= valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        } else {
                                            respuestaFiltro.push(element);
                                        }
                                    });
                                    filtroEfectuado = true;

                                    break;
                                case 'IGUAL_A':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x == valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });
                                    filtroEfectuado = true;
                                    break;
                            }

                        }
                        else {
                            console.log("No se pudo realizar filtro");
                            console.log(valorPorcentajeperdida);
                        }

                    }

                    if (KwhPerdidaActivo) {

                        //console.log(selectFiltrokwhPerdida.current.value);
                        let valorKwperdida = parseFloat(inputValorKwhPerdida.current.value);
                        if (!isNaN(valorKwperdida)) {
                            //Filtrar
                            switch (valorSelectKwhPerdida) {
                                case 'MAYOR_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x > valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    //arrayRespuesta=respuestaFiltro;
                                    break;
                                case 'MAYOR_IGUAL_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x >= valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    break;
                                case 'MENOR_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x < valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    break;
                                case 'MENOR_IGUAL_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x <= valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    break;
                                case 'IGUAL_A':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x == valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    break;
                            }

                        } else {
                            console.log("No se pudo realizar filtro");
                            console.log(valorKwperdida);
                        }

                    }
                } else {

                    if (PorcentajePerdidaActivoTemp) {

                        let valorPorcentajeperdida = parseFloat(valorInputPorcntajePerdidaTemp);
                        if (!isNaN(valorPorcentajeperdida)) {
                            //Filtrar
                            switch (valorSelectPorcentajePerdidaTemp) {
                                case 'MAYOR_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x > valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;

                                    break;
                                case 'MAYOR_IGUAL_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x >= valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;

                                    break;
                                case 'MENOR_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x < valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                        else {
                                            respuestaFiltro.push(element);
                                        }
                                    });

                                    filtroEfectuado = true;

                                    break;
                                case 'MENOR_IGUAL_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x <= valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                        else {
                                            respuestaFiltro.push(element);
                                        }
                                    });

                                    filtroEfectuado = true;

                                    break;
                                case 'IGUAL_A':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.porcentaje);
                                        if (!isNaN(x)) {
                                            if (x == valorPorcentajeperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;

                                    break;
                            }

                        } else {
                            console.log("No se pudo realizar filtro");
                            console.log(inputValorKwhPerdida);
                        }

                    }

                    if (KwhPerdidaActivo) {

                        let valorKwperdida = parseFloat(valorInputKwhPerdidaTemp);
                        if (!isNaN(valorKwperdida)) {
                            //Filtrar
                            switch (valorSelectKwhPerdidaTemp) {
                                case 'MAYOR_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x > valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    //arrayRespuesta=respuestaFiltro;
                                    break;
                                case 'MAYOR_IGUAL_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x >= valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    break;
                                case 'MENOR_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x < valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    break;
                                case 'MENOR_IGUAL_QUE':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x <= valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    break;
                                case 'IGUAL_A':
                                    arrayRespuesta.forEach(element => {
                                        let x = parseFloat(element.kwh_perdida);
                                        if (!isNaN(x)) {
                                            if (x == valorKwperdida) {
                                                respuestaFiltro.push(element);
                                            }
                                        }
                                    });

                                    filtroEfectuado = true;
                                    break;
                            }

                        } else {
                            console.log("No se pudo realizar filtro");
                            console.log(valorKwperdida);
                        }
                    }
                }

                //Verificar si se efectuo Filtro de %perdida o kwhPerdida
                if (filtroEfectuado) {
                    arrayRespuesta = respuestaFiltro;
                }

                //Formatear Valores Recibidos
                arrayRespuesta.forEach(elemento => {

                    let porcentajePerdida = parseFloat(elemento.porcentaje_perdida);
                    let colorFont = 'MUI-Cells-black'
                    if (isNaN(porcentajePerdida)) {
                        porcentajePerdida = elemento.porcentaje_perdida;
                    } else {
                        // porcentajePerdida=Math.round(porcentajePerdida,-2);
                        if (porcentajePerdida >= 0) {

                            if (porcentajePerdida < 7) {
                                colorFont = 'MUI-Cells-green';
                            } else {
                                if (porcentajePerdida >= 7 && porcentajePerdida < 12) {
                                    colorFont = 'MUI-Cells-yellow'
                                } else {
                                    colorFont = 'MUI-Cells-red'
                                }
                            }
                        } else {
                            colorFont = 'MUI-Cells-black'
                        }
                        porcentajePerdida = porcentajePerdida.toFixed(3);
                    }

                    let porcentaje = parseFloat(elemento.porcentaje);

                    if (!isNaN(porcentaje)) {
                        porcentaje = porcentaje.toFixed(3);
                    }
                    let _elemento = {
                        id: elemento.id_calculo_balance,
                        accion: '',
                        id_base_sed: elemento.id_base_sed,
                        sed_codigo: elemento.sed_codigo,
                        cant_tt: elemento.cant_tt,
                        all_nombre: elemento.all_nombre,
                        set_nombre: elemento.set_nombre,
                        sed_direccion: elemento.sed_direccion,
                        sed_particular: elemento.sed_particular,
                        sed_tipo: elemento.sed_tipo,
                        sed_usuarios: elemento.sed_usuarios,
                        cantidad_suministros: elemento.cantidad_suministros,
                        suma_totalizador: elemento.suma_totalizador,
                        suma_comun: elemento.suma_comun,
                        suma_ap: elemento.suma_ap,
                        suma_gi: elemento.suma_gi,
                        observacion: elemento.observacion,
                        envio: elemento.envio,
                        kwh_perdida: elemento.kwh_perdida,
                        porcentaje: porcentaje,
                        porcentaje_perdida: porcentajePerdida,
                        mes_proceso: elemento.mes_proceso,
                        balance_casa_x_casa: elemento.balance_casa_x_casa,
                        dias_facturados: elemento.dias_facturados,
                        lat: elemento.lat,
                        lng: elemento.lng,
                        colorFont: colorFont,
                        MicroBalancesProgramados: elemento.MicroBalancesProgramados,
                        MicroBalancesFinalizados: elemento.MicroBalancesFinalizados
                    };
                    arrayData.push(_elemento)
                });

                setmostrarModalFiltro(false);
                setdataBalances(arrayData);
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }

    }
    const ValidarFiltroExistente = async () => {
        return new Promise(async (resolve, reject) => {
            let valorFiltros = ObtenerFiltros();
            if (valorFiltros) {

                setmatrizCriterios(valorFiltros.filtro);

                setmatrizPeriodosSeleccionados(valorFiltros.periodo);
                let matrizPeriodoTemp = [];
                valorFiltros.periodo.forEach(element => {
                    matrizPeriodoTemp.push(element.mes_proceso);
                });
                setcadenaPeriodosSeleccionados(matrizPeriodoTemp);


                setmatrizSetSeleccionados(valorFiltros.set);
                let matrizSetTemp = [];
                valorFiltros.set.forEach(element => {
                    matrizSetTemp.push(element.set_nombre);
                });
                setcadenaSetSeleccionados(matrizSetTemp);

                setmatrizAlimentadorSeleccionados(valorFiltros.alimentador);
                let matrizAlimTemp = [];
                valorFiltros.alimentador.forEach(element => {
                    matrizAlimTemp.push(element.all_nombre);
                });
                setcadenAlimentadorSeleccionados(matrizAlimTemp);

                setmatrizSedSeleccionados(valorFiltros.sed);
                let matrizSedTemp = [];
                for (let x = 0; x < valorFiltros.sed.length; x++) {
                    matrizSedTemp.push(valorFiltros.sed[x].sed_codigo);
                }
                setcadenaSedSeleccionados(matrizSedTemp);

                setvalorSelectPorcentajePerdida(valorFiltros.filtro_porc_perdida);
                setvalorInputPorcntajePerdida(valorFiltros.valor_por_perdida);
                setvalorSelectKwhPerdida(valorFiltros.filtro_kwh_perdida);
                setvalorInputKwhPerdida(valorFiltros.valor_kwh_perdida);
                setcambiarMicrobalancesProgramados(valorFiltros.valor_mostrar_microbalances);
            }
            // console.log(valorFiltros);
            resolve(valorFiltros);
        });
    }
    const ValidarCriterios = (event, criterios) => {
        return new Promise((resolve, reject) => {
            //Periodo
            const busquedaPeriodo = criterios.find(x => x.criterio == 'Periodo')
            if (busquedaPeriodo) {
                if (!filtroPeriodoActivo) {
                    setfiltroPeriodoActivo(true);
                    BuscarListaPeriodosComercialesBalance();
                    setMostrarFiltroPeriodo(true);
                }
            } else {
                setfiltroPeriodoActivo(false);
                setMostrarFiltroPeriodo(false);
                setmatrizPeriodosSeleccionados([]);

                let valorFiltros = ObtenerFiltros();
                valorFiltros['periodo'] = [];
                GuardarEnStorage(config.storageFiltros, valorFiltros);
            }

            //SET
            const busquedaSet = criterios.find(x => x.criterio == 'SET_Nombre')
            if (busquedaSet) {
                if (!filtroSetActivo) {
                    setfiltroSetActivo(true);
                    setMostrarFiltroSET(true);
                    BuscarListaSetBalance(matrizAlimentadorSeleccionados);
                }
                // BuscarListadosFiltro('SET_Nombre');            
            } else {
                setfiltroSetActivo(false);
                setMostrarFiltroSET(false);
                setmatrizSetSeleccionados([]);
                if (filtroAlimnentadorActivo) {
                    BuscarListaAlimentadorBalance([]);
                }

                let valorFiltros = ObtenerFiltros();
                valorFiltros['set'] = [];
                GuardarEnStorage(config.storageFiltros, valorFiltros);
            }

            //Alimentador
            const busquedaAlimnetador = criterios.find(x => x.criterio == 'ALL_Nombre')

            if (busquedaAlimnetador) {
                if (!filtroAlimnentadorActivo) {

                    setfiltroAlimnentadorActivo(true);
                    setMostrarFiltroAlimentador(true);
                    BuscarListaAlimentadorBalance(matrizSetSeleccionados);
                }
                // BuscarListadosFiltro('ALL_Nombre');
            } else {
                setfiltroAlimnentadorActivo(false);
                setMostrarFiltroAlimentador(false);
                setmatrizAlimentadorSeleccionados([]);
                if (filtroSetActivo) {
                    BuscarListaSetBalance([]);
                }

                let valorFiltros = ObtenerFiltros();
                valorFiltros['alimentador'] = [];
                GuardarEnStorage(config.storageFiltros, valorFiltros);
            }

            //SED
            const busquedaSed = criterios.find(x => x.criterio == 'SED_Codigo')
            if (busquedaSed) {
                if (!filtroSedActivo) {

                    setfiltroSedActivo(true);
                    setMostrarFiltroSed(true);
                }

                if (filtroSetActivo || filtroAlimnentadorActivo) {
                    BuscarListaSedBalance(matrizSetSeleccionados, matrizAlimentadorSeleccionados);
                }
            } else {
                setfiltroSedActivo(false);
                setMostrarFiltroSed(false)
                setmatrizSedSeleccionados([]);

                let valorFiltros = ObtenerFiltros();
                valorFiltros['sed'] = [];
                GuardarEnStorage(config.storageFiltros, valorFiltros);
            }

            //Porcentaje Perdida
            const busquedaPorcentajePerdida = criterios.find(x => x.criterio == 'Porcentaje_Perdida')
            if (busquedaPorcentajePerdida) {

                if (!filtroPorcentajePerdidaActivo) {

                    setfiltroPorcentajePerdidaActivo(true);
                    setMostrarFiltroPorcentajePerdida(true);
                }
                if (filtroKwhPerdidaActivo) {
                    setfiltroKwhPerdidaActivo(false);
                    setMostrarFiltroKwhPerdida(false);
                    let criterioFiltrado = criterios.filter(x => x.criterio != 'KWH_Perdida');
                    criterios = criterioFiltrado;
                }

            } else {
                setfiltroPorcentajePerdidaActivo(false);
                setMostrarFiltroPorcentajePerdida(false)

                setvalorSelectPorcentajePerdida('');
                setvalorInputPorcntajePerdida(null);

                let valorFiltros = ObtenerFiltros();
                valorFiltros['filtro_porc_perdida'] = '';
                valorFiltros['valor_por_perdida'] = null;
                GuardarEnStorage(config.storageFiltros, valorFiltros);
            }

            //Kwh Perdida
            const busquedaKwhPerdida = criterios.find(x => x.criterio == 'KWH_Perdida')
            if (busquedaKwhPerdida) {
                if (!filtroKwhPerdidaActivo) {

                    setfiltroKwhPerdidaActivo(true);
                    setMostrarFiltroKwhPerdida(true);
                }
                if (filtroPorcentajePerdidaActivo) {
                    setfiltroPorcentajePerdidaActivo(false);
                    setMostrarFiltroPorcentajePerdida(false);
                    let criterioFiltrado = criterios.filter(x => x.criterio != 'Porcentaje_Perdida');
                    criterios = criterioFiltrado;
                }
            } else {
                setfiltroKwhPerdidaActivo(false);
                setMostrarFiltroKwhPerdida(false)

                setvalorSelectKwhPerdida('');
                setvalorInputKwhPerdida(null);

                let valorFiltros = ObtenerFiltros();
                valorFiltros['filtro_kwh_perdida'] = '';
                valorFiltros['valor_kwh_perdida'] = null;
                GuardarEnStorage(config.storageFiltros, valorFiltros);
            }

            if (criterios) {
                let valorFiltros = ObtenerFiltros();
                valorFiltros['filtro'] = criterios;
                GuardarEnStorage(config.storageFiltros, valorFiltros);
                setmatrizCriterios(criterios);
            }
            resolve(true);
        });
    }
    const criteriosFiltro = [
        { criterio: 'Periodo' },
        { criterio: 'SET_Nombre' },
        { criterio: 'ALL_Nombre' },
        { criterio: 'SED_Codigo' },
        { criterio: 'Porcentaje_Perdida' },
        { criterio: "KWH_Perdida" },
    ];
    const BuscarBalances = (TIPO_BUSQUEDA) => {

        if (TIPO_BUSQUEDA == "FILTRO") {
            ListarBalances();
            inputBuscar.current.value = '';
        } else {
            //Limpiar 
            if (inputBuscar.current.value.trim() != '') {
                ValidarCriterios(null, []);
                ListarBalancesBusqueda();
            } else {
                ListarBalances();
            }
        }

    }
    const ListarBalancesBusqueda = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/balance/ListarBalance`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    listaPeriodos: '',
                    listaSet: '',
                    listaAlimentador: '',
                    listaSed: inputBuscar.current.value,
                    mostrarmicrobalance: 0
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                let arrayData = [];
                let arrayRespuesta = data.content;

                //Formatear Valores Recibidos
                arrayRespuesta.forEach(elemento => {

                    let porcentajePerdida = parseFloat(elemento.porcentaje_perdida);
                    let colorFont = 'MUI-Cells-black'
                    if (isNaN(porcentajePerdida)) {

                        porcentajePerdida = elemento.porcentaje_perdida;
                    } else {
                        // porcentajePerdida=Math.round(porcentajePerdida,-2);
                        if (porcentajePerdida > 0) {

                            if (porcentajePerdida < 7) {
                                colorFont = 'MUI-Cells-green';
                            } else {
                                if (porcentajePerdida > 7 && porcentajePerdida < 12) {
                                    colorFont = 'MUI-Cells-yellow'
                                } else {
                                    colorFont = 'MUI-Cells-red'
                                }
                            }
                        } else {
                            colorFont = 'MUI-Cells-black'
                        }
                        porcentajePerdida = porcentajePerdida.toFixed(3);
                    }

                    let porcentaje = parseFloat(elemento.porcentaje);

                    if (!isNaN(porcentaje)) {
                        porcentaje = porcentaje.toFixed(3);
                    }
                    let _elemento = {
                        id: elemento.id_calculo_balance,
                        accion: '',
                        id_base_sed: elemento.id_base_sed,
                        sed_codigo: elemento.sed_codigo,
                        cant_tt: elemento.cant_tt,
                        all_nombre: elemento.all_nombre,
                        set_nombre: elemento.set_nombre,
                        sed_direccion: elemento.sed_direccion,
                        sed_particular: elemento.sed_particular,
                        sed_tipo: elemento.sed_tipo,
                        sed_usuarios: elemento.sed_usuarios,
                        cantidad_suministros: elemento.cantidad_suministros,
                        suma_totalizador: elemento.suma_totalizador,
                        suma_comun: elemento.suma_comun,
                        suma_ap: elemento.suma_ap,
                        suma_gi: elemento.suma_gi,
                        observacion: elemento.observacion,
                        envio: elemento.envio,
                        kwh_perdida: elemento.kwh_perdida,
                        porcentaje: porcentaje,
                        porcentaje_perdida: porcentajePerdida,
                        mes_proceso: elemento.mes_proceso,
                        balance_casa_x_casa: elemento.balance_casa_x_casa,
                        dias_facturados: elemento.dias_facturados,
                        lat: elemento.lat,
                        lng: elemento.lng,
                        colorFont: colorFont
                    };

                    arrayData.push(_elemento)
                });

                setmostrarModalFiltro(false);
                setdataBalances(arrayData);
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }

    }

    // Filtros
    // Buscar Lista Periodos Balance
    const BuscarListaPeriodosComercialesBalance = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/balance/ListaPeriodosBalance`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({})
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {

                setvalorFiltroPeriodo(data.content)
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const BuscarListaSetBalance = (arrayValores) => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {

            let arrayAlimentador = [];
            arrayValores.forEach(element => {
                arrayAlimentador.push(element.all_nombre)
            });

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/balance/ListaSetBalance`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ arrayAlimentador: arrayAlimentador.toString() })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                setvalorFiltroSet(data.content)
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const BuscarListaAlimentadorBalance = (arrayValores) => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {

            setmostrarCircularProgress(true);

            let arraySet = [];
            arrayValores.forEach(element => {
                arraySet.push(element.set_nombre)
            });


            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/balance/ListaAlimentadorBalance`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ listaSet: arraySet.toString() })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                setvalorFiltroAlimentador(data.content)
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const BuscarListaSedBalance = (arraySetValores, arrayAlimentadorValores) => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {

            setmostrarCircularProgress(true);

            if (arraySetValores.length > 0 || arrayAlimentadorValores.length > 0) {
                sethabilitarMensajeSed(false);
                let arraySet = []
                let arrayAlimentador = [];

                arraySetValores.forEach(element => {
                    arraySet.push(element.set_nombre)
                });

                arrayAlimentadorValores.forEach(element => {
                    arrayAlimentador.push(element.all_nombre)
                });

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/balance/ListaSedBalance`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        listaSet: arraySet.toString(),
                        listaAlimendador: arrayAlimentador.toString()
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    setvalorFiltroSED(data.content)
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });

            } else {
                sethabilitarMensajeSed(true);
            }
        }
    }
    //***************************************** */
    //Modal Filtro
    //***************************************** */
    const MostrarModalFiltro = async () => {
        await setmostrarModalFiltro(true);
        if (filtroPorcentajePerdidaActivo) {
            inputValorPorcentajePerdida.current.value = valorInputPorcntajePerdida
        }

        if (filtroKwhPerdidaActivo) {
            inputValorKwhPerdida.current.value = valorInputKwhPerdida;
        }
    }
    //Actualizar Cambios Filtros        
    const changeFiltroPeriodo = async (value) => {
        await setmatrizPeriodosSeleccionados(value);

        let valorFiltros = ObtenerFiltros();
        valorFiltros['periodo'] = value;
        GuardarEnStorage(config.storageFiltros, valorFiltros);

        let matrizTemp = [];
        value.forEach(element => {
            matrizTemp.push(element.mes_proceso);
        });
        setcadenaPeriodosSeleccionados(matrizTemp);
    }
    const ChangeFiltroSet = async (value) => {
        await setmatrizSetSeleccionados(value);

        let valorFiltros = ObtenerFiltros();
        valorFiltros['set'] = value;
        GuardarEnStorage(config.storageFiltros, valorFiltros);

        if (filtroAlimnentadorActivo) {
            BuscarListaAlimentadorBalance(value);
        }
        if (filtroSedActivo) {
            if (filtroSetActivo || filtroAlimnentadorActivo) {
                BuscarListaSedBalance(value, matrizAlimentadorSeleccionados);
            }
        }

        let matrizTemp = [];
        value.forEach(element => {
            matrizTemp.push(element.set_nombre);
        });
        setcadenaSetSeleccionados(matrizTemp);
    }
    const ChangeFiltroAlimentador = async (value) => {
        await setmatrizAlimentadorSeleccionados(value);

        let valorFiltros = ObtenerFiltros();
        valorFiltros['alimentador'] = value;
        GuardarEnStorage(config.storageFiltros, valorFiltros);

        if (filtroSedActivo) {
            if (filtroSetActivo || filtroAlimnentadorActivo) {
                BuscarListaSedBalance(matrizSetSeleccionados, value);
            }
        }

        let matrizTemp = [];
        value.forEach(element => {
            matrizTemp.push(element.all_nombre);
        });
        setcadenAlimentadorSeleccionados(matrizTemp);
    }
    const ChangeFiltroSed = (value) => {
        setmatrizSedSeleccionados(value)

        let valorFiltros = ObtenerFiltros();
        valorFiltros['sed'] = value;
        GuardarEnStorage(config.storageFiltros, valorFiltros);

        let matrizTemp = [];
        value.forEach(element => {
            matrizTemp.push(element.sed_codigo);
        });
        setcadenaSedSeleccionados(matrizTemp);
    }
    const ChangeFiltroProcentajePerdida = (valor) => {
        setvalorSelectPorcentajePerdida(valor);

        let valorFiltros = ObtenerFiltros();
        valorFiltros['filtro_porc_perdida'] = valor;
        GuardarEnStorage(config.storageFiltros, valorFiltros);
    }
    const ChangeValorPorcentajePerdida = (valor) => {
        setvalorInputPorcntajePerdida(valor);

        let valorFiltros = ObtenerFiltros();
        valorFiltros['valor_por_perdida'] = valor;
        GuardarEnStorage(config.storageFiltros, valorFiltros);
    }
    const ChangeFiltroKwhPerdida = (valor) => {
        setvalorSelectKwhPerdida(valor);

        let valorFiltros = ObtenerFiltros();
        valorFiltros['filtro_kwh_perdida'] = valor;
        GuardarEnStorage(config.storageFiltros, valorFiltros);
    }
    const ChangeValorKwhPerdida = (valor) => {
        setvalorInputKwhPerdida(valor);

        let valorFiltros = ObtenerFiltros();
        valorFiltros['valor_kwh_perdida'] = valor;
        GuardarEnStorage(config.storageFiltros, valorFiltros);
    }
    const ChangeValorCambiarMicrobalance = (valor) => {
        setcambiarMicrobalancesProgramados(valor);
        let valorFiltros = ObtenerFiltros();
        valorFiltros['valor_mostrar_microbalances'] = valor;
        GuardarEnStorage(config.storageFiltros, valorFiltros);
    }
    //Mapa    
    const ListarSuminstrosBuscar = () => {
        if (inputListaBuscar.current.value !== '') {
            inputListaBuscar.current.classList.remove('is-invalid');

            let arrayLista = inputListaBuscar.current.value.split(/\r\n|\r|\n/);
            let listaBuscar = "";

            arrayLista.forEach(elemento => {
                if (elemento.trim() !== '') {
                    listaBuscar = listaBuscar + elemento.trim() + ",";
                }
            });

            listaBuscar = listaBuscar.substring(1, listaBuscar.length - 1);
            inputBuscar.current.value = listaBuscar;

            setmostrarModalAgregarSuministros(false);
        } else {
            inputBuscar.current.clasList.add('is-invalid');
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`No se encontro cadena para realizar esta accion.`)
            setabrirToast(true);
        }
    }

    return (
        <Fragment>

            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Balance / Mapa'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Balance / Mapa</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/balance/lista') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>


            <div className='container border rounded mt-2'>

                <Container className='my-2'>
                    <Row >
                        <Col sm={12} md={4} className={size.width < 768 ? '' : ''}>
                            <Stack direction='horizontal'>
                                <Form.Control ref={inputBuscar} placeholder='Valor a buscar SED' size='sm'></Form.Control>
                                <IconButton className='mx-2' onClick={() => { setmostrarModalAgregarSuministros(true) }}>
                                    <PlaylistAddIcon fontSize='inherit'></PlaylistAddIcon>
                                </IconButton>
                            </Stack>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button onClick={() => { ListarBalances() }} size='sm' variant='primary'>Buscar</Button>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : 'text-end'}>
                            <Button variant='primary' size='sm' onClick={() => { MostrarModalFiltro() }}>Filtros</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <small className='text-secondary'>
                                {
                                    `Filtros: ${filtroPeriodoActivo ? `Periodo [${cadenaPeriodosSeleccionados.toString()}] ; ` : ''} 
                                                ${filtroSetActivo ? ` SET [${cadenaSetSeleccionados.toString()}] ; ` : ''}
                                                ${filtroAlimnentadorActivo ? `Alimentador [${cadenaAlimenadorSeleccionados.toString()}] ; ` : ''}
                                                ${filtroSedActivo ? `Sed [${cadenaSedSeleccionados.toString()}] ; ` : ''}
                                                ${filtroPorcentajePerdidaActivo ? `%Perdida [${valorSelectPorcentajePerdida} ${valorInputPorcntajePerdida ? valorInputPorcntajePerdida : ''}] ; ` : ''}
                                                ${filtroKwhPerdidaActivo ? `Kwh Perdida [${valorSelectKwhPerdida} ${valorInputKwhPerdida ? valorInputKwhPerdida : ''}]` : ''}
                                                ${cambiarMicrobalancesProgramados ? `Mostrar Microbalances Programados` : ''}
                                                Total Registros:[${dataBalances.length}]`
                                }
                            </small>
                        </Col>
                    </Row>
                </Container>

            </div>
            <Container fluid className='mt-1'>
                <div className='histogramaMapa'>
                    <div className='caja'>
                        <div className='histogramaMapa-caja' style={{ backgroundColor: 'green' }}></div>
                        <div className='caja-text'>
                            {`< 7%`}
                        </div>
                    </div>
                    <div className='caja'>
                        <div className='histogramaMapa-caja' style={{ backgroundColor: 'yellow' }}></div>
                        <div className='caja-text'>
                            {`>7% - 12%`}
                        </div>
                    </div>
                    <div className='caja'>
                        <div className='histogramaMapa-caja' style={{ backgroundColor: 'red' }}></div>
                        <div className='caja-text'>
                            {`> 12%`}
                        </div>
                    </div>
                    <div className='caja'>
                        <div className='histogramaMapa-caja' style={{ backgroundColor: 'black' }}></div>
                        <div className='caja-text'>
                            {` Negativos`}
                        </div>
                    </div>
                    <div className='caja'>
                        <div className='histogramaMapa-caja' style={{ backgroundColor: 'blue' }}></div>
                        <div className='caja-text'>
                            {`S/TOTA`}
                        </div>
                    </div>
                </div>
                <Row>
                    <Col>

                        <MapContainer center={{
                            lat: -16.398821,
                            lng: -71.536862
                        }} zoom={14} style={{ height: `75vh` }}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            {
                                dataBalances.map((row, index) => {
                                    console.log(row);

                                    var PinAgregar;
                                    switch (row.colorFont) {
                                        case 'MUI-Cells-green':
                                            PinAgregar = PinVerde;
                                            break;
                                        case 'MUI-Cells-yellow':
                                            PinAgregar = PinAmarillo;
                                            break;
                                        case 'MUI-Cells-red':
                                            PinAgregar = PinRojo;
                                            break;
                                        case 'MUI-Cells-black':
                                            PinAgregar = PinNego;
                                            break;
                                        default:
                                            PinAgregar = PinAzul;
                                            break;
                                    }

                                    return (
                                        <Marker key={index} title="" position={[row.lat, row.lng]} icon={PinAgregar}>

                                            <Popup direction="bottom" offset={[0, 20]} opacity={0.9} minWidth={200}>
                                                <Container>
                                                    <Row>
                                                        <Col className='text-bold'>
                                                            <strong className='text-primary'>SED:</strong>
                                                        </Col>
                                                        <Col>{row.sed_codigo}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-primary'>Cant. Sum:</strong></Col>
                                                        <Col>{row.cantidad_suministros}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-primary'>TOT:</strong>
                                                        </Col>
                                                        <Col>{row.suma_totalizador}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-primary'>AP:</strong>
                                                        </Col>
                                                        <Col>{row.suma_ap}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-primary'>Comun:</strong>
                                                        </Col>
                                                        <Col>{row.suma_comun}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-primary'>GI:</strong>
                                                        </Col>
                                                        <Col>{row.suma_gi}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-primary'>Kwh Perdida:</strong>
                                                        </Col>
                                                        <Col>{row.kwh_perdida}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-primary'>% Perdida:</strong>
                                                        </Col>
                                                        <Col>{`${row.porcentaje_perdida} %`}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {/* <IconButton> */}
                                                            {
                                                                !row.programado ?
                                                                    <></> :
                                                                    row.programado == '1' ?
                                                                        <EngineeringIcon color='success' />
                                                                        :
                                                                        row.programado == '2' ?
                                                                            <EngineeringIcon color='primary' />
                                                                            : <EngineeringIcon color='error' />
                                                            }
                                                            {/* </IconButton> */}
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-2'>
                                                        <Col>
                                                            {
                                                                row.MicroBalancesProgramados > 0 ?
                                                                    <Tooltip title="Microbalance Programado" >
                                                                        <Badge badgeContent={row.MicroBalancesProgramados} color='primary'>
                                                                            <EngineeringIcon color='primary' size='small' />
                                                                        </Badge>
                                                                    </Tooltip>
                                                                    : <></>
                                                            }
                                                        </Col>
                                                        <Col>
                                                            {
                                                                row.MicroBalancesFinalizados > 0 ?
                                                                    <Tooltip title="Microbalance Finalizados" >
                                                                        <Badge badgeContent={row.MicroBalancesFinalizados} color='success'>
                                                                            <EngineeringIcon color='success' />
                                                                        </Badge>
                                                                    </Tooltip>
                                                                    : <></>
                                                            }
                                                        </Col>
                                                        {/* <Col className='text-end'>
                                                            <Button size='sm'>Ver</Button>
                                                        </Col> */}
                                                    </Row>
                                                </Container>
                                            </Popup>
                                        </Marker>
                                    )
                                })
                            }
                        </MapContainer>

                    </Col>
                </Row>
            </Container>


            {/* Modal Filtro */}
            <Modal
                show={mostrarModalFiltro}
                onHide={() => { setmostrarModalFiltro(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Seleccionar Criterios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <small className='text-primary'>Criterio</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Autocomplete
                                    onChange={(event, value) => { ValidarCriterios(event, value) }}
                                    isOptionEqualToValue={(option, value) => option.criterio === value.criterio}
                                    multiple
                                    size='small'
                                    options={criteriosFiltro}
                                    // disableCloseOnSelect
                                    getOptionLabel={(option) => option.criterio}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 4 }}
                                                checked={selected}
                                            />
                                            {option.criterio}
                                        </li>
                                    )}
                                    style={{}}
                                    renderInput={(params) => (
                                        <TextField {...params} label="" placeholder="Criterios" />
                                    )}
                                    value={matrizCriterios}
                                />
                            </Col>
                        </Row>
                        <Divider></Divider>

                        {
                            mostrarFiltroPeriodo ?
                                <>
                                    <Row>
                                        <Col>
                                            <small className='text-primary'>{`Periodo (${valorFiltroPeriodo.length})`}</small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Autocomplete
                                                onChange={(event, value) => { changeFiltroPeriodo(value) }}
                                                isOptionEqualToValue={(option, value) => option.mes_proceso === value.mes_proceso}
                                                multiple
                                                size='small'
                                                options={valorFiltroPeriodo}
                                                // disableCloseOnSelect
                                                getOptionLabel={(option) => option.mes_proceso}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 4 }}
                                                            checked={selected}
                                                        />
                                                        {option.mes_proceso}
                                                    </li>
                                                )}
                                                style={{}}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="" placeholder="Periodo" />
                                                )}
                                                value={matrizPeriodosSeleccionados}
                                            />
                                        </Col>
                                    </Row>

                                </>
                                : <></>
                        }

                        {
                            mostrarFiltroSET ?
                                <>
                                    <Row>
                                        <Col>
                                            <small className='text-primary'>{`SET Nombre (${valorFiltroSet.length})`}</small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Autocomplete
                                                onChange={(event, value) => { ChangeFiltroSet(value) }}
                                                isOptionEqualToValue={(option, value) => option.set_nombre === value.set_nombre}
                                                multiple
                                                size='small'
                                                options={valorFiltroSet}
                                                // disableCloseOnSelect
                                                getOptionLabel={(option) => option.set_nombre}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 4 }}
                                                            checked={selected}
                                                        />
                                                        {option.set_nombre}
                                                    </li>
                                                )}
                                                style={{}}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="" placeholder="Nombre SET" />
                                                )}
                                                value={matrizSetSeleccionados}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                : <></>
                        }

                        {
                            mostrarFiltroAlimentador ?
                                <>
                                    <Row>
                                        <Col>
                                            <small className='text-primary'>{`ALIMENTADOR (${valorFiltroAlimentador.length})`}</small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Autocomplete
                                                onChange={(event, value) => { ChangeFiltroAlimentador(value) }}
                                                isOptionEqualToValue={(option, value) => option.all_nombre === value.all_nombre}
                                                multiple
                                                size='small'
                                                options={valorFiltroAlimentador}
                                                // disableCloseOnSelect
                                                getOptionLabel={(option) => option.all_nombre}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 4 }}
                                                            checked={selected}
                                                        />
                                                        {option.all_nombre}
                                                    </li>
                                                )}
                                                style={{}}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="" placeholder="Nombre ALIMENTADOR" />
                                                )}
                                                value={matrizAlimentadorSeleccionados}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                : <></>
                        }

                        {
                            mostrarFiltroSed ?
                                <>
                                    <Row>
                                        <Col>
                                            <small className='text-primary'>{`Codigo SED (${valorFiltroSED.length})`}</small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Autocomplete
                                                onChange={(event, value) => { ChangeFiltroSed(value) }}
                                                isOptionEqualToValue={(option, value) => option.sed_codigo === value.sed_codigo}
                                                multiple
                                                size='small'
                                                options={valorFiltroSED}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.sed_codigo}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 2 }}
                                                            checked={selected}
                                                        />
                                                        {option.sed_codigo}
                                                    </li>
                                                )}
                                                style={{}}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="" placeholder="Codigo SED" />
                                                )}
                                                value={matrizSedSeleccionados}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        habilitarMensajeSed ?
                                            <small className='text-secondary'>El criterio de busqueda SED's contiene demasiada informacion para ser mostrada, debe elegir uno o mas filtros anteriores para reducir la cantidad de datos a mostrar.</small>
                                            : <></>
                                    }
                                </>
                                : <></>
                        }

                        {
                            mostrarFiltroPorcentajePerdida ?
                                <>
                                    <Row>
                                        <Col>
                                            <small className='text-primary'>% Perdida</small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Select onChange={(ev) => { ChangeFiltroProcentajePerdida(ev.target.value) }} ref={selectFiltroPorcentajePerdida} defaultValue={valorSelectPorcentajePerdida}>
                                                <option value="" disabled>Seleccione</option>
                                                <option value="MAYOR_QUE">{`Mayor que`}</option>
                                                <option value="MAYOR_IGUAL_QUE">{`Mayor o igual que`}</option>
                                                <option value="MENOR_QUE">{`Menor que`}</option>
                                                <option value="MENOR_IGUAL_QUE">{`Menor o igual que`}</option>
                                                <option value="IGUAL_A">{'Igual a'}</option>
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Control onChange={(ev) => { ChangeValorPorcentajePerdida(ev.target.value) }} ref={inputValorPorcentajePerdida} placeholder='Valor' type='number'></Form.Control>
                                        </Col>
                                    </Row>
                                </>
                                : <></>
                        }

                        {
                            mostrarFiltroKwhPerdida ?
                                <>
                                    <Row>
                                        <Col>
                                            <small className='text-primary'>Kwh Perdida</small>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Select onChange={(ev) => { ChangeFiltroKwhPerdida(ev.target.value) }} ref={selectFiltrokwhPerdida} defaultValue={valorSelectKwhPerdida}>
                                                <option value="" disabled>Seleccione</option>
                                                <option value="MAYOR_QUE">{`Mayor que`}</option>
                                                <option value="MAYOR_IGUAL_QUE">{`Mayor o igual que`}</option>
                                                <option value="MENOR_QUE">{`Menor que`}</option>
                                                <option value="MENOR_IGUAL_QUE">{`Menor o igual que`}</option>
                                                <option value="IGUAL_A">{'Igual a'}</option>
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Control onChange={(ev) => { ChangeValorKwhPerdida(ev.target.value) }} ref={inputValorKwhPerdida} placeholder='Valor' type='number'></Form.Control>
                                        </Col>
                                    </Row>
                                </>
                                : <></>
                        }
                        <Row className='mt-2'>
                            <Col>
                                <Form.Check defaultChecked={cambiarMicrobalancesProgramados} onChange={(ev) => { ChangeValorCambiarMicrobalance(ev.target.checked) }} type='checkbox' label='Mostrar Microbalances Programados'></Form.Check>
                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col className='text-center'>
                                <Button onClick={() => { BuscarBalances(`FILTRO`) }} className='w-50'>Filtrar</Button>
                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Suministros */}
            <Modal
                show={mostrarModalAgregarSuministros}
                onHide={() => { setmostrarModalAgregarSuministros(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Lista
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <Form.Control ref={inputListaBuscar} as='textarea' rows={6} placeholder='Agregar Lista' size='sm'></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <Button variant='primary' size='sm' onClick={() => { ListarSuminstrosBuscar() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal  */}
            <Modal
                show={mostrarModalMensajeExcedente}
                onHide={() => { setmostrarModalMensajeExcedente(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <h5>Alerta !!</h5>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2 fw-light'>
                            <Col>
                                <span className='text-muted'>
                                    {
                                        `El filtro seleccionado actual tiene mas de ${cantidadMaximaRegistros} registros, esto podria generar una perdida de performance del sistema o que el sistema se cuelgue. Realice un filtro que permita reducir la cantidad de registros.`
                                    }
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <Button variant='primary' size='sm' onClick={() => { setmostrarModalMensajeExcedente(false) }}>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


        </Fragment>
    );
}

export default ListaBalancesMapa;