import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Bootstrap
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle, CircularProgress, Tooltip } from '@mui/material';
import ButtonMUI from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import { ListItem } from '@mui/material';
import List from '@mui/material/List';
//Material Grid
import { DataGrid, esES } from '@mui/x-data-grid';

//Material Icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import CheckIcon from '@mui/icons-material/Check';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//Importaciones
import Header from '../../dashboard/Header';
import PaginaCarga from '../../../config/PaginaCarga';
import { ObtenerUsuario, config, ValidarUsuario, useWindowSize, listarPermisos, ValidarPermisos, LIST_SCREENS } from '../../../config/utils';
import moment from 'moment-timezone';
import 'moment/locale/es';


function CargarConsumos(props) {
    const navigate = useNavigate();

    //toast y progress Bar
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [mostrarBarraProgreso, setmostrarBarraProgreso] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const [objetoMenu, setobjetoMenu] = React.useState(null);

    //hook actualizacion tamaño de ventana
    const size = useWindowSize();

    //Ventanas Modales
    const [mostrarModalEliminarArchivo, setmostrarModalEliminarArchivo] = useState(false);
    const [mostrarNodalRecalcularArchivo, setmostrarNodalRecalcularArchivo] = useState(false);
    const [mostrarModalCargarArchivo, setmostrarModalCargarArchivo] = useState(false);


    const [elementoActual, setelementoActual] = useState(null);
    const [dataArchivoConsumos, setdataArchivoConsumos] = useState([]);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //referncias
    const inputFileBaseSuministro = useRef();
    const inputValorBuscar = useRef();

    const columns = [
        {
            field: 'accion', headerName: 'Acción', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {
                            setelementoActual(cellValues);
                            setmostrarModalEliminarArchivo(true);
                        }}>
                            <Tooltip title="Eliminar datos de archivo.">
                                <DeleteIcon></DeleteIcon>
                            </Tooltip>
                        </IconButton>

                        <IconButton onClick={() => {
                            setelementoActual(cellValues);
                            GenerarBalancePeriodo(cellValues);
                        }}>
                            <Tooltip title="Genear / Actualizar calculo de balance">
                                <UpdateIcon ></UpdateIcon>
                            </Tooltip>
                        </IconButton>
                    </>
                );
            }
        },
        {
            field: 'balance', headerName: 'Balance', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (

                    cellValues.row.balance > 0 ?
                        <Tooltip title="Tiene calculo de balance">
                            <BatchPredictionIcon color='primary'></BatchPredictionIcon>
                        </Tooltip>
                        : <></>
                    // {
                    //     cellValues.row.balance>0?
                    //     :<></>
                    // }
                );
            }
        },
        { field: 'nombrearchivo', headerName: 'Nombre Archivo', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'fechacarga', headerName: 'Fecha Carga', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'periodo', headerName: 'Periodo', minWidth: 150, headerClassName: 'header-colum-table' },
        { field: 'usuario', headerName: 'Usuario', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'cantregistros', headerName: 'Cant. Registros', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },

    ];

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_CARGAR_RPT, permisos)) {
                    BuscarListaArchivos();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }

    const AbrirMenu = (event) => {
        setobjetoMenu(event.currentTarget);
    };

    const CerrarMenu = () => {
        setobjetoMenu(null);
    };

    //Procedimientos y funciones
    const ValidarCargaArchivo = () => {
        let error = 0;
        let mensaje = "";
        if (inputFileBaseSuministro.current.files.length > 0) {
            inputFileBaseSuministro.current.classList.remove('is-invalid');
        } else {
            inputFileBaseSuministro.current.classList.add('is-invalid');
            mensaje = "Archivo no encontrado o no se ha seleccionado el archivo."
            error++;
        }

        if (error > 0) {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(error.toString())
            setabrirToast(true);
            return false;
        }

        return true;
    }

    const SubirArchivo = async () => {
        if (ValidarUsuario()) {
            if (ValidarCargaArchivo()) {
                try {
                    setmostrarBarraProgreso(true);
                    inputFileBaseSuministro.current.disabled = true;
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let url = `${config.endPoint}api/basesuministro/cargarreporteconsumos`;

                    let formData = new FormData();
                    formData.append("file", inputFileBaseSuministro.current.files[0]);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: formData
                    };

                    await fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        // console.log(data);
                        if (data.success) {
                            settipoToast("success");
                            settituloToast("Completado");
                        } else {
                            settipoToast("error");
                            settituloToast("Error");
                        }
                        setmensajeToast(data.message)
                        setabrirToast(true);
                        setmostrarBarraProgreso(false);
                        setmostrarModalCargarArchivo(false)
                        BuscarListaArchivos();
                    }).catch(async (error) => {
                        setmostrarBarraProgreso(false);
                        throw new Error(`${config.MessageErrorServer}; ${error.toString()}`);
                    });

                } catch (error) {
                    console.log(error.toString());
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(error.toString())
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }
            }
        } else {
            navigate("/login");
        }
    }

    const ActualizarDeReporteExistente = () => {
        if (ValidarUsuario()) {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/basesuministro/ActualizarReporteConsumos`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({})
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data);
                if (data.success) {
                    settipoToast("success");
                    settituloToast("Completado");
                } else {
                    settipoToast("error");
                    settituloToast("Error");
                }
                setmensajeToast(data.message)
                setabrirToast(true);                
                BuscarListaArchivos();
                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate("/login");
        }

    }

    const BuscarListaArchivos = () => {
        if (ValidarUsuario()) {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/consumosuministro/archivos/listar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ valorbuscar: inputValorBuscar.current.value })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data);
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: index,
                            accion: '',
                            nombrearchivo: elemento.archivo_origen,
                            fechacarga: moment(elemento.fecha_crea).format("YYYY-MM-DD"),
                            periodo: elemento.periodo_comercial,
                            balance: elemento.balance,
                            usuario: elemento.usuario_crea,
                            cantregistros: elemento.cantidad
                        }
                    );
                });
                setdataArchivoConsumos(DataTemp);
                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate("/login");
        }

    }

    const ELiminarListaArchivos = () => {

        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/consumosuministro/archivos/eliminar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(
                    {
                        archivoOrigen: elementoActual.row.nombrearchivo,
                        periodoComercial: elementoActual.row.periodo
                    }
                )
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    BuscarListaArchivos();
                    setmostrarModalEliminarArchivo(false);
                }
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate('/login');
        }
    }

    const GenerarBalancePeriodo = async (elemento) => {
        // console.log(elemento.row.periodo);

        setmostrarNodalRecalcularArchivo(true);

        if (!ValidarUsuario()) {
            navigate('/login');
        }
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);

        let url = `${config.endPoint}api/balance/GenerarBalancePeriodo`;

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify({
                periodoComercial: elemento.row.periodo
            })
        };

        await fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            // console.log(data);         
            if (data.success) {
                settituloToast("Completo");
                settipoToast("success");
                setmensajeToast(data.message)
                setabrirToast(true);
                BuscarListaArchivos();
            }
            setmostrarNodalRecalcularArchivo(false);
        }).catch(async (error) => {
            // console.log(error);
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
            setabrirToast(true);
        });
    }

    const ActualizarInputBuscar = () => {
        if (inputValorBuscar.current.value.trim() === '') {
            BuscarListaArchivos()
        }
    }


    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Balance / Cargar'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Balance / Cargar</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/balance') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4} className=''>
                            <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputValorBuscar} placeholder='Valor a buscar' size='sm'></Form.Control>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'mt-md-0 mt-1 d-grid gap-2' : ''}>
                            <Button onClick={() => { BuscarListaArchivos() }} size='sm' variant='primary'>Buscar</Button>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'mt-md-0 mt-1 d-grid gap-2' : 'text-end'}>
                            <Button variant='primary' size='sm' onClick={() => { setmostrarModalCargarArchivo(true) }}>Cargar Archivo</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-end'>
                            <ButtonMUI variant="text" size='sm'
                                onClick={AbrirMenu}>
                                <small>opciones</small>
                                <KeyboardArrowDownIcon />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={objetoMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(objetoMenu)}
                                onClose={CerrarMenu}
                            >
                                <MenuItem onClick={() => { CerrarMenu();ActualizarDeReporteExistente() }}>Actualizar Con Data Existente</MenuItem>
                                {/* <MenuItem onClick={() => { CerrarMenu(); AbrirModalProgramarMicrobalance() }}>Programar Microbalance</MenuItem> */}
                                {/* <Divider />
                                <MenuItem onClick={() => { CerrarMenu();}}>Seleccionar Todos</MenuItem>    
                                <MenuItem onClick={() => { CerrarMenu(); }}>Quitar Seleccion</MenuItem>     */}
                                {/* <Divider />
                                <MenuItem onClick={() => { CerrarMenu(); BuscarBalances('BUSCAR') }}>Actualizar</MenuItem> */}
                            </Menu>
                        </Col>
                    </Row>

                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid density='compact' rows={dataArchivoConsumos} columns={columns} localeText={esES.components.MuiDataGrid.defaultProps.localeText} />
                    </div>
                </div>
            </div>


            {/* Ventanas Modales */}

            {/* Modal Mensaje Confirmar Recalcular Archivo */}
            <Modal
                show={mostrarNodalRecalcularArchivo}
                onHide={() => { setmostrarNodalRecalcularArchivo(false) }}
                keyboard={false}
                centered
            >
                <Modal.Body >
                    <Container>
                        <Row className='mb-4'>
                            <Form.Label>
                                Se está realizando las operaciones...
                                <List>
                                    {/* <ListItem> <CheckIcon />Agrupando Lista de Suministros ...</ListItem>
                                    <ListItem><CheckIcon />Sumando Agrupacion de Suministros ...</ListItem> */}
                                    <ListItem><CircularProgress size={20} />Generando Balance ...</ListItem>
                                </List>
                            </Form.Label>
                        </Row>
                        <Row>
                            <Col className='text-center'>
                                <CircularProgress></CircularProgress>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center'>
                                Actualizando ...
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


            {/* Modal Mensaje Confirmar Eliminar Archivo */}
            <Modal
                show={mostrarModalEliminarArchivo}
                onHide={() => { setmostrarModalEliminarArchivo(false) }}
                keyboard={false}
            >
                <Modal.Body >
                    <Container>
                        <Row className='mb-4'>
                            <Form.Label>
                                {
                                    elementoActual ?
                                        `¿Esta seguro de eliminar el contenido cargado del archivo ${elementoActual.row.nombrearchivo} con fecha ${moment(elementoActual.row.fechacarga).format("DD/MM/YYYY")}?`
                                        : <></>
                                }
                            </Form.Label>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={() => { setmostrarModalEliminarArchivo(false) }} variant='outline-primary'>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button onClick={() => { ELiminarListaArchivos() }}>Aceptar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Mensaje Cargar Archivo */}
            <Modal
                show={mostrarModalCargarArchivo}
                onHide={() => { setmostrarModalCargarArchivo(false) }}
                keyboard={false}
                size='lg'
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>
                        Cargar Archivo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <small className='text-primary my-2'>Seleccione el archivo de consumos a cargar(*.xlsx)</small>
                        </Row>
                        <Row className='mb-4'>
                            <Col>
                                <Form.Control ref={inputFileBaseSuministro} size='sm' type="file" />
                            </Col>
                        </Row>
                        <Row>
                            <small className='text-secondary'>Se cargará el archivo de consumos, se actualizara la base de suministros si es necesario y se agregara los consumos a la base de consumos segun el periodo comercial correspondiente.</small>
                        </Row>
                        {/* <Row>
                            <Col md={2}>
                                <Form.Label>Periodo:</Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control ref={inputPeriodoComercial} size='sm' placeholder='Ingrese Periodo'></Form.Control>
                            </Col>
                            <Col>
                                <Form.Check ref={checkCalcularBalance} type='checkbox' label='Calcular Balance al subir el Archivo.'></Form.Check>
                            </Col>
                        </Row> */}
                        {
                            mostrarBarraProgreso ?
                                <>
                                    <Row className='mt-4'>
                                        <Col className='text-center'>
                                            <Form.Label>
                                                <List>
                                                    <ListItem><CircularProgress size={20} className="mx-1" /> Subiendo archivo al servidor</ListItem>
                                                    <ListItem><CircularProgress size={20} className="mx-1" /> Actualizando base de Suministros</ListItem>
                                                    <ListItem><CircularProgress size={20} className="mx-1" /> Agregando consumos</ListItem>
                                                    <ListItem><CircularProgress size={20} className="mx-1" /> Actualizando base de consumos</ListItem>
                                                </List>
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col className='text-center'>
                                            <CircularProgress></CircularProgress>
                                        </Col>
                                    </Row> */}
                                </>

                                : <Row className='mt-4'>
                                    <Col>
                                        <Button variant='outline-primary' onClick={() => { setmostrarModalCargarArchivo(false) }}>Cerrar</Button>
                                    </Col>
                                    <Col className='text-end'>
                                        <Button variant='primary' onClick={() => { SubirArchivo() }}>Cargar Archivo</Button>
                                    </Col>
                                </Row>
                        }
                    </Container>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default CargarConsumos;