import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';

import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle } from '@mui/material';
import { DataGrid,esES } from '@mui/x-data-grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { ValidarUsuario, config, ObtenerUsuario, useWindowSize, ValidarPermisos, listarPermisos, LIST_SCREENS } from '../../config/utils';

function ConfiguracionDetalleVivienda(props) {
    const navigate = useNavigate();
    const size = useWindowSize();

    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const [mostrarModalDetalleVivienda, setmostrarModalDetalleVivienda] = useState(false);
    const [dataDetalleViviendas, setdataDetalleViviendas] = useState([]);
    const [accionActual, setaccionActual] = useState('');
    const [elementoActual, setelementoActual] = useState({});
    const [listaCodigosVivienda, setlistaCodigosVivienda] = useState([]);
    const [listaSubCodigosCivienda, setlistaSubCodigosCivienda] = useState([]);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const inputBuscar = useRef();
    const inputCodigoVivienda = useRef();
    const inputSubCodigoVivienda = useRef();
    const selectEstadoDetalleVivienda = useRef();

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_DETALLE_VIVIENDA, permisos)) {
                    BuscarDetalleVivienda();
                    ListarCodigoVivienda();
                    ListarSubCodigoVivienda();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/configuracion');
                    }, 1000);
                }
            });
        }
    }

    const BuscarDetalleVivienda = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/DetalleVivienda/Listar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ valorbuscar: inputBuscar.current.value })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: elemento.id_detalle_vivienda,
                            accion: '',
                            codigo: elemento.codigo,
                            subcodigo: elemento.subcodigo,
                            estado: elemento.estado
                        }
                    );
                });
                setdataDetalleViviendas(DataTemp);
                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }

    const ListarCodigoVivienda = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            let url = "";
            url = `${config.endPoint}api/DetalleVivienda/ListarAgrupacionCodigoVivienda`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {                
                setlistaCodigosVivienda(data.content);
            });
        }
    }

    const ListarSubCodigoVivienda = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            let url = "";
            url = `${config.endPoint}api/DetalleVivienda/ListarAgrupacionSubCodigoVivienda`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {                
                setlistaSubCodigosCivienda(data.content);
            });
        }
    }

    const columns = [
        {

            field: 'accion', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {

                            AbrirModalDetalleVivienda('VER', cellValues.row)
                        }}>
                            <EditIcon fontSize='small'></EditIcon>
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'codigo', headerName: 'Tipo Vivienda', flex: 1, headerClassName: 'header-colum-gray' },
        { field: 'subcodigo', headerName: 'Nivel Consumo', flex: 1, headerClassName: 'header-colum-gray' },
        { field: 'estado', headerName: 'Estado', flex: 1, headerClassName: 'header-colum-gray' },

    ];

    const AbrirModalDetalleVivienda = async (accion, row) => {
        setaccionActual(accion);
        setelementoActual(row);
        await setmostrarModalDetalleVivienda(true);
        if (row) {
            inputCodigoVivienda.current.value = row.codigo;
            inputSubCodigoVivienda.current.value = row.subcodigo;
            selectEstadoDetalleVivienda.current.value = row.estado;

            inputCodigoVivienda.current.disabled = true
            inputSubCodigoVivienda.current.disabled = true
            selectEstadoDetalleVivienda.current.disabled = true
        }
    }

    const Guardar = () => {
        switch (accionActual) {
            case 'AGREGAR':
                AgregarDetalleVivienda();
                break;
            case 'MODIFICAR':
                ActualizarDetalleVivienda();
                break;
        }
    }

    const ValidarFormularioDetalleVivienda = () => {
        let error = 0;
        if (inputCodigoVivienda.current.value == '') {
            inputCodigoVivienda.current.classList.add("is-invalid");
            error++;
        } else {
            inputCodigoVivienda.current.classList.remove("is-invalid");
        }

        if (inputSubCodigoVivienda.current.value == '') {
            inputSubCodigoVivienda.current.classList.add('is-invalid');
            error++;
        } else {
            inputSubCodigoVivienda.current.classList.remove('is-invalid');
        }

        if (selectEstadoDetalleVivienda.current.value == '') {
            selectEstadoDetalleVivienda.current.classList.add('is-invalid');
            error++;
        } else {
            selectEstadoDetalleVivienda.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }

    const AgregarDetalleVivienda = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarFormularioDetalleVivienda()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/DetalleVivienda/Agregar`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        codigo: inputCodigoVivienda.current.value.toUpperCase(),
                        subcodigo: inputSubCodigoVivienda.current.value.toUpperCase(),
                        estado: selectEstadoDetalleVivienda.current.value.toUpperCase()
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    BuscarDetalleVivienda();
                    setmostrarModalDetalleVivienda(false);
                    setmostrarCircularProgress(false);

                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    const ActualizarDetalleVivienda = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarFormularioDetalleVivienda()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/DetalleVivienda/Modificar`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        iddetallevivienda: elementoActual.id,
                        codigo: inputCodigoVivienda.current.value.toUpperCase(),
                        subcodigo: inputSubCodigoVivienda.current.value.toUpperCase(),
                        estado: selectEstadoDetalleVivienda.current.value.toUpperCase()
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    BuscarDetalleVivienda();
                    setmostrarModalDetalleVivienda(false);
                    setmostrarCircularProgress(false);

                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    const ModificarDetalleVivienda = () => {
        inputCodigoVivienda.current.disabled = false
        inputSubCodigoVivienda.current.disabled = false
        selectEstadoDetalleVivienda.current.disabled = false
        setaccionActual("MODIFICAR");
    }

    const ActualizarInputBuscar = () => {
        if (inputBuscar.current.value.trim() === '') {
            BuscarDetalleVivienda();
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración / DetalleViviendas'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Configuración / DetalleViviendas-Acceso</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/configuracion') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2' : ''}>
                            <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputBuscar} placeholder='Valor a buscar' size='sm'></Form.Control>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button onClick={() => { BuscarDetalleVivienda() }} variant='primary' size='sm'>
                                Buscar
                            </Button>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : 'text-end'}>
                            <Button variant='primary' size='sm' onClick={() => { AbrirModalDetalleVivienda('AGREGAR', null) }}>Agregar <AddIcon fontSize='small'></AddIcon></Button>
                        </Col>
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={dataDetalleViviendas}
                            columns={columns}
                            getCellClassName={(params) => {
                                if (params.colDef.field == "estado") {
                                    if (params.row.estado == "ACTIVO") {
                                        return 'text-primary';
                                    } else {
                                        return 'text-danger';
                                    }
                                }
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </div>
            </div>


            {/* Modal DetalleVivienda */}

            <Modal
                show={mostrarModalDetalleVivienda}
                onHide={() => { setmostrarModalDetalleVivienda(false) }}
                keyboard={false}
                size="md"
            >
                <Modal.Header>
                    <Modal.Title>
                        Codigo Lectura
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Tipo Vivienda</small>
                                    <Form.Control size='sm' list='listacodigosvivienda' ref={inputCodigoVivienda} type="text" placeholder='Ingrese Codigo Lectura'></Form.Control>
                                    <datalist id='listacodigosvivienda'>
                                        {
                                            listaCodigosVivienda.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.codigo } />
                                                )
                                            })
                                        }
                                    </datalist>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nivel de Consumo</small>
                                    <Form.Control list='listanivelconsumo' ref={inputSubCodigoVivienda} size='sm' type="text" placeholder='Ingrese Descripción'></Form.Control>
                                    <datalist id='listanivelconsumo'>
                                        {
                                            listaSubCodigosCivienda.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.subcodigo } />
                                                )
                                            })
                                        }
                                    </datalist>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <select ref={selectEstadoDetalleVivienda} className="form-select" defaultValue="">
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                </Form.Group>

                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <Button size='sm' variant="outline-primary" onClick={() => { setmostrarModalDetalleVivienda(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                {
                                    accionActual == 'VER' ?
                                        <Button onClick={() => { ModificarDetalleVivienda() }} size='sm' variant="primary">Modificar</Button>
                                        : <Button onClick={() => { Guardar() }} size='sm' variant="primary">Guardar</Button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}

export default ConfiguracionDetalleVivienda;
