import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
//Material UI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Badge, Tooltip, Snackbar, Alert, AlertTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';


import MessageIcon from '@mui/icons-material/Message';
import AddTaskIcon from '@mui/icons-material/AddTask';

//Importaciones
import { ObtenerUsuario, config, ValidarUsuario,ValidarPermisos,listarPermisos,LIST_SCREENS } from '../../config/utils'
import PaginaCarga from '../../config/PaginaCarga';
import moment from 'moment';

export default function Alertas(props) {
    const navigate = useNavigate();

    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //Modales
    const [abrirModalDetalleAlertaSuministro, setabrirModalDetalleAlertaSuministro] = useState(false);
    const [abrirModalAtenderAlertaSuministro, setabrirModalAtenderAlertaSuministro] = useState(false);

    //variables
    const [alertaActual, setalertaActual] = useState({})
    const [suministroAlertas, setsuministroAlertas] = useState([]);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //referencias
    const inputObservacion = useRef();

    useEffect(()=>{
        ValidarAccesos();
    },[])

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_SUMINISTRO_ALERTA, permisos)) {
                    BuscarAlertasSuministroPendientes();
                }
                //  else {
                //     settituloToast("Alerta");
                //     settipoToast("warning");
                //     setmensajeToast("No tiene permisos para acceder a este Recurso.");
                //     setabrirToast(true);
                //     setTimeout(() => {
                //         navigate('/');
                //     }, 1000);
                // }
            });
        }       
    }

    const MostrarModalDetalleAlertas = () => {
        setabrirModalDetalleAlertaSuministro(true);
        BuscarAlertasSuministroPendientes();
    }
    const BuscarAlertasSuministroPendientes = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/AlertaSuministro/BuscarAlertaSuministroPendientes`;

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {                
                if (data.success) {
                    //actualizar    
                    setsuministroAlertas(data.content);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(data.message);
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        }
    }
    const MostrarModalAtenderObservacion = (row) => {
        setalertaActual(row);
        setabrirModalAtenderAlertaSuministro(true)
    }
    const ValidarAtenderObservacion = () => {
        let error = 0;
        if (inputObservacion.current.value.trim() == '') {
            inputObservacion.current.classList.add('is-invalid');
            error++;
        } else {
            inputObservacion.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const AtenderObservacion = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            if (ValidarAtenderObservacion()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/AlertaSuministro/AtenderAlertaSuministro`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idalertasuministro: alertaActual.id_alerta_suministro,
                        observacion: inputObservacion.current.value,
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        setabrirModalAtenderAlertaSuministro(false);
                        //Actualizar
                        BuscarAlertasSuministroPendientes();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            {
                suministroAlertas.length > 0 ?
                    <div style={{ position: 'fixed', left: '20px', bottom: '20px' }}>
                        <Box >
                            <Fab onClick={() => { MostrarModalDetalleAlertas() }} color="error" aria-label="add">
                                <Badge badgeContent={suministroAlertas.length} color="primary">
                                    <MessageIcon />
                                </Badge>
                            </Fab>
                        </Box>
                    </div>

                    : <></>
            }           

            {/* Modal Detalle Observacion */}
            <Modal
                show={abrirModalDetalleAlertaSuministro}
                onHide={() => { setabrirModalDetalleAlertaSuministro(false) }}
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header>
                    <Modal.Title>
                        Alertas Pendientes de Atención
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <TableContainer style={{ maxHeight: '300px' }}>
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell>*</TableCell>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Suministro</TableCell>
                                                <TableCell>Obs.</TableCell>
                                                <TableCell>Prioridad</TableCell>
                                                {/* <TableCell>Usuario Atiende</TableCell>
                                                <TableCell>Obs. Atención</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                suministroAlertas.map((row, index) => {
                                                    let usuarioAtiende = "";
                                                    if (Object.entries(row.usuario_atiende) > 0) {
                                                        usuarioAtiende = row.usuario_atiende
                                                    }
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell >
                                                                <IconButton onClick={() => { MostrarModalAtenderObservacion(row) }}>
                                                                    <AddTaskIcon></AddTaskIcon>
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell >
                                                                {moment(row.fecha_crea).format('yyyy-MM-DD hh:mm:ss')}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.codigo_suministro}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.observacion}
                                                            </TableCell>
                                                            <TableCell className={row.prioridad == "URGENTE" ? 'text-danger' : 'text-primary'}>
                                                                {row.prioridad}
                                                            </TableCell>
                                                            {/* <TableCell >
                                                                {usuarioAtiende}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.observacion_atencion}
                                                            </TableCell> */}
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row >
                            <Col >
                                <Button size='sm' onClick={() => { setabrirModalDetalleAlertaSuministro(false) }} variant='outline-primary'>Cerrar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button size='sm' onClick={() => { }} variant=' primary'>Ir a Base</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {/* Modal Atender Observacion */}
            <Modal
                show={abrirModalAtenderAlertaSuministro}
                onHide={() => { setabrirModalAtenderAlertaSuministro(false) }}
                keyboard={false}
                centered
            >

                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <Container>
                        <h5>Agregar Observacion</h5>
                        <hr></hr>
                        <Row>
                            <Col>
                                <small className='text-primary'>Observación</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control size='sm' ref={inputObservacion} as="textarea" rows={3} placeholder='Observación'></Form.Control>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button onClick={() => { setabrirModalAtenderAlertaSuministro(false) }} variant='outline-primary' size='sm' >Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button size='sm' onClick={() => { AtenderObservacion() }}>Registrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </Fragment>
    )
}