import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, Modal, Stack, Accordion, FormControl, ModalHeader } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';

//Material UI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Snackbar, Alert, AlertTitle, Divider, Badge, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import AddCommentIcon from '@mui/icons-material/AddComment';
import MessageIcon from '@mui/icons-material/Message';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import GradingIcon from '@mui/icons-material/Grading';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import CommentIcon from '@mui/icons-material/Comment';
import AddTaskIcon from '@mui/icons-material/AddTask';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

//Importaciones
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';
import { ObtenerUsuario, config, ValidarUsuario, useWindowSize, listarPermisos, ValidarPermisos, LIST_SCREENS } from '../../config/utils'
import moment from 'moment';

import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

function Suministro(props) {
    ChartJS.register(...registerables);
    const navigate = useNavigate();

    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //hook actualizacion tamaño de ventana
    const size = useWindowSize();

    //
    const [suministroResumen, setsuministroResumen] = useState([]);
    const [suministroConsumos, setsuministroConsumos] = useState([]);
    const [suministroDeteccion, setsuministroDeteccion] = useState([]);
    const [suministroRecupero, setsuministroRecupero] = useState([]);
    const [dataChartsLectura, setdataChartsLectura] = useState(null);
    const [dataChartsConsumo, setdataChartsConsumo] = useState(null);
    const [detalleDeteccionActual, setdetalleDeteccionActual] = useState({});
    const [detalleRecuperoActual, setdetalleRecuperoActual] = useState({});
    //AlertaSuministro
    const [suministroAlertas, setsuministroAlertas] = useState([]);
    const [mostrarModalAgregarSuministros, setmostrarModalAgregarSuministros] = useState(false);

    //Modales
    const [abrirModalDetalleDeteccion, setabrirModalDetalleDeteccion] = useState(false);
    const [abrirModalDetalleRecupero, setabrirModalDetalleRecupero] = useState(false);
    //Modal Observaciones
    const [abrirModalAgregarObservacion, setabrirModalAgregarObservacion] = useState(false);
    const [abrirModalDetalleAlertaSuministro, setabrirModalDetalleAlertaSuministro] = useState(false);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const inputBuscar = useRef();
    //Referencias Observacion Suministro
    const inputObservacionSuministro = useRef();
    const selectPrioridadObservacion = useRef();
    const inputListaBuscar = useRef();

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_SUMINISTRO, permisos)) {

                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }

    const BuscarSuministroResumen = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            //Reiniciar
            setsuministroAlertas([]);
            setsuministroResumen([]);
            setsuministroConsumos([]);
            setsuministroDeteccion([]);
            setsuministroRecupero([]);
            setdataChartsLectura(null);
            setdataChartsConsumo(null);

            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/Suministro/Resumen`;
            console.log(url);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ suministro: inputBuscar.current.value })
            };

            // console.log(headers);

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {

                if (data.success && data.contentSuministro.length > 0) {

                    let idbasesuministro = data.contentSuministro[0].id_base_suministro;
                    BuscarAlertasSuministro(idbasesuministro);
                    setsuministroResumen(data.contentSuministro);
                    setsuministroConsumos(data.contentConsumo);
                    setsuministroDeteccion(data.contentDeteccion);
                    setsuministroRecupero(data.contentRecupero);
                    //Buscar  Alertas Suministro


                    let arrayLabels = [];
                    let arrayLecturas = [];
                    let arrayPromedio = []
                    let arrayConsumos = []

                    for (let x = data.contentConsumo.length - 1; x >= 0; x--) {
                        arrayLabels.push(data.contentConsumo[x].periodo_comercial);
                        arrayLecturas.push(data.contentConsumo[x].utlima_lectura);
                        arrayConsumos.push(data.contentConsumo[x].cea_consumo);
                        arrayPromedio.push(data.contentConsumo[x].promedio);
                    }

                    let dataChartLecturaTemp = {
                        // labels: ['RECLAMOS', 'NUEVOS SUMINISTROS', 'MANTENIMINETOS', 'AP Y TOTALIZADRORES', 'CONTRASTES', 'ZONA PEDREGAL', 'ZONAL CAMANA'],
                        labels: arrayLabels,
                        datasets: [
                            {
                                type: 'line',
                                label: 'Lecturas',
                                backgroundColor: 'rgba(54, 162, 235,0.3)',
                                borderColor: 'rgba(54, 162, 235,0.9)',
                                borderWidth: 2,
                                fill: false,
                                data: arrayLecturas
                                // data: [85, 66, 45, 29, 37, 115, 96],
                            },
                        ],
                    };

                    let dataChartConsumosTemp = {
                        // labels: ['RECLAMOS', 'NUEVOS SUMINISTROS', 'MANTENIMINETOS', 'AP Y TOTALIZADRORES', 'CONTRASTES', 'ZONA PEDREGAL', 'ZONAL CAMANA'],
                        labels: arrayLabels,
                        datasets: [
                            {
                                type: 'bar',
                                label: 'Consumo',
                                backgroundColor: 'rgba(255, 99, 132,0.3)',
                                borderColor: 'rgba(255, 99, 132)',
                                borderWidth: 2,
                                data: arrayConsumos
                                // data: [86, 60, 20, 20, 37, 115, 83],
                                // borderColor: 'white',
                            },
                            {
                                type: 'line',
                                label: 'Promedio Consumo',
                                backgroundColor: 'rgba(54, 162, 235,0.3)',
                                borderColor: 'rgba(54, 162, 235,0.9)',
                                borderWidth: 2,
                                data: arrayPromedio
                                // data: [86, 60, 20, 20, 37, 115, 83],
                                // borderColor: 'white',
                            }
                        ],
                    };

                    setdataChartsLectura(dataChartLecturaTemp);
                    setdataChartsConsumo(dataChartConsumosTemp);

                } else {
                    settituloToast("alerta");
                    settipoToast("warning");
                    setmensajeToast(`No se encontraron datos para mostrar.`)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const KeyPressBuscar = (e) => {
        if (e.keyCode == 13) {
            BuscarSuministroResumen();
        }
    }
    const MostrarModalDetecciones = (row) => {
        setdetalleDeteccionActual(row);
        setabrirModalDetalleDeteccion(true);
    }
    const MostrarMotalRecupero = (row) => {
        console.log(row);
        setdetalleRecuperoActual(row);
        setabrirModalDetalleRecupero(true);
    }
    //************************************************* */
    //MODAL AGREGAR OBSERVACION SUMINISTRO */
    //************************************************* */
    const ValidarControlesObservacion = () => {
        let error = 0;
        if (inputObservacionSuministro.current.value == '') {
            inputObservacionSuministro.current.classList.add('is-invalid');
            error++;
        } else {
            inputObservacionSuministro.current.classList.remove('is-invalid');
        }

        if (selectPrioridadObservacion.current.value == '') {
            selectPrioridadObservacion.current.classList.add('is-invalid');
            error++;
        } else {
            selectPrioridadObservacion.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const AgregarObservacionSuministro = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            console.log(suministroResumen);
            if (ValidarControlesObservacion()) {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/AlertaSuministro/Agregar`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idbasesuministro: suministroResumen[0].id_base_suministro,
                        observacion: inputObservacionSuministro.current.value,
                        prioridad: selectPrioridadObservacion.current.value,
                        suministro: suministroResumen[0].codigo_suministro
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        setabrirModalAgregarObservacion(false);
                        //actualizar                        
                        BuscarAlertasSuministro(suministroResumen[0].id_base_suministro);
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message);
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const BuscarAlertasSuministro = (idbasesuministro) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/AlertaSuministro/BuscarAlertaSuministro/${idbasesuministro}`;

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    //actualizar    
                    setsuministroAlertas(data.content);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(data.message);
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        }
    }
    const MostrarModalDetalleAlertaSuministro = (idbasesuministro) => {
        setabrirModalDetalleAlertaSuministro(true);
        BuscarAlertasSuministro(idbasesuministro)
    }

    // Agregar Lista  Busqueda
    const ListarvaloresBuscar = () => {
        if (inputListaBuscar.current.value !== '') {
            inputListaBuscar.current.classList.remove('is-invalid');

            let arrayLista = inputListaBuscar.current.value.split(/\r\n|\r|\n/);
            let listaBuscar = "";

            arrayLista.forEach(elemento => {
                if (elemento.trim() !== '') {
                    listaBuscar = listaBuscar + elemento.trim() + ",";
                }
            });

            listaBuscar = listaBuscar.substring(0, listaBuscar.length - 1);
            inputBuscar.current.value = listaBuscar;

            setmostrarModalAgregarSuministros(false);
        } else {
            inputListaBuscar.current.clasList.add('is-invalid');
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`No se encontro cadena para realizar esta accion.`)
            setabrirToast(true);
        }
    }

    return (
        <Fragment>
            {
                suministroResumen.length > 0 ?
                    <div style={{ position: 'fixed', right: '20px', bottom: '20px' }}>
                        <Box >
                            <Fab onClick={() => { setabrirModalAgregarObservacion(true) }} color="primary" aria-label="add">
                                <AddCommentIcon />
                            </Fab>
                        </Box>
                    </div>
                    : <></>
            }
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración / Agrupacion'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Suministro</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/dashboard') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded my-3'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4}>
                            <Stack direction='horizontal' >
                                <Form.Control onKeyDown={(e) => { KeyPressBuscar(e) }} onChange={() => { }} ref={inputBuscar} placeholder='Suministro(s) a buscar.' size='sm'></Form.Control>
                                <IconButton onClick={() => { setmostrarModalAgregarSuministros(true) }}>
                                    <PlaylistAddIcon></PlaylistAddIcon>
                                </IconButton>
                            </Stack>
                        </Col>
                        {/* <Col sm={12} md={4} className=''>
                        </Col> */}
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button onClick={() => { BuscarSuministroResumen() }} variant='primary' size='sm'>
                                Buscar
                            </Button>
                        </Col>
                    </Row>
                    <hr></hr>
                    {
                        suministroResumen.length > 0 ?
                            <Row className='mt-2'>
                                <Accordion>
                                    <Accordion.Item>
                                        <Accordion.Header>{`Suministro ${suministroResumen[0].codigo_suministro}`}</Accordion.Header>
                                        <Accordion.Body>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Nombre: </strong>
                                                        <Form.Text className='mx-2'>{suministroResumen[0].nombre_suministro}</Form.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Dirección: </strong>
                                                        <Form.Text className='mx-2'>{suministroResumen[0].direccion_suministro}</Form.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Tarifa :</strong>
                                                        <Form.Text className='mx-2'>{suministroResumen[0].tarifa}</Form.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>SED :</strong>
                                                        <Form.Text className='mx-2'>{suministroResumen[0].sed_suministro}</Form.Text>
                                                    </Col>

                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>SET :</strong>
                                                        <Form.Text className='mx-2'>{suministroResumen[0].set_suministro}</Form.Text>
                                                    </Col>

                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>AMT :</strong>
                                                        <Form.Text className='mx-2'>{suministroResumen[0].amt_suministro}</Form.Text>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Zona Administrativa :</strong>
                                                        <Form.Text className='mx-2'>{suministroResumen[0].zona_administrativa}</Form.Text>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Sistema Electrico :</strong>
                                                        <Form.Text className='mx-2'>{suministroResumen[0].sistema_electrico}</Form.Text>
                                                    </Col>
                                                    {
                                                        suministroAlertas.length > 0 ?
                                                            <Col className='text-end'>
                                                                <IconButton onClick={() => {
                                                                    MostrarModalDetalleAlertaSuministro(suministroResumen[0].id_base_suministro)
                                                                }}>
                                                                    <Badge badgeContent={suministroAlertas.length} color='secondary'>
                                                                        <CommentIcon color='primary'></CommentIcon>
                                                                    </Badge>
                                                                </IconButton>
                                                            </Col>
                                                            : <></>
                                                    }
                                                </Row>

                                            </Container>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Row>
                            : <></>
                    }

                    {
                        suministroConsumos.length > 0 ?
                            <Row className='mt-2'>
                                <Col >
                                    <Accordion>
                                        <Accordion.Item>
                                            <Accordion.Header>Resumen Consumos</Accordion.Header>
                                            <Accordion.Body>
                                                <Container fluid>
                                                    <Row>
                                                        <TableContainer style={{ maxHeight: '300px' }}>
                                                            <Table size='small' stickyHeader>
                                                                <TableHead>
                                                                    <TableRow >
                                                                        <TableCell>Periodo</TableCell>
                                                                        <TableCell>Lectura</TableCell>
                                                                        <TableCell>Consumo</TableCell>
                                                                        <TableCell>Fecha Lectura</TableCell>
                                                                        <TableCell>Cod Lectura</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        suministroConsumos.map((row, index) => {
                                                                            return (
                                                                                <TableRow key={index}>
                                                                                    <TableCell >
                                                                                        {row.periodo_comercial}
                                                                                    </TableCell>
                                                                                    <TableCell >
                                                                                        {row.utlima_lectura}
                                                                                    </TableCell>
                                                                                    <TableCell >
                                                                                        {row.cea_consumo}
                                                                                    </TableCell>
                                                                                    <TableCell >
                                                                                        {moment(row.fecha_lectura_mes_actual).format("DD/MM/YYYY")}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {row.codigo_lectura_mes_actual}
                                                                                    </TableCell>
                                                                                </TableRow>

                                                                            )
                                                                        })
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    </Row>
                                                    <hr></hr>

                                                    {
                                                        dataChartsLectura ?
                                                            <Row>
                                                                <Col sm={12} md={6}>
                                                                    <Line data={dataChartsLectura} options={
                                                                        {
                                                                            responsive: true,
                                                                            maintainAspectRatio: false,
                                                                            plugins: {
                                                                                legend: {
                                                                                    position: 'top',
                                                                                },
                                                                                title: {
                                                                                    display: true,
                                                                                    text: 'Resumen Lecturas',
                                                                                }
                                                                            }
                                                                        }
                                                                    } />
                                                                </Col>
                                                                <Col sm={12} md={6}>
                                                                    <Bar data={dataChartsConsumo} options={
                                                                        {
                                                                            responsive: true,
                                                                            maintainAspectRatio: false,
                                                                            plugins: {
                                                                                legend: {
                                                                                    position: 'top',
                                                                                },
                                                                                title: {
                                                                                    display: true,
                                                                                    text: 'Resumen Consumos',
                                                                                }
                                                                            }
                                                                        }
                                                                    } />
                                                                </Col>
                                                            </Row> : <></>

                                                    }
                                                </Container>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>

                            : <></>
                    }
                    {
                        suministroDeteccion.length > 0 ?
                            <Row className='mt-2'>
                                <Accordion>
                                    <Accordion.Item>
                                        <Accordion.Header>Resumen Detecciones</Accordion.Header>
                                        <Accordion.Body>

                                            <TableContainer style={{ maxHeight: '300px' }}>
                                                <Table size='small' stickyHeader>
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell>Acciones</TableCell>
                                                            <TableCell>Fecha Intervencion</TableCell>
                                                            <TableCell>Detalle</TableCell>
                                                            <TableCell>Observaciones</TableCell>
                                                            <TableCell>Area Procedencia</TableCell>
                                                            <TableCell>zona</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            suministroDeteccion.map((row, index) => {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell >
                                                                            <IconButton onClick={() => { MostrarModalDetecciones(row) }}>
                                                                                <WysiwygIcon></WysiwygIcon>
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            {row.fecha_intervencion}
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            {row.detalle}
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            {row.observaciones}
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            {row.area_procedencia}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.zona}
                                                                        </TableCell>
                                                                    </TableRow>

                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Row>
                            : <></>
                    }
                    {
                        suministroRecupero.length > 0 ?
                            <Row className='mt-2'>
                                <Accordion>
                                    <Accordion.Item>
                                        <Accordion.Header>Resumen Recuperos</Accordion.Header>
                                        <Accordion.Body>

                                            <TableContainer style={{ maxHeight: '300px' }}>
                                                <Table size='small' stickyHeader>
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell>Acciones</TableCell>
                                                            <TableCell>Fecha</TableCell>
                                                            <TableCell>Oficina</TableCell>
                                                            <TableCell>Descripción</TableCell>
                                                            <TableCell>Mes Proceso</TableCell>
                                                            <TableCell>JUO</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            suministroRecupero.map((row, index) => {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell >
                                                                            <IconButton onClick={() => { MostrarMotalRecupero(row) }}>
                                                                                <WysiwygIcon></WysiwygIcon>
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            {row.fecha}
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            {row.oficina}
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            {row.descripcion_irregularidad}
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            {row.mes_proceso}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {row.juo}
                                                                        </TableCell>
                                                                    </TableRow>

                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Row>
                            : <></>
                    }

                </Container>
            </div>


            {/* Modal Agregar Observacion */}
            <Modal
                show={abrirModalAgregarObservacion}
                onHide={() => { setabrirModalAgregarObservacion(false) }}
                keyboard={false}
                centered
            >

                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <Container>
                        <h5>Agregar Observacion</h5>
                        <hr></hr>
                        <Row>
                            <Col>
                                <small className='text-primary'>Observación</small>
                                <Form.Control ref={inputObservacionSuministro} size='sm' as="textarea" rows={3} placeholder='Observación'></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className='text-primary'>Prioridad</small>
                                <Form.Select ref={selectPrioridadObservacion} size='sm' placeholder='Prioridad' defaultValue="">
                                    <option value="">Seleccione un elemento</option>
                                    <option value="NORMAL">NORMAL</option>
                                    <option value="URGENTE">URGENTE</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button onClick={() => { setabrirModalAgregarObservacion(false) }} variant='outline-primary' size='sm' >Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button size='sm' onClick={() => { AgregarObservacionSuministro() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            {/* Modal Detalle Observacion */}
            <Modal
                show={abrirModalDetalleAlertaSuministro}
                onHide={() => { setabrirModalDetalleAlertaSuministro(false) }}
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header>
                    <Modal.Title>
                        Resumen  Alertas
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <TableContainer style={{ maxHeight: '300px' }}>
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell>*</TableCell>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Suministro</TableCell>
                                                <TableCell>Obs.</TableCell>
                                                <TableCell>Prioridad</TableCell>
                                                <TableCell>Usuario Atiende</TableCell>
                                                <TableCell>Obs. Atención</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                suministroAlertas.map((row, index) => {
                                                    let usuarioAtiende = "";
                                                    if (Object.entries(row.usuario_atiende) > 0) {
                                                        usuarioAtiende = row.usuario_atiende
                                                    }
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell >
                                                                <IconButton>
                                                                    <Tooltip title="Atender Observación">
                                                                        <AddTaskIcon></AddTaskIcon>
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell >
                                                                {moment(row.fecha_crea).format('yyyy-MM-DD hh:mm:ss')}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.codigo_suministro}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.observacion}
                                                            </TableCell>
                                                            <TableCell className={row.prioridad == "URGENTE" ? 'text-danger' : 'text-primary'}>
                                                                {row.prioridad}
                                                            </TableCell>
                                                            <TableCell >
                                                                {row.usuario_atiende}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.observacion_atencion}
                                                            </TableCell>
                                                        </TableRow>

                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className='text-end'>
                                <Button size='sm' onClick={() => { setabrirModalDetalleAlertaSuministro(false) }} variant='outline-primary'>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            {/* Modal Detecciones*/}
            <Modal
                show={abrirModalDetalleDeteccion}
                onHide={() => { setabrirModalDetalleDeteccion(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Detalle Detección
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Suministro: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.suministro}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Zona: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.zona}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Sistema: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.sistema}</Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <strong className='text-muted'>SED: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.sed}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>SET: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.deteccion_set}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Distrito: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.distrito}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Alimentador: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.alimentador}</Form.Text>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Fecha Intervención: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.fecha_intervencion}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Observaciones: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.observaciones}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Historia: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.historia}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Detalle: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.detalle}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Monto: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.monto}</Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <strong className='text-muted'>Kwh: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.kwh}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Mes Proceso: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.mes_proceso}</Form.Text>
                            </Col>
                        </Row>



                        <Row>
                            <Col>
                                <strong className='text-muted'>Guion: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.guion}</Form.Text>
                            </Col>
                        </Row>




                        <Row>
                            <Col>
                                <strong className='text-muted'>Cantidad: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.cantidad}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Area Procedencia: </strong>
                                <Form.Text className='mx-2'>{detalleDeteccionActual.area_procedencia}</Form.Text>
                            </Col>
                        </Row>


                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className='text-end'>
                            <Button size='sm' variant='outline-primary' onClick={() => { setabrirModalDetalleDeteccion(false) }}>Cerrar</Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal>
            {/* Modal Recuperos */}
            <Modal
                show={abrirModalDetalleRecupero}
                onHide={() => { setabrirModalDetalleRecupero(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Detalle Recupero
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Suministro: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.suministro}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Zona: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.zona}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Zonal: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.zonal}</Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <strong className='text-muted'>SED: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.recupero_sed}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Sistema Electrico: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.sistema_electrico}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>SET: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.recupero_set}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Distrito: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.distrito}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Alimentador: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.alimentador}</Form.Text>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Fecha: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.fecha}</Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <strong className='text-muted'>Observación: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.observacion}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Descripcion Irregularidad: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.descripcion_irregularidad}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Tipo: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.tipo}</Form.Text>
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                                <strong className='text-muted'>Semana: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.semana}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>SED programado de balance: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.sed_programada_de_balance}</Form.Text>
                            </Col>
                        </Row>




                        <Row>
                            <Col>
                                <strong className='text-muted'>Oficina: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.oficina}</Form.Text>
                            </Col>
                        </Row>



                        <Row>
                            <Col>
                                <strong className='text-muted'>Monto: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.monto}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>kwh: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.kwh}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Mes Proceso: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.mes_proceso}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Mes Ejecución: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.mes_ejecucion}</Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <strong className='text-muted'>Cod: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.cod}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Carta: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.carta}</Form.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong className='text-muted'>Cantidad: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.cantidad}</Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <strong className='text-muted'>Acta Intervención: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.acta_intervencion}</Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <strong className='text-muted'>JUO: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.juo}</Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <strong className='text-muted'>Supervisor: </strong>
                                <Form.Text className='mx-2'>{detalleRecuperoActual.supervisor}</Form.Text>
                            </Col>
                        </Row>


                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className='text-end'>
                            <Button size='sm' variant='outline-primary' onClick={() => { setabrirModalDetalleRecupero(false) }}>Cerrar</Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal>

            {/* Modal Agregar Suministros */}
            <Modal
                show={mostrarModalAgregarSuministros}
                onHide={() => { setmostrarModalAgregarSuministros(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Lista
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <Form.Control ref={inputListaBuscar} as='textarea' rows={6} placeholder='Agregar Lista' size='sm'></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <Button variant='primary' onClick={() => { ListarvaloresBuscar() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </Fragment>
    );
}

export default Suministro;