import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Alertas from '../suministro/alertas';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PolicyIcon from '@mui/icons-material/Policy';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SettingsIcon from '@mui/icons-material/Settings';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Snackbar, Alert, AlertTitle } from '@mui/material'

//Importaciones
import { ValidarUsuario, listarPermisos, LIST_SCREENS, ValidarPermisos } from '../../config/utils'

function Dashboard(props) {
    const navigate = useNavigate();
    //controles Toast
    const [tituloToast, settituloToast] = useState("");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipoToast, settipoToast] = useState("error");
    const [mensajeToast, setmensajeToast] = useState("");

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    useEffect(() => {
        ValidarAccesos();
    }, []);


    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {   
                // console.log(permisos);             
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_DASHBOARD, permisos)) {

                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }

    return (
        <Fragment>
            <Alertas></Alertas>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipoToast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajeToast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas'></Header>
            <Container className='mt-4'>

                <Row>
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <QueryStatsIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></QueryStatsIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/balance'); }}>Balances</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_DETECCIONES, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <PolicyIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></PolicyIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/detecciones') }}>Detecciones</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_RECUPEROS, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <CurrencyExchangeIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></CurrencyExchangeIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/recuperos') }}>Recuperos</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_SUMINISTRO, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <ElectricMeterIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></ElectricMeterIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/suministro') }}>Buscar Suministro</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALERTAS_SUMINISTRO, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <ReportProblemIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></ReportProblemIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/alertas') }}>Alertas Suministro</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }                    
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <SettingsIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></SettingsIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/configuracion') }}>Configuración</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }

                </Row>
            </Container>

        </Fragment>
    );
}

export default Dashboard;

