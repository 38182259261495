import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../dashboard/Header';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle } from '@mui/material';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Person2Icon from '@mui/icons-material/Person2';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import ChaletIcon from '@mui/icons-material/Chalet';
import { ValidarUsuario, listarPermisos, ValidarPermisos, LIST_SCREENS } from '../../config/utils';

function MenuConfiguracion(props) {
    const navigate = useNavigate();

    //toast y progress Bar    
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    useEffect(() => {
        ValidarAccesos();
    }, [])
    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION, permisos)) {

                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }

    return (
        <Fragment>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Configuración</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/dashboard') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <Container className='mt-4'>
                <Row>
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_PERSONAL, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <Person2Icon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></Person2Icon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/configuracion/trabajador'); }}>Personal</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_PANTALLAS, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <ViewCarouselIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></ViewCarouselIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/configuracion/pantallas') }}>Pantallas</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_TIPO_USUARIO, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <VerifiedUserIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></VerifiedUserIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/configuracion/permisos') }}>Permisos</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_AGRUPACIONES, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <WorkspacesIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></WorkspacesIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/configuracion/agrupaciones') }}>Agrupaciones</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_VARIABLES, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <FilterAltIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></FilterAltIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/configuracion/variables') }}>Variables</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_BASE_SED, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <OfflineBoltIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></OfflineBoltIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/configuracion/basesed') }}>Base Sed</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_CODIGO_LECTURA, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <DynamicFormIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></DynamicFormIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/configuracion/codigolectura') }}>Codigo Lectura</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_CODIGO_LECTURA, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <ChaletIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></ChaletIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/configuracion/detallevivienda') }}>Detalle Vivienda</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                </Row>

            </Container>
        </Fragment>
    );
}

export default MenuConfiguracion;