import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Bootstrap
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle } from '@mui/material';
//Material Grid
import { DataGrid,esES } from '@mui/x-data-grid';

//Material Icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';

//Importaciones
import Header from '../dashboard/Header';
import PaginaCarga from '../../config/PaginaCarga';
import { LEER_EXCEL_DE_ARCHIVO, ObtenerUsuario, config, ValidarUsuario,listarPermisos,ValidarPermisos,LIST_SCREENS } from '../../config/utils';
import moment from 'moment-timezone';
import 'moment/locale/es';

function CargarRecuperos(props) {

    const navigate = useNavigate();

    //toast y progress Bar
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //Modales
    const [mostrarModalEliminarArchivo, setmostrarModalEliminarArchivo] = useState(false);
    const [elementoActual, setelementoActual] = useState(null);

    const [dataArchivoRecupero, setdataArchivoRecupero] = useState([]);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const intputFileRecupero = useRef();
    const inputfiltroRecupero = useRef();
    
    const columns = [
        {
            field: 'accion', headerName: 'Acción', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {                            
                            setelementoActual(cellValues);
                            setmostrarModalEliminarArchivo(true);
                        }}>
                            <DeleteIcon></DeleteIcon>
                        </IconButton>

                    </>
                );
            }
        },
        { field: 'nombrearchivo', headerName: 'Nombre Archivo', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'fechacarga', headerName: 'Fecha Carga', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        // { field: 'periodo', headerName: 'Periodo', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'usuario', headerName: 'Usuario', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'cantregistros', headerName: 'Cant. Registros', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },

    ];
    useEffect(() => {
        ValidarAccesos();
    }, []);
    //Procedimientos y Funciones

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_RECUPEROS_CARGAR, permisos)) {
                    BuscarListaArchivos();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }       
    }

    const ValidarCargaArchivo = () => {
        let error = 0;
        if (intputFileRecupero.current.files.length > 0) {
            intputFileRecupero.current.classList.remove('is-invalid');
        } else {
            intputFileRecupero.current.classList.add('is-invalid');
            settipoToast("Error");
            settipoToast("error");
            setmensajeToast("Archivo no encontrado o no se ha seleccionado el archivo.");
            setabrirToast(true);
            error++;
        }

        if (error > 0) return false;

        return true;
    }

    const CargaArchivo = async () => {
        if (ValidarCargaArchivo()) {
            setmostrarCircularProgress(true);
            await LEER_EXCEL_DE_ARCHIVO(intputFileRecupero.current.files[0]).then(async (dataXls) => {
                if (dataXls.length <= 0) {
                    throw new Error("No se encontraron registros en el archivo .");
                }

                let dataEnviar = [];
                // console.log(dataXls.length);
                for (let x = 0; x < dataXls.length; x++) {
                    let nuevaFila = {};
                    //Normalizando Informacion
                    for (var clave in dataXls[x]) {
                        //Verificando Claves Existes
                        if (dataXls[x].hasOwnProperty(clave)) {
                            //Verificando si la Clave es Cabecera o Material 
                            switch (clave.toUpperCase().trim()) {    
                                case 'FECHA':
                                    let fecha = dataXls[x][clave];
                                    let nFecha = moment(fecha).format('YYYY-MM-DD')
                                    nuevaFila.Fecha = nFecha.toString();
                                    break;
                                case 'OFICINA':
                                    nuevaFila.Oficina = dataXls[x][clave];
                                    break;                                    
                                case 'SUMINISTRO':
                                    nuevaFila.Suministro = dataXls[x][clave];
                                    break;                                    
                                case 'ZONA':
                                    nuevaFila.Zona = dataXls[x][clave];
                                    break;
                                case 'ACTA DE INTERVENCION':
                                    nuevaFila.ActaIntervencion = dataXls[x][clave];
                                    break;
                                case 'TIPO':
                                    nuevaFila.Tipo = dataXls[x][clave];
                                    break;
                                case 'COD':
                                    nuevaFila.Cod = dataXls[x][clave];
                                    break;
                                case 'DESCRIPCION DE LA IRREGULARIDAD':
                                    nuevaFila.DescripcionIrregularidad = dataXls[x][clave];
                                    break;
                                case 'OBSERVACION':
                                    nuevaFila.Observacion = dataXls[x][clave];
                                    break;
                                case 'CARTA':
                                    nuevaFila.Carta = dataXls[x][clave];
                                    break;
                                case 'KWH':
                                    nuevaFila.Kwh = dataXls[x][clave];
                                    break;
                                case 'MONTO':
                                    nuevaFila.Monto = dataXls[x][clave];
                                    break;
                                case 'MES PROCESO':
                                    let mesproceso=dataXls[x][clave];
                                    let nmesproceso=moment(mesproceso).tz("America/Lima").format("MMM YYYY");
                                    nuevaFila.MesProceso = nmesproceso.toString();
                                    break;
                                case 'MES EJECUCION':
                                    let mesejecucion=dataXls[x][clave];
                                    let nmesejecucion=moment(mesejecucion).tz("America/Lima").format("MMM YYYY");
                                    nuevaFila.MesEjecucion = nmesejecucion.toString();
                                    break;
                                case 'SEMANA':
                                    nuevaFila.Semana = dataXls[x][clave];
                                    break;
                                case 'JUO':
                                    nuevaFila.Juo = dataXls[x][clave];
                                    break;
                                case 'SUPERVISOR':
                                    nuevaFila.Supervisor = dataXls[x][clave];
                                    break;
                                case 'CANTIDAD':
                                    nuevaFila.Cantidad = dataXls[x][clave];
                                    break;
                                case 'SED PROGRAMADA DEL BALANCE':
                                    nuevaFila.SedProgramadaDeBalance = dataXls[x][clave];
                                    break;
                                case 'SED':
                                    nuevaFila.RecuperoSed = dataXls[x][clave];
                                    break;
                                case 'SISTEMA ELECTRICO':
                                    nuevaFila.SistemaElectrico = dataXls[x][clave];
                                    break;
                                case 'DISTRITO':
                                    nuevaFila.Distrito = dataXls[x][clave];
                                    break;
                                case 'ZONAL':
                                    nuevaFila.Zonal = dataXls[x][clave];
                                    break;
                                case 'SET':
                                    nuevaFila.RecuperoSet = dataXls[x][clave];
                                    break;
                                case 'ALIMENTADOR':
                                    nuevaFila.Alimentador = dataXls[x][clave];
                                    break;        
                            }
                        }
                    }
                    dataEnviar.push(nuevaFila);
                }
                
                if (dataEnviar.length <= 0) {
                    throw new Error("No se encontraron datos para procesar.");
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/recupero/archivos/subir`;

                let dataEnviarRecuperos = {
                    nombreArchivo: intputFileRecupero.current.files[0].name,
                    dataRecuperos: dataEnviar
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(dataEnviarRecuperos)
                };


                await fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        settipoToast("success");
                        settituloToast("Completado");
                    } else {
                        settipoToast("error");
                        settituloToast("Error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    BuscarListaArchivos();
                    setmostrarCircularProgress(false);

                }).catch(async (error) => {
                    throw new Error(`${config.MessageErrorServer}; ${error.toString()}`);
                });

                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(error.toString())
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }

    const BuscarListaArchivos = () => {
        if (ValidarUsuario()) {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/recupero/archivos/lista/50`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ filtro: inputfiltroRecupero.current.value })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: index, accion: '',
                            nombrearchivo: elemento.origen_carga,
                            fechacarga: moment(elemento.fecha_crea).format("YYYY-MM-DD HH:mm:ss"),                             
                            usuario: elemento.usuario_crea,
                            cantregistros: elemento.Registros
                        }
                    );
                });
                setdataArchivoRecupero(DataTemp);
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate("/login");
        }

    }

    const ELiminarListaArchivos = () => {

        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/recupero/archivos/eliminar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ nombreArchivo: elementoActual.row.nombrearchivo })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    BuscarListaArchivos();
                    setmostrarModalEliminarArchivo(false);
                }
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate('/login');
        }
    }

    const ActualizarInputBuscar = () => {
        if (inputfiltroRecupero.current.value.trim() === '') {
            BuscarListaArchivos()
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Balance BT'></Header>


            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Recuperos / Cargar</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/recuperos') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col md={6}>
                            <Form.Control ref={intputFileRecupero} type='file' size='sm'></Form.Control>
                        </Col>
                        <Col md={3} className='mt-1 mt-md-0 d-grid gap-2' >
                            <Button onClick={() => { CargaArchivo() }} variant='primary' size='sm' >Subir Archivo</Button>
                        </Col>                        
                    </Row>
                    <Row  className='mt-1'>
                        <Col md={3}>
                            <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputfiltroRecupero} placeholder='Valor a buscar' size='sm'></Form.Control>
                        </Col>
                        <Col md={2} className='mt-1 mt-md-0 d-grid' >
                            <Button onClick={()=>{BuscarListaArchivos()}} size='sm' variant='primary'>Buscar</Button>
                        </Col>
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid 
                            density='compact' 
                            rows={dataArchivoRecupero} 
                            columns={columns} 
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            />
                    </div>
                </div>
            </div>



            {/* Ventanas Modales */}
            {/* Modal Mensaje Confirmar Eliminar Archivo */}
            <Modal
                show={mostrarModalEliminarArchivo}
                onHide={() => { setmostrarModalEliminarArchivo(false) }}
                keyboard={false}
                centered
            >
                <Modal.Body >
                    <Container>
                        <Row className='mb-4'>
                            <Form.Label>
                                {
                                    elementoActual ?
                                        `¿Esta seguro de eliminar el contenido cargado del archivo ${elementoActual.row.nombrearchivo} con fecha ${moment( elementoActual.row.fechacarga).format("DD/MM/YYYY")}?`
                                        : <></>
                                }
                            </Form.Label>
                        </Row>
                        <Row>

                            <Col>
                                <Button onClick={() => { setmostrarModalEliminarArchivo(false) }} variant='outline-primary'>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button onClick={() => { ELiminarListaArchivos() }}>Aceptar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default CargarRecuperos;