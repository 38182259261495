import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, Modal, Stack, Accordion, FormControl, ModalHeader } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';

//Material UI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Snackbar, Alert, AlertTitle, Divider, Badge, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import AddCommentIcon from '@mui/icons-material/AddComment';
import MessageIcon from '@mui/icons-material/Message';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import GradingIcon from '@mui/icons-material/Grading';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import CommentIcon from '@mui/icons-material/Comment';
import AddTaskIcon from '@mui/icons-material/AddTask';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

//Importaciones
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';
import { ObtenerUsuario, config, ValidarUsuario, useWindowSize, listarPermisos, ValidarPermisos, LIST_SCREENS } from '../../config/utils'
import moment from 'moment';

import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

function ScriptSQL(props) {
    ChartJS.register(...registerables);
    const navigate = useNavigate();

    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //hook actualizacion tamaño de ventana
    const size = useWindowSize();

    //
    const [suministroResumen, setsuministroResumen] = useState([]);
    const [suministroConsumos, setsuministroConsumos] = useState([]);
    const [suministroDeteccion, setsuministroDeteccion] = useState([]);
    const [suministroRecupero, setsuministroRecupero] = useState([]);
    const [dataChartsLectura, setdataChartsLectura] = useState(null);
    const [dataChartsConsumo, setdataChartsConsumo] = useState(null);
    const [detalleDeteccionActual, setdetalleDeteccionActual] = useState({});
    const [detalleRecuperoActual, setdetalleRecuperoActual] = useState({});
    //AlertaSuministro
    const [suministroAlertas, setsuministroAlertas] = useState([]);
    const [mostrarModalAgregarSuministros, setmostrarModalAgregarSuministros] = useState(false);

    //Modales
    const [abrirModalDetalleDeteccion, setabrirModalDetalleDeteccion] = useState(false);
    const [abrirModalDetalleRecupero, setabrirModalDetalleRecupero] = useState(false);
    //Modal Observaciones
    const [abrirModalAgregarObservacion, setabrirModalAgregarObservacion] = useState(false);
    const [abrirModalDetalleAlertaSuministro, setabrirModalDetalleAlertaSuministro] = useState(false);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const inputBuscar = useRef();
    //Referencias Observacion Suministro
    const inputObservacionSuministro = useRef();
    const selectPrioridadObservacion = useRef();
    const inputListaBuscar = useRef();

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_SUMINISTRO, permisos)) {

                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }

    const EjecutarConsulta = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            //Reiniciar           
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/script/ExecNonQuery`;
            console.log(url);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ consulta: inputBuscar.current.value })
            };

            // console.log(headers);

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {

                if(data.success){
                    settituloToast("Completado");
                    settipoToast("success");
                }else{
                    settituloToast("Error");
                    settipoToast("error");
                }                
                setmensajeToast(`${data.message} `)
                setabrirToast(true);
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const KeyPressBuscar = (e) => {
        if (e.keyCode == 13) {
            EjecutarConsulta();
        }
    }
  

    return (
        <Fragment>
            {
                suministroResumen.length > 0 ?
                    <div style={{ position: 'fixed', right: '20px', bottom: '20px' }}>
                        <Box >
                            <Fab onClick={() => { setabrirModalAgregarObservacion(true) }} color="primary" aria-label="add">
                                <AddCommentIcon />
                            </Fab>
                        </Box>
                    </div>
                    : <></>
            }
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración / Agrupacion'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Suministro</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/dashboard') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded my-3'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={10}>
                            <Stack direction='horizontal' >
                                <Form.Control as="textarea" onKeyDown={(e) => { KeyPressBuscar(e) }} onChange={() => { }} ref={inputBuscar} placeholder='Script : Ejm. select * from ta_ejemplo' size='sm'></Form.Control>                                
                            </Stack>
                        </Col>
                        {/* <Col sm={12} md={4} className=''>
                        </Col> */}
                        <Col sm={12} md={2} className={size.width < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button onClick={() => { EjecutarConsulta() }} variant='primary' size='sm'>
                                Consulta
                            </Button>
                        </Col>
                    </Row>
                    <hr></hr>
                    
                </Container>
            </div>


            {/* Modal Agregar Suministros */}
            {/* <Modal
                show={mostrarModalAgregarSuministros}
                onHide={() => { setmostrarModalAgregarSuministros(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Lista
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <Form.Control ref={inputListaBuscar} as='textarea' rows={6} placeholder='Agregar Lista' size='sm'></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <Button variant='primary' onClick={() => { ListarvaloresBuscar() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal> */}

        </Fragment>
    );
}

export default ScriptSQL;