import React, { Fragment, useState, useEffect, useRef } from 'react';
import Header from '../../dashboard/Header'

import { Await, useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Modal, Stack, Accordion, FormSelect, FormControl } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import TableB from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

//Material UI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Badge, Snackbar, Alert, AlertTitle, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ButtonMUI from '@mui/material/Button';
import { DataGrid, esES } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PolicyIcon from '@mui/icons-material/Policy';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SmsIcon from '@mui/icons-material/Sms';
import SaveIcon from '@mui/icons-material/Save';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';

//Autocomplete
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//Mapa Leaflet
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Pin1, Pin2 } from '../../../config/iconos'
import 'leaflet/dist/leaflet.css';

import PaginaCarga from '../../../config/PaginaCarga';

//Importaciones
import { ValidarUsuario, ObtenerUsuario, config, LIST_SCREENS, listarPermisos, ValidarPermisos, CONVERTIR_JSON_A_EXCEL } from '../../../config/utils';
import moment from 'moment';
//Charts
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Line, PolarArea, Pie } from 'react-chartjs-2';

function DetalleMicrobalance(props) {
    ChartJS.register(...registerables);
    const navigate = useNavigate();

    const [objetoMenu, setobjetoMenu] = React.useState(null);

    const { idmicrobalance } = useParams();

    //toast y progress Bar
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [mostrarBarraProgreso, setmostrarBarraProgreso] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const [mostrarModalObservacion, setmostrarModalObservacion] = useState(false);

    //Modales
    const [mostrarModalModificarSuministro, setmostrarModalModificarSuministro] = useState(false);
    const [mostrarResumenMicrobalance, setmostrarResumenMicrobalance] = useState(false);
    const [mostrarModalHabilitarLectura, setmostrarModalHabilitarLectura] = useState(false);
    const [mostrarModalObservaciones, setmostrarModalObservaciones] = useState(false);
    const [mostrarModalResumenConsumos, setmostrarModalResumenConsumos] = useState(false);
    const [mostrarProyeccionCalculada, setmostrarProyeccionCalculada] = useState(false);
    const [mostrarModalAgregarTotalizador, setmostrarModalAgregarTotalizador] = useState(false);
    const [mostrarModalAgregarSuministro, setmostrarModalAgregarSuministro] = useState(false);
    const [mostrarModalMensajeConfirmacionAgregarSuministro, setmostrarModalMensajeConfirmacionAgregarSuministro] = useState(false);

    //Variables
    const [dataResumenMicrobalance, setdataResumenMicrobalance] = useState({
        fechaLectura1: '',
        fechaLectura2: '',
        diferenciaFechas: '',
        periodoComercial: '',
        diasfacturados: '',
        sed: '',
        calculomicrobalance: {
            consumoTotalTotalizadores: '',
            consumoTotalUsuarios: '',
            dif: '',
            porcPerdida: '',
            cantSum: '',
            kwh_sum: '',
        },
        proyectado: {
            consumoTotalTotalizadores: '',
            consumoTotalUsuarios: '',
            dif: '',
            porcPerdida: '',
            kwh_sum: '',
        },
        facturacion: {
            consumoTotalTotalizadores: '',
            consumoTotalUsuarios: '',
            dif: '',
            porcPerdida: '',
            kwh_sum: '',
        }
    });
    const [dataMatricesMicrobalances, setdataMatricesMicrobalances] = useState([]);
    //Data Microbalance General
    const [listaTotalizadores, setlistaTotalizadores] = useState([]);
    const [listaSuministros, setlistaSuministros] = useState([]);
    const [tipoLecturaProgramar, settipoLecturaProgramar] = useState('');

    //DataMicroBalance Totalizador
    const [dataResumenMicrobalanceSegmento, setdataResumenMicrobalanceSegmento] = useState({
        fechaLectura1: '',
        fechaLectura2: '',
        diferenciaFechas: '',
        periodoComercial: '',
        diasfacturados: '',
        sed: '',
        calculomicrobalance: {
            consumoTotalTotalizadores: '',
            consumoTotalUsuarios: '',
            dif: '',
            porcPerdida: '',
            cantSum: '',
            kwh_sum: '',
        },
        proyectado: {
            consumoTotalTotalizadores: '',
            consumoTotalUsuarios: '',
            dif: '',
            porcPerdida: '',
            kwh_sum: '',
        },
        facturacion: {
            consumoTotalTotalizadores: '',
            consumoTotalUsuarios: '',
            dif: '',
            porcPerdida: '',
            kwh_sum: '',
        }
    });
    const [listaTotalizadoresSegmento, setlistaTotalizadoresSegmento] = useState([]);
    const [listaSuministrosSegmento, setlistaSuministrosSegmento] = useState([]);
    const [gpsMostrarSegmento, setgpsMostrarSegmento] = useState('');
    const [centroTotalizador, setcentroTotalizador] = useState({ lat: -16.398821, lng: -71.536862 });

    //Variables - DetalleSuministro
    const [detalleSuministroMicrobalance, setdetalleSuministroMicrobalance] = useState(null);
    const [listaLectura1Suministro, setlistaLectura1Suministro] = useState(null);
    const [listaLectura2Suministro, setlistaLectura2Suministro] = useState(null);
    const [listaTotalizadoresSuministro, setlistaTotalizadoresSuministro] = useState([]);
    const [listaTotalizadoresTemporales, setlistaTotalizadoresTemporales] = useState([]);
    const [listaCodigosLectura, setlistaCodigosLectura] = useState([]);
    const [listaDetallesVivienda, setlistaDetallesVivienda] = useState([]);
    const [listaSubDetallevivienda, setlistaSubDetallevivienda] = useState([]);
    const [listaSubDetallevivienda2, setlistaSubDetallevivienda2] = useState([]);

    const [accionModificarFactorMedicion, setaccionModificarFactorMedicion] = useState(false);
    const [accionModificarLectura1, setaccionModificarLectura1] = useState(false);
    const [accionModificarLectura2, setaccionModificarLectura2] = useState(false);

    //Resumen Consumos Suministro
    const [suministroResumen, setsuministroResumen] = useState([]);
    const [suministroConsumos, setsuministroConsumos] = useState([]);
    const [suministroDeteccion, setsuministroDeteccion] = useState([]);
    const [suministroRecupero, setsuministroRecupero] = useState([]);
    const [dataChartsLectura, setdataChartsLectura] = useState(null);
    const [dataChartsConsumo, setdataChartsConsumo] = useState(null);
    // const [detalleDeteccionActual, setdetalleDeteccionActual] = useState({});
    // const [detalleRecuperoActual, setdetalleRecuperoActual] = useState({});
    const [suministroEncontrado, setsuministroEncontrado] = useState(null);
    const [suministroEncontradoActual, setsuministroEncontradoActual] = useState(null);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Habilitar Controles
    const [habilitarInputTotalizador1, sethabilitarInputTotalizador1] = useState(false);
    const [habilitarInputLectura1, sethabilitarInputLectura1] = useState(false);
    const [habiliarInputDetalleVivienda1, sethabiliarInputDetalleVivienda1] = useState(false);
    const [habilitarInputNivelConsumo1, sethabilitarInputNivelConsumo1] = useState(false);
    const [habilitarInputAgregarLecturaFin1, sethabilitarInputAgregarLecturaFin1] = useState(false);

    const [habilitarInputTotalizador2, sethabilitarInputTotalizador2] = useState(false);
    const [habilitarInputLectura2, sethabilitarInputLectura2] = useState(false);
    const [habiliarInputDetalleVivienda2, sethabiliarInputDetalleVivienda2] = useState(false);
    const [habilitarInputNivelConsumo2, sethabilitarInputNivelConsumo2] = useState(false);
    const [habilitarInputAgregarLecturaFin2, sethabilitarInputAgregarLecturaFin2] = useState(false);

    //Referencias
    const inputFechaProgramacionLectura = useRef();
    const inputTipoProgramacionLectura = useRef();
    //Referencias SuministroDetalle
    const inputFactorMedicion = useRef();
    const selectTotalizadorAsociado1 = useRef();
    const inputLectura1 = useRef();
    const inputLecturaFin1 = useRef();
    const selectCodigoLectura1 = useRef();
    const selectDetalleVivienda1 = useRef();
    const selectNivelConsumo1 = useRef();
    const inputObservacion1 = useRef();

    const selectTotalizadorAsociado2 = useRef();
    const inputLectura2 = useRef();
    const inputLecturaFin2 = useRef();
    const selectCodigoLectura2 = useRef();
    const selectDetalleVivienda2 = useRef();
    const selectNivelConsumo2 = useRef();
    const inputObservacion2 = useRef();

    //Ref Agregar Totalizador
    const inputSuministroAgregarTemporal = useRef();
    const inputFactorMedicionAgregarTemporal = useRef();



    // const rows = [
    //     { id: 1, accion: '', item: '1', suministro: '459455', factormedicion: '', tarifa: 'BT5C-AP', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 2, accion: '', item: '2', suministro: '480603', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 3, accion: '', item: '3', suministro: '460604', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 4, accion: '', item: '4', suministro: '460605', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 5, accion: '', item: '5', suministro: '460606', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 6, accion: '', item: '6', suministro: '460607', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 7, accion: '', item: '7', suministro: '460809', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 8, accion: '', item: '2', suministro: '480603', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 9, accion: '', item: '3', suministro: '460604', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 10, accion: '', item: '4', suministro: '460605', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 11, accion: '', item: '5', suministro: '460606', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 12, accion: '', item: '6', suministro: '460607', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 13, accion: '', item: '7', suministro: '460809', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 14, accion: '', item: '2', suministro: '480603', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 15, accion: '', item: '3', suministro: '460604', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 16, accion: '', item: '4', suministro: '460605', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 17, accion: '', item: '5', suministro: '460606', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 18, accion: '', item: '6', suministro: '460607', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    //     { id: 19, accion: '', item: '7', suministro: '460809', factormedicion: '', tarifa: 'BT5B', lec1: '', lec2: '', dif: '', obs: '' },
    // ];

    const columns = [
        {
            field: 'accion', headerName: '*', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Programacion Microbalance */}
                        <Tooltip title={<div>Ver/Modificar</div>}>
                            <IconButton onClick={() => {
                                MostrarModalDetalleSuministroMicrobalance(cellValues.row.id_suministro_microbalance)
                            }}>
                                <EditIcon fontSize='small'></EditIcon>
                            </IconButton>

                        </Tooltip>
                        <Tooltip title={<div>Resumen Consumos</div>}>
                            <IconButton onClick={() => { AbrirModalResumenConsumos(cellValues.row) }}>
                                <SsidChartIcon fontSize='small'></SsidChartIcon>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        },
        {
            field: 'item', headerName: 'ITEM', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (

                    <Tooltip title={<
                        div>{cellValues.row.codigo_suministro}</div>
                    }>
                        <ButtonMUI className='text-decoration-underline text-primary'>{cellValues.row.item}</ButtonMUI>
                    </Tooltip>


                )
            }
        },
        { field: 'tipo', headerName: 'Tipo', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        {
            field: 'codigo_suministro', headerName: 'Suministro', minWidth: 100, flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    cellValues.row.tipo == "ADICIONAL" ?
                        <Tooltip title='Adicional'>
                            <Badge badgeContent="AD" color='secondary' >
                                <small className='mx-3'>{cellValues.row.codigo_suministro}</small>
                            </Badge>
                        </Tooltip>
                        : <small className='mx-3'>{cellValues.row.codigo_suministro}</small>
                )
            }
        },
        { field: 'fact_medicion', headerName: 'Fac. Med.', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'tarifa', headerName: 'Tarifa', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'lectura_lectura1', headerName: 'Lect 1', minWidth: 50, flex: 0.75, headerClassName: 'header-colum-yellow' },
        {
            field: 'cod_lectura1', headerName: 'Cod 1', minWidth: 100, flex: 1, headerClassName: 'header-colum-yellow', renderCell: (cellValues) => {
                let styleJson = {};
                if (cellValues.row.cod_lectura1 !== '') {
                    if (cellValues.row.tomar_lectura1) {
                        styleJson = { color: 'rgb(0,97,0)' };
                        // styleJson={ color: 'rgb(0,97,0)',backgroundColor:'rgb(198,239,206)' };
                    }
                    if (cellValues.row.calcular_promedio1) {
                        styleJson = { color: 'rgb(156,87,0)' };
                        // styleJson={ color: 'rgb(156,87,0)',backgroundColor:'rgb(255,235,156)' };
                    }

                    if (!cellValues.row.calcular_promedio1 && !cellValues.row.tomar_lectura1) {
                        styleJson = { color: 'rgb(156,0,6)' };
                        // styleJson={ color: 'rgb(156,0,6)',backgroundColor:'rgb(255,199,206)' };
                    }
                }

                return (

                    cellValues.row.cod_lectura1 != '' ?
                        <span style={styleJson}>{cellValues.row.cod_lectura1}</span>
                        : <></>

                )
            }
        },
        { field: 'lectura_lectura2', headerName: 'Lect 2', minWidth: 50, flex: 0.75, headerClassName: 'header-colum-yellow2' },
        {
            field: 'cod_lectura2', headerName: 'Cod 2', minWidth: 100, flex: 1, headerClassName: 'header-colum-yellow2', renderCell: (cellValues) => {
                let styleJson = {};
                if (cellValues.row.cod_lectura2 !== '') {
                    if (cellValues.row.tomar_lectura2) {
                        styleJson = { color: 'rgb(0,97,0)' };
                        // styleJson={ color: 'rgb(0,97,0)',backgroundColor:'rgb(198,239,206)' };
                    }
                    if (cellValues.row.calcular_promedio2) {
                        styleJson = { color: 'rgb(156,87,0)' };
                        // styleJson={ color: 'rgb(156,87,0)',backgroundColor:'rgb(255,235,156)' };
                    }

                    if (!cellValues.row.calcular_promedio2 && !cellValues.row.tomar_lectura2) {
                        styleJson = { color: 'rgb(156,0,6)' };
                        // styleJson={ color: 'rgb(156,0,6)',backgroundColor:'rgb(255,199,206)' };
                    }
                }
                return (
                    cellValues.row.cod_lectura2 != '' ?
                        <span style={styleJson}>{cellValues.row.cod_lectura2}</span>
                        : <></>

                )
            }
        },
        { field: 'diferenciaLecturas', headerName: 'Diferencia', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'observacionlectura1_lectura1', headerName: 'Obs.', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
    ];

    const columnsProyeccion = [
        {
            field: 'accion', headerName: '*', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Programacion Microbalance */}
                        <Tooltip title={<div>Ver/Modificar</div>}>
                            <IconButton onClick={() => {
                                MostrarModalDetalleSuministroMicrobalance(cellValues.row.id_suministro_microbalance)
                            }}>
                                <EditIcon fontSize='small'></EditIcon>
                            </IconButton>

                        </Tooltip>
                        <Tooltip title={<div>Resumen Consumos</div>}>
                            <IconButton onClick={() => { AbrirModalResumenConsumos(cellValues.row) }}>
                                <SsidChartIcon fontSize='small'></SsidChartIcon>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        },
        {
            field: 'item', headerName: 'ITEM', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (

                    <Tooltip title={<
                        div>{cellValues.row.codigo_suministro}</div>
                    }>
                        <ButtonMUI className='text-decoration-underline text-primary'>{cellValues.row.item}</ButtonMUI>
                    </Tooltip>


                )
            }
        },
        // { field: 'tipo', headerName: 'Tipo', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        {
            field: 'codigo_suministro', headerName: 'Suministro', minWidth: 100, flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    cellValues.row.tipo == "ADICIONAL" ?
                        <Tooltip title='Adicional'>
                            <Badge badgeContent="AD" color='secondary' >
                                <small className='mx-3'>{cellValues.row.codigo_suministro}</small>
                            </Badge>
                        </Tooltip>
                        : <small className='mx-3'>{cellValues.row.codigo_suministro}</small>
                )
            }
        },
        { field: 'fact_medicion', headerName: 'Fac. Med.', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'tarifa', headerName: 'Tarifa', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'lectura_lectura1', headerName: 'Lect 1', minWidth: 50, flex: 0.75, headerClassName: 'header-colum-yellow' },
        // {
        //     field: 'cod_lectura1', headerName: 'Cod 1', minWidth: 100, flex: 1, headerClassName: 'header-colum-yellow', renderCell: (cellValues) => {
        //         let styleJson = {};
        //         if (cellValues.row.cod_lectura1 !== '') {
        //             if (cellValues.row.tomar_lectura1) {
        //                 styleJson = { color: 'rgb(0,97,0)' };
        //                 // styleJson={ color: 'rgb(0,97,0)',backgroundColor:'rgb(198,239,206)' };
        //             }
        //             if (cellValues.row.calcular_promedio1) {
        //                 styleJson = { color: 'rgb(156,87,0)' };
        //                 // styleJson={ color: 'rgb(156,87,0)',backgroundColor:'rgb(255,235,156)' };
        //             }

        //             if (!cellValues.row.calcular_promedio1 && !cellValues.row.tomar_lectura1) {
        //                 styleJson = { color: 'rgb(156,0,6)' };
        //                 // styleJson={ color: 'rgb(156,0,6)',backgroundColor:'rgb(255,199,206)' };
        //             }
        //         }

        //         return (

        //             cellValues.row.cod_lectura1 != '' ?
        //                 <span style={styleJson}>{cellValues.row.cod_lectura1}</span>
        //                 : <></>

        //         )
        //     }
        // },
        { field: 'lectura_lectura2', headerName: 'Lect 2', minWidth: 50, flex: 0.75, headerClassName: 'header-colum-yellow2' },
        // {
        //     field: 'cod_lectura2', headerName: 'Cod 2', minWidth: 100, flex: 1, headerClassName: 'header-colum-yellow2', renderCell: (cellValues) => {
        //         let styleJson = {};
        //         if (cellValues.row.cod_lectura2 !== '') {
        //             if (cellValues.row.tomar_lectura2) {
        //                 styleJson = { color: 'rgb(0,97,0)' };
        //                 // styleJson={ color: 'rgb(0,97,0)',backgroundColor:'rgb(198,239,206)' };
        //             }
        //             if (cellValues.row.calcular_promedio2) {
        //                 styleJson = { color: 'rgb(156,87,0)' };
        //                 // styleJson={ color: 'rgb(156,87,0)',backgroundColor:'rgb(255,235,156)' };
        //             }

        //             if (!cellValues.row.calcular_promedio2 && !cellValues.row.tomar_lectura2) {
        //                 styleJson = { color: 'rgb(156,0,6)' };
        //                 // styleJson={ color: 'rgb(156,0,6)',backgroundColor:'rgb(255,199,206)' };
        //             }
        //         }
        //         return (
        //             cellValues.row.cod_lectura2 != '' ?
        //                 <span style={styleJson}>{cellValues.row.cod_lectura2}</span>
        //                 : <></>

        //         )
        //     }
        // },
        { field: 'diferenciaLecturas', headerName: 'Diferencia', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'observacionlectura1_lectura1', headerName: 'Obs.', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'proyeccion', headerName: 'Proyección', minWidth: 100, flex: 1, headerClassName: 'header-colum-green' },
        { field: 'cea_consumo_fact', headerName: 'Facturacion', minWidth: 100, flex: 1, headerClassName: 'header-colum-green' },
        {
            field: 'desviacion', headerName: 'Desviación', minWidth: 100, flex: 1, headerClassName: 'header-colum-red', renderCell: (cellValues) => {
                let valorDesviacion = cellValues.row.desviacion;
                if (isNaN(cellValues.row.desviacion)) {
                    valorDesviacion = 0;
                } else {
                    valorDesviacion = cellValues.row.desviacion;
                }
                return (
                    <span style={{}}>{valorDesviacion}</span>
                )
            }
        },
    ];

    const columnsSegmento = [
        {
            field: 'accion', headerName: '*', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Programacion Microbalance */}
                        <Tooltip title={<div>Ver/Modificar</div>}>
                            <IconButton onClick={() => {
                                MostrarModalDetalleSuministroMicrobalance(cellValues.row.id_suministro_microbalance)
                            }}>
                                <EditIcon fontSize='small'></EditIcon>
                            </IconButton>

                        </Tooltip>
                        <Tooltip title={<div>Resumen Consumos</div>}>
                            <IconButton onClick={() => { AbrirModalResumenConsumos(cellValues.row) }}>
                                <SsidChartIcon fontSize='small'></SsidChartIcon>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        },
        {
            field: 'item', headerName: 'ITEM', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (

                    <Tooltip title={<
                        div>{cellValues.row.codigo_suministro}</div>
                    }>
                        <ButtonMUI className='text-decoration-underline text-primary'>{cellValues.row.item}</ButtonMUI>
                    </Tooltip>


                )
            }
        },
        { field: 'tipo', headerName: 'Tipo', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        {
            field: 'codigo_suministro', headerName: 'Suministro', minWidth: 100, flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    cellValues.row.tipo == "ADICIONAL" ?
                        <Tooltip title='Adicional'>
                            <Badge badgeContent="AD" color='secondary' >
                                <small className='mx-3'>{cellValues.row.codigo_suministro}</small>
                            </Badge>
                        </Tooltip>
                        : <small className='mx-3'>{cellValues.row.codigo_suministro}</small>
                )
            }
        },
        { field: 'fact_medicion', headerName: 'Fac. Med.', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'tarifa', headerName: 'Tarifa', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'lectura_lectura1', headerName: 'Lect 1', minWidth: 75, flex: 0.75, headerClassName: 'header-colum-yellow' },
        {
            field: 'cod_lectura1', headerName: 'Cod 1', minWidth: 100, flex: 1, headerClassName: 'header-colum-yellow', renderCell: (cellValues) => {
                let styleJson = {};
                if (cellValues.row.cod_lectura1 !== '') {
                    if (cellValues.row.tomar_lectura1) {
                        styleJson = { color: 'rgb(0,97,0)' };
                        // styleJson={ color: 'rgb(0,97,0)',backgroundColor:'rgb(198,239,206)' };
                    }
                    if (cellValues.row.calcular_promedio1) {
                        styleJson = { color: 'rgb(156,87,0)' };
                        // styleJson={ color: 'rgb(156,87,0)',backgroundColor:'rgb(255,235,156)' };
                    }

                    if (!cellValues.row.calcular_promedio1 && !cellValues.row.tomar_lectura1) {
                        styleJson = { color: 'rgb(156,0,6)' };
                        // styleJson={ color: 'rgb(156,0,6)',backgroundColor:'rgb(255,199,206)' };
                    }
                }

                return (

                    cellValues.row.cod_lectura1 != '' ?
                        <span style={styleJson}>{cellValues.row.cod_lectura1}</span>
                        : <></>

                )
            }
        },
        { field: 'lectura_lectura2', headerName: 'Lect 2', minWidth: 75, flex: 0.75, headerClassName: 'header-colum-yellow2' },
        {
            field: 'cod_lectura2', headerName: 'Cod 2', minWidth: 100, flex: 1, headerClassName: 'header-colum-yellow2', renderCell: (cellValues) => {
                let styleJson = {};
                if (cellValues.row.cod_lectura2 !== '') {
                    if (cellValues.row.tomar_lectura2) {
                        styleJson = { color: 'rgb(0,97,0)' };
                        // styleJson={ color: 'rgb(0,97,0)',backgroundColor:'rgb(198,239,206)' };
                    }
                    if (cellValues.row.calcular_promedio2) {
                        styleJson = { color: 'rgb(156,87,0)' };
                        // styleJson={ color: 'rgb(156,87,0)',backgroundColor:'rgb(255,235,156)' };
                    }

                    if (!cellValues.row.calcular_promedio2 && !cellValues.row.tomar_lectura2) {
                        styleJson = { color: 'rgb(156,0,6)' };
                        // styleJson={ color: 'rgb(156,0,6)',backgroundColor:'rgb(255,199,206)' };
                    }
                }
                return (
                    cellValues.row.cod_lectura2 != '' ?
                        <span style={styleJson}>{cellValues.row.cod_lectura2}</span>
                        : <></>

                )
            }
        },
        { field: 'diferenciaLecturas', headerName: 'Diferencia', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'observacionlectura1_lectura1', headerName: 'Obs.', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
    ];

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MICROBALANCE_DETALLE, permisos)) {
                    BuscarResumenMicrobalanceGeneral();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }
    const AbrirMenu = (event) => {
        setobjetoMenu(event.currentTarget);
    };
    const CerrarMenu = () => {
        setobjetoMenu(null);
    };
    //Microbalance Detalle
    const BuscarResumenMicrobalanceGeneral = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let url = `${config.endPoint}api/Microbalance/ResumenMicrobalanceGeneral/${idmicrobalance}`;
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data.content);
                if (data.success) {
                    setdataMatricesMicrobalances(data.content);
                    CalcularMicrobalance(data.content);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(data.message)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                // console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(error.tostring())
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const CalcularMicrobalance = async (matrices) => {
        // console.log(matrices);
        //
        let _dataMicrobalances = {
            fechaLectura1: '',
            fechaLectura2: '',
            diferenciaFechas: '',
            periodoComercial: '',
            diasfacturados: '',
            sed: '',
            estado: '',
            calculomicrobalance: {
                consumoTotalTotalizadores: '',
                consumoTotalUsuarios: '',
                dif: '',
                porcPerdida: '',
                cantSum: '',
                kwh_sum: '',
            },
            proyectado: {
                consumoTotalTotalizadores: '',
                consumoTotalUsuarios: '',
                dif: '',
                porcPerdida: '',
                kwh_sum: '',
            },
            facturacion: {
                consumoTotalTotalizadores: '',
                consumoTotalUsuarios: '',
                dif: '',
                porcPerdida: '',
                kwh_sum: '',
            }
        };

        //Volcar Informacion
        let matrizFechasProgramadas = matrices.tablaFechasProgramadas;
        let matrizAgrupaciones = matrices.tablaAgrupaciones;
        let matrizResumenMicrobalance = matrices.tablaResumenMicrobalance;
        let matrizDetalleMicrobalance = matrices.tablaDetalleMicrobalance;

        //llenar datos de mcirobalance
        if (matrizDetalleMicrobalance.length > 0) {
            _dataMicrobalances.periodoComercial = matrizDetalleMicrobalance[0].mes_proceso;
            _dataMicrobalances.diasfacturados = parseFloat(matrizDetalleMicrobalance[0].dias_facturados);
            _dataMicrobalances.sed = matrizDetalleMicrobalance[0].sed_codigo;
            _dataMicrobalances.estado = matrizDetalleMicrobalance[0].estado;
        }

        //Buscar Fechas Programadas
        if (matrizFechasProgramadas) {
            let fechaLectura1 = await matrizFechasProgramadas.find(x => x.tipo === 'LECTURA_1');
            let fechaLectura2 = await matrizFechasProgramadas.find(x => x.tipo === 'LECTURA_2');

            if (fechaLectura1) {
                _dataMicrobalances.fechaLectura1 = moment(fechaLectura1.fecha_programacion).format("YYYY-MM-DD");
            }

            if (fechaLectura2) {
                _dataMicrobalances.fechaLectura2 = moment(fechaLectura2.fecha_programacion).format("YYYY-MM-DD");
            }

            if (fechaLectura1 && fechaLectura2) {
                let fecha1 = moment(fechaLectura1.fecha_programacion);
                let fecha2 = moment(fechaLectura2.fecha_programacion);

                let diferenciaFechas = fecha1.diff(fecha2, 'days', false);
                _dataMicrobalances.diferenciaFechas = Math.abs(diferenciaFechas) + 1;
            }
        }

        //Buscar Lista Totalizadores
        let arrayTotalizadores = [];
        let arraySuministros = [];
        // console.log(matrizResumenMicrobalance);
        let _totalizador = matrizAgrupaciones.find(x => x.nombre_agrupacion == 'TOTALIZADOR');
        // console.log(_totalizador);
        if (_totalizador) {
            let splitElementosTotalizador = _totalizador.elementos.split(',');

            //Filtrar elementos que son TOTALIZADOR
            splitElementosTotalizador.forEach(splitElementos => {
                let elemetosEncontrados = matrizResumenMicrobalance.filter(y => y.tarifa == splitElementos);
                arrayTotalizadores = elemetosEncontrados;
            });
            let sumaConsumoTota = 0;
            let sumaConsumoTotaFacturacion = 0;
            for (let x = 0; x < arrayTotalizadores.length; x++) {
                if (arrayTotalizadores[x].tipo == "TEMPORAL") {
                    arrayTotalizadores[x].item = 'TOT-TEMP';
                } else {
                    arrayTotalizadores[x].item = 'TOT';
                }

                let lectura_1 = parseFloat(arrayTotalizadores[x].lectura_lectura1);
                let lectura_2 = parseFloat(arrayTotalizadores[x].lectura_lectura2);
                // let lectura_fin_1 = parseFloat(arrayTotalizadores[x].lectura_lectura_fin1);
                let lectura_fin_2 = parseFloat(arrayTotalizadores[x].lectura_lectura_fin2);
                let recalcular_medidor_cambio = arrayTotalizadores[x].agregar_lectura_fin2;

                let factorMedicion = parseFloat(arrayTotalizadores[x].fact_medicion);
                // let diasFacturados = parseFloat(arrayTotalizadores[x].dias_facturados);
                let diasFacturados = parseFloat(_dataMicrobalances.diasfacturados);
                let CEA_consumo = parseFloat(arrayTotalizadores[x].cea_consumo_fact);

                if (!isNaN(lectura_1) && !isNaN(lectura_2) && !isNaN(factorMedicion)) {
                    let diferencia = 0;

                    if (recalcular_medidor_cambio) {
                        console.log("Calcular medidor cambiado");
                        //Calcular Diferencia => [(Lectura_fin_2 - Lectura_1)+Lectura_2} X Factor_Medicion
                        diferencia = ((lectura_fin_2 - lectura_1) + lectura_2) * factorMedicion;
                    } else {
                        //Calcular Diferencia => (Lectura_1 - Lectura_2) X Factor_Medicion
                        diferencia = (lectura_2 - lectura_1) * factorMedicion;
                    }

                    //Calcular Proyeccion=> (DiferenciaLecturas * DiasFacturados)/PeriodoLectura
                    let proyeccion = (diferencia * diasFacturados) / _dataMicrobalances.diferenciaFechas;
                    arrayTotalizadores[x].diferenciaLecturas = diferencia;
                    arrayTotalizadores[x].proyeccion = proyeccion.toFixed(0);
                    sumaConsumoTota = sumaConsumoTota + diferencia;
                    // arrayTotalizadores[x].desviacion = (CEA_consumo - proyeccion) / CEA_consumo;
                    arrayTotalizadores[x].desviacion = (((CEA_consumo - proyeccion) / CEA_consumo) * 100).toFixed(0);

                } else {
                    sumaConsumoTota = 0;
                }
                if (Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura1).length === 0) {
                    arrayTotalizadores[x].lectura_lectura1 = '';
                }
                if (Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura2).length === 0) {
                    arrayTotalizadores[x].lectura_lectura2 = '';
                }
                if (Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura1).length === 0 && Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura2).length) {
                    arrayTotalizadores[x].diferenciaLecturas = '';
                }

                if (Object.entries(arrayTotalizadores[x].observacionlectura1_lectura1).length === 0) {
                    arrayTotalizadores[x].observacionlectura1_lectura1 = '';
                }

                if (Object.entries(arrayTotalizadores[x].observacionlectura1_lectura2).length === 0) {
                    arrayTotalizadores[x].observacionlectura1_lectura2 = '';
                }

                if (Object.entries(arrayTotalizadores[x].cod_lectura1).length === 0) {
                    arrayTotalizadores[x].cod_lectura1 = '';
                }

                if (Object.entries(arrayTotalizadores[x].cod_lectura2).length === 0) {
                    arrayTotalizadores[x].cod_lectura2 = '';
                }

                // console.log(arrayTotalizadores);
                //Calculo consumo Facturacion
                sumaConsumoTotaFacturacion = sumaConsumoTotaFacturacion + CEA_consumo;

            }

            _dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores = sumaConsumoTota;
            _dataMicrobalances.facturacion.consumoTotalTotalizadores = sumaConsumoTotaFacturacion;
            setlistaTotalizadores(arrayTotalizadores);
            // console.log(arrayTotalizadores);

            //Filtrar elementos que no son TOTALIZADOR
            splitElementosTotalizador.forEach(splitElementos => {
                let elemetosEncontrados = matrizResumenMicrobalance.filter(y => y.tarifa !== splitElementos);
                arraySuministros = elemetosEncontrados;
            });
            let sumaConsumoSum = 0;
            let sumaConsumoSumFacturacion = 0;

            for (let x = 0; x < arraySuministros.length; x++) {
                // console.log(arraySuministros[x]);
                arraySuministros[x].item = x + 1;
                let lectura_1 = parseFloat(arraySuministros[x].lectura_lectura1);
                let lectura_2 = parseFloat(arraySuministros[x].lectura_lectura2);
                let lectura_fin_1 = parseFloat(arraySuministros[x].lectura_lectura_fin1);
                let lectura_fin_2 = parseFloat(arraySuministros[x].lectura_lectura_fin2);
                let recalcular_medidor_cambio = arraySuministros[x].agregar_lectura_fin2;

                // console.log(recalcular_medidor_cambio);

                let factorMedicion = parseFloat(arraySuministros[x].fact_medicion);
                // let diasFacturados = parseFloat(arraySuministros[x].dias_facturados);

                let diasFacturados = parseFloat(_dataMicrobalances.diasfacturados);
                let CEA_consumo = parseFloat(arraySuministros[x].cea_consumo_fact);

                if (!isNaN(lectura_1) && !isNaN(lectura_2) && !isNaN(factorMedicion)) {
                    // console.log("Calcular medidor cambiado");
                    let diferencia = 0;

                    if (lectura_2 < lectura_1) {
                        //Calcular Diferencia => [(Lectura_fin_2 - Lectura_1)+Lectura_2} X Factor_Medicion
                        // console.log("calculo diferencia");
                        diferencia = ((lectura_fin_2 - lectura_1) + lectura_2) * factorMedicion;
                    } else {
                        //Calcular Diferencia => (Lectura_1 - Lectura_2) X Factor_Medicion
                        // console.log("calculo normal");
                        diferencia = (lectura_2 - lectura_1) * factorMedicion;
                    }

                    //Calcular Proyeccion=> (DiferenciaLecturas * DiasFacturados)/PeriodoLectura
                    let proyeccion = (diferencia * diasFacturados) / _dataMicrobalances.diferenciaFechas;
                    arraySuministros[x].diferenciaLecturas = diferencia;
                    arraySuministros[x].proyeccion = proyeccion.toFixed(0);
                    sumaConsumoSum = sumaConsumoSum + diferencia;
                    // arraySuministros[x].desviacion = (CEA_consumo - proyeccion) / CEA_consumo;
                    arraySuministros[x].desviacion = (((CEA_consumo - proyeccion) / CEA_consumo) * 100).toFixed(0);

                } else {
                    sumaConsumoSum = 0;
                }

                if (Object.entries(arraySuministros[x].fecha_ejecucion_lectura1).length === 0) {
                    arraySuministros[x].lectura_lectura1 = '';
                }

                if (Object.entries(arraySuministros[x].cod_lectura1).length === 0) {
                    arraySuministros[x].cod_lectura1 = '';
                }

                if (Object.entries(arraySuministros[x].fecha_ejecucion_lectura2).length === 0) {
                    arraySuministros[x].lectura_lectura2 = '';
                }

                if (Object.entries(arraySuministros[x].cod_lectura2).length === 0) {
                    arraySuministros[x].cod_lectura2 = '';
                }

                if (Object.entries(arraySuministros[x].observacionlectura1_lectura1).length === 0) {
                    arraySuministros[x].observacionlectura1_lectura1 = '';
                }

                if (Object.entries(arraySuministros[x].observacionlectura1_lectura2).length === 0) {
                    arraySuministros[x].observacionlectura1_lectura2 = '';
                }

                arraySuministros[x].observacionlectura1_lectura1 = arraySuministros[x].observacionlectura1_lectura1 + ';' + arraySuministros[x].observacionlectura1_lectura2;
                sumaConsumoSumFacturacion = sumaConsumoSumFacturacion + CEA_consumo;
            }
            // console.log(arraySuministros);
            setlistaSuministros(arraySuministros);
            _dataMicrobalances.calculomicrobalance.consumoTotalUsuarios = sumaConsumoSum;
            _dataMicrobalances.facturacion.consumoTotalUsuarios = sumaConsumoSumFacturacion;

            //********************************************************************* */
            //* CALCULO MICROBALANCE Y PROYECCIONES
            //********************************************************************* */
            //1.calculomicrobalance:

            _dataMicrobalances.calculomicrobalance.dif = _dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores - _dataMicrobalances.calculomicrobalance.consumoTotalUsuarios;

            _dataMicrobalances.calculomicrobalance.porcPerdida = ((_dataMicrobalances.calculomicrobalance.dif / _dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores) * 100).toFixed(2);
            _dataMicrobalances.calculomicrobalance.cantSum = arraySuministros.length;
            _dataMicrobalances.calculomicrobalance.kwh_sum = (_dataMicrobalances.calculomicrobalance.dif / _dataMicrobalances.calculomicrobalance.cantSum).toFixed(2);
            //2.Proyectado
            _dataMicrobalances.proyectado.consumoTotalTotalizadores = Math.round((_dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores * _dataMicrobalances.diasfacturados) / _dataMicrobalances.diferenciaFechas);
            _dataMicrobalances.proyectado.consumoTotalUsuarios = Math.round((_dataMicrobalances.calculomicrobalance.consumoTotalUsuarios * _dataMicrobalances.diasfacturados) / _dataMicrobalances.diferenciaFechas);
            _dataMicrobalances.proyectado.dif = Math.round(_dataMicrobalances.proyectado.consumoTotalTotalizadores - _dataMicrobalances.proyectado.consumoTotalUsuarios);
            _dataMicrobalances.proyectado.porcPerdida = ((_dataMicrobalances.proyectado.dif / _dataMicrobalances.proyectado.consumoTotalTotalizadores) * 100).toFixed(2);
            _dataMicrobalances.proyectado.kwh_sum = (_dataMicrobalances.proyectado.dif / _dataMicrobalances.calculomicrobalance.cantSum).toFixed(0);

            //3.Facturacion
            _dataMicrobalances.facturacion.consumoTotalTotalizadores = Math.round(_dataMicrobalances.facturacion.consumoTotalTotalizadores);
            _dataMicrobalances.facturacion.consumoTotalUsuarios = Math.round(_dataMicrobalances.facturacion.consumoTotalUsuarios);
            _dataMicrobalances.facturacion.dif = Math.round(_dataMicrobalances.facturacion.consumoTotalTotalizadores - _dataMicrobalances.facturacion.consumoTotalUsuarios);
            _dataMicrobalances.facturacion.porcPerdida = ((_dataMicrobalances.facturacion.dif / _dataMicrobalances.facturacion.consumoTotalTotalizadores) * 100).toFixed(2);
            _dataMicrobalances.facturacion.kwh_sum = (_dataMicrobalances.facturacion.dif / _dataMicrobalances.calculomicrobalance.cantSum).toFixed(0);


        } else {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast("No se encontró elemento de agrupacion para realizar el calculo.")
            setabrirToast(true);
        }
        //Realizar Calculo de Microbalance
        // console.log(_dataMicrobalances);
        setdataResumenMicrobalance(_dataMicrobalances);
    }

    const CalcularMicrobalanceTotalizador = async (matrices) => {
        setgpsMostrarSegmento('LECTURA_1');
        //
        let _dataMicrobalances = {
            fechaLectura1: '',
            fechaLectura2: '',
            diferenciaFechas: '',
            periodoComercial: '',
            diasfacturados: '',
            sed: '',
            estado: '',
            calculomicrobalance: {
                consumoTotalTotalizadores: '',
                consumoTotalUsuarios: '',
                dif: '',
                porcPerdida: '',
                cantSum: '',
                kwh_sum: '',
            },
            proyectado: {
                consumoTotalTotalizadores: '',
                consumoTotalUsuarios: '',
                dif: '',
                porcPerdida: '',
                kwh_sum: '',
            },
            facturacion: {
                consumoTotalTotalizadores: '',
                consumoTotalUsuarios: '',
                dif: '',
                porcPerdida: '',
                kwh_sum: '',
            }
        };

        //Volcar Informacion
        let matrizFechasProgramadas = matrices.tablaFechasProgramadas;
        let matrizAgrupaciones = matrices.tablaAgrupaciones;
        let matrizResumenMicrobalance = matrices.tablaResumenMicrobalance;
        let matrizDetalleMicrobalance = matrices.tablaDetalleMicrobalance;

        //llenar datos de mcirobalance
        if (matrizDetalleMicrobalance.length > 0) {
            _dataMicrobalances.periodoComercial = matrizDetalleMicrobalance[0].mes_proceso;
            _dataMicrobalances.diasfacturados = parseFloat(matrizDetalleMicrobalance[0].dias_facturados);
            _dataMicrobalances.sed = matrizDetalleMicrobalance[0].sed_codigo;
            _dataMicrobalances.estado = matrizDetalleMicrobalance[0].estado;
        }

        //Buscar Fechas Programadas
        if (matrizFechasProgramadas) {
            let fechaLectura1 = await matrizFechasProgramadas.find(x => x.tipo === 'LECTURA_1');
            let fechaLectura2 = await matrizFechasProgramadas.find(x => x.tipo === 'LECTURA_2');

            if (fechaLectura1) {
                _dataMicrobalances.fechaLectura1 = moment(fechaLectura1.fecha_programacion).format("YYYY-MM-DD");
            }

            if (fechaLectura2) {
                _dataMicrobalances.fechaLectura2 = moment(fechaLectura2.fecha_programacion).format("YYYY-MM-DD");
            }

            if (fechaLectura1 && fechaLectura2) {
                let fecha1 = moment(fechaLectura1.fecha_programacion);
                let fecha2 = moment(fechaLectura2.fecha_programacion);

                let diferenciaFechas = fecha1.diff(fecha2, 'days', false);
                _dataMicrobalances.diferenciaFechas = Math.abs(diferenciaFechas) + 1;
            }
        }

        //Buscar Lista Totalizadores
        let arrayTotalizadores = [];
        let arraySuministros = [];
        // console.log(matrizResumenMicrobalance);
        let _totalizador = matrizAgrupaciones.find(x => x.nombre_agrupacion == 'TOTALIZADOR');
        // console.log(_totalizador);
        if (_totalizador) {
            let splitElementosTotalizador = _totalizador.elementos.split(',');

            //Filtrar elementos que son TOTALIZADOR
            splitElementosTotalizador.forEach(splitElementos => {
                let elemetosEncontrados = matrizResumenMicrobalance.filter(y => y.tarifa == splitElementos);
                arrayTotalizadores = elemetosEncontrados;
            });
            let sumaConsumoTota = 0;
            let sumaConsumoTotaFacturacion = 0;
            for (let x = 0; x < arrayTotalizadores.length; x++) {
                let latTotalizador = parseFloat(arrayTotalizadores[x].lat1);

                if (!isNaN(latTotalizador)) {
                    let latTotalizador = parseFloat(arrayTotalizadores[x].lat1);
                    let lngTotalizador = parseFloat(arrayTotalizadores[x].lng1);
                    setcentroTotalizador({ lat: latTotalizador, lng: lngTotalizador });
                }

                if (arrayTotalizadores[x].tipo == "TEMPORAL") {
                    arrayTotalizadores[x].item = 'TOT-TEMP';
                } else {
                    arrayTotalizadores[x].item = 'TOT';
                }

                let lectura_1 = parseFloat(arrayTotalizadores[x].lectura_lectura1);
                let lectura_2 = parseFloat(arrayTotalizadores[x].lectura_lectura2);
                let factorMedicion = parseFloat(arrayTotalizadores[x].fact_medicion);

                let lectura_fin_2 = parseFloat(arrayTotalizadores[x].lectura_lectura_fin2);
                let recalcular_medidor_cambio = arrayTotalizadores[x].agregar_lectura_fin2;
                // let diasFacturados = parseFloat(arrayTotalizadores[x].dias_facturados);
                let diasFacturados = parseFloat(_dataMicrobalances.diasfacturados);
                let CEA_consumo = parseFloat(arrayTotalizadores[x].cea_consumo_fact);

                if (!isNaN(lectura_1) && !isNaN(lectura_2) && !isNaN(factorMedicion)) {

                    let diferencia = 0;

                    if (lectura_2 < lectura_1) {
                        //Calcular Diferencia => [(Lectura_fin_2 - Lectura_1)+Lectura_2} X Factor_Medicion
                        // console.log("calculo diferencia");
                        diferencia = ((lectura_fin_2 - lectura_1) + lectura_2) * factorMedicion;
                    } else {
                        //Calcular Diferencia => (Lectura_1 - Lectura_2) X Factor_Medicion
                        // console.log("calculo normal");
                        diferencia = (lectura_2 - lectura_1) * factorMedicion;
                    }

                    //Calcular Proyeccion=> (DiferenciaLecturas * DiasFacturados)/PeriodoLectura
                    let proyeccion = (diferencia * diasFacturados) / _dataMicrobalances.diferenciaFechas;
                    arrayTotalizadores[x].diferenciaLecturas = diferencia;
                    arrayTotalizadores[x].proyeccion = proyeccion;
                    sumaConsumoTota = sumaConsumoTota + diferencia;
                    arrayTotalizadores[x].desviacion = (CEA_consumo - proyeccion) / CEA_consumo;
                } else {
                    sumaConsumoTota = 0;
                }
                if (Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura1).length === 0) {
                    arrayTotalizadores[x].lectura_lectura1 = '';
                }
                if (Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura2).length === 0) {
                    arrayTotalizadores[x].lectura_lectura2 = '';
                }
                if (Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura1).length === 0 && Object.entries(arrayTotalizadores[x].fecha_ejecucion_lectura2).length) {
                    arrayTotalizadores[x].diferenciaLecturas = '';
                }

                if (Object.entries(arrayTotalizadores[x].observacionlectura1_lectura1).length === 0) {
                    arrayTotalizadores[x].observacionlectura1_lectura1 = '';
                }

                if (Object.entries(arrayTotalizadores[x].observacionlectura1_lectura2).length === 0) {
                    arrayTotalizadores[x].observacionlectura1_lectura2 = '';
                }

                if (Object.entries(arrayTotalizadores[x].cod_lectura1).length === 0) {
                    arrayTotalizadores[x].cod_lectura1 = '';
                }

                if (Object.entries(arrayTotalizadores[x].cod_lectura2).length === 0) {
                    arrayTotalizadores[x].cod_lectura2 = '';
                }

                // console.log(arrayTotalizadores);
                //Calculo consumo Facturacion
                sumaConsumoTotaFacturacion = sumaConsumoTotaFacturacion + CEA_consumo;

            }
            _dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores = sumaConsumoTota;
            _dataMicrobalances.facturacion.consumoTotalTotalizadores = sumaConsumoTotaFacturacion;

            setlistaTotalizadoresSegmento(arrayTotalizadores);
            // console.log(arrayTotalizadores);

            //Filtrar elementos que no son TOTALIZADOR
            splitElementosTotalizador.forEach(splitElementos => {
                let elemetosEncontrados = matrizResumenMicrobalance.filter(y => y.tarifa !== splitElementos);
                arraySuministros = elemetosEncontrados;
            });

            let sumaConsumoSum = 0;
            let sumaConsumoSumFacturacion = 0;

            for (let x = 0; x < arraySuministros.length; x++) {
                arraySuministros[x].item = x + 1;
                let lectura_1 = parseFloat(arraySuministros[x].lectura_lectura1);
                let lectura_2 = parseFloat(arraySuministros[x].lectura_lectura2);
                let factorMedicion = parseFloat(arraySuministros[x].fact_medicion);

                let lectura_fin_2 = parseFloat(arraySuministros[x].lectura_lectura_fin2);
                let recalcular_medidor_cambio = arraySuministros[x].agregar_lectura_fin2;

                // let diasFacturados = parseFloat(arraySuministros[x].dias_facturados);
                let diasFacturados = parseFloat(_dataMicrobalances.diasfacturados);
                let CEA_consumo = parseFloat(arraySuministros[x].cea_consumo_fact);

                if (!isNaN(lectura_1) && !isNaN(lectura_2) && !isNaN(factorMedicion)) {
                    //Calcular Diferencia => (Lectura_1 - Lectura_2) X Factor_Medicion
                    let diferencia = 0;
                    if (lectura_2 < lectura_1) {
                        //Calcular Diferencia => [(Lectura_fin_2 - Lectura_1)+Lectura_2} X Factor_Medicion
                        // console.log("calculo diferencia");
                        diferencia = ((lectura_fin_2 - lectura_1) + lectura_2) * factorMedicion;
                    } else {
                        //Calcular Diferencia => (Lectura_1 - Lectura_2) X Factor_Medicion
                        // console.log("calculo normal");
                        diferencia = (lectura_2 - lectura_1) * factorMedicion;
                    }

                    //Calcular Proyeccion=> (DiferenciaLecturas * DiasFacturados)/PeriodoLectura
                    let proyeccion = (diferencia * diasFacturados) / _dataMicrobalances.diferenciaFechas;
                    arraySuministros[x].diferenciaLecturas = diferencia;
                    arraySuministros[x].proyeccion = proyeccion;
                    sumaConsumoSum = sumaConsumoSum + diferencia;
                    arraySuministros[x].desviacion = (CEA_consumo - proyeccion) / CEA_consumo;

                } else {
                    sumaConsumoSum = 0;
                }

                if (Object.entries(arraySuministros[x].fecha_ejecucion_lectura1).length === 0) {
                    arraySuministros[x].lectura_lectura1 = '';
                }

                if (Object.entries(arraySuministros[x].cod_lectura1).length === 0) {
                    arraySuministros[x].cod_lectura1 = '';
                }

                if (Object.entries(arraySuministros[x].fecha_ejecucion_lectura2).length === 0) {
                    arraySuministros[x].lectura_lectura2 = '';
                }

                if (Object.entries(arraySuministros[x].cod_lectura2).length === 0) {
                    arraySuministros[x].cod_lectura2 = '';
                }

                if (Object.entries(arraySuministros[x].observacionlectura1_lectura1).length === 0) {
                    arraySuministros[x].observacionlectura1_lectura1 = '';
                }

                if (Object.entries(arraySuministros[x].observacionlectura1_lectura2).length === 0) {
                    arraySuministros[x].observacionlectura1_lectura2 = '';
                }

                arraySuministros[x].observacionlectura1_lectura1 = arraySuministros[x].observacionlectura1_lectura1 + ';' + arraySuministros[x].observacionlectura1_lectura2;
                sumaConsumoSumFacturacion = sumaConsumoSumFacturacion + CEA_consumo;
            }

            setlistaSuministrosSegmento(arraySuministros);
            _dataMicrobalances.calculomicrobalance.consumoTotalUsuarios = sumaConsumoSum;
            _dataMicrobalances.facturacion.consumoTotalUsuarios = sumaConsumoSumFacturacion;

            //********************************************************************* */
            //* CALCULO MICROBALANCE Y PROYECCIONES
            //********************************************************************* */
            //1.calculomicrobalance:

            _dataMicrobalances.calculomicrobalance.dif = _dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores - _dataMicrobalances.calculomicrobalance.consumoTotalUsuarios;
            _dataMicrobalances.calculomicrobalance.porcPerdida = ((_dataMicrobalances.calculomicrobalance.dif / _dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores) * 100).toFixed(2);
            _dataMicrobalances.calculomicrobalance.cantSum = arraySuministros.length;
            _dataMicrobalances.calculomicrobalance.kwh_sum = (_dataMicrobalances.calculomicrobalance.dif / _dataMicrobalances.calculomicrobalance.cantSum).toFixed(2);
            //2.Proyectado
            _dataMicrobalances.proyectado.consumoTotalTotalizadores = Math.round((_dataMicrobalances.calculomicrobalance.consumoTotalTotalizadores * _dataMicrobalances.diasfacturados) / _dataMicrobalances.diferenciaFechas);
            _dataMicrobalances.proyectado.consumoTotalUsuarios = Math.round((_dataMicrobalances.calculomicrobalance.consumoTotalUsuarios * _dataMicrobalances.diasfacturados) / _dataMicrobalances.diferenciaFechas);
            _dataMicrobalances.proyectado.dif = Math.round(_dataMicrobalances.proyectado.consumoTotalTotalizadores - _dataMicrobalances.proyectado.consumoTotalUsuarios);
            _dataMicrobalances.proyectado.porcPerdida = ((_dataMicrobalances.proyectado.dif / _dataMicrobalances.proyectado.consumoTotalTotalizadores) * 100).toFixed(2);
            _dataMicrobalances.proyectado.kwh_sum = (_dataMicrobalances.proyectado.dif / _dataMicrobalances.calculomicrobalance.cantSum).toFixed(0);

            //3.Facturacion
            _dataMicrobalances.facturacion.consumoTotalTotalizadores = Math.round(_dataMicrobalances.facturacion.consumoTotalTotalizadores);
            _dataMicrobalances.facturacion.consumoTotalUsuarios = Math.round(_dataMicrobalances.facturacion.consumoTotalUsuarios);
            _dataMicrobalances.facturacion.dif = Math.round(_dataMicrobalances.facturacion.consumoTotalTotalizadores - _dataMicrobalances.facturacion.consumoTotalUsuarios);
            _dataMicrobalances.facturacion.porcPerdida = ((_dataMicrobalances.facturacion.dif / _dataMicrobalances.facturacion.consumoTotalTotalizadores) * 100).toFixed(2);
            _dataMicrobalances.facturacion.kwh_sum = (_dataMicrobalances.facturacion.dif / _dataMicrobalances.calculomicrobalance.cantSum).toFixed(0);


        } else {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast("No se encontró elemento de agrupacion para realizar el calculo.")
            setabrirToast(true);
        }
        //Realizar Calculo de Microbalance
        // console.log(arrayTotalizadores);
        // console.log(arraySuministros);
        // console.log(_dataMicrobalances);
        setdataResumenMicrobalanceSegmento(_dataMicrobalances);

    }
    const BuscarCantidadPendientesMicrobalance = () => {
        return new Promise((resolve, reject) => {
            if (!ValidarUsuario()) {
                reject(null);
            } else {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/BuscarCantidadPendientesMicrobalance/${idmicrobalance}`;

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    setmostrarCircularProgress(false);
                    resolve(data);
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                    resolve(null);
                });
            }
        });
    }
    //Programacion de Lectura
    const MostrarModalHabilitarLectura = async (tipo) => {
        //Verificar si todas las lecturas fueron ejecutadas
        settipoLecturaProgramar('');
        BuscarCantidadPendientesMicrobalance().then(async (respuesta) => {
            if (respuesta) {
                // console.log(respuesta);
                if (respuesta.content > 0) {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast(`Existen ${respuesta.content} suministros pendientes de ejecución, no debe existir nungun suministro pediente para poder realizar esta acción.`)
                    setabrirToast(true);
                } else {
                    await setmostrarModalHabilitarLectura(true);
                    settipoLecturaProgramar(tipo);
                    // inputTipoProgramacionLectura.current.value = tipo;
                }
            }
        });
    }
    const ValidarProgramacionLectura = () => {
        let error = 0;
        if (inputFechaProgramacionLectura.current.value == '') {
            inputFechaProgramacionLectura.current.classList.add('is-invalid');
            error++;
        } else {
            inputFechaProgramacionLectura.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const ProgramarLectura = () => {
        try {
            if (!ValidarUsuario()) {
                navigate("/");
            } else {
                if (ValidarProgramacionLectura()) {
                    //Verificar que la lectura sea mayor a la lectura                     
                    if (tipoLecturaProgramar == 'LECTURA_2') {
                        let fechaLectura1 = moment(dataResumenMicrobalance.fechaLectura1);
                        let fechaLectura2 = moment(inputFechaProgramacionLectura.current.value);
                        let diferenciaFechas = fechaLectura2.diff(fechaLectura1, 'days');
                        if (diferenciaFechas <= 0) {
                            throw ("La fecha debe ser mayor a la fecha de lectura 1");
                        }
                    }

                    setmostrarCircularProgress(true);
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let url = `${config.endPoint}api/Microbalance/HabilitarLectura`;
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify({
                            idmicrobalance: idmicrobalance,
                            fechaprogramacion: inputFechaProgramacionLectura.current.value,
                            tipo: tipoLecturaProgramar
                        })
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        // console.log(data);
                        if (data.success) {
                            settituloToast("Correcto");
                            settipoToast("success");
                            BuscarResumenMicrobalanceGeneral();
                            setmostrarModalHabilitarLectura(false);
                        } else {
                            settituloToast("Alerta");
                            settipoToast("warning");
                        }
                        setmensajeToast(data.message)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    }).catch(async (error) => {
                        // console.log(error);
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    });
                }
            }

        } catch (error) {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`${error.toString()}`)
            setabrirToast(true);
            setmostrarCircularProgress(false);
        }
    }
    //Detalle Suministro
    const MostrarModalDetalleSuministroMicrobalance = async (idsuministromicrobalance) => {


        try {

            //Deshabilitando Controles
            setaccionModificarFactorMedicion(false);
            setaccionModificarLectura1(false);
            setaccionModificarLectura2(false);

            //Deshabilitar controles Lectura1
            sethabilitarInputTotalizador1(false);
            sethabilitarInputLectura1(false);
            sethabiliarInputDetalleVivienda1(false);
            sethabilitarInputNivelConsumo1(false);
            sethabilitarInputAgregarLecturaFin1(false);

            //Deshabilitar controles Lectura1
            sethabilitarInputTotalizador2(false);
            sethabilitarInputLectura2(false);
            sethabiliarInputDetalleVivienda2(false);
            sethabilitarInputNivelConsumo2(false);
            sethabilitarInputAgregarLecturaFin2(false);

            //Reiniciar Valores
            setdetalleSuministroMicrobalance(null);
            setlistaLectura1Suministro(null);
            setlistaLectura2Suministro(null);

            setmostrarCircularProgress(true);
            await BuscarDetalleSuministro(idsuministromicrobalance).then(async (respuesta) => {
                // console.log(idsuministromicrobalance);
                // console.log(respuesta);

                if (respuesta) {
                    await setmostrarModalModificarSuministro(true);
                }

            }).catch((error) => {
                // console.log(error);
                navigate('/');
            });
            setmostrarCircularProgress(false);
        } catch (error) {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(error.tostring())
            setabrirToast(true);
        }
    }
    const BuscarDetalleSuministro = (idsuministromicrobalance) => {
        setdetalleSuministroMicrobalance(null);
        setlistaTotalizadoresSuministro([]);
        setlistaCodigosLectura([]);
        setlistaDetallesVivienda([]);
        setlistaSubDetallevivienda([]);
        setlistaLectura1Suministro(null);
        setlistaLectura2Suministro(null);

        return new Promise((resolve, reject) => {
            if (!ValidarUsuario()) {
                reject(false);
            } else {

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/DetalleSuministroMicrobalance/${idsuministromicrobalance}/${idmicrobalance}`;
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        let lectura1 = null;
                        let lectura2 = null;
                        let _lecturas = data.content.lecturas;
                        let _suministro = data.content.suministro;
                        let _totalizadores = data.content.totalizadores;


                        for (let x = 0; x < _lecturas.length; x++) {
                            switch (_lecturas[x].tipo) {
                                case 'LECTURA_1':
                                    lectura1 = _lecturas[x];
                                    break;
                                case 'LECTURA_2':
                                    lectura2 = _lecturas[x];
                                    break;
                            }
                        }
                        //Corregir Lectura 
                        // console.log(lectura1);
                        // console.log(lectura2);
                        if (lectura1) {
                            if (lectura1.estado == 'EJECUTADO') {
                                await CambiarTipoVivienda1(lectura1.codigo1);
                            }
                        }

                        // console.log(data.content.codigoslectura);
                        setdetalleSuministroMicrobalance(_suministro);
                        setlistaTotalizadoresSuministro(_totalizadores);
                        setlistaCodigosLectura(data.content.codigoslectura);
                        setlistaDetallesVivienda(data.content.detallesvivienda);
                        setlistaLectura1Suministro(lectura1);
                        setlistaLectura2Suministro(lectura2);

                        resolve({
                            suministro: _suministro,
                            lectura1: lectura1,
                            lectura2: lectura2
                        });

                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(data.message)
                        setabrirToast(true);
                        resolve(null);
                    }

                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(error.tostring())
                    setabrirToast(true);
                    resolve(null);
                });
            }
        });
    }
    const ValidarControlesDetalleSuministro = () => {
        let error = 0;
        if (inputFactorMedicion.current.value == '') {
            inputFactorMedicion.current.classList.add('is-invalid');
            error++;
        } else {
            inputFactorMedicion.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false
        }
        return true
    }
    const ActualizarFactorMedicionSuministro = async () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarControlesDetalleSuministro()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Microbalance/ActualizarFactorMedicionSuministroMicrobalance`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idsuministromicrobalance: detalleSuministroMicrobalance.id_suministro_microbalance,
                        factormedicion: inputFactorMedicion.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        setaccionModificarFactorMedicion(false);

                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const ValidarControlesLectura1 = () => {
        let error = 0;
        if (selectCodigoLectura1.current.value == '') {
            selectCodigoLectura1.current.classList.add('is-invalid');
            error++;

        } else {
            selectCodigoLectura1.current.classList.remove('is-invalid');
            const CodigoLectura = BuscarCodigoLecturaSeleccionado(selectCodigoLectura1.current.value);

            if (CodigoLectura.elegir_totalizador) {
                if (selectTotalizadorAsociado1.current.value.trim() == '') {
                    selectTotalizadorAsociado1.current.classList.add('is-invalid');
                    error++;
                } else {
                    selectTotalizadorAsociado1.current.classList.remove('is-invalid');
                }
            }

            if (CodigoLectura.ingresar_datos) {


                if (selectDetalleVivienda1.current.value == '') {
                    selectDetalleVivienda1.current.classList.add('is-invalid');
                    error++;
                } else {
                    selectDetalleVivienda1.current.classList.remove('is-invalid');
                }

                if (selectNivelConsumo1.current.value == '') {
                    selectNivelConsumo1.current.classList.add('is-invalid');
                    error++;
                } else {
                    selectNivelConsumo1.current.classList.remove('is-invalid');
                }
            } else {
                // selectTotalizadorAsociado1.current.classList.remove('is-invalid');
                selectDetalleVivienda1.current.classList.remove('is-invalid');
                selectNivelConsumo1.current.classList.remove('is-invalid');
            }
        }

        if (inputLectura1.current.value.trim() == '') {
            inputLectura1.current.classList.add('is-invalid');
            error++;
        } else {
            inputLectura1.current.classList.remove('is-invalid');
        }

        // if (inputObservacion1.current.value.trim() == '') {
        //     inputObservacion1.current.classList.add('is-invalid');
        // }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const ModificarLectura1 = () => {
        setaccionModificarLectura1(true);
        CambiarCodigoLectura(selectCodigoLectura1.current.value, false);
    }
    const BuscarCodigoLecturaSeleccionado = (idcodigolectura) => {
        const codigoEncontrado = listaCodigosLectura.find(x => x.id_codigo_lectura == idcodigolectura);
        if (codigoEncontrado) {
            return codigoEncontrado;
        }
        return null;
    }

    const CambiarCodigoLectura = (idcodigolectura, recalcular) => {
        try {
            const CodigoLectura = BuscarCodigoLecturaSeleccionado(idcodigolectura);
            // console.log(detalleSuministroMicrobalance);
            // console.log(listaLectura1Suministro);
            if (CodigoLectura) {
                //Calcular Promedio
                if (CodigoLectura.calcular_promedio && recalcular) {
                    //Consultar si es necesario realizar el calculo
                    if (detalleSuministroMicrobalance.tipo == "REGISTRADO") {
                        let fechaAnterior = moment(detalleSuministroMicrobalance.fecha_lectura_mes_actual);
                        let fechaLectura1 = moment(listaLectura1Suministro.fecha_programacion);
                        let promedioLectura = parseFloat(detalleSuministroMicrobalance.promedio);
                        let ultimaLectura = parseFloat(detalleSuministroMicrobalance.utlima_lectura);
                        let diasFacturados = parseFloat(detalleSuministroMicrobalance.dias_facturados);
                        let diasDiferencia = fechaLectura1.diff(fechaAnterior, 'days');
                        let lecturaCalculada = ((diasDiferencia * promedioLectura) / diasFacturados) + ultimaLectura;
                        inputLectura1.current.value = lecturaCalculada.toFixed(0);

                    } else {
                        // inputLectura1.current.value = '0';
                        inputLectura1.current.value = listaLectura1Suministro.lectura;

                    }
                } else {
                    if (recalcular) {
                        // inputLectura1.current.value = '0';
                        inputLectura1.current.value = listaLectura1Suministro.lectura;
                    }
                }

                //Tomar Lectura
                if (CodigoLectura.tomar_lectura) {
                    sethabilitarInputLectura1(true);
                    // if (recalcular) {
                    //     inputLectura1.current.value = ''
                    // }
                } else {
                    sethabilitarInputLectura1(false);
                }

                if (CodigoLectura.elegir_totalizador) {
                    sethabilitarInputTotalizador1(true);
                } else {
                    sethabilitarInputTotalizador1(false);
                }

                //Ingresar Datos
                if (CodigoLectura.ingresar_datos) {
                    sethabiliarInputDetalleVivienda1(true);
                    sethabilitarInputNivelConsumo1(true);
                } else {
                    sethabiliarInputDetalleVivienda1(false);
                    sethabilitarInputNivelConsumo1(false);
                }

                //Agregar Lectura Fin
                if (CodigoLectura.agregar_lectura_fin) {
                    sethabilitarInputAgregarLecturaFin1(true);
                } else {
                    sethabilitarInputAgregarLecturaFin1(false);
                }

                if (recalcular) {
                    selectTotalizadorAsociado1.current.value = '';
                    selectDetalleVivienda1.current.value = '';
                    selectNivelConsumo1.current.value = '';
                }
            }

        } catch (error) {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`${error.toString()}`)
            setabrirToast(true);
        }
    }
    const ActualizarLectura1 = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            if (ValidarControlesLectura1()) {

                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Microbalance/ActualizarLecturaMicrobalanceSuministro_v1`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        id_lectura_microbalance_suministro: listaLectura1Suministro.id_lectura_microbalance_suministro,
                        id_suministro_dependiente: selectTotalizadorAsociado1.current.value,
                        id_codigo_lectura: selectCodigoLectura1.current.value,
                        id_detalle_vivienda: selectNivelConsumo1.current.value,
                        lectura: inputLectura1.current.value,
                        lecturafin: inputLectura1.current.value,
                        observacion: inputObservacion1.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        //Deshabilitar
                        setaccionModificarLectura1(false);
                        sethabilitarInputTotalizador1(false);
                        sethabilitarInputLectura1(false);
                        sethabiliarInputDetalleVivienda1(false);
                        sethabilitarInputNivelConsumo1(false);
                        sethabilitarInputAgregarLecturaFin2(false);

                        BuscarDetalleSuministro(detalleSuministroMicrobalance.id_suministro_microbalance);
                        BuscarResumenMicrobalanceGeneral();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const ValidarControlesLectura2 = () => {
        let error = 0;
        if (selectCodigoLectura2.current.value == '') {
            selectCodigoLectura2.current.classList.add('is-invalid');
            error++;

        } else {
            selectCodigoLectura2.current.classList.remove('is-invalid');
            const CodigoLectura = BuscarCodigoLecturaSeleccionado(selectCodigoLectura2.current.value);

            if (CodigoLectura.elegir_totalizador) {
                if (selectTotalizadorAsociado2.current.value.trim() == '') {
                    selectTotalizadorAsociado2.current.classList.add('is-invalid');
                    error++;
                } else {
                    selectTotalizadorAsociado2.current.classList.remove('is-invalid');
                }
            }

            if (CodigoLectura.ingresar_datos) {
                if (selectDetalleVivienda2.current.value == '') {
                    selectDetalleVivienda2.current.classList.add('is-invalid');
                    error++;
                } else {
                    selectDetalleVivienda2.current.classList.remove('is-invalid');
                }

                if (selectNivelConsumo2.current.value == '') {
                    selectNivelConsumo2.current.classList.add('is-invalid');
                    error++;
                } else {
                    selectNivelConsumo2.current.classList.remove('is-invalid');
                }
            } else {
                // selectTotalizadorAsociado2.current.classList.remove('is-invalid');
                selectDetalleVivienda2.current.classList.remove('is-invalid');
                selectNivelConsumo2.current.classList.remove('is-invalid');
            }
        }

        if (inputLectura2.current.value.trim() == '') {
            inputLectura2.current.classList.add('is-invalid');
            error++;
        } else {
            inputLectura2.current.classList.remove('is-invalid');
        }


        if (error > 0) {
            return false;
        }
        return true;
    }
    const ActualizarLectura2 = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            if (ValidarControlesLectura2()) {

                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Microbalance/ActualizarLecturaMicrobalanceSuministro_v1`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let lectura_fin = 0;
                if (habilitarInputAgregarLecturaFin2) {
                    lectura_fin = inputLecturaFin2.current.value;
                } else {
                    lectura_fin = inputLectura2.current.value;
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        id_lectura_microbalance_suministro: listaLectura2Suministro.id_lectura_microbalance_suministro,
                        id_suministro_dependiente: selectTotalizadorAsociado2.current.value,
                        id_codigo_lectura: selectCodigoLectura2.current.value,
                        id_detalle_vivienda: selectNivelConsumo2.current.value,
                        lectura: inputLectura2.current.value,
                        lecturafin: lectura_fin,
                        observacion: inputObservacion2.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        //Deshabilitar
                        setaccionModificarLectura2(false);
                        sethabilitarInputTotalizador2(false);
                        sethabilitarInputLectura2(false);
                        sethabiliarInputDetalleVivienda2(false);
                        sethabilitarInputNivelConsumo2(false);
                        sethabilitarInputAgregarLecturaFin2(false);

                        BuscarDetalleSuministro(detalleSuministroMicrobalance.id_suministro_microbalance);
                        BuscarResumenMicrobalanceGeneral();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const ModificarLectura2 = () => {
        setaccionModificarLectura2(true);
        CambiarCodigoLectura2(selectCodigoLectura2.current.value, false);
    }
    const CambiarCodigoLectura2 = (idcodigolectura, recalcular) => {
        try {
            const CodigoLectura = BuscarCodigoLecturaSeleccionado(idcodigolectura);
            // console.log(CodigoLectura);
            if (CodigoLectura) {
                //Calcular Promedio
                if (CodigoLectura.calcular_promedio && recalcular) {
                    //Consultar si es necesario realizar el calculo
                    if (detalleSuministroMicrobalance.tipo == "REGISTRADO") {
                        let fechaAnterior = moment(detalleSuministroMicrobalance.fecha_lectura_mes_actual);
                        let fechaLectura1 = moment(listaLectura2Suministro.fecha_programacion);
                        let promedioLectura = parseFloat(detalleSuministroMicrobalance.promedio);
                        let ultimaLectura = parseFloat(detalleSuministroMicrobalance.utlima_lectura);
                        let diasFacturados = parseFloat(detalleSuministroMicrobalance.dias_facturados);
                        let diasDiferencia = fechaLectura1.diff(fechaAnterior, 'days');
                        let lecturaCalculada = ((diasDiferencia * promedioLectura) / diasFacturados) + ultimaLectura;
                        inputLectura2.current.value = lecturaCalculada.toFixed(0);

                    } else {
                        // inputLectura2.current.value = '0';
                        inputLectura2.current.value = listaLectura2Suministro.lectura;
                    }
                } else {
                    if (recalcular) {
                        // inputLectura2.current.value = '0';
                        inputLectura2.current.value = listaLectura2Suministro.lectura;
                    }
                }

                //Tomar Lectura
                if (CodigoLectura.tomar_lectura) {
                    sethabilitarInputLectura2(true);
                    // if (recalcular) {
                    //     inputLectura2.current.value = ''
                    // }
                } else {
                    sethabilitarInputLectura2(false);
                }

                if (CodigoLectura.elegir_totalizador) {
                    sethabilitarInputTotalizador2(true);
                } else {
                    sethabilitarInputTotalizador2(false);
                }

                //Ingresar Datos
                if (CodigoLectura.ingresar_datos) {

                    sethabiliarInputDetalleVivienda2(true);
                    sethabilitarInputNivelConsumo2(true);
                } else {

                    sethabiliarInputDetalleVivienda2(false);
                    sethabilitarInputNivelConsumo2(false);
                }

                //Agregar Lectura Fin
                if (CodigoLectura.agregar_lectura_fin) {
                    sethabilitarInputAgregarLecturaFin2(true);
                } else {
                    sethabilitarInputAgregarLecturaFin2(false);
                }



                if (recalcular) {
                    selectTotalizadorAsociado2.current.value = '';
                    selectDetalleVivienda2.current.value = '';
                    selectNivelConsumo2.current.value = '';
                }
            }

        } catch (error) {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`${error.toString()}`)
            setabrirToast(true);
        }
    }
    // Resumen Consumos Suministro
    const AbrirModalResumenConsumos = (row) => {
        BuscarSuministroResumen(row.codigo_suministro).then((respuesta) => {
            if (respuesta) {
                setmostrarModalResumenConsumos(true);
            }
            // console.log(respuesta);
        }).catch((error) => {
            navigate('/');
        })
    }
    const BuscarSuministroResumen = (suministro) => {
        return new Promise(async (resolve, reject) => {
            if (!ValidarUsuario()) {
                reject(null);
            } else {
                //Reiniciar
                // setsuministroAlertas([]);
                setsuministroResumen([]);
                setsuministroConsumos([]);
                setsuministroDeteccion([]);
                setsuministroRecupero([]);
                setdataChartsLectura(null);
                setdataChartsConsumo(null);

                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Suministro/Resumen`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({ suministro: suministro })
                };

                // console.log(headers);

                await fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success && data.contentSuministro.length > 0) {

                        // let idbasesuministro = data.contentSuministro[0].id_base_suministro;
                        // BuscarAlertasSuministro(idbasesuministro);

                        setsuministroResumen(data.contentSuministro);
                        setsuministroConsumos(data.contentConsumo);
                        setsuministroDeteccion(data.contentDeteccion);
                        setsuministroRecupero(data.contentRecupero);
                        //Buscar  Alertas Suministro

                        let arrayLabels = [];
                        let arrayLecturas = [];
                        let arrayPromedio = []
                        let arrayConsumos = []

                        for (let x = data.contentConsumo.length - 1; x >= 0; x--) {
                            arrayLabels.push(data.contentConsumo[x].periodo_comercial);
                            arrayLecturas.push(data.contentConsumo[x].utlima_lectura);
                            arrayConsumos.push(data.contentConsumo[x].cea_consumo);
                            arrayPromedio.push(data.contentConsumo[x].promedio);
                        }

                        let dataChartLecturaTemp = {
                            // labels: ['RECLAMOS', 'NUEVOS SUMINISTROS', 'MANTENIMINETOS', 'AP Y TOTALIZADRORES', 'CONTRASTES', 'ZONA PEDREGAL', 'ZONAL CAMANA'],
                            labels: arrayLabels,
                            datasets: [
                                {
                                    type: 'line',
                                    label: 'Lecturas',
                                    backgroundColor: 'rgba(54, 162, 235,0.3)',
                                    borderColor: 'rgba(54, 162, 235,0.9)',
                                    borderWidth: 2,
                                    fill: false,
                                    data: arrayLecturas
                                    // data: [85, 66, 45, 29, 37, 115, 96],
                                },
                            ],
                        };

                        let dataChartConsumosTemp = {
                            // labels: ['RECLAMOS', 'NUEVOS SUMINISTROS', 'MANTENIMINETOS', 'AP Y TOTALIZADRORES', 'CONTRASTES', 'ZONA PEDREGAL', 'ZONAL CAMANA'],
                            labels: arrayLabels,
                            datasets: [
                                {
                                    type: 'bar',
                                    label: 'Consumo',
                                    backgroundColor: 'rgba(255, 99, 132,0.3)',
                                    borderColor: 'rgba(255, 99, 132)',
                                    borderWidth: 2,
                                    data: arrayConsumos
                                    // data: [86, 60, 20, 20, 37, 115, 83],
                                    // borderColor: 'white',
                                },
                                {
                                    type: 'line',
                                    label: 'Promedio Consumo',
                                    backgroundColor: 'rgba(54, 162, 235,0.3)',
                                    borderColor: 'rgba(54, 162, 235,0.9)',
                                    borderWidth: 2,
                                    data: arrayPromedio
                                    // data: [86, 60, 20, 20, 37, 115, 83],
                                    // borderColor: 'white',
                                }
                            ],
                        };

                        setdataChartsLectura(dataChartLecturaTemp);
                        setdataChartsConsumo(dataChartConsumosTemp);
                        resolve(true);

                    } else {
                        settituloToast("Alerta");
                        settipoToast("warning");
                        setmensajeToast(`No se encontraron datos para mostrar.`)
                        setabrirToast(true);
                        resolve(false)
                    }
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        });
    }
    //Finalizar microbalance
    const ConfirmarFinalizarMicrobalance = () => {
        BuscarCantidadPendientesMicrobalance().then(async (respuesta) => {
            if (respuesta) {
                if (respuesta.content > 0) {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast(`Existen ${respuesta.content} suministros pendientes de ejecución, no debe existir nungun suministro pediente para poder realizar esta acción.`)
                    setabrirToast(true);
                } else {
                    if (window.confirm("¿Esta seguro de finalizar lectura microbalance?")) {
                        FinalizarMicrobalance();
                    }
                }
            }
        });
    }
    const FinalizarMicrobalance = () => {
        try {
            if (!ValidarUsuario()) {
                navigate("/");
            } else {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/FinalizarMicrobalance/${idmicrobalance}`;
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        BuscarResumenMicrobalanceGeneral();

                    } else {
                        settituloToast("Alerta");
                        settipoToast("warning");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    // console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });

            }

        } catch (error) {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`${error.toString()}`)
            setabrirToast(true);
            setmostrarCircularProgress(false);
        }

    }
    //
    const CambiarTipoVivienda1 = (codigovivienda) => {
        setlistaSubDetallevivienda([]);
        if (codigovivienda !== '') {
            if (!ValidarUsuario()) {
                navigate("/");
            } else {

                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/DetalleVivienda/BuscarSubDetalleVivienda`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        codigo: codigovivienda
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        setlistaSubDetallevivienda(data.content);
                        // console.log(data.content);

                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(data.message)
                        setabrirToast(true);
                    }
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });

            }
        }
    }
    const CambiarTipoVivienda2 = (codigovivienda) => {
        setlistaSubDetallevivienda2([]);
        if (codigovivienda !== '') {
            if (!ValidarUsuario()) {
                navigate("/");
            } else {

                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/DetalleVivienda/BuscarSubDetalleVivienda`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        codigo: codigovivienda
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        setlistaSubDetallevivienda2(data.content);
                        // console.log(data.content);

                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(data.message)
                        setabrirToast(true);
                    }
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });

            }
        }
    }

    //Reportes
    const ReporteResumenMicrobalance = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let url = `${config.endPoint}api/Microbalance/Reporte/ResumenMicrobalanceGeneral/${idmicrobalance}`;
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data.content);
                if (data.success) {
                    await CONVERTIR_JSON_A_EXCEL(data.content.tablaResumenMicrobalance, `Resumen SED ${dataResumenMicrobalance.sed}`);

                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(data.message)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(error.tostring())
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }

    //ResumenMicrobalance por Totalizador
    const BuscarResumenMicrobalanceTotalizador = (idsuministrototalizador) => {
        return new Promise((resolve, reject) => {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/ResumenMicrobalanceTotalizador/${idmicrobalance}/${idsuministrototalizador}`;

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                setmostrarCircularProgress(false);
                resolve(data.content);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
                resolve(null);
            });
        });
    }
    const AbrirModalResumenMicrobalanceTotalizador = (idsuministrototalizador) => {
        if (!ValidarUsuario()) {
            navigate('/');
        } {
            BuscarResumenMicrobalanceTotalizador(idsuministrototalizador).then(async (respuesta) => {
                if (respuesta) {
                    await CalcularMicrobalanceTotalizador(respuesta);
                    setmostrarResumenMicrobalance(true);
                }
            });
        }
    }

    const CambiarMostrarProyeccionCalculada = (value) => {
        setmostrarProyeccionCalculada(value)
    }

    //Validar RegresarMicrobalance
    const RetornarMicrobalanceDetalle = () => {
        //Buscar Id en storage
        let idbalance = localStorage.getItem(config.storageidBalance);
        if (idbalance) {
            navigate(`/balance/detalle/${JSON.parse(idbalance)}`);
        } else {
            navigate('/balance/microbalance');
        }
    }
    //************************************ */
    //Agregar Totalizador Temporal
    //************************************ */
    const AbrirModalAgregarTotalizadorTemporal = () => {
        BuscarListaTotalizadoresTemporales();
        setmostrarModalAgregarTotalizador(true)
    }

    const BuscarListaTotalizadoresTemporales = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/TotalizadorTemporal/listar/${idmicrobalance}`;

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    setlistaTotalizadoresTemporales(data.content);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(data.message)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        }
    }

    const ValidarControlesAgregarTemporal = () => {
        let error = 0;
        if (inputSuministroAgregarTemporal.current.value.trim() == '') {
            inputSuministroAgregarTemporal.current.classList.add('is-invalid');
            error++;
        } else {
            inputSuministroAgregarTemporal.current.classList.remove('is-invalid');
        }
        let _factorMedicion = parseFloat(inputFactorMedicionAgregarTemporal.current.value);
        if (isNaN(_factorMedicion)) {
            inputFactorMedicionAgregarTemporal.current.classList.add('is-invalid');
            error++;
        } else {
            inputFactorMedicionAgregarTemporal.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const AgregarTotalizadorTemporal = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarControlesAgregarTemporal()) {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/AgregarTotalizadorTemporal`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idmicrobalance: idmicrobalance,
                        codigosuministro: inputSuministroAgregarTemporal.current.value,
                        factormedicion: inputFactorMedicionAgregarTemporal.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        inputSuministroAgregarTemporal.current.value = "";
                        inputFactorMedicionAgregarTemporal.current.value = "";
                        settituloToast("Correcto");
                        settipoToast("success");
                        BuscarResumenMicrobalanceGeneral();
                        BuscarListaTotalizadoresTemporales();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    const ActualzarEstadoSuministroMicrobalance = (idsuministromicrobalance, estado) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/SuministroMicrobalance/actualizarEstado`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idsuministromicrobalance: idsuministromicrobalance,
                    estado: estado,
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    settituloToast("Correcto");
                    settipoToast("success");
                    BuscarListaTotalizadoresTemporales();
                    BuscarResumenMicrobalanceGeneral();
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                }
                setmensajeToast(data.message)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }

    const EliminarSuministroMicrobalance = (idsuministromicrobalance) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (window.confirm("Esta trando de eliminar un suministro TOTALIZADOR (temporal), esta accion no puede revertirse.\n¿Esta seguro de eliminar este registro?")) {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/SuministroMicrobalance/eliminar`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idsuministromicrobalance: idsuministromicrobalance
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");

                        BuscarListaTotalizadoresTemporales();
                        BuscarResumenMicrobalanceGeneral();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }

    }
    const CrearLectura1Suministro = () => {
        // console.log(detalleSuministroMicrobalance);
        // console.log(dataResumenMicrobalance);
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let url = `${config.endPoint}api/Microbalance/CrearLecturaSuministro`;
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idsuministromicrobalance: detalleSuministroMicrobalance.id_suministro_microbalance,
                    fechaprogramacion: dataResumenMicrobalance.fechaLectura1,
                    tipo: 'LECTURA_1'
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data);
                if (data.success) {
                    settituloToast("Correcto");
                    settipoToast("success");
                    BuscarDetalleSuministro(detalleSuministroMicrobalance.id_suministro_microbalance);
                    // setmostrarModalHabilitarLectura(false);
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                }

                setmensajeToast(data.message)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                // console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        }
    }

    // ************************************************
    // Agregar suministro Adicional
    // ************************************************
    const ValidarSuministroBuscar = () => {
        if (inputSuministroAgregarTemporal.current.value == "") {
            inputSuministroAgregarTemporal.current.classList.add("is-invalid");
            return false;
        } else {
            inputSuministroAgregarTemporal.current.classList.remove("is-invalid");
            return true;
        }
    }
    const BuscarSuministro = () => {
        return new Promise((resolve, reject) => {
            if (!ValidarUsuario()) {
                reject(null);
            } else {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let url = `${config.endPoint}api/basesuministro/BuscarSuministro`;
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        suministro: inputSuministroAgregarTemporal.current.value
                    })
                };


                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    setmostrarCircularProgress(false);
                    resolve(data);
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                    resolve(null);
                });
            }
        });

    }

    const EjecutarAgregarSuministro = async () => {
        setsuministroEncontrado(null);

        if (ValidarSuministroBuscar()) {
            setsuministroEncontradoActual(inputSuministroAgregarTemporal.current.value);

            await BuscarSuministro().then((respuesta) => {
                console.log(respuesta);
                if (respuesta.success) {
                    setsuministroEncontrado(respuesta.content);
                    setmostrarModalAgregarSuministro(false);
                    setmostrarModalMensajeConfirmacionAgregarSuministro(true);

                } else {
                    // No encontrado
                    setsuministroEncontrado(null);
                    setmostrarModalAgregarSuministro(false);
                    setmostrarModalMensajeConfirmacionAgregarSuministro(true);


                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast(`${respuesta.message}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }
            });
        }
    }

    const AgregarSuministroAdicional = () => {
        console.log(dataResumenMicrobalance);
        console.log(suministroEncontrado);
        console.log(idmicrobalance);

        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/AgregarAdicionalLecturaMicrobalanceSuministroWeb`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idmicrobalance: idmicrobalance,
                    idbasesuministro: suministroEncontrado.id_base_suministro,
                    idconsumosuministro: suministroEncontrado.id_consumo_suministro,
                    codigosuministro: suministroEncontrado.codigo_suministro,
                    tarifa: suministroEncontrado.tarifa,
                    facmedicion: suministroEncontrado.factor_medicion,
                    fecha_lectura1: dataResumenMicrobalance.fechaLectura1,
                    fecha_lectura2: dataResumenMicrobalance.fechaLectura2,
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    settituloToast("Correcto");
                    settipoToast("success");
                    BuscarListaTotalizadoresTemporales();
                    BuscarResumenMicrobalanceGeneral();
                    setmostrarModalMensajeConfirmacionAgregarSuministro(false);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                }
                setmensajeToast(data.message)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }

    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Balance / Microbalance / Detalle'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Balance / Microbalance / Detalle</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { RetornarMicrobalanceDetalle() }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            {/* Tabla de Datos Suministros */}
            <div className='container border rounded mt-2'>
                <Container className='mt-2'>
                    <Row >
                        <Col md={3}>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <Stack direction='horizontal' gap={3}>
                                            <Form.Label>{`SED : ${dataResumenMicrobalance.sed} `}
                                                {
                                                    dataResumenMicrobalance.estado == 'FINALIZADO' ?
                                                        <span className='text-danger fw-bold'>{`(${dataResumenMicrobalance.estado})`}</span>
                                                        : <></>
                                                }
                                            </Form.Label>
                                            {/* <IconButton onClick={() => { setmostrarModalObservaciones(true) }}>
                                                <Badge badgeContent={2} color="secondary">
                                                    <SmsIcon></SmsIcon>
                                                </Badge >
                                            </IconButton> */}
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col className='mt-1'>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td className='tabletd ' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px', fontWeight: 'bold' }}>Fecha Lec. 1</td>
                                                    <td className='tabletd'>{dataResumenMicrobalance.fechaLectura1}</td>
                                                </tr>
                                                <tr>
                                                    <td className='tabletd' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px', fontWeight: 'bold' }}>Fecha Lec. 2</td>
                                                    <td className='tabletd'>{dataResumenMicrobalance.fechaLectura2}</td>

                                                </tr>
                                                <tr>

                                                    <td className='tabletd' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px', fontWeight: 'bold' }}>Periodo(Dias)</td>
                                                    <td className='tabletd text-center' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px' }}>
                                                        {dataResumenMicrobalance.diferenciaFechas}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col className='d-grid'>
                                        <Button onClick={() => { setmostrarModalAgregarSuministro(true) }} variant='primary' size='sm'>Agregar Suministro</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col md={3}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td className='tabletd'>CONS TOT</td>
                                        <td style={{ minWidth: 100 }} className='tabletd'>{dataResumenMicrobalance.calculomicrobalance.consumoTotalTotalizadores}</td>
                                        <td className='fw-bold tabletd'>KWH</td>
                                    </tr>
                                    <tr>
                                        <td className='tabletd'>CONS USU</td>
                                        <td className='tabletd'>{dataResumenMicrobalance.calculomicrobalance.consumoTotalUsuarios}</td>
                                        <td className='fw-bold tabletd'>KWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>DIF</td>
                                        <td className='tabletd'>{dataResumenMicrobalance.calculomicrobalance.dif}</td>
                                        <td className='fw-bold tabletd'>KWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>% PERD</td>
                                        <td style={{ color: 'red' }} className='tabletd'>{isNaN(dataResumenMicrobalance.calculomicrobalance.porcPerdida) ? '' : dataResumenMicrobalance.calculomicrobalance.porcPerdida}</td>
                                        <td className='fw-bold tabletd'>%</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>#SUM</td>
                                        <td className='tabletd'>{dataResumenMicrobalance.calculomicrobalance.cantSum}</td>
                                        <td className='fw-bold tabletd'>Sum</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>% Perd./ Usuario</td>
                                        {/* <td className='tabletd' style={{ backgroundColor: 'rgb(132,205,92)' }}>{dataResumenMicrobalance.calculomicrobalance.kwh_sum}</td> */}
                                        <td className='tabletd' >{isNaN(dataResumenMicrobalance.calculomicrobalance.kwh_sum) ? '' : dataResumenMicrobalance.calculomicrobalance.kwh_sum}</td>
                                        <td className='fw-bold tabletd'>Kwh/Sum</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        {
                            mostrarProyeccionCalculada ?
                                <>
                                    <Col>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td className='tabletd-black'>PROYECTADO</td>
                                                    <td style={{ minWidth: 50 }} className='tabletd-black'>{dataResumenMicrobalance.diasfacturados}</td>
                                                    <td className='fw-bold tabletd-black'>Dias</td>
                                                </tr>
                                                <tr>
                                                    <td className='tabletd-black'>CONS. TOT</td>
                                                    <td className='tabletd-black'>{isNaN(dataResumenMicrobalance.proyectado.consumoTotalTotalizadores) ? '' : dataResumenMicrobalance.proyectado.consumoTotalTotalizadores}</td>
                                                    <td className='fw-bold tabletd-black'>KWH</td>
                                                </tr>
                                                <tr >
                                                    <td className='tabletd-black'>CONS. USU</td>
                                                    <td className='tabletd-black'>{isNaN(dataResumenMicrobalance.proyectado.consumoTotalUsuarios) ? '' : dataResumenMicrobalance.proyectado.consumoTotalUsuarios}</td>
                                                    <td className='fw-bold tabletd-black'>KWH</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd-black'>DIF</td>
                                                    <td style={{ color: 'red' }} className='tabletd-black'>{isNaN(dataResumenMicrobalance.proyectado.dif) ? '' : dataResumenMicrobalance.proyectado.dif}</td>
                                                    <td className='fw-bold tabletd-black'>kWH</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd-black'>% Perdida</td>
                                                    <td className='tabletd-black'>{isNaN(dataResumenMicrobalance.proyectado.porcPerdida) ? '' : dataResumenMicrobalance.proyectado.porcPerdida}</td>
                                                    <td className='fw-bold tabletd-black'>%</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd-black'>% Perd./ Usuario</td>
                                                    {/* <td className='tabletd-black' style={{ backgroundColor: 'rgb(244,173,156)', color: 'red' }}>{dataResumenMicrobalance.proyectado.kwh_sum}</td> */}
                                                    <td className='tabletd-black' >{isNaN(dataResumenMicrobalance.proyectado.kwh_sum) ? '' : dataResumenMicrobalance.proyectado.kwh_sum}</td>
                                                    <td className='fw-bold tabletd-black'>Kwh/Sum</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td className='tabletd-black'>FACTURACION</td>
                                                    <td style={{ minWidth: 50 }} className='tabletd-black'>{dataResumenMicrobalance.diasfacturados}</td>
                                                    <td className='fw-bold tabletd-black'>Dias</td>
                                                </tr>
                                                <tr>
                                                    <td className='tabletd-black'>CONS TOT</td>
                                                    <td className='tabletd-black'>{dataResumenMicrobalance.facturacion.consumoTotalTotalizadores}</td>
                                                    <td className='fw-bold tabletd-black'>KWH</td>
                                                </tr>
                                                <tr >
                                                    <td className='tabletd-black'>CONS USU</td>
                                                    <td className='tabletd-black'>{dataResumenMicrobalance.facturacion.consumoTotalUsuarios}</td>
                                                    <td className='fw-bold tabletd-black'>KWH</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd-black'>DIF</td>
                                                    <td style={{ color: 'blue' }} className='tabletd-black'>{dataResumenMicrobalance.facturacion.dif}</td>
                                                    <td className='fw-bold tabletd-black'>KWH</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd-black'>% PERD</td>
                                                    <td className='tabletd-black'>{dataResumenMicrobalance.facturacion.porcPerdida}</td>
                                                    <td className='fw-bold tabletd-black'>%</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd-black'>% PERD / USU</td>
                                                    {/* <td className='tabletd-black' style={{ backgroundColor: 'rgb(132,205,92)' }}>{dataResumenMicrobalance.facturacion.kwh_sum}</td> */}
                                                    <td className='tabletd-black' >{dataResumenMicrobalance.facturacion.kwh_sum}</td>
                                                    <td className='fw-bold tabletd-black'>Kwh/Sum</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </>
                                : <></>
                        }
                    </Row>

                    <Row>
                        <Col className='text-end'>
                            <ButtonMUI variant="text"
                                onClick={AbrirMenu}>
                                Opciones
                                <KeyboardArrowDownIcon />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={objetoMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(objetoMenu)}
                                onClose={CerrarMenu}
                            >
                                {/* <MenuItem onClick={() => { CerrarMenu(); setmostrarModalHabilitarLectura(true) }} disabled={dataResumenMicrobalance.fechaLectura1==''?false:true}>Habilitar Lectura 1</MenuItem>
                                <MenuItem onClick={() => { CerrarMenu(); setmostrarModalHabilitarLectura(true) }} disabled={dataResumenMicrobalance.fechaLectura2==''?false:true}>Habilitar Lectura 2</MenuItem> */}

                                <MenuItem onClick={() => { CerrarMenu(); MostrarModalHabilitarLectura('LECTURA_1') }} disabled={dataResumenMicrobalance.fechaLectura1 == '' ? false : true}>Habilitar Lectura 1</MenuItem>
                                <MenuItem onClick={() => { CerrarMenu(); MostrarModalHabilitarLectura('LECTURA_2') }} disabled={dataResumenMicrobalance.fechaLectura2 == '' && dataResumenMicrobalance.fechaLectura1 != '' ? false : true}>Habilitar Lectura 2</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => { CerrarMenu(); ReporteResumenMicrobalance() }}>Descargar xls</MenuItem>
                                {/* <MenuItem onClick={() => { CerrarMenu(); }}>Ver historial descargas</MenuItem> */}
                                {/* <Divider></Divider> */}
                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MICROBALANCE_MENU_FINALIZAR, listapermisos) ?
                                        dataResumenMicrobalance.estado != 'FINALIZADO' ?
                                            <MenuItem onClick={() => { CerrarMenu(); ConfirmarFinalizarMicrobalance() }} disabled={dataResumenMicrobalance.fechaLectura2 == '' || dataResumenMicrobalance.fechaLectura1 == '' ? true : false}>Finalizar</MenuItem>
                                            : <MenuItem onClick={() => { CerrarMenu() }} disabled={true}>Finalizar</MenuItem>
                                        :
                                        null
                                }
                                {/* <Divider></Divider> */}
                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MICROBALANCE_MENU_MOSTRAR_PROYECCION, listapermisos) ?
                                        mostrarProyeccionCalculada ?
                                            <MenuItem onClick={() => { CerrarMenu(); CambiarMostrarProyeccionCalculada(false) }}>Ocultar Proyección</MenuItem>
                                            : <MenuItem onClick={() => { CerrarMenu(); CambiarMostrarProyeccionCalculada(true) }}>Mostrar Proyección</MenuItem>
                                        : null
                                }
                                {/* <Divider></Divider> */}
                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MICROBALANCE_MENU_AGREGAR_TOTALIZADOR, listapermisos) ?
                                        <MenuItem onClick={() => { CerrarMenu(); AbrirModalAgregarTotalizadorTemporal() }} >Agregar Totalizador(s) Temporal(es)</MenuItem>
                                        : null
                                }
                            </Menu>
                        </Col>
                    </Row>

                </Container>
                {
                    mostrarProyeccionCalculada ?
                        <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650, minWidth: '100%' }}>
                            <Table size='small' >
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ width: 100 }} className="header-colum-table">*</TableCell>
                                        <TableCell style={{ width: 50 }} className="header-colum-table">ITEM</TableCell>
                                        {/* <TableCell style={{ width: 50 }} className="header-colum-table">Tipo</TableCell> */}
                                        <TableCell style={{ width: 100 }} className="header-colum-table">Suministro</TableCell>
                                        {/* <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Prim.</TableCell>
                                            <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Sec.</TableCell> */}
                                        {/* <TableCell className="header-colum-table">Estado</TableCell> */}
                                        <TableCell style={{ width: 150 }} className="header-colum-table">Fac. Med.</TableCell>
                                        <TableCell className="header-colum-table">Tarifa</TableCell>
                                        <TableCell style={{ width: 50, backgroundColor: 'rgb(255,235,156)' }} className="header-colum-table">Lect_1</TableCell>
                                        {/* <TableCell style={{ backgroundColor: 'rgb(255,235,156)' }} className="header-colum-table">Cod 1</TableCell> */}
                                        <TableCell style={{ width: 50, backgroundColor: 'rgb(255,204,153)' }} className="header-colum-table">Lect_2</TableCell>
                                        {/* <TableCell style={{ backgroundColor: 'rgb(255,204,153)' }} className="header-colum-table">Cod 2</TableCell> */}
                                        <TableCell className="header-colum-table">Dif.</TableCell>
                                        <TableCell className="header-colum-table">Obs.</TableCell>
                                        <TableCell className="header-colum-table" style={{ backgroundColor: 'rgb(146,211,110)' }}>Proyección</TableCell>
                                        <TableCell className="header-colum-table" style={{ backgroundColor: 'rgb(146,211,110)' }}>{dataResumenMicrobalance.periodoComercial}</TableCell>
                                        <TableCell style={{ width: 80 }} className="header-colum-table" ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        listaTotalizadores.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell style={{ fontSize: '0.75em' }}>
                                                        <Stack direction='horizontal'>
                                                            <IconButton onClick={() => { MostrarModalDetalleSuministroMicrobalance(row.id_suministro_microbalance) }}>
                                                                <EditIcon fontSize='small'></EditIcon>
                                                            </IconButton>
                                                            <IconButton onClick={() => { AbrirModalResumenMicrobalanceTotalizador(row.id_suministro_microbalance) }}>
                                                                <InsertChartIcon fontSize='small'></InsertChartIcon>
                                                            </IconButton>

                                                            <Tooltip title={<div>Resumen Consumos</div>}>
                                                                <IconButton onClick={() => {
                                                                    AbrirModalResumenConsumos(row)
                                                                }}>
                                                                    <SsidChartIcon fontSize='small'></SsidChartIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>
                                                        {
                                                            row.tipo == "TEMPORAL" ?
                                                                <Badge badgeContent="Temp" color='secondary'>
                                                                    <ButtonMUI className='text-decoration-underline text-primary' >{row.item}</ButtonMUI>
                                                                </Badge> :
                                                                <ButtonMUI className='text-decoration-underline text-primary' >{row.item}</ButtonMUI>
                                                        }
                                                    </TableCell>
                                                    {/* <TableCell style={{ fontSize: '0.75em' }}>{row.tipo}</TableCell> */}
                                                    <TableCell style={{ fontSize: '0.75em' }}>
                                                        {row.codigo_suministro}
                                                    </TableCell>
                                                    {/* <TableCell style={{ fontSize: '0.75em' }}>{`L1`}</TableCell> */}
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.fact_medicion}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.tarifa}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.lectura_lectura1}</TableCell>
                                                    {/* <TableCell style={{ fontSize: '0.75em' }}>{row.cod_lectura1}</TableCell> */}
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.lectura_lectura2}</TableCell>
                                                    {/* <TableCell style={{ fontSize: '0.75em' }}>{row.cod_lectura2}</TableCell> */}
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.diferenciaLecturas}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{`${row.observacionlectura1_lectura1};${row.observacionlectura1_lectura2}`}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.proyeccion}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.cea_consumo_fact}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650, minWidth: '100%' }}>
                            <Table size='small' >
                                <TableHead>
                                    <TableRow >
                                        <TableCell style={{ width: 100 }} className="header-colum-table">*</TableCell>
                                        <TableCell style={{ width: 50 }} className="header-colum-table">ITEM</TableCell>
                                        <TableCell style={{ width: 50 }} className="header-colum-table">Tipo</TableCell>
                                        <TableCell style={{ width: 100 }} className="header-colum-table">Suministro</TableCell>
                                        {/* <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Prim.</TableCell>
                                <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Sec.</TableCell> */}
                                        {/* <TableCell className="header-colum-table">Estado</TableCell> */}
                                        <TableCell style={{ width: 150 }} className="header-colum-table">Fac. Med.</TableCell>
                                        <TableCell className="header-colum-table">Tarifa</TableCell>
                                        <TableCell style={{ width: 50, backgroundColor: 'rgb(255,235,156)' }} className="header-colum-table">Lect_1</TableCell>
                                        <TableCell style={{ backgroundColor: 'rgb(255,235,156)' }} className="header-colum-table">Cod 1</TableCell>
                                        <TableCell style={{ width: 50, backgroundColor: 'rgb(255,204,153)' }} className="header-colum-table">Lect_2</TableCell>
                                        <TableCell style={{ backgroundColor: 'rgb(255,204,153)' }} className="header-colum-table">Cod 2</TableCell>
                                        <TableCell className="header-colum-table">Dif.</TableCell>
                                        <TableCell className="header-colum-table">Obs.</TableCell>
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {
                                        listaTotalizadores.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell style={{ fontSize: '0.75em' }}>
                                                        <Stack direction='horizontal'>
                                                            <IconButton onClick={() => { MostrarModalDetalleSuministroMicrobalance(row.id_suministro_microbalance) }}>
                                                                <EditIcon fontSize='small'></EditIcon>
                                                            </IconButton>
                                                            <IconButton onClick={() => { AbrirModalResumenMicrobalanceTotalizador(row.id_suministro_microbalance) }}>
                                                                <InsertChartIcon fontSize='small'></InsertChartIcon>
                                                            </IconButton>

                                                            <Tooltip title={<div>Resumen Consumos</div>}>
                                                                <IconButton onClick={() => {
                                                                    AbrirModalResumenConsumos(row)
                                                                }}>
                                                                    <SsidChartIcon fontSize='small'></SsidChartIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>
                                                        {
                                                            row.tipo == "TEMPORAL" ?
                                                                <Badge badgeContent="Temp" color='secondary'>
                                                                    <ButtonMUI className='text-decoration-underline text-primary' >{row.item}</ButtonMUI>
                                                                </Badge> :
                                                                <ButtonMUI className='text-decoration-underline text-primary' >{row.item}</ButtonMUI>
                                                        }
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.tipo}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>
                                                        {row.codigo_suministro}
                                                    </TableCell>
                                                    {/* <TableCell style={{ fontSize: '0.75em' }}>{`L1`}</TableCell> */}
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.fact_medicion}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.tarifa}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.lectura_lectura1}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.cod_lectura1}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.lectura_lectura2}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.cod_lectura2}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.diferenciaLecturas}</TableCell>
                                                    <TableCell style={{ fontSize: '0.75em' }}>{`${row.observacionlectura1_lectura1};${row.observacionlectura1_lectura2}`}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                }
                <div className='my-1 mt-3'>
                    <div style={{ height: 400, width: '100%' }}>
                        {
                            mostrarProyeccionCalculada ?
                                <DataGrid
                                    density='compact'
                                    rows={listaSuministros}
                                    columns={columnsProyeccion}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    getCellClassName={(params) => {
                                        if (params.colDef.field == "desviacion") {
                                            let porcentajePerdida = parseFloat(params.row.desviacion)
                                            if (!isNaN(porcentajePerdida)) {
                                                if (porcentajePerdida < 0) {
                                                    return 'MUI-Cells-red'
                                                } else {
                                                    return 'MUI-Cells-black-normal'
                                                }
                                            } else {
                                                return 'MUI-Cells-red'
                                            }
                                        }
                                    }}
                                />
                                :
                                <DataGrid
                                    density='compact'
                                    rows={listaSuministros}
                                    columns={columns}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                />
                        }
                    </div>
                </div>
            </div>

            {/* Modal Modificar Suministro */}
            <Modal
                show={mostrarModalModificarSuministro}
                onHide={() => { setmostrarModalModificarSuministro(false) }}
                keyboard={false}
                size='lg'
            >
                <Modal.Header>
                    <Modal.Title>
                        Detalle
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        {
                            detalleSuministroMicrobalance ?
                                <>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Suministro:</small>
                                            <Form.Control defaultValue={detalleSuministroMicrobalance.codigo_suministro} disabled size='sm'></Form.Control>
                                        </Col>
                                        {/* <Col>
                                            <small className=''>{detalleSuministroMicrobalance.codigo_suministro}</small>
                                        </Col> */}
                                        <Col >
                                            <small className='text-primary'>Tarifa</small>
                                            <Form.Control defaultValue={detalleSuministroMicrobalance.tarifa} disabled size='sm'></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Cliente</small>
                                            <Form.Control defaultValue={detalleSuministroMicrobalance.nombre_suministro} disabled size='sm'></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Direccion</small>
                                            <Form.Control defaultValue={detalleSuministroMicrobalance.direccion_suministro} disabled size='sm'></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col>
                                            <small className='text-primary'>Factor de Medicion</small>
                                            <Stack direction='horizontal' gap={2}>
                                                <Form.Control type='number' ref={inputFactorMedicion} size='sm' disabled={!accionModificarFactorMedicion} defaultValue={detalleSuministroMicrobalance.fact_medicion}></Form.Control>
                                                {
                                                    accionModificarFactorMedicion ?
                                                        <IconButton onClick={() => { ActualizarFactorMedicionSuministro() }} disabled={dataResumenMicrobalance.estado == 'FINALIZADO' ? true : false}>
                                                            <SaveIcon fontSize='small' color='primary'></SaveIcon>
                                                        </IconButton>
                                                        :
                                                        <IconButton onClick={() => { setaccionModificarFactorMedicion(true) }} disabled={dataResumenMicrobalance.estado == 'FINALIZADO' ? true : false}>
                                                            <EditIcon fontSize='small'></EditIcon>
                                                        </IconButton>
                                                }
                                            </Stack>
                                        </Col>

                                    </Row>
                                    <hr></hr>
                                </>
                                : <></>
                        }

                        {/* Lectura */}
                        {/* <Row>
                            {
                                listaLectura2Suministro && !listaLectura1Suministro ?
                                    <Col sm={12} md={6} className="mb-2">
                                        <Container>
                                            <Row>
                                                <small>No se encontro lectura_1 </small>
                                            </Row>
                                            <Row className='text-start'>

                                                <IconButton onClick={() => { ActualizarLectura1() }} disabled={listaLectura1Suministro ? false : true}>
                                                    <ElectricMeterIcon color='primary'></ElectricMeterIcon>
                                                </IconButton>
                                            </Row>
                                        </Container>
                                    </Col>
                                    : <></>
                            }

                        </Row> */}
                        <Row>
                            {
                                listaLectura2Suministro && !listaLectura1Suministro ?
                                    <Col sm={12} md={6} className="mb-2">
                                        <Container>
                                            <Row>
                                                <small className='text-danger'> No se encontro programación de lectura_1 para este suministro, si desea crear una programacion lectura_1 de clic el el boton.</small>
                                            </Row>
                                            <Row className='text-start'>
                                                <Col>
                                                    <IconButton onClick={() => { CrearLectura1Suministro() }} >
                                                        <ElectricMeterIcon color='primary'></ElectricMeterIcon>
                                                    </IconButton>
                                                </Col>

                                            </Row>
                                        </Container>
                                    </Col>
                                    : <></>
                            }

                            {
                                listaLectura1Suministro ?
                                    <Col sm={12} md={6} className="mb-2">
                                        <Container >
                                            <Row>
                                                <Col>
                                                    <Stack>
                                                        <span className='text-primary'>Lectura 1</span>
                                                        <small className='text-secondary' style={{ fontSize: '0.6em' }}>{
                                                            listaLectura1Suministro ? listaLectura1Suministro.estado : 'SIN PROGRAMAR'
                                                        }</small>
                                                    </Stack>
                                                </Col>
                                                {
                                                    dataResumenMicrobalance.estado != 'FINALIZADO' ?
                                                        <Col className='text-end'>
                                                            {
                                                                accionModificarLectura1 ?
                                                                    <IconButton onClick={() => { ActualizarLectura1() }} disabled={listaLectura1Suministro ? false : true}>
                                                                        <SaveIcon fontSize='small' color='primary'></SaveIcon>
                                                                    </IconButton>
                                                                    :
                                                                    <IconButton onClick={() => { ModificarLectura1() }} disabled={listaLectura1Suministro ? false : true}>
                                                                        <EditIcon fontSize='small'></EditIcon>
                                                                    </IconButton>
                                                            }
                                                        </Col>
                                                        : <></>
                                                }
                                            </Row>

                                            {
                                                Object.entries(listaLectura1Suministro.usuario_ejecuta).length === 0 ?
                                                    <></> :
                                                    <Row>
                                                        <Col>
                                                            <small className='text-dark'>{`Ejecutor :  ${listaLectura1Suministro.usuario_ejecuta ? listaLectura1Suministro.usuario_ejecuta : ""}`}</small>
                                                        </Col>
                                                        {/* <Col>
                                                    <small className='text-dark'>{`Modifica :  ${listaLectura1Suministro.usuario_modifica}`}</small>
                                                </Col> */}
                                                    </Row>

                                            }

                                            <Row>
                                                <Col>
                                                    <small className='text-primary'>Codigo Lectura</small>
                                                    <FormSelect onChange={(ev) => { CambiarCodigoLectura(ev.target.value, true) }} ref={selectCodigoLectura1} size='sm' defaultValue={listaLectura1Suministro.id_codigo_lectura1} disabled={!accionModificarLectura1}>
                                                        <option value="">Seleccione un Elemento</option>
                                                        {
                                                            listaCodigosLectura.map((row, index) => {
                                                                return (
                                                                    <option key={index} value={row.id_codigo_lectura}>{
                                                                        row.descripcion
                                                                    }</option>
                                                                )
                                                            })
                                                        }
                                                    </FormSelect>
                                                </Col>
                                            </Row>

                                            <Row >
                                                <Col>
                                                    <small className='text-primary'>Totalizador Asociado</small>
                                                    <Form.Select ref={selectTotalizadorAsociado1} size="sm" defaultValue={listaLectura1Suministro.id_suministro_dependiente} disabled={!habilitarInputTotalizador1} >
                                                        <option value="">Seleccione un Elemento</option>
                                                        {
                                                            listaTotalizadoresSuministro.map((row, index) => {
                                                                return (
                                                                    <option key={index} value={row.id_suministro_microbalance}>{
                                                                        row.tipo == 'REGISTRADO' ? `[${row.codigo_suministro}] ${row.nombre_suministro}` : row.codigo_suministro
                                                                    }</option>
                                                                )
                                                            })

                                                        }
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                            {/* <Row >
                                                        <Col>
                                                            <small className='text-primary'>Lectura Fin</small>
                                                            <Form.Control ref={inputLecturaFin1} size='sm' defaultValue={listaLectura1Suministro.lectura_fin} disabled={!habilitarInputAgregarLecturaFin1}></Form.Control>
                                                        </Col>
                                                    </Row> */}

                                            {/* {
                                                habilitarInputAgregarLecturaFin1 ?
                                                    <Row >
                                                        <Col>
                                                            <small className='text-primary'>Lectura Fin</small>
                                                            <Form.Control ref={inputLecturaFin1} size='sm' defaultValue={listaLectura1Suministro.lectura_fin} disabled={!habilitarInputAgregarLecturaFin1}></Form.Control>
                                                        </Col>
                                                    </Row>
                                                    : <></>
                                            } */}

                                            <Row >
                                                <Col>
                                                    <small className='text-primary'>Lectura</small>
                                                    <Form.Control ref={inputLectura1} size='sm' defaultValue={listaLectura1Suministro.lectura} disabled={!habilitarInputLectura1}></Form.Control>
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col>
                                                    <small className='text-primary'>Tipo Vivienda</small>
                                                    <FormSelect ref={selectDetalleVivienda1} size='sm' onChange={(ev) => { CambiarTipoVivienda1(ev.target.value) }} defaultValue={Object.entries(listaLectura1Suministro.codigo1).length > 0 ? listaLectura1Suministro.codigo1 : ''} disabled={!habiliarInputDetalleVivienda1}>
                                                        <option value="">Seleccione un Elemento</option>
                                                        {
                                                            listaDetallesVivienda.map((row, index) => {
                                                                return (
                                                                    <option key={index} value={row.codigo}>{
                                                                        row.codigo
                                                                    }</option>
                                                                )
                                                            })
                                                        }
                                                    </FormSelect>
                                                </Col>
                                            </Row>


                                            {
                                                accionModificarLectura1 ?
                                                    <Row>
                                                        <Col>
                                                            <small className='text-primary'>Nivel de Consumo</small>
                                                            <FormSelect ref={selectNivelConsumo1} size='sm' defaultValue={listaLectura1Suministro.id_detalle_vivienda} disabled={!habilitarInputNivelConsumo1}>
                                                                <option value="">Seleccione un Elemento</option>
                                                                {
                                                                    listaSubDetallevivienda.map((row, index) => {
                                                                        return (
                                                                            <option key={index} value={row.id_detalle_vivienda}>{
                                                                                row.subcodigo
                                                                            }</option>
                                                                        )
                                                                    })
                                                                }
                                                            </FormSelect>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Row>
                                                        <Col>
                                                            <small className='text-primary'>Nivel de Consumo</small>
                                                            <FormControl size='sm' defaultValue={Object.entries(listaLectura1Suministro.subcodigo).length > 0 ? listaLectura1Suministro.subcodigo : ''} disabled></FormControl>
                                                        </Col>
                                                    </Row>


                                            }
                                            <Row >
                                                <Col>
                                                    <small className='text-primary'>Observación</small>
                                                    <Form.Control ref={inputObservacion1} defaultValue={listaLectura1Suministro.observacion} size='sm' disabled={!accionModificarLectura1}></Form.Control>
                                                </Col>
                                            </Row>

                                        </Container>

                                    </Col>
                                    : <></>
                            }

                            {listaLectura2Suministro ?
                                <Col sm={12} md={6} className="mb-2">
                                    <Container >
                                        <Row>
                                            <Col>
                                                <Stack>
                                                    <span className='text-primary'>Lectura 2</span>
                                                    <small className='text-secondary' style={{ fontSize: '0.6em' }}>{
                                                        listaLectura2Suministro ? listaLectura2Suministro.estado : 'SIN PROGRAMAR'
                                                    }</small>
                                                </Stack>
                                            </Col>
                                            {
                                                dataResumenMicrobalance.estado != 'FINALIZADO' ?
                                                    <Col className='text-end'>
                                                        {
                                                            accionModificarLectura2 ?
                                                                <IconButton onClick={() => { ActualizarLectura2() }} disabled={listaLectura2Suministro ? false : true}>
                                                                    <SaveIcon fontSize='small' color='primary'></SaveIcon>
                                                                </IconButton>
                                                                :
                                                                <IconButton onClick={() => { ModificarLectura2() }} disabled={listaLectura2Suministro ? false : true}>
                                                                    <EditIcon fontSize='small'></EditIcon>
                                                                </IconButton>
                                                        }
                                                    </Col>
                                                    : <></>
                                            }
                                        </Row>

                                        {
                                            Object.entries(listaLectura2Suministro.usuario_ejecuta).length === 0 ?
                                                <></> :
                                                <Row>
                                                    <Col>
                                                        <small className='text-dark'>{`Ejecutor :  ${listaLectura2Suministro.usuario_ejecuta ? listaLectura2Suministro.usuario_ejecuta : ""}`}</small>
                                                    </Col>
                                                    {/* <Col>
                                                    <small className='text-dark'>{`Modifica :  ${listaLectura2Suministro.usuario_modifica}`}</small>
                                                </Col> */}
                                                </Row>
                                        }

                                        <Row>
                                            <Col>
                                                <small className='text-primary'>Codigo Lectura</small>
                                                <FormSelect onChange={(ev) => { CambiarCodigoLectura2(ev.target.value, true) }} ref={selectCodigoLectura2} size='sm' defaultValue={listaLectura2Suministro.id_codigo_lectura1} disabled={!accionModificarLectura2}>
                                                    <option value="">Seleccione un Elemento</option>
                                                    {
                                                        listaCodigosLectura.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.id_codigo_lectura}>{
                                                                    row.descripcion
                                                                }</option>
                                                            )
                                                        })
                                                    }
                                                </FormSelect>
                                            </Col>
                                        </Row>

                                        <Row >
                                            <Col>
                                                <small className='text-primary'>Totalizador Asociado</small>
                                                <Form.Select ref={selectTotalizadorAsociado2} size="sm" defaultValue={listaLectura2Suministro.id_suministro_dependiente} disabled={!habilitarInputTotalizador2} >
                                                    <option value="">Seleccione un Elemento</option>
                                                    {
                                                        listaTotalizadoresSuministro.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.id_suministro_microbalance}>{
                                                                    row.tipo == 'REGISTRADO' ? `[${row.codigo_suministro}] ${row.nombre_suministro}` : row.codigo_suministro
                                                                }</option>
                                                            )
                                                        })

                                                    }
                                                </Form.Select>
                                            </Col>
                                        </Row>

                                        {/* <Row >
                                                    <Col>
                                                        <small className='text-primary'>Lectura Fin</small>
                                                        <Form.Control ref={inputLecturaFin2} size='sm' defaultValue={listaLectura2Suministro.lectura_fin} disabled={!habilitarInputAgregarLecturaFin2}></Form.Control>
                                                    </Col>
                                                </Row>  */}

                                        {
                                            habilitarInputAgregarLecturaFin2 ?
                                                <Row >
                                                    <Col>
                                                        <small className='text-primary'>Lectura Fin</small>
                                                        <Form.Control ref={inputLecturaFin2} size='sm' defaultValue={listaLectura2Suministro.lectura_fin} disabled={!habilitarInputAgregarLecturaFin2}></Form.Control>
                                                    </Col>
                                                </Row> :
                                                <></>

                                        }

                                        <Row >
                                            <Col>
                                                <small className='text-primary'>Lectura</small>
                                                <Form.Control ref={inputLectura2} size='sm' defaultValue={listaLectura2Suministro.lectura} disabled={!habilitarInputLectura2}></Form.Control>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col>
                                                <small className='text-primary'>Tipo Vivienda</small>
                                                <FormSelect ref={selectDetalleVivienda2} size='sm' onChange={(ev) => { CambiarTipoVivienda2(ev.target.value) }} defaultValue={Object.entries(listaLectura2Suministro.codigo1).length > 0 ? listaLectura2Suministro.codigo1 : ''} disabled={!habiliarInputDetalleVivienda2}>
                                                    <option value="">Seleccione un Elemento</option>
                                                    {
                                                        listaDetallesVivienda.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.codigo}>{
                                                                    row.codigo
                                                                }</option>
                                                            )
                                                        })
                                                    }
                                                </FormSelect>
                                            </Col>
                                        </Row>


                                        {
                                            accionModificarLectura2 ?
                                                <Row>
                                                    <Col>
                                                        <small className='text-primary'>Nivel de Consumo</small>
                                                        <FormSelect ref={selectNivelConsumo2} size='sm' defaultValue={listaLectura2Suministro.id_detalle_vivienda} disabled={!habilitarInputNivelConsumo2}>
                                                            <option value="">Seleccione un Elemento</option>
                                                            {
                                                                listaSubDetallevivienda2.map((row, index) => {
                                                                    return (
                                                                        <option key={index} value={row.id_detalle_vivienda}>{
                                                                            row.subcodigo
                                                                        }</option>
                                                                    )
                                                                })
                                                            }
                                                        </FormSelect>
                                                    </Col>
                                                </Row>
                                                :
                                                <Row>
                                                    <Col>
                                                        <small className='text-primary'>Nivel de Consumo</small>
                                                        <FormControl size='sm' defaultValue={Object.entries(listaLectura2Suministro.subcodigo).length > 0 ? listaLectura2Suministro.subcodigo : ''} disabled></FormControl>
                                                    </Col>
                                                </Row>


                                        }
                                        <Row >
                                            <Col>
                                                <small className='text-primary'>Observación</small>
                                                <Form.Control ref={inputObservacion2} defaultValue={listaLectura2Suministro.observacion} size='sm' disabled={!accionModificarLectura2}></Form.Control>
                                            </Col>
                                        </Row>

                                    </Container>

                                </Col>
                                : <></>
                            }

                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Row className='mt-4'>
                        <Col className='text-end'>
                            <Button variant='outline-primary' onClick={() => { setmostrarModalModificarSuministro(false) }}>Cerrar</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            {/* Modal Resumen Microbalance */}
            <Modal
                show={mostrarResumenMicrobalance}
                onHide={() => { setmostrarResumenMicrobalance(false) }}
                keyboard={false}
                size='xl'
            // fullscreen
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Resumen SED ${dataResumenMicrobalanceSegmento.sed}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Tabs defaultActiveKey="mapa" className="mb-3">
                        {/* Resumen */}
                        <Tab eventKey="resumen" title="Resumen">
                            <Container>
                                <Row >
                                    <Col >
                                        <Container fluid>
                                            <Row className='mt-3'>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className='tabletd ' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px', fontWeight: 'bold' }}>Fecha Lec. 1</td>
                                                            <td className='tabletd'>{dataResumenMicrobalanceSegmento.fechaLectura1}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='tabletd' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px', fontWeight: 'bold' }}>Fecha Lec. 2</td>
                                                            <td className='tabletd'>{dataResumenMicrobalanceSegmento.fechaLectura2}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='tabletd' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px', fontWeight: 'bold' }}>Periodo(Dias)</td>
                                                            <td className='tabletd text-center' style={{ backgroundColor: 'rgb(240,240,240)', width: '120px' }}>
                                                                {dataResumenMicrobalanceSegmento.diferenciaFechas}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Row>
                                        </Container>
                                    </Col>
                                    <Col >
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr>
                                                    <td className='tabletd'>CONS TOT</td>
                                                    <td style={{ minWidth: 100 }} className='tabletd'>{dataResumenMicrobalanceSegmento.calculomicrobalance.consumoTotalTotalizadores}</td>
                                                    <td className='fw-bold tabletd'>KWH</td>
                                                </tr>
                                                <tr>
                                                    <td className='tabletd'>CONS USU</td>
                                                    <td className='tabletd'>{dataResumenMicrobalanceSegmento.calculomicrobalance.consumoTotalUsuarios}</td>
                                                    <td className='fw-bold tabletd'>KWH</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd'>DIF</td>
                                                    <td className='tabletd'>{dataResumenMicrobalanceSegmento.calculomicrobalance.dif}</td>
                                                    <td className='fw-bold tabletd'>KWH</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd'>% PERD</td>
                                                    <td style={{ color: 'red' }} className='tabletd'>{isNaN(dataResumenMicrobalanceSegmento.calculomicrobalance.porcPerdida) ? '' : dataResumenMicrobalanceSegmento.calculomicrobalance.porcPerdida}</td>
                                                    <td className='fw-bold tabletd'>%</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd'>#SUM</td>
                                                    <td className='tabletd'>{dataResumenMicrobalanceSegmento.calculomicrobalance.cantSum}</td>
                                                    <td className='fw-bold tabletd'>Sum</td>
                                                </tr>
                                                <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                                    <td className='tabletd'>% Perd./ Usuario</td>
                                                    {/* <td className='tabletd' style={{ backgroundColor: 'rgb(132,205,92)' }}>{dataResumenMicrobalance.calculomicrobalance.kwh_sum}</td> */}
                                                    <td className='tabletd' >{isNaN(dataResumenMicrobalanceSegmento.calculomicrobalance.kwh_sum) ? '' : dataResumenMicrobalanceSegmento.calculomicrobalance.kwh_sum}</td>
                                                    <td className='fw-bold tabletd'>Kwh/Sum</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col md={5} className='align-self-center'>

                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col>
                                        {/* Tabla totalizadores */}
                                        <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650, minWidth: '100%' }}>
                                            <Table size='small' >
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell style={{ width: 100 }} className="header-colum-table">*</TableCell>
                                                        <TableCell style={{ width: 50 }} className="header-colum-table">ITEM</TableCell>
                                                        <TableCell style={{ width: 50 }} className="header-colum-table">Tipo</TableCell>
                                                        <TableCell style={{ width: 100 }} className="header-colum-table">Suministro</TableCell>
                                                        {/* <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Prim.</TableCell>
                                                        <TableCell style={{ color: 'rgb(32,32,255)' }} className="header-colum-table">TC Sec.</TableCell> */}
                                                        {/* <TableCell className="header-colum-table">Estado</TableCell> */}
                                                        <TableCell style={{ width: 150 }} className="header-colum-table">Fac. Med.</TableCell>
                                                        <TableCell className="header-colum-table">Tarifa</TableCell>
                                                        <TableCell style={{ width: 50, backgroundColor: 'rgb(255,235,156)' }} className="header-colum-table">Lect_1</TableCell>
                                                        <TableCell style={{ backgroundColor: 'rgb(255,235,156)' }} className="header-colum-table">Cod 1</TableCell>
                                                        <TableCell style={{ width: 50, backgroundColor: 'rgb(255,204,153)' }} className="header-colum-table">Lect_2</TableCell>
                                                        <TableCell style={{ backgroundColor: 'rgb(255,204,153)' }} className="header-colum-table">Cod 2</TableCell>
                                                        <TableCell className="header-colum-table">Dif.</TableCell>
                                                        <TableCell className="header-colum-table">Obs.</TableCell>
                                                    </TableRow>

                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        listaTotalizadoresSegmento.map((row, index) => {
                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>
                                                                        <Stack direction='horizontal'>
                                                                            <IconButton onClick={() => { MostrarModalDetalleSuministroMicrobalance(row.id_suministro_microbalance) }}>
                                                                                <EditIcon fontSize='small'></EditIcon>
                                                                            </IconButton>

                                                                            <Tooltip title={<div>Resumen Consumos</div>}>
                                                                                <IconButton onClick={() => {
                                                                                    AbrirModalResumenConsumos(row)
                                                                                }}>
                                                                                    <SsidChartIcon fontSize='small'></SsidChartIcon>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>
                                                                        {
                                                                            row.tipo == "TEMPORAL" ?
                                                                                <Badge badgeContent="Temp" color='secondary'>
                                                                                    <ButtonMUI className='text-decoration-underline text-primary' >{row.item}</ButtonMUI>
                                                                                </Badge> :
                                                                                <ButtonMUI className='text-decoration-underline text-primary' >{row.item}</ButtonMUI>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.tipo}</TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>
                                                                        {row.codigo_suministro}
                                                                    </TableCell>
                                                                    {/* <TableCell style={{ fontSize: '0.75em' }}>{`L1`}</TableCell> */}
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.fact_medicion}</TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.tarifa}</TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.lectura_lectura1}</TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.cod_lectura1}</TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.lectura_lectura2}</TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.cod_lectura2}</TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{row.diferenciaLecturas}</TableCell>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{`${row.observacionlectura1_lectura1};${row.observacionlectura1_lectura2}`}</TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Tabla Suministros asociados a SED y Totalizador */}
                                        <div className='my-1 mt-3'>
                                            <div style={{ height: 400, width: '100%' }}>
                                                <DataGrid
                                                    density='compact'
                                                    rows={listaSuministrosSegmento}
                                                    columns={columnsSegmento}
                                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                />
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        {/* Mapa */}
                        <Tab eventKey="mapa" title="Mapa">
                            <Row>
                                <Col>
                                    <Form>
                                        <div key={`inline-radio`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label="Lectura 1"
                                                name="group1"
                                                type='radio'
                                                id={`1`}
                                                defaultChecked
                                                onChange={(ev) => { setgpsMostrarSegmento('LECTURA_1') }}
                                            />
                                            <Form.Check
                                                inline
                                                label="Lectura 2"
                                                name="group1"
                                                type='radio'
                                                id={`2`}
                                                onChange={(ev) => { setgpsMostrarSegmento('LECTURA_2') }}
                                            />
                                        </div>
                                    </Form>
                                </Col>
                            </Row>

                            <MapContainer center={centroTotalizador} zoom={17} style={{ width: "100%", height: "70vh" }}>
                                {/* <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                /> */}
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                />
                                {/* <Marker title="" position={[-16.399542, -71.536466]} icon={Pin1}></Marker> */}
                                {
                                    listaSuministrosSegmento.map((row, index) => {
                                        switch (gpsMostrarSegmento) {
                                            case 'LECTURA_1':
                                                let latitud1 = parseFloat(row.lat1);
                                                let longitud1 = parseFloat(row.lng1);
                                                if (!isNaN(latitud1)) {
                                                    return (
                                                        <Marker key={index} title={row.codigo_suministro} position={[latitud1, longitud1]} icon={Pin1}>
                                                            <Popup>
                                                                {row.codigo_suministro}
                                                            </Popup>
                                                        </Marker>
                                                    )
                                                }
                                                break;
                                            case 'LECTURA_2':
                                                let latitud2 = parseFloat(row.lat2);
                                                let longitud2 = parseFloat(row.lng2);
                                                if (!isNaN(latitud2)) {
                                                    return (
                                                        <Marker key={index} title={row.codigo_suministro} position={[latitud2, longitud2]} icon={Pin1}>
                                                            <Popup>
                                                                {row.codigo_suministro}
                                                            </Popup>
                                                        </Marker>
                                                    )
                                                }
                                                break;
                                        }
                                    })
                                }
                                {
                                    listaTotalizadoresSegmento.map((row, index) => {
                                        switch (gpsMostrarSegmento) {
                                            case 'LECTURA_1':
                                                let latitud1 = parseFloat(row.lat1);
                                                let longitud1 = parseFloat(row.lng1);
                                                if (!isNaN(latitud1)) {
                                                    return (
                                                        <Marker key={index} title={row.codigo_suministro} position={[latitud1, longitud1]} icon={Pin2}>
                                                            <Popup>
                                                                {row.codigo_suministro}
                                                            </Popup>
                                                        </Marker>
                                                    )
                                                }
                                                break;
                                            case 'LECTURA_2':
                                                let latitud2 = parseFloat(row.lat2);
                                                let longitud2 = parseFloat(row.lng2);
                                                if (!isNaN(latitud2)) {
                                                    return (
                                                        <Marker key={index} title={row.codigo_suministro} position={[latitud2, longitud2]} icon={Pin2}>
                                                            <Popup>
                                                                {row.codigo_suministro}
                                                            </Popup>
                                                        </Marker>
                                                    )
                                                }
                                                break;
                                        }
                                    })
                                }
                            </MapContainer>
                        </Tab>
                    </Tabs>
                    <Row className='mt-2 text-end'>
                        <Col>
                            <Button variant='primary'>Cerrar</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            {/* Modal Habilitar Lecturas */}
            <Modal
                show={mostrarModalHabilitarLectura}
                onHide={() => { setmostrarModalHabilitarLectura(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Habilitar Lectura
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <small className='text-primary'>Tipo Lectura</small>
                                <Form.Control size='sm' disabled defaultValue={tipoLecturaProgramar}></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className='text-primary'>Elegir Fecha Lectura</small>
                                <Form.Control ref={inputFechaProgramacionLectura} size='sm' type='date'></Form.Control>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button variant='outline-primary' size='sm' onClick={() => { setmostrarModalHabilitarLectura(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button variant='primary' size='sm' onClick={() => { ProgramarLectura() }}>Aceptar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Observaciones */}
            <Modal
                show={mostrarModalObservaciones}
                onHide={() => { setmostrarModalObservaciones(false) }}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Observaciones
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row >
                            <Col>
                                <small className='text-primary'>Observación</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Stack direction='horizontal' gap={2}>
                                    <Form.Control size='sm' placeholder='Observación'></Form.Control>
                                    <IconButton>
                                        <AddIcon></AddIcon>
                                    </IconButton>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <TableContainer style={{ maxHeight: 200, minWidth: '100%' }} >
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell className="" style={{ width: '80px' }}>Accion</TableCell>
                                                <TableCell className="">Observación</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell style={{ fontSize: '0.75em' }}>
                                                    <Stack direction='horizontal'>

                                                        <IconButton className='mx-2'>
                                                            <EditIcon fontSize='small'></EditIcon>
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '0.75em' }}>Observacion General 1</TableCell>

                                            </TableRow>
                                            <TableRow >
                                                <TableCell style={{ fontSize: '0.75em' }}>
                                                    <Stack direction='horizontal'>
                                                        <IconButton className='mx-2'>
                                                            <EditIcon fontSize='small'></EditIcon>
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '0.75em' }}>Observacion General 1</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Col>
                        </Row>
                        <Row className='mt-4 '>
                            <Col className='text-end'>
                                <Button size='sm' variant='outline-primary' onClick={() => { setmostrarModalObservaciones(false) }}>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Resumen Suministro */}
            <Modal
                show={mostrarModalResumenConsumos}
                onHide={() => { setmostrarModalResumenConsumos(false) }}
                keyboard={false}
                size='xl'
            >
                <Modal.Header>
                    <Modal.Title>
                        Resumen Suministro
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        {
                            suministroResumen.length > 0 ?
                                <Row className='mt-2'>
                                    <Accordion>
                                        <Accordion.Item>
                                            <Accordion.Header>{`Suministro ${suministroResumen[0].codigo_suministro}`}</Accordion.Header>
                                            <Accordion.Body>
                                                <Container>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Nombre: </strong>
                                                            <Form.Text className='mx-2'>{suministroResumen[0].nombre_suministro}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Dirección: </strong>
                                                            <Form.Text className='mx-2'>{suministroResumen[0].direccion_suministro}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Tarifa :</strong>
                                                            <Form.Text className='mx-2'>{suministroResumen[0].tarifa}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>SED :</strong>
                                                            <Form.Text className='mx-2'>{suministroResumen[0].sed_suministro}</Form.Text>
                                                        </Col>

                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>SET :</strong>
                                                            <Form.Text className='mx-2'>{suministroResumen[0].set_suministro}</Form.Text>
                                                        </Col>

                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>AMT :</strong>
                                                            <Form.Text className='mx-2'>{suministroResumen[0].amt_suministro}</Form.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Zona Administrativa :</strong>
                                                            <Form.Text className='mx-2'>{suministroResumen[0].zona_administrativa}</Form.Text>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Sistema Electrico :</strong>
                                                            <Form.Text className='mx-2'>{suministroResumen[0].sistema_electrico}</Form.Text>
                                                        </Col>
                                                        {/* {
                                                            suministroAlertas.length > 0 ?
                                                                <Col className='text-end'>
                                                                    <IconButton onClick={() => {
                                                                        MostrarModalDetalleAlertaSuministro(suministroResumen[0].id_base_suministro)
                                                                    }}>
                                                                        <Badge badgeContent={suministroAlertas.length} color='secondary'>
                                                                            <CommentIcon color='primary'></CommentIcon>
                                                                        </Badge>
                                                                    </IconButton>
                                                                </Col>
                                                                : <></>
                                                        } */}
                                                    </Row>

                                                </Container>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Row>
                                : <></>
                        }

                        {
                            suministroConsumos.length > 0 ?
                                <Row className='mt-2'>
                                    <Col >
                                        <Accordion>
                                            <Accordion.Item>
                                                <Accordion.Header>Resumen Consumos</Accordion.Header>
                                                <Accordion.Body>
                                                    <Container fluid>
                                                        <Row>
                                                            <TableContainer style={{ maxHeight: '300px' }}>
                                                                <Table size='small' stickyHeader>
                                                                    <TableHead>
                                                                        <TableRow >
                                                                            <TableCell>Periodo</TableCell>
                                                                            <TableCell>Lectura</TableCell>
                                                                            <TableCell>Consumo</TableCell>
                                                                            <TableCell>Fecha Lectura</TableCell>
                                                                            <TableCell>Cod Lectura</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            suministroConsumos.map((row, index) => {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell >
                                                                                            {row.periodo_comercial}
                                                                                        </TableCell>
                                                                                        <TableCell >
                                                                                            {row.utlima_lectura}
                                                                                        </TableCell>
                                                                                        <TableCell >
                                                                                            {row.cea_consumo}
                                                                                        </TableCell>
                                                                                        <TableCell >
                                                                                            {moment(row.fecha_lectura_mes_actual).format("DD/MM/YYYY")}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {row.codigo_lectura_mes_actual}
                                                                                        </TableCell>
                                                                                    </TableRow>

                                                                                )
                                                                            })
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>

                                                        </Row>
                                                        <hr></hr>

                                                        {
                                                            dataChartsLectura ?
                                                                <Row>
                                                                    <Col sm={12} md={6}>
                                                                        <Line data={dataChartsLectura} options={
                                                                            {
                                                                                responsive: true,
                                                                                maintainAspectRatio: false,
                                                                                plugins: {
                                                                                    legend: {
                                                                                        position: 'top',
                                                                                    },
                                                                                    title: {
                                                                                        display: true,
                                                                                        text: 'Resumen Lecturas',
                                                                                    }
                                                                                }
                                                                            }
                                                                        } />
                                                                    </Col>
                                                                    <Col sm={12} md={6}>
                                                                        <Bar data={dataChartsConsumo} options={
                                                                            {
                                                                                responsive: true,
                                                                                maintainAspectRatio: false,
                                                                                plugins: {
                                                                                    legend: {
                                                                                        position: 'top',
                                                                                    },
                                                                                    title: {
                                                                                        display: true,
                                                                                        text: 'Resumen Consumos',
                                                                                    }
                                                                                }
                                                                            }
                                                                        } />
                                                                    </Col>
                                                                </Row> : <></>

                                                        }
                                                    </Container>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Col>
                                </Row>

                                : <></>
                        }
                        {
                            suministroDeteccion.length > 0 ?
                                <Row className='mt-2'>
                                    <Accordion>
                                        <Accordion.Item>
                                            <Accordion.Header>Resumen Detecciones</Accordion.Header>
                                            <Accordion.Body>

                                                <TableContainer style={{ maxHeight: '300px' }}>
                                                    <Table size='small' stickyHeader>
                                                        <TableHead>
                                                            <TableRow >
                                                                <TableCell>Acciones</TableCell>
                                                                <TableCell>Fecha Intervencion</TableCell>
                                                                <TableCell>Detalle</TableCell>
                                                                <TableCell>Observaciones</TableCell>
                                                                <TableCell>Area Procedencia</TableCell>
                                                                <TableCell>zona</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                suministroDeteccion.map((row, index) => {
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <TableCell >
                                                                                <IconButton onClick={() => {
                                                                                    // MostrarModalDetecciones(row)
                                                                                }}>
                                                                                    <WysiwygIcon></WysiwygIcon>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.fecha_intervencion}
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.detalle}
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.observaciones}
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.area_procedencia}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {row.zona}
                                                                            </TableCell>
                                                                        </TableRow>

                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Row>
                                : <></>
                        }
                        {
                            suministroRecupero.length > 0 ?
                                <Row className='mt-2'>
                                    <Accordion>
                                        <Accordion.Item>
                                            <Accordion.Header>Resumen Recuperos</Accordion.Header>
                                            <Accordion.Body>

                                                <TableContainer style={{ maxHeight: '300px' }}>
                                                    <Table size='small' stickyHeader>
                                                        <TableHead>
                                                            <TableRow >
                                                                <TableCell>Acciones</TableCell>
                                                                <TableCell>Fecha</TableCell>
                                                                <TableCell>Oficina</TableCell>
                                                                <TableCell>Descripción</TableCell>
                                                                <TableCell>Mes Proceso</TableCell>
                                                                <TableCell>JUO</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                suministroRecupero.map((row, index) => {
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <TableCell >
                                                                                <IconButton onClick={() => {
                                                                                    // MostrarMotalRecupero(row)
                                                                                }}>
                                                                                    <WysiwygIcon></WysiwygIcon>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.fecha}
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.oficina}
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.descripcion_irregularidad}
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.mes_proceso}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {row.juo}
                                                                            </TableCell>
                                                                        </TableRow>

                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Row>
                                : <></>
                        }
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Totalizador */}
            <Modal
                show={mostrarModalAgregarTotalizador}
                onHide={() => { setmostrarModalAgregarTotalizador(false) }}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Totalizador Temporal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row >
                            <Col>
                                <small className='text-primary'>Codigo Suministro</small>
                                <Form.Control ref={inputSuministroAgregarTemporal} size='sm' placeholder='Ingrese el Suministro' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 != '' ? true : false}></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className='text-primary'>Factor de Medicion</small>
                                <Stack direction='horizontal' gap={2}>
                                    <Form.Control ref={inputFactorMedicionAgregarTemporal} size='sm' placeholder='Ingrese el Factor de Medicion' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 != '' ? true : false}></Form.Control>
                                    <Tooltip title="Agregar">
                                        <IconButton onClick={() => { AgregarTotalizadorTemporal() }} disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 != '' ? true : false}>
                                            <AddIcon></AddIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row className='mt-2'>
                            <Col>
                                <TableContainer style={{ maxHeight: 200, minWidth: '100%' }} >
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell className="header-colum-table">Accion</TableCell>
                                                <TableCell className="header-colum-table">Totalizador</TableCell>
                                                <TableCell className="header-colum-table">Obs.</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                listaTotalizadoresTemporales.map((row) => {
                                                    return (
                                                        <TableRow key={row.id_suministro_microbalance}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <Stack direction='horizontal'>
                                                                    {
                                                                        row.estado == "ACTIVO" ?
                                                                            <Form.Check onClick={() => { ActualzarEstadoSuministroMicrobalance(row.id_suministro_microbalance, 'INACTIVO') }} defaultChecked type="switch" className='mx-2' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 != '' ? true : false} />
                                                                            : <Form.Check onClick={() => { ActualzarEstadoSuministroMicrobalance(row.id_suministro_microbalance, 'ACTIVO') }} type="switch" className='mx-2' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 != '' ? true : false} />

                                                                    }
                                                                    <Tooltip title="Eliminar Elemento">
                                                                        <IconButton onClick={() => { EliminarSuministroMicrobalance(row.id_suministro_microbalance) }} className='mx-2' disabled={dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 != '' ? true : false}>
                                                                            <DeleteIcon fontSize='small'></DeleteIcon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.codigo_suministro}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.tipo}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Col>
                            {
                                dataResumenMicrobalance.fechaLectura2 != '' || dataResumenMicrobalance.fechaLectura1 != '' ?
                                    <Row>
                                        <Col>
                                            <small className='text-muted'>
                                                No se puede agregar y/o modificar los datos registrados si ya se encuentra generado una programacion de lectura.
                                            </small>
                                        </Col>
                                    </Row>
                                    : <></>
                            }
                        </Row>
                        <Row className='mt-4'>
                            <Col className='text-end'>
                                <Button size='sm' variant='outline-primary' onClick={() => { setmostrarModalAgregarTotalizador(false) }}>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Suministro Adicional*/}
            <Modal
                show={mostrarModalAgregarSuministro}
                onHide={() => { setmostrarModalAgregarSuministro(false) }}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Buscar Suministro
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row >
                            <Col>
                                <small className='text-primary'>Codigo Suministro</small>
                                <Form.Control ref={inputSuministroAgregarTemporal} size='sm' placeholder='Ingrese el Suministro' ></Form.Control>
                            </Col>
                        </Row>

                        <Row >
                            <Col>
                                <small className='text-secondary'>Para agregar un suministro adicional primero debe buscarlo para agregarlo, ingrese el codigo de suministro y pulse buscar.</small>

                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col className='text-end'>
                                <Button size='sm' variant='outline-primary' onClick={() => { EjecutarAgregarSuministro() }}>Buscar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Mensaje Confirmacion */}
            <Modal
                show={mostrarModalMensajeConfirmacionAgregarSuministro}
                onHide={() => { setmostrarModalMensajeConfirmacionAgregarSuministro(false) }}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Suministro Encontrado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row >
                            {
                                suministroEncontrado ?
                                    <Col>
                                        <small className='text-dark'>{`Datos Encontrados\n
                                                Nombre: ${suministroEncontrado.nombre_suministro}\n
                                                Dirección: ${suministroEncontrado.direccion_suministro}\n
                                                Tarifa: ${suministroEncontrado.tarifa} \n
                                                ¿Desea agregar el suministro ${suministroEncontrado.codigo_suministro}?`}</small>
                                    </Col> :
                                    <Col>
                                        <small className='text-dark'>{`No se encontraron datos del suministro ${suministroEncontradoActual}`}</small>
                                        <small className='fw-bold'>{`¿Desea agregar el suministro con datos vacios?`}</small>
                                    </Col>
                            }
                        </Row>

                        <Row className='mt-4'>
                            <Col className='text-end'>
                                <Button size='sm' variant='outline-primary' onClick={() => { AgregarSuministroAdicional() }}>Agregar Suministro</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


        </Fragment>
    );
}

export default DetalleMicrobalance;