import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import InputLabel from '@mui/material/InputLabel';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';

//icons
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import HouseIcon from '@mui/icons-material/House';
import PolicyIcon from '@mui/icons-material/Policy';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SettingsIcon from '@mui/icons-material/Settings';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';

//Bootstrap
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { Snackbar, Alert, AlertTitle } from '@mui/material';

//Importaciones
import { BorrarStorage, config, ObtenerUsuario, listarPermisos, ValidarPermisos, LIST_SCREENS, ValidarUsuario } from '../../config/utils'

function Header(props) {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [state, setState] = useState(false);
    const [usuario, setusuario] = useState("");

    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);    
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //Modals
    const [mostrarModalCambiarPassword, setmostrarModalCambiarPassword] = useState();

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //referencias
    const inputContraAnterior = useRef();
    const inputContaNueva = useRef();
    const inputReingresoPassword = useRef();

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        //Validar Permisos
        BuscarUsuario();
        listarPermisos().then((permisos) => {
            // console.log(permisos);
            setlistapermisos(permisos);
        });

    }
    const BuscarUsuario = () => {
        let UsuarioStorage = ObtenerUsuario();
        if (UsuarioStorage) {
            UsuarioStorage = JSON.parse(UsuarioStorage);
            setusuario(UsuarioStorage.nombretrabajador);
        }
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState(open);
    };

    const CerrarSesion = () => {
        BorrarStorage(config.storageName);
        BorrarStorage(config.storageFiltros);
        navigate('/');
    }

    const ValidarControlesCambiarContraseña = () => {
        let error = 0;
        if (inputContraAnterior.current.value.trim() == '') {
            inputContraAnterior.current.classList.add('is-invalid');
            error++;
        } else {
            inputContraAnterior.current.classList.remove('is-invalid');
        }

        if (inputContaNueva.current.value.trim() == '') {
            inputContaNueva.current.classList.add('is-invalid');
            error++;
        } else {
            inputContaNueva.current.classList.remove('is-invalid');
        }

        if (inputReingresoPassword.current.value.trim() == '') {
            inputReingresoPassword.current.classList.add('is-invalid');
            error++;
        } else {
            inputReingresoPassword.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }

    const CambiarContraseña = () => {
        if (ValidarUsuario()) {
            if (ValidarControlesCambiarContraseña()) {
                if (inputContaNueva.current.value === inputReingresoPassword.current.value) {
                    inputContaNueva.current.classList.remove('is-invalid');
                    inputReingresoPassword.current.classList.remove('is-invalid');

                    setmostrarCircularProgress(true);
                    let url = "";
                    url = `${config.endPoint}api/usuario/CambiarContraUsuario`;

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify({
                            paswordAnterior: inputContraAnterior.current.value,
                            nuevoPassword: inputContaNueva.current.value
                        })
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {
                        if (data.success) {
                            setmostrarModalCambiarPassword(false);
                            settituloToast("Correcto");
                            settipoToast("success");
                        }else{
                            settituloToast("Error");
                            settipoToast("error");
                        }
                        setabrirToast(true);                        
                        setmensajeToast(data.message)
                        setmostrarCircularProgress(false);
                    }).catch((error) => {
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    });
                } else {
                    inputContaNueva.current.classList.add('is-invalid');
                    inputReingresoPassword.current.classList.add('is-invalid');
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast(`Contraseñas no coinciden.`)
                    setabrirToast(true);
                }
            }
        } else {
            navigate("/");
        }
    }

    return (
        <Fragment>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={toggleDrawer('left', true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography style={{ cursor: 'pointer' }} onClick={() => { navigate('/dashboard') }} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {/* {
                                props.titulo
                            } */}
                            Balance BT
                        </Typography>
                        {(
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                    <InputLabel style={{ color: 'white' }} className='mx-1'>{usuario}</InputLabel>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {/* <MenuItem onClick={handleClose}>Bloquear</MenuItem>
                                    <MenuItem onClick={handleClose}>Perfil</MenuItem> */}
                                    <MenuItem onClick={() => { handleClose(); setmostrarModalCambiarPassword(true) }}>Cambiar Contraseña</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={() => { CerrarSesion() }}>Cerrar Sesión</MenuItem>
                                </Menu>

                                {/* Drawer*/}
                                <SwipeableDrawer
                                    anchor='left'
                                    open={state}
                                    onClose={toggleDrawer('left', false)}
                                    onOpen={toggleDrawer('left', true)}
                                >
                                    <Box
                                        sx={{ width: 250 }}
                                        role="presentation"
                                        onClick={toggleDrawer('left', false)}
                                        onKeyDown={toggleDrawer('left', false)}
                                    >
                                        <Divider />
                                        <InputLabel color={'primary'} className='mx-1'>
                                            Inicio
                                        </InputLabel>
                                        <List>
                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_DASHBOARD, listapermisos) ?
                                                    <ListItem disablePadding>
                                                        <ListItemButton onClick={() => { navigate('/dashboard') }}>
                                                            <ListItemIcon>
                                                                <HomeIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary='Inicio' />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    : <></>
                                            }
                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES, listapermisos) ?
                                                    <ListItem disablePadding>
                                                        <ListItemButton onClick={() => { navigate('/balance') }}>
                                                            <ListItemIcon>
                                                                <QueryStatsIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary='Balances' />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    : <></>
                                            }



                                            <List component="div" disablePadding>
                                                {
                                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_CARGAR_RPT, listapermisos) ?
                                                        <ListItemButton sx={{ pl: 4 }} onClick={() => { navigate('/balance/cargar') }}>
                                                            <ListItemIcon>
                                                                <CloudUploadIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Cargar RPT" />
                                                        </ListItemButton>
                                                        : <></>
                                                }
                                                {
                                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_LISTA, listapermisos) ?
                                                        <ListItemButton sx={{ pl: 4 }} onClick={() => { navigate('/balance/lista') }}>
                                                            <ListItemIcon>
                                                                <DynamicFormIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Lista Balances" />
                                                        </ListItemButton>
                                                        : <></>
                                                }
                                                {
                                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_MICROBALANCE, listapermisos) ?
                                                        <ListItemButton sx={{ pl: 4 }} onClick={() => { navigate('/balance/microbalance') }}>
                                                            <ListItemIcon>
                                                                <HouseIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Microbalance" />
                                                        </ListItemButton>
                                                        : <></>
                                                }
                                            </List>

                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_DETECCIONES, listapermisos) ?
                                                    <ListItem disablePadding>
                                                        <ListItemButton onClick={() => { navigate('/detecciones') }}>
                                                            <ListItemIcon>
                                                                <PolicyIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary='Detecciones' />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    : <></>
                                            }
                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_RECUPEROS, listapermisos) ?
                                                    <ListItem disablePadding>
                                                        <ListItemButton onClick={() => { navigate('/recuperos') }}>
                                                            <ListItemIcon>
                                                                <CurrencyExchangeIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary='Recuperos' />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    : <></>
                                            }
                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_SUMINISTRO, listapermisos) ?
                                                    <ListItem disablePadding>
                                                        <ListItemButton onClick={() => { navigate('/suministro') }}>
                                                            <ListItemIcon>
                                                                <ElectricMeterIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary='Suministro' />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    : <></>
                                            }
                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION, listapermisos) ?
                                                    <ListItem disablePadding>
                                                        <ListItemButton onClick={() => { navigate('/configuracion') }}>
                                                            <ListItemIcon>
                                                                <SettingsIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary='Configuración' />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    : <></>
                                            }

                                        </List>


                                        {/* {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_CODIGOS_GASTO, listapermisos) ?
                                                    : <></>
                                            } */}
                                    </Box>
                                </SwipeableDrawer>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>


            {/* Modales  Cambiar Contraseña*/}
            <Modal
                show={mostrarModalCambiarPassword}
                onHide={() => { setmostrarModalCambiarPassword(false) }}
                keyboard={false}
                size="md"
            >
                {/* <Modal.Header>
                    <Modal.Title>
                        Tipo Usuario
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Contraseña Anterior</small>
                                    <Form.Control ref={inputContraAnterior} type="password" size='sm' placeholder='Ingrese Contraseña Anterior' ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nueva Contraseña</small>
                                    <Form.Control ref={inputContaNueva} type="password" size='sm' placeholder='Ingrese Nueva Contraseña' ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Reingrese Contraseña</small>
                                    <Form.Control ref={inputReingresoPassword} type="password" size='sm' placeholder='Reingrese la Contraseña' ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <Button size='sm' variant="outline-primary" onClick={() => { }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end '>
                                <Button size='sm' onClick={() => { CambiarContraseña() }} variant="primary">Cambiar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}

export default Header;