import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, Modal, Stack, } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';

//Material UI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Snackbar, Alert, AlertTitle, } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DataGrid,esES } from '@mui/x-data-grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import GradingIcon from '@mui/icons-material/Grading';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

//Importaciones
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';
import { ObtenerUsuario, config, ValidarUsuario, useWindowSize,listarPermisos,ValidarPermisos,LIST_SCREENS } from '../../config/utils'


function ConfiguracionAgrupacion(props) {

    const navigate = useNavigate();

    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //hook actualizacion tamaño de ventana
    const size = useWindowSize();

    //modales
    const [mostrarModalDetallaAgrupacion, setmostrarModalDetallaAgrupacion] = useState(false);
    const [mostrarModalElementos, setmostrarModalElementos] = useState(false);

    //
    const [dataAgrupacionTarifa, setdataAgrupacionTarifa] = useState([]);
    const [dataListaTarifas, setdataListaTarifas] = useState([]);
    const [elementoActual, setelementoActual] = useState(null);
    const [elementosagrupados, setelementosagrupados] = useState([]);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //referencias
    const inputBuscar = useRef();
    const inputNombreAgrupacion = useRef();
    const selectEstadoagrupacion = useRef();
    const inputNombreAgrupacionElementos = useRef();
    const inputEstadoAgrupacionElementos = useRef();
    const selectListaTarifas = useRef();

    const columns = [
        {

            field: 'accion', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton disabled onClick={() => {
                            setelementoActual(cellValues);
                            setmostrarModalDetallaAgrupacion(true)
                        }}>
                            <EditIcon fontSize='small'></EditIcon>
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'nombre_agrupacion', headerName: 'Nombre Agrupacion', flex: 1, headerClassName: 'header-colum-gray' },
        { field: 'elementos', headerName: 'Elementos', flex: 1, headerClassName: 'header-colum-gray' },
        { field: 'estado', headerName: 'Estado', flex: 1, headerClassName: 'header-colum-gray' },
        {
            field: 'permisos', headerName: 'Agregar', flex: 1, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (
                    <IconButton onClick={() => {
                        setelementoActual(cellValues);
                        AbrirModalElementosAgrupacion(cellValues);
                    }}>
                        <GradingIcon fontSize='small' color='success'></GradingIcon>
                    </IconButton>
                )
            }
        },

    ];

    useEffect(() => {
        ValidarAccesos();
    }, []);

    //Procedimientos
    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_AGRUPACIONES, permisos)) {
                    BuscarAgrupacionTarifas();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }       
    }

    const BuscarAgrupacionTarifas = () => {
        if (ValidarUsuario()) {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/agrupaciontarifa/listar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ valorbuscar: inputBuscar.current.value })
            };

            fetch(url, headers).then((respuesta) => {

                return respuesta.json();
            }).then((data) => {
                
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: elemento.id_agrupacion_tarifa,
                            nombre_agrupacion: elemento.nombre_agrupacion,
                            elementos: elemento.elementos,
                            estado: elemento.estado
                        }
                    );
                });
                
                setdataAgrupacionTarifa(DataTemp);
                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate("/login");
        }
    }

    const ActualizarInputBuscar = () => {
        if (inputBuscar.current.value.trim() === '') {
            BuscarAgrupacionTarifas();
        }
    }
    const ValidarFormulario = () => {
        let mensaje = "";
        let errores = 0;
        if (inputNombreAgrupacion.current.value.trim() !== '') {
            inputNombreAgrupacion.current.classList.remove("is-invalid");
        } else {
            inputNombreAgrupacion.current.classList.add("is-invalid");
            mensaje = "Falta el nombre de la agrupacion.";
            errores++
        }

        if (selectEstadoagrupacion.current.value !== '') {
            selectEstadoagrupacion.current.classList.remove("is-invalid");
        } else {
            mensaje = mensaje + ", Seleccione el estado.";
            errores++;
            selectEstadoagrupacion.current.classList.add("is-invalid");
        }

        if (errores > 0) {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(mensaje)
            setabrirToast(true);
            return false;
        }
        return true;
    }

    const AgregarAgrupacionTarifa = async () => {
        if (!ValidarUsuario()) {
            navigate("/login");
        }
        if (ValidarFormulario()) {
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/agrupaciontarifa/agregar`;

            let _agrupacionTarifa = {
                "nombre_agrupacion": inputNombreAgrupacion.current.value,
                "estado": selectEstadoagrupacion.current.value
            };

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(_agrupacionTarifa)
            };

            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                console.log(data);

                if (data.success) {
                    settipoToast("success");
                    settituloToast("Completado");
                } else {
                    settipoToast("error");
                    settituloToast("Error");
                }
                setmensajeToast(data.message)
                setabrirToast(true);

                BuscarAgrupacionTarifas();
                setmostrarCircularProgress(false);
                setmostrarModalDetallaAgrupacion(false);

            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
            });
            setmostrarCircularProgress(false);
        }
    }

    const BuscarListaTarifas = async () => {
        if (!ValidarUsuario()) {
            navigate("login");
        }

        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);

        let url = `${config.endPoint}api/basesuministro/listarAgrupacionTarifa`;

        let headers = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            }
        };

        await fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            if (data.success) {
                setdataListaTarifas(data.content)
            }
        }).catch(async (error) => {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
            setabrirToast(true);
        });

    }
    const BuscarIdAgrupacionTarifa=async(idagrupaciontarifa)=>{
        if (!ValidarUsuario()) {
            navigate("login");
        }

        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);

        let url = `${config.endPoint}api/agrupaciontarifa/buscarid/${idagrupaciontarifa}`;

        let headers = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            }
        };

        await fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {            
            if (data.content.length>0) {                                
                let arrayElementos=data.content[0].elementos.split(',');
                let listaElementos=[];
                arrayElementos.forEach(element => {
                    if(element.trim()!==''){
                        listaElementos.push(element);
                    }                    
                });                
                setelementosagrupados(listaElementos);                                
            }            
        }).catch(async (error) => {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
            setabrirToast(true);
        });
    }

    const ActualizarElementosAgrupacion=async(id,elementos)=>{
        if(!ValidarUsuario()){
            navigate('/login');
        }

        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);

        let _agrupacionTarifa = {
            "id_agrupacion_tarifa": id,
            "elementos": elementos
        };

        let url = `${config.endPoint}api/agrupaciontarifa/actualizarelementos`;

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body:JSON.stringify(_agrupacionTarifa)
        };

        await fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            // console.log(data);         
            if(data.success){
                BuscarIdAgrupacionTarifa(elementoActual.id);
                settituloToast("Info");
                settipoToast("info");
                setmensajeToast(`Elementos Actualizados`)
                setabrirToast(true);
            }
        }).catch(async (error) => {
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
            setabrirToast(true);
        });
    }

    const AgregarListaElementos = async() => {
        if (selectListaTarifas.current.value != '') {
            let arrayElementos = elementosagrupados;
            var buscarExistente = arrayElementos.filter(x => x === selectListaTarifas.current.value);
            if (buscarExistente.length <= 0) {

                arrayElementos.push(selectListaTarifas.current.value);                
                ActualizarElementosAgrupacion(elementoActual.id,arrayElementos.toString())               

            } else {
                settituloToast("Alerta");
                settipoToast("warning");
                setmensajeToast(`Elemento ya existe en la lista.`)
                setabrirToast(true);
            }            
        }        
    }

    const EliminarElementosLista=async (elementoTarifa)=>{
        let narray=await elementosagrupados.filter(x=>x!=elementoTarifa);
        ActualizarElementosAgrupacion(elementoActual.id,narray.toString());        
    }
    
    const AbrirModalElementosAgrupacion = async (en) => {
        await setmostrarModalElementos(true)
        await BuscarListaTarifas();
        await BuscarIdAgrupacionTarifa(en.row.id)
        inputNombreAgrupacionElementos.current.value = en.row.nombre_agrupacion;
        inputEstadoAgrupacionElementos.current.value = en.row.estado;        
    }

    const CerrarModalElementosAgrupacion=()=>{
        setmostrarModalElementos(false);
        BuscarAgrupacionTarifas();
        setelementosagrupados([]);
    }


    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración / Agrupacion'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Configuración / Agrupacion</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/configuracion') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4} className=''>
                            <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputBuscar} placeholder='Valor a buscar' size='sm'></Form.Control>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button onClick={()=>{BuscarAgrupacionTarifas()}} variant='primary' size='sm'>
                                Buscar
                            </Button>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : 'text-end'}>
                            <Button variant='primary' disabled size='sm' onClick={() => { setmostrarModalDetallaAgrupacion(true) }}>Agregar <AddIcon fontSize='small'></AddIcon></Button>
                        </Col>
                        {/* <Col className='text-end'>
                            <IconButton color='primary' onClick={() => { navigate('/configuracion') }} >
                                <ArrowBackIcon ></ArrowBackIcon>
                            </IconButton>
                        </Col> */}
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid 
                            density='compact' 
                            rows={dataAgrupacionTarifa} 
                            columns={columns} 
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            />
                    </div>
                </div>
            </div>


            {/* Modales  Agrupacion Tarifas*/}
            <Modal
                show={mostrarModalDetallaAgrupacion}
                onHide={() => { setmostrarModalDetallaAgrupacion(false) }}
                keyboard={false}
                size="md"
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar / Modificar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >

                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre de Agrupacion</small>
                                    <Form.Control size='sm' ref={inputNombreAgrupacion} type="text" placeholder='Ingrese Nombre' ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <Form.Select size='sm' ref={selectEstadoagrupacion} defaultValue="">
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </Form.Select>
                                </Form.Group>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <Button size='sm' variant="outline-primary" onClick={() => { }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end '>
                                <Button size='sm' onClick={() => { AgregarAgrupacionTarifa() }} variant="primary">Guardar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>


            {/* Modal Permisos */}
            <Modal
                show={mostrarModalElementos}
                onHide={() => { CerrarModalElementosAgrupacion() }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar Elementos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className='mb-2'>
                        <Row>
                            <Col>
                                <small className="text-primary">Nombre de Agrupacion</small>
                                <Form.Control ref={inputNombreAgrupacionElementos} size='sm' disabled type="text" defaultValue={'TOT'}></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Estado</small>
                                <Form.Control ref={inputEstadoAgrupacionElementos} size='sm' disabled type="text" defaultValue={'ACTIVO'} ></Form.Control>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <small className="text-primary">Tarifa</small>
                                <Stack gap={2} direction='horizontal'>
                                    <Form.Select size='sm' ref={selectListaTarifas} defaultValue="">
                                        <option value="" disabled>Seleccione Acceso</option>
                                        {
                                            dataListaTarifas.map((row, index) => {
                                                return (
                                                    <option key={`${index}${row.tarifa}`} value={row.tarifa}>{row.tarifa}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                    <Button size='sm' onClick={() => { AgregarListaElementos() }} variant='primary'>Agregar</Button>
                                </Stack>
                            </Col>
                        </Row>
                    </Container>

                    <TableContainer style={{ maxHeight: '300px' }}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Tarifa</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    elementosagrupados.map((row,index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell >
                                                    <Container fluid>
                                                        <Row>
                                                            <Col>
                                                                <Stack gap={4} direction='horizontal'>
                                                                    <IconButton onClick={() => {EliminarElementosLista(row) }}>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                    {/* <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                        <input className="form-check-input" type="checkbox" defaultChecked />
                                                                    </div> */}
                                                                </Stack>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </TableCell>
                                                <TableCell>
                                                    {row}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col className='text-end'>
                                <Button size='sm' onClick={() => { CerrarModalElementosAgrupacion() }} variant="outline-primary">Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}

export default ConfiguracionAgrupacion;