import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle, CircularProgress } from '@mui/material';
import { DataGrid,esES } from '@mui/x-data-grid';

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

//Importaciones
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';
import { ObtenerUsuario, config, ValidarUsuario,useWindowSize,listarPermisos,ValidarPermisos,LIST_SCREENS } from '../../config/utils'

import moment from 'moment';

function Detecciones(props) {
    const navigate = useNavigate();
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const size = useWindowSize();

    //
    const [dataArchivoDetecciones, setdataArchivoDetecciones] = useState([]);
    const [elementoActual, setelementoActual] = useState(null);

    //Modales
    const [mostrarModalAgregarSuministros, setmostrarModalAgregarSuministros] = useState(false);
    const [mostrarModealDetalleSuministro, setmostrarModealDetalleSuministro] = useState(false);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    const inputListaBuscar = useRef();
    const inpurBuscar = useRef();
    //ref Detalle Suministro    
    const inputSed = useRef();
    const inputNombre = useRef();
    const inputDireccion = useRef();
    const inputGuion = useRef();
    const inputFechaIntervencion = useRef();
    const inputKwh = useRef();
    const inputMonto = useRef();
    const inputDetalle = useRef();
    const inputObservaciones = useRef();
    const inputAreaProcedencia = useRef();
    const inputMesProceso = useRef();
    const inputZona = useRef();
    const inputCantidad = useRef();
    const inputSistema = useRef();
    const inputDistrito = useRef();
    const inputHistoria = useRef();
    const inputDeteccion_set = useRef();
    const inputAlimentador = useRef();

    const columns = [
        {
            field: 'accion', headerName: 'Acción', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {
                            setelementoActual(cellValues);
                            MostrarDetalleSuministro(cellValues.row);
                        }}>
                            <WysiwygIcon></WysiwygIcon>
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'suministro', headerName: 'Suministro', minWidth: 100, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'fechaintervencion', headerName: 'Fecha Intervencion', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'kwh', headerName: 'KWH', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'monto', headerName: 'Monto', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'mesproceso', headerName: 'Mes Proceso', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'areaprocedencia', headerName: 'Area Procedencia', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },

    ];

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_DETECCIONES_LISTA, permisos)) {
                    BuscarDetecciones();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }       
    }

    const BuscarDetecciones = () => {
        if (ValidarUsuario()) {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/deteccion/lista`;
            let listaBuscar = "";

            if (inpurBuscar.current.value.trim() !== '') {
                listaBuscar = inpurBuscar.current.value.trim();
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ listaBuscar: listaBuscar })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                let DataTemp = [];
                data.content.forEach((elemento,index) => {
                    DataTemp.push(
                        {
                            id: index,
                            accion: '',
                            suministro: elemento.suministro,
                            sed: elemento.sed,
                            nombre: elemento.nombre,
                            direccion: elemento.direccion,
                            guion: elemento.guion,
                            fechaintervencion: moment(elemento.fecha_intervencion).format("DD/MM/YYYY"),
                            kwh: elemento.kwh,
                            monto: elemento.monto,
                            detalle: elemento.detalle,
                            observaciones: elemento.observaciones,
                            areaprocedencia: elemento.area_procedencia,
                            mesproceso: elemento.mes_proceso,
                            zona: elemento.zona,
                            cantidad: elemento.cantidad,
                            sistema: elemento.sistema,
                            distrito: elemento.distrito,
                            historia: elemento.historia,
                            deteccion_set: elemento.deteccion_set,
                            alimentador: elemento.alimentador
                        }
                    );
                });
 
                setdataArchivoDetecciones(DataTemp);
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate("/");
        }
    }

    const ListarSuminstrosBuscar = () => {
        if (inputListaBuscar.current.value !== '') {
            inputListaBuscar.current.classList.remove('is-invalid');

            let arrayLista = inputListaBuscar.current.value.split(/\r\n|\r|\n/);
            let listaBuscar = "";

            arrayLista.forEach(elemento => {
                if (elemento.trim() !== '') {
                    listaBuscar = listaBuscar + elemento.trim() + ",";
                }
            });

            listaBuscar = listaBuscar.substring(1, listaBuscar.length - 1);
            inpurBuscar.current.value = listaBuscar;

            setmostrarModalAgregarSuministros(false);
        } else {
            inputListaBuscar.current.clasList.add('is-invalid');
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`No se encontro cadena para realizar esta accion.`)
            setabrirToast(true);
        }
    }

    const MostrarDetalleSuministro = async (registro) => {
        await setmostrarModealDetalleSuministro(true);

        inputSed.current.value = registro.sed;
        inputNombre.current.value = registro.nombre;
        inputDireccion.current.value = registro.distrito;
        inputGuion.current.value = registro.guion;
        inputFechaIntervencion.current.value = registro.fechaintervencion;
        inputKwh.current.value = registro.kwh;
        inputMonto.current.value = registro.monto;
        inputDetalle.current.value = registro.detalle;
        inputObservaciones.current.value = registro.observaciones;
        inputAreaProcedencia.current.value = registro.areaprocedencia;
        inputMesProceso.current.value = registro.mesproceso;
        inputZona.current.value = registro.zona;
        inputCantidad.current.value = registro.cantidad;
        inputSistema.current.value = registro.sistema;
        inputDistrito.current.value = registro.distrito;
        inputHistoria.current.value = registro.historia;
        inputDeteccion_set.current.value = registro.deteccion_set;
        inputAlimentador.current.value = registro.alimentador;

        inputSed.current.disabled = true;
        inputNombre.current.disabled = true;
        inputDireccion.current.disabled = true;
        inputGuion.current.disabled = true;
        inputFechaIntervencion.current.disabled = true;
        inputKwh.current.disabled = true;
        inputMonto.current.disabled = true;
        inputDetalle.current.disabled = true;
        inputObservaciones.current.disabled = true;
        inputAreaProcedencia.current.disabled = true;
        inputMesProceso.current.disabled = true;
        inputZona.current.disabled = true;
        inputCantidad.current.disabled = true;
        inputSistema.current.disabled = true;
        inputDistrito.current.disabled = true;
        inputHistoria.current.disabled = true;
        inputDeteccion_set.current.disabled = true;
        inputAlimentador.current.disabled = true;
    }

    const ActualizarInputBuscar=()=>{
        if(inpurBuscar.current.value.trim()==='')
        {
            BuscarDetecciones();
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Balances BT'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Detecciones</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/dashboard') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4} className={size.width<768?'d-grid gap-2':''} >
                            {/* <Stack direction="row" className='d-grid gap-2'> */}
                                <Form.Control onChange={()=>{ActualizarInputBuscar()}} ref={inpurBuscar} placeholder='Valor a buscar' size='sm' className='mx-1'></Form.Control>
                            {/* </Stack> */}
                        </Col>
                        <Col sm={12} md={4} className= {size.width<768?'d-grid gap-2 mt-1 mt-md-0':''}>
                            {/* <Stack direction="row" spacing={1}> */}
                                <Button onClick={() => { BuscarDetecciones() }} variant='primary' size='sm' className=''>
                                    Buscar
                                </Button>
                                <IconButton onClick={() => { setmostrarModalAgregarSuministros(true) }}>
                                    <PlaylistAddIcon></PlaylistAddIcon>
                                </IconButton>

                            {/* </Stack> */}
                        </Col>
                        <Col sm={12} md={4} className={size.width<768?'d-grid gap-2 mt-1':'text-end'} >
                            <Button variant='primary' size='sm' onClick={() => { navigate('/detecciones/cargar') }}>Cargar Archivo</Button>
                        </Col>                        
                    </Row>          
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid 
                        density='compact' 
                        rows={dataArchivoDetecciones} 
                        columns={columns} 
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </div>
            </div>


            {/* Modal Agregar Suministros */}
            <Modal
                show={mostrarModalAgregarSuministros}
                onHide={() => { setmostrarModalAgregarSuministros(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Lista Suministros
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <Form.Control ref={inputListaBuscar} as='textarea' rows={6} placeholder='Agregar Lista' size='sm'></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <Button size='sm' variant='primary' onClick={() => { ListarSuminstrosBuscar() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


            {/* Modal Detalle Suministro */}
            <Modal
                show={mostrarModealDetalleSuministro}
                onHide={() => { setmostrarModealDetalleSuministro(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        {
                            elementoActual ?
                                `Suministro ${elementoActual.row.suministro}`
                                : <></>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {
                        elementoActual ?
                            <Container>

                                <Row className=''>
                                    <Col >
                                        <small className='text-primary'>Nombre</small>
                                        <Form.Control size='sm' ref={inputNombre} className='border rounded-1'></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>Dirección</small>
                                        <Form.Control size='sm' ref={inputDireccion} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>Guión</small>
                                        <Form.Control size='sm' ref={inputGuion} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>Fecha Intervencion</small>
                                        <Form.Control size='sm' ref={inputFechaIntervencion} className=''></Form.Control>
                                    </Col>
                                    <Col >
                                        <small className='text-primary'>Fecha Intervencion</small>
                                        <Form.Control size='sm' ref={inputSed} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>kwh</small>
                                        <Form.Control size='sm' ref={inputKwh} className=''></Form.Control>
                                    </Col>
                                    <Col >
                                        <small className='text-primary'>Monto</small>
                                        <Form.Control size='sm' ref={inputMonto} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>Detalle</small>
                                        <Form.Control size='sm' ref={inputDetalle} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>Observaciones</small>
                                        <Form.Control size='sm' ref={inputObservaciones} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>Area Procedencia</small>
                                        <Form.Control size='sm' ref={inputAreaProcedencia} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>Mes Proceso</small>
                                        <Form.Control size='sm' ref={inputMesProceso} className=''></Form.Control>
                                    </Col>
                                    <Col >
                                        <small className='text-primary'>Zona</small>
                                        <Form.Control size='sm' ref={inputZona} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <hr></hr>
                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>Cantidad</small>
                                        <Form.Control size='sm' ref={inputCantidad} className=''></Form.Control>
                                    </Col>
                                    <Col >
                                        <small className='text-primary'>Sistema Electrico</small>
                                        <Form.Control size='sm' ref={inputSistema} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>Distrito</small>
                                        <Form.Control size='sm' ref={inputDistrito} className=''></Form.Control>
                                    </Col>
                                    <Col >
                                        <small className='text-primary'>Historia</small>
                                        <Form.Control size='sm' ref={inputHistoria} className=''></Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-1'>
                                    <Col >
                                        <small className='text-primary'>SET</small>
                                        <Form.Control size='sm' ref={inputDeteccion_set} className=''></Form.Control>
                                    </Col>
                                    <Col >
                                        <small className='text-primary'>Alimentador</small>
                                        <Form.Control size='sm' ref={inputAlimentador} className=''></Form.Control>
                                    </Col>
                                </Row>

                            </Container>
                            : <Container>
                                <Row>
                                    <Col className='text-center'>
                                        <CircularProgress></CircularProgress>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='text-center'>
                                        Cargando...
                                    </Col>
                                </Row>
                            </Container>
                    }
                    <hr></hr>
                    <Row className='mt-1'>
                        <Col className='text-end'>
                            <Button className='outline' onClick={() => { setmostrarModealDetalleSuministro(false) }}>Cerrar</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </Fragment>
    );
}

export default Detecciones;

