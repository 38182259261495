import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Modal, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle } from '@mui/material';
import { DataGrid,esES } from '@mui/x-data-grid';

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';

//Importaciones
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';
import { ObtenerUsuario, config, ValidarUsuario, useWindowSize, ValidarPermisos, listarPermisos, LIST_SCREENS } from '../../config/utils'

import moment from 'moment';

function ListadoAlertas(props) {
    const navigate = useNavigate();
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);    
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //hook actualizacion tamaño de ventana
    const size = useWindowSize();

    //
    const [dataArchivoListadoAlertas, setdataArchivoListadoAlertas] = useState([]);
    const [elementoActual, setelementoActual] = useState(null);

    //Modales
    const [mostrarModalAgregarSuministros, setmostrarModalAgregarSuministros] = useState(false);
    const [mostrarModealDetalleSuministro, setmostrarModealDetalleSuministro] = useState(false);    
    const [abrirModalAtenderAlertaSuministro, setabrirModalAtenderAlertaSuministro] = useState(false);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //inputs     
    const inputBuscar = useRef();    
    const inputObservacion = useRef();    
    const inputListaBuscar=useRef();

    const columns = [
        {
            field: 'accion', headerName: 'Acción', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {
                            setelementoActual(cellValues.row);
                            MostrarDetalleSuministro(cellValues.row);
                        }}>
                            <WysiwygIcon></WysiwygIcon>
                        </IconButton>

                        {/* Eliminar Archivo */}
                        {
                            cellValues.row.estado != 'ATENDIDO' ?
                                <IconButton onClick={() => {
                                    setelementoActual(cellValues.row);
                                    setabrirModalAtenderAlertaSuministro(true);
                                }}>
                                    <CancelScheduleSendIcon></CancelScheduleSendIcon>
                                </IconButton>
                                : <></>
                        }
                    </>
                );
            }
        },
        { field: 'suministro', headerName: 'Suministro', minWidth: 100, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'observacion', headerName: 'Observación', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        {
            field: 'prioridad', headerName: 'Prioridad', minWidth: 150, flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                let style = { color: 'blue' };
                if (cellValues.row.prioridad == 'URGENTE') {
                    style = { color: 'red' };
                }

                return (
                    <span style={style}>{cellValues.row.prioridad}</span>
                )
            }
        },

        {
            field: 'estado', headerName: 'Estado', minWidth: 150, flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                let style = { color: 'blue' };
                if (cellValues.row.estado == 'ATENDIDO') {
                    style = { color: 'green' };
                }

                return (
                    <span style={style}>{cellValues.row.estado}</span>
                )
            }
        },
        { field: 'usuario_crea', headerName: 'Usuario Registra', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'usuario_atiende', headerName: 'Usuario Atiende', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'fecha_crea', headerName: 'Fecha Creacion', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'fecha_modifica', headerName: 'Fecha Modificación', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
    ];

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALERTAS_SUMINISTRO, permisos)) {
                    BuscarListadoAlertas();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }

    const BuscarListadoAlertas = () => {
        if (ValidarUsuario()) {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/AlertaSuministro/ListarAlertasSuministro`;
            let listaBuscar = "";

            if (inputBuscar.current.value.trim() !== '') {
                listaBuscar = inputBuscar.current.value.trim();
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    listasuministros: listaBuscar
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {                
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: elemento.id_alerta_suministro,
                            alerta: '',
                            suministro: elemento.suministro,
                            observacion: elemento.observacion,
                            prioridad: elemento.prioridad,
                            estado: elemento.estado,
                            usuario_crea: elemento.usuario_crea,
                            observacion_atencion: elemento.observacion_atencion,
                            fecha_crea: moment(elemento.fecha_crea).format('DD/MM/YYYY'),
                            usuario_atiende:Object.entries(elemento.usuario_atiende).length === 0? '':elemento.usuario_atiende,
                            fecha_modifica: Object.entries(elemento.usuario_atiende).length === 0?'':moment(elemento.fecha_modifica).format('DD/MM/YYYY'),
                        }
                    );
                });
                setdataArchivoListadoAlertas(DataTemp);
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate("/");
        }
    }

    const MostrarDetalleSuministro = async (registro) => {
        await setmostrarModealDetalleSuministro(true);
    }

    const ActualizarInputBuscar = () => {
        if (inputBuscar.current.value.trim() === '') {
            BuscarListadoAlertas();
        }
    }

    const ValidarAtenderObservacion = () => {
        let error = 0;
        if (inputObservacion.current.value.trim() == '') {
            inputObservacion.current.classList.add('is-invalid');
            error++;
        } else {
            inputObservacion.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }

    const AtenderObservacion = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            if (ValidarAtenderObservacion()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/AlertaSuministro/AtenderAlertaSuministro`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idalertasuministro: elementoActual.id,
                        observacion: inputObservacion.current.value,
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        setabrirModalAtenderAlertaSuministro(false);
                        //Actualizar
                        BuscarListadoAlertas();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    // Agregar Lista  Busqueda
    const ListarvaloresBuscar = () => {
        if (inputListaBuscar.current.value !== '') {
            inputListaBuscar.current.classList.remove('is-invalid');

            let arrayLista = inputListaBuscar.current.value.split(/\r\n|\r|\n/);
            console.log(arrayLista);
            let listaBuscar = "";

            arrayLista.forEach(elemento => {
                if (elemento.trim() !== '') {
                    listaBuscar = listaBuscar + elemento.trim() + ",";
                }
            });

            listaBuscar = listaBuscar.substring(0, listaBuscar.length - 1);
            inputBuscar.current.value = listaBuscar;

            setmostrarModalAgregarSuministros(false);
        } else {
            inputListaBuscar.current.clasList.add('is-invalid');
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`No se encontro cadena para realizar esta accion.`)
            setabrirToast(true);
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>
            <Header></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Configuración / Alertas Suministro</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/dashboard') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4}>
                            <Stack direction='horizontal' >
                                <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputBuscar} placeholder='Valor a buscar' size=''></Form.Control>
                                <IconButton onClick={() => { setmostrarModalAgregarSuministros(true) }}>
                                    <PlaylistAddIcon></PlaylistAddIcon>
                                </IconButton>
                            </Stack>
                        </Col>

                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1 mt-md-0' : ''}>
                            <Button onClick={() => { BuscarListadoAlertas() }} variant='primary' size='sm'>
                                Buscar
                            </Button>
                        </Col>
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid density='compact' rows={dataArchivoListadoAlertas} columns={columns} localeText={esES.components.MuiDataGrid.defaultProps.localeText}/>
                    </div>
                </div>
            </div>

            {/* Modal Detalle Suministro */}
            <Modal
                show={mostrarModealDetalleSuministro}
                onHide={() => { setmostrarModealDetalleSuministro(false) }}
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            elementoActual ?
                                `SED ${elementoActual.suministro}`
                                : <></>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        {
                            elementoActual ?
                                <>
                                    <Row>
                                        <Col  >
                                            <small className='text-primary'>Observación</small>
                                            <Form.Control defaultValue={elementoActual.observacion} size="sm" className='' disabled></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className=''>
                                        <Col >
                                            <small className='text-primary'>Prioridad</small>
                                            <Form.Control size="sm" defaultValue={elementoActual.prioridad} className='' disabled></Form.Control>
                                        </Col>
                                        <Col  >
                                            <small className='text-primary'>Fecha Registro</small>
                                            <Form.Control size="sm" defaultValue={elementoActual.fecha_crea} className='' disabled></Form.Control>
                                        </Col>
                                    </Row>

                                    <Row className=''>
                                        <Col >
                                            <small className='text-primary'>Usuario Registra</small>
                                            <Form.Control size="sm" defaultValue={elementoActual.usuario_crea} className='' disabled></Form.Control>
                                        </Col>
                                        <Col  >
                                            <small className='text-primary'>Estado</small>
                                            <Form.Control size="sm" defaultValue={elementoActual.estado} className='' disabled></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row className=''>
                                        <Col >
                                            <small className='text-primary'>Observación Atención</small>
                                            <Form.Control size="sm" defaultValue={elementoActual.observacion_atencion} className='' disabled></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className=''>
                                        <Col  >
                                            <small className='text-primary'>Usuario Atiende</small>
                                            <Form.Control size="sm" defaultValue={elementoActual.usuario_atiende} className='' disabled></Form.Control>
                                        </Col>
                                        <Col  >
                                            <small className='text-primary'>Fecha Atención</small>
                                            <Form.Control size="sm" defaultValue={elementoActual.fecha_modifica} className='' disabled></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col className='text-end'>
                                            <Button onClick={() => { setmostrarModealDetalleSuministro(false) }} size="sm">Cerrar</Button>
                                        </Col>
                                    </Row>
                                </>

                                : <></>
                        }
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Atender Observacion */}
            <Modal
                show={abrirModalAtenderAlertaSuministro}
                onHide={() => { setabrirModalAtenderAlertaSuministro(false) }}
                keyboard={false}
                centered
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <Container>
                        <h5>Agregar Observacion de Atención</h5>
                        <hr></hr>
                        <Row>
                            <Col>
                                <small className='text-primary'>Observación</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control size='sm' ref={inputObservacion} as="textarea" rows={3} placeholder='Observación'></Form.Control>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button onClick={() => { setabrirModalAtenderAlertaSuministro(false) }} variant='outline-primary' size='sm' >Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button size='sm' onClick={() => { AtenderObservacion() }}>Registrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

              {/* Modal Agregar Suministros */}
              <Modal
                show={mostrarModalAgregarSuministros}
                onHide={() => { setmostrarModalAgregarSuministros(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Lista
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <Form.Control ref={inputListaBuscar} as='textarea' rows={6} placeholder='Agregar Lista' size='sm'></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <Button variant='primary' onClick={() => { ListarvaloresBuscar() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </Fragment>
    );
}

export default ListadoAlertas;