import React, { Fragment, useState, useEffect, useRef, createRef } from 'react';
import Header from '../../dashboard/Header'
import PaginaCarga from '../../../config/PaginaCarga';

import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Modal, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Alert, AlertTitle, Tooltip, Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ButtonMUI from '@mui/material/Button';
import { DataGrid, esES } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

//Autocomplete
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import moment from 'moment';

import { ValidarUsuario, ObtenerUsuario, config, useWindowSize, LIST_SCREENS, listarPermisos, ValidarPermisos, BorrarStorage } from '../../../config/utils';
import { logDOM } from '@testing-library/react';

function Microbalances(props) {

    const navigate = useNavigate();
    const size = useWindowSize();

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    //toast y progress Bar
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [mostrarBarraProgreso, setmostrarBarraProgreso] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //Variables Generaes
    const [listaMicrobalances, setlistaMicrobalances] = useState([]);
    const [listaTrabajadoresMicrobalance, setlistaTrabajadoresMicrobalance] = useState([]);
    const [listaTrabajadoresActivos, setlistaTrabajadoresActivos] = useState([]);
    const [microbalanceActual, setmicrobalanceActual] = useState({});
    const [tipoProgramacionLectura, settipoProgramacionLectura] = useState('');

    //
    const [valorFiltroPeriodo, setvalorFiltroPeriodo] = useState([]);
    const [cadenaPeriodosSeleccionados, setcadenaPeriodosSeleccionados] = useState([]);
    const [mostrarTodosEstadosMicrobalance, setmostrarTodosEstadosMicrobalance] = useState(false);

    //Modales
    const [mostrarModalAgregarSuministros, setmostrarModalAgregarSuministros] = useState(false);
    const [mostrarModalFiltro, setmostrarModalFiltro] = useState(false);
    const [mostrarModalProgramarMicrobalance, setmostrarModalProgramarMicrobalance] = useState(false);
    const [mostrarModalAgregarTrabajador, setmostrarModalAgregarTrabajador] = useState(false);
    const [mostrarModalHabilitarLectura, setmostrarModalHabilitarLectura] = useState(false);
    const [mostrarModalReporgramarMicrobalance, setmostrarModalReporgramarMicrobalance] = useState(false);
    const [mostrarModalReporgramarMicrobalanceGrupo, setmostrarModalReporgramarMicrobalanceGrupo] = useState(false);
    const [mostrarModalAgregarTrabajadorGrupo, setmostrarModalAgregarTrabajadorGrupo] = useState(false);

    const [elementoactual, setelementoActual] = useState(null);
    const [objetoMenu, setobjetoMenu] = React.useState(null);
    const [elementosSeleccionadosDataGrid, setelementosSeleccionadosDataGrid] = useState([]);

    //Matrices Filtro
    const [matrizCriterios, setmatrizCriterios] = useState([]);
    const [matrizPeriodosSeleccionados, setmatrizPeriodosSeleccionados] = useState([]);
    const [matrizSetSeleccionados, setmatrizSetSeleccionados] = useState([]);
    const [matrizAlimentadorSeleccionados, setmatrizAlimentadorSeleccionados] = useState([]);
    const [matrizSedSeleccionados, setmatrizSedSeleccionados] = useState([]);

    const [mostrarFiltroPeriodo, setMostrarFiltroPeriodo] = useState(false);
    const [mostrarFiltroSET, setMostrarFiltroSET] = useState(false);
    const [mostrarFiltroAlimentador, setMostrarFiltroAlimentador] = useState(false);
    const [mostrarFiltroSed, setMostrarFiltroSed] = useState();
    const [mostrarFiltroPorcentajePerdida, setMostrarFiltroPorcentajePerdida] = useState(false);
    const [mostrarFiltroKwhPerdida, setMostrarFiltroKwhPerdida] = useState(false);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const inputValorBuscar = useRef();
    const inputNombreTrabajador = useRef();
    const inputListaBuscar = createRef();
    const inputFechaProgramacionLectura = createRef();
    const checkMostrarTodosMicrobalances = createRef();


    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then(async (permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MICROBALANCE, permisos)) {
                    await BuscarListaPeriodosComercialesMicrobalance();
                    await BuscarListaMicrobalances().then(() => {
                        setmostrarCircularProgress(false);
                    }).catch(() => {
                        navigate('/');
                    });

                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }

    //Procedimientos Microbalance
    const BuscarListaMicrobalances = () => {
        return new Promise((resolve, reject) => {
            if (!ValidarUsuario()) {
                reject(false);
            } else {

                let listaPeriodos = "";
                if (matrizPeriodosSeleccionados.length > 0) {
                    let arrayPeriodos = [];
                    matrizPeriodosSeleccionados.forEach(element => {
                        arrayPeriodos.push(element.mes_proceso)
                    });
                    listaPeriodos = arrayPeriodos.toString();
                }

                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/listar`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        listased: inputValorBuscar.current.value,
                        listaperiodos: listaPeriodos,
                        estadomicrobalance: mostrarTodosEstadosMicrobalance ? '' : 'FINALIZADO'
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    // console.log(data);
                    if (data.success) {
                        let DataTemp = [];
                        data.content.forEach((elemento, index) => {
                            DataTemp.push(
                                {
                                    id: elemento.id_microbalance,
                                    accion: '',
                                    id_calculo_balance: elemento.id_calculo_balance,
                                    sed_codigo: elemento.sed_codigo,
                                    mes_proceso: elemento.mes_proceso,
                                    L1: Object.entries(elemento.L1).length == 0 ? '' : moment(elemento.L1).format('DD/MM/YYYY'),
                                    L2: Object.entries(elemento.L2).length == 0 ? '' : moment(elemento.L2).format('DD/MM/YYYY'),
                                    ejecutadol1: `${elemento.ejecutadol1}/${elemento.totalsuministros}`,
                                    ejecutadol2: `${elemento.ejecutadol2}/${elemento.totalsuministros}`,
                                    fecha_crea: moment(elemento.fecha_crea).format("DD/MM/YYYY hh:mm:ss").toString(),
                                    trabajadores: elemento.trabajadores,
                                    totalsuministros: elemento.totalsuministros,
                                    estado: elemento.estado
                                }
                            );
                        });
                        // console.log(DataTemp);
                        setlistaMicrobalances(DataTemp);
                        setmostrarModalFiltro(false);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    // console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                    resolve(false);
                });
            }
        });
    }

    const BuscarListaPeriodosComercialesMicrobalance = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/BuscarPeriodosComercialesMicrobalance`;

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data);     
                setvalorFiltroPeriodo(data.content)
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }

    const AbrirMenu = (event) => {
        setobjetoMenu(event.currentTarget);
    };

    const CerrarMenu = () => {
        setobjetoMenu(null);
    };

    const ValidarCriterios = (event, criterios) => {
        //Periodo
        const busqeudaPeriodo = criterios.find(x => x.criterio == 'Periodo')
        if (busqeudaPeriodo) {
            setMostrarFiltroPeriodo(true)
        } else {
            setMostrarFiltroPeriodo(false)
        }

        //SET
        const busqeudaSet = criterios.find(x => x.criterio == 'SET_Nombre')
        if (busqeudaSet) {
            setMostrarFiltroSET(true)
        } else {
            setMostrarFiltroSET(false)
        }

        //Alimentador
        const busqeudaAlimnetador = criterios.find(x => x.criterio == 'ALL_Nombre')
        if (busqeudaAlimnetador) {
            setMostrarFiltroAlimentador(true)
        } else {
            setMostrarFiltroAlimentador(false)
        }

        //SED
        const busqeudaSed = criterios.find(x => x.criterio == 'SED_Codigo')
        if (busqeudaSed) {
            setMostrarFiltroSed(true)
        } else {
            setMostrarFiltroSed(false)
        }

        //Porcentaje Perdida
        const busqeudaPorcentajePerdida = criterios.find(x => x.criterio == 'Porcentaje_Perdida')
        if (busqeudaPorcentajePerdida) {
            setMostrarFiltroPorcentajePerdida(true)
        } else {
            setMostrarFiltroPorcentajePerdida(false)
        }

        //Kwh Perdida
        const busqeudaKwhPerdida = criterios.find(x => x.criterio == 'KWH_Perdida')
        if (busqeudaKwhPerdida) {
            setMostrarFiltroKwhPerdida(true)
        } else {
            setMostrarFiltroKwhPerdida(false)
        }

        if (criterios) {
            setmatrizCriterios(criterios);
        }
    }
    const columns = [
        {
            field: 'accion', headerName: 'Acción', minWidth: 100, flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton onClick={() => {
                            BorrarStorage(config.storageidBalance);
                            navigate(`/balance/microbalance/detalle/${cellValues.row.id}`)
                        }}>
                            <Tooltip title="Ver Detalle Microbalance">
                                <VisibilityIcon></VisibilityIcon>
                            </Tooltip>
                        </IconButton>

                        {
                            cellValues.row.L1 !== "" && cellValues.row.L2 == "" ?
                                <IconButton onClick={() => {
                                    MostrarModalReporgramarLectura(cellValues.row)
                                }}>
                                    <Tooltip title="Reprogramar Microbalance" color='primary'>
                                        <PublishedWithChangesIcon></PublishedWithChangesIcon>
                                    </Tooltip>
                                </IconButton>
                                : <></>
                        }
                    </>
                );
            }
        },
        {
            field: 'edicion', headerName: 'Trabajadores', minWidth: 50, flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton onClick={() => {
                            AbrirModalAgregarTrabajador(cellValues.row)
                        }}>
                            <Tooltip title="Lista Trabajadores">
                                <GroupAddIcon></GroupAddIcon>
                            </Tooltip>
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'fecha_crea', headerName: 'Fecha Creación', minWidth: 125, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'sed_codigo', headerName: 'SED', minWidth: 75, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'mes_proceso', headerName: 'Periodo', minWidth: 75, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'L1', headerName: 'Fecha Lectura 1', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'ejecutadol1', headerName: 'Avance L1', minWidth: 70, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'L2', headerName: 'Fecha Lectura 2', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'ejecutadol2', headerName: 'Avance L2', minWidth: 70, flex: 0.5, headerClassName: 'header-colum-table' },
        // { field: 'totalsuministros', headerName: 'Cant. Suministros', minWidth: 100, flex: 0.5, headerClassName: 'header-colum-table' },
        {
            field: 'estado', headerName: 'Estado', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                let style = {}
                if (cellValues.row.estado == 'FINALIZADO') {
                    style = { color: 'green' }
                } else {
                    style = { color: 'blue' }
                }
                return (
                    <span style={style}>{cellValues.row.estado}</span>
                )
            }
        },
        { field: 'trabajadores', headerName: 'Trabajador(es)', minWidth: 50, flex: 0.5, headerClassName: 'header-colum-table' },

        {
            field: 'delete', headerName: '*', minWidth: 50, flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {
                            cellValues.row.L1 == "" && cellValues.row.L2 == "" ?
                                <IconButton onClick={() => {
                                    EliminarMicrobalance(cellValues.row);

                                }}>
                                    <Tooltip title="Eliminar Microbalance">
                                        <DeleteForeverIcon color='warning'></DeleteForeverIcon>
                                    </Tooltip>
                                </IconButton>
                                : <></>
                        }
                    </>

                );
            }
        },
    ];

    const criteriosFiltro = [
        { criterio: 'Periodo' },
        { criterio: 'SET_Nombre' },
        { criterio: 'ALL_Nombre' },
        { criterio: 'SED_Codigo' },
        { criterio: 'Porcentaje_Perdida' },
        { criterio: "KWH_Perdida" },
    ];

    // const valorFiltroPeriodo = [
    //     { criterio: '202210' },
    //     { criterio: '202209' },
    //     { criterio: '202208' },
    //     { criterio: '202207' },
    //     { criterio: '202206' },
    //     { criterio: '202205' },
    //     { criterio: '202204' },
    //     { criterio: '202203' },
    //     { criterio: '202202' },
    //     { criterio: '202201' },
    // ];

    const valorFiltroSet = [
        { criterio: 'SOCABAYA' },
        { criterio: 'SAN LAZARO' },
        { criterio: 'ALTO CAYMA' },
        { criterio: 'PARQUE INDUSTRIAL' },
        { criterio: 'JESUS' },
        { criterio: 'CHILINA' },
        { criterio: 'CHALLAPAMPA' },
        { criterio: 'LAMBRAMANI' },
        { criterio: 'TIABAYA' },
        { criterio: 'PORONGOCHE' },
    ];

    const valorFiltroAlimentador = [
        { criterio: 'LAS PEÑAS' },
        { criterio: 'MERCADERES' },
        { criterio: 'Chachani' },
        { criterio: 'PARRA' },
        { criterio: 'JORGE CHAVEZ' },
        { criterio: 'VENEZUELA' },
        { criterio: 'SELVA ALEGRE' },
        { criterio: 'PERU' },
        { criterio: 'CIUDAD MI TRABAJO' },
        { criterio: 'ALTO CAYMA' },
    ];

    const valorFiltroSED = [
        { criterio: '169' },
        { criterio: '1000' },
        { criterio: '1001' },
        { criterio: '1002' },
        { criterio: '1003' },
        { criterio: '1004' },
        { criterio: '1005' },
        { criterio: '1006' },
        { criterio: '1007' },
        { criterio: '1008' },
    ];

    const EliminarMicrobalance = (row) => {        
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (window.confirm(`Al eliminar una programación de Microbalance todos los datos contenidos (Lectura_1, Lectura_2) se perderán.\n¿Estas seguro de Eliminar el microbalance de SED ${row.sed_codigo} del periodo ${row.mes_proceso}?`)) {
                setmostrarCircularProgress(true);

                let url = "";
                url = `${config.endPoint}api/Microbalance/EliminarMicrobalance`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idmicrobalance: row.id,
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        BuscarListaMicrobalances();
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    //Agregar Trabajador a Microbalance
    const AbrirModalAgregarTrabajador = async (row) => {
        // console.log(row);
        setlistaTrabajadoresMicrobalance([]);
        setmicrobalanceActual(row);
        await BuscarTrabajadoresActivos().then();
        BuscarTrabajadoresMicrobalance(row.id).then((respuesta) => {
            if (respuesta) {
                setmostrarModalAgregarTrabajador(true);
            } else {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`No se encontro lista de Trabajadores de Microbalance.`)
                setabrirToast(true);
            }
            setmostrarCircularProgress(false);
        }).catch(() => {
            setmostrarCircularProgress(false);
            navigate('/');
        })
    }
    const CerrarModalAgregarTrabajador = async () => {
        setmostrarModalAgregarTrabajador(false);
        BuscarListaMicrobalances();

    }
    const AbrirModalAgregarTrabajadorGrupo = async () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            // Verificar si se selecciono algun elementoelementosSeleccionadosDataGrid        
            if (elementosSeleccionadosDataGrid.length > 0) {
                setlistaTrabajadoresMicrobalance([]);
                // setmicrobalanceActual(row);
                await BuscarTrabajadoresActivos().then();
                //Buscar Trabajadores Asociados a Lista de elementos seleccionados
                // console.log(elementosSeleccionadosDataGrid);
                BuscarTrabajadoresMicrobalanceGrupo().then((respuesta) => {
                    if (respuesta) {
                        setmostrarModalAgregarTrabajadorGrupo(true);
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(`No se encontro lista de Trabajadores de Microbalance.`)
                        setabrirToast(true);
                    }
                    setmostrarCircularProgress(false);
                }).catch(() => {
                    setmostrarCircularProgress(false);
                })

            } else {
                settituloToast("Alerta");
                settipoToast("warning");
                setmensajeToast(`Debe seleccionar al menos un elemento para ejecutar esta acción.`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            }
        }
    }
    const CerrarModalAgregarTrabajadorGrupo = async () => {
        setmostrarModalAgregarTrabajadorGrupo(false);
        BuscarListaMicrobalances();
    }
    const BuscarTrabajadoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (!ValidarUsuario()) {
                reject(null);
            } else {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/usuario/listaractivos`;

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    // console.log(data);
                    if (data.success) {
                        setlistaTrabajadoresActivos(data.content);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    resolve(false);
                });
            }
        });
    }
    const BuscarTrabajadoresMicrobalance = (idmicrobalance) => {
        return new Promise((resolve, reject) => {
            if (!ValidarUsuario()) {
                reject(null);
            } else {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/listartrabajadores/${idmicrobalance}`;

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    // console.log(data);
                    if (data.success) {
                        setlistaTrabajadoresMicrobalance(data.content);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    resolve(false);
                });
            }
        });
    }
    const BuscarTrabajadoresMicrobalanceGrupo = (idmicrobalance) => {
        return new Promise((resolve, reject) => {
            if (!ValidarUsuario()) {
                reject(null);
            } else {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/listartrabajadoresgrupo`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        listamicrobalances: elementosSeleccionadosDataGrid.toString()
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        setlistaTrabajadoresMicrobalance(data.content);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch(async (error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    resolve(false);
                });
            }
        });
    }
    const BuscarIdTrabajador = async (nombretrabajador) => {
        for await (const trabajador of listaTrabajadoresActivos) {
            if (nombretrabajador.indexOf(trabajador.trabajador_nombre) > -1) {
                return trabajador.usuario;
            }
        }
        return null;
    }
    const ValidarAgregarTrabajador = () => {
        let error = 0;
        if (inputNombreTrabajador.current.value == '') {
            inputNombreTrabajador.current.classList.add('is-invalid');
            error++;
        } else {
            inputNombreTrabajador.current.classList.remove('is-invalid');
        }
        // console.log("revisar errores");
        if (error > 0) {
            return false;
        }
        return true;
    }
    const AgregarTrabajadorMicrobalance = async () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarAgregarTrabajador()) {
                setmostrarCircularProgress(true);

                let idtrabajador = await BuscarIdTrabajador(inputNombreTrabajador.current.value)
                // console.log(idtrabajador);
                if (idtrabajador) {

                    let url = "";
                    url = `${config.endPoint}api/Microbalance/AgregarTrabajador`;
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify({
                            idmicrobalance: microbalanceActual.id,
                            usuario: idtrabajador
                        })
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {
                        if (data.success) {
                            settituloToast("Correcto");
                            settipoToast("success");
                            inputNombreTrabajador.current.value = "";
                            BuscarTrabajadoresMicrobalance(microbalanceActual.id);
                        } else {
                            settituloToast("Error");
                            settipoToast("error");
                        }
                        setmensajeToast(data.message)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    }).catch((error) => {
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    });
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast(`No se encontro Id de trabajador ingresado`);
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }
            }
        }
    }
    const AgregarTrabajadorMicrobalanceGrupo = async () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarAgregarTrabajador()) {
                setmostrarCircularProgress(true);

                let idtrabajador = await BuscarIdTrabajador(inputNombreTrabajador.current.value)
                // console.log(idtrabajador);
                //Verificar que id !=null
                if (idtrabajador) {

                    let url = "";
                    url = `${config.endPoint}api/Microbalance/AgregarTrabajadorGrupo`;
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify({
                            listamicrobalances: elementosSeleccionadosDataGrid.toString(),
                            idtrabajador: idtrabajador
                        })
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {
                        if (data.success) {
                            settituloToast("Correcto");
                            settipoToast("success");
                            inputNombreTrabajador.current.value = "";
                            BuscarTrabajadoresMicrobalanceGrupo();
                        } else {
                            settituloToast("Alerta");
                            settipoToast("warning");
                        }
                        setmensajeToast(data.message)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    }).catch((error) => {
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    });
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast(`No se encontro Id de trabajador ingresado`);
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }
            }
        }
    }
    const EliminarTrabajadorMicrobalanceGrupo = (row) => {
        // console.log(row);
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (window.confirm(`¿Estas seguro de Eliminar el trabajador ${row.usuario} de la lista de Microbalance(s) ?`)) {
                setmostrarCircularProgress(true);

                let url = "";
                url = `${config.endPoint}api/Microbalance/EliminarTrabajadorGrupo`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        listamicrobalances: elementosSeleccionadosDataGrid.toString(),
                        idtrabajador: row.usuario
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        BuscarTrabajadoresMicrobalanceGrupo();

                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const EliminarTrabajadorMicrobalance = (row) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (window.confirm(`¿Estas seguro de Eliminar el trabajador ${row.trabajador_nombre} de este Microbalance?`)) {
                setmostrarCircularProgress(true);


                let url = "";
                url = `${config.endPoint}api/Microbalance/EliminarTrabajador`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idtrabajadormicrobalance: row.id_trabajador_microbalance,
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        BuscarTrabajadoresMicrobalance(microbalanceActual.id);
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const ActualizarEstadoTrabajadorMicrobalance = (row, estado) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);

            let url = "";
            url = `${config.endPoint}api/Microbalance/ActualizarTrabajador`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idtrabajadormicrobalance: row.id_trabajador_microbalance,
                    estado: estado
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.success) {
                    settituloToast("Correcto");
                    settipoToast("success");
                    BuscarTrabajadoresMicrobalance(microbalanceActual.id);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                }
                setmensajeToast(data.message)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    // Agregar Lista  Busqueda
    const ListarvaloresBuscar = () => {
        if (inputListaBuscar.current.value !== '') {
            inputListaBuscar.current.classList.remove('is-invalid');

            let arrayLista = inputListaBuscar.current.value.split(/\r\n|\r|\n/);
            let listaBuscar = "";

            arrayLista.forEach(elemento => {
                if (elemento.trim() !== '') {
                    listaBuscar = listaBuscar + elemento.trim() + ",";
                }
            });

            listaBuscar = listaBuscar.substring(0, listaBuscar.length - 1);
            inputValorBuscar.current.value = listaBuscar;

            setmostrarModalAgregarSuministros(false);
        } else {
            inputListaBuscar.current.clasList.add('is-invalid');
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`No se encontro cadena para realizar esta accion.`)
            setabrirToast(true);
        }
    }
    const ActualizarInputBuscar = () => {
        if (inputValorBuscar.current.value.trim() === '') {
            BuscarListaMicrobalances();
        }
    }
    //Habilitar Lectura
    const MostrarModalHabilitarLectura = async (tipo) => {
        settipoProgramacionLectura("");
        if (elementosSeleccionadosDataGrid.length > 0) {
            await setmostrarModalHabilitarLectura(true);
            settipoProgramacionLectura(tipo);
        } else {
            settituloToast("Alerta");
            settipoToast("warning");
            setmensajeToast(`Debe seleccionar al menos un registro de la lista de elementos para realizar esta acción.`)
            setabrirToast(true);
        }

    }
    const ValidarProgramacionLectura = () => {
        let error = 0;
        if (inputFechaProgramacionLectura.current.value == '') {
            inputFechaProgramacionLectura.current.classList.add('is-invalid');
            error++;
        } else {
            inputFechaProgramacionLectura.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const ProgramarLectura = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarProgramacionLectura()) {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let url = `${config.endPoint}api/Microbalance/HabilitarLectura`;
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idmicrobalance: elementosSeleccionadosDataGrid.toString(),
                        fechaprogramacion: inputFechaProgramacionLectura.current.value,
                        tipo: tipoProgramacionLectura
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    // console.log(data);
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        BuscarListaMicrobalances();
                        setmostrarModalHabilitarLectura(false);
                    } else {
                        settituloToast("Alerta");
                        settipoToast("warning");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    // console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    // Reprogramar Lectura
    const MostrarModalReporgramarLectura = async (elemento) => {
        // console.log(elemento);
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            await VerificarProgramacionMicrobalance(elemento.id).then((respuesta) => {
                if (respuesta) {
                    settipoProgramacionLectura("LECTURA_1");
                    setmostrarModalReporgramarMicrobalance(true);
                    setmicrobalanceActual(elemento);
                }
            });
        }
    }

    const MostrarModalReporgramarLecturaGrupo = async () => {
        // console.log(elementosSeleccionadosDataGrid);
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (elementosSeleccionadosDataGrid.length > 0) {
                settipoProgramacionLectura("LECTURA_1");
                setmostrarModalReporgramarMicrobalanceGrupo(true);
            } else {
                settituloToast("Alerta");
                settipoToast("warning");
                setmensajeToast(`Debe seleccionar al menos un elemento apra ejecutar esta acción.`)
                setabrirToast(true);
            }
        }
    }

    const MostrarModalReporgramarLectura2Grupo = async () => {
        // console.log(elementosSeleccionadosDataGrid);
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (elementosSeleccionadosDataGrid.length > 0) {
                settipoProgramacionLectura("LECTURA_2");
                setmostrarModalReporgramarMicrobalanceGrupo(true);
            } else {
                settituloToast("Alerta");
                settipoToast("warning");
                setmensajeToast(`Debe seleccionar al menos un elemento apra ejecutar esta acción.`)
                setabrirToast(true);
            }
        }
    }

    const VerificarProgramacionMicrobalance = async (idmicrobalance) => {
        return new Promise((resolve, reject) => {
            if (!ValidarUsuario()) {
                reject(false);
            } else {

                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/Microbalance/BuscarResumenProgramacionMicrobalance`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idmicrobalance: idmicrobalance
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        resolve(true);
                    } else {
                        settituloToast("Alerta");
                        settipoToast("warning");
                        setmensajeToast(`${data.message.toString()}`)
                        setabrirToast(true);
                        resolve(false);
                    }
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    // console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                    resolve(false);
                });
            }
        });
    }

    const ReprogramarLectura = () => {

        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarProgramacionLectura()) {

                if (window.confirm("Al reprogramar un microbalance todos los cambios y/o avance realizado se perderan, ¿Estas seguro de reprogramar esta programación de Microbalance?")) {
                    setmostrarCircularProgress(true);
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let url = `${config.endPoint}api/Microbalance/ReprogramarMicroBalance`;
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify({
                            idmicrobalance: microbalanceActual.id,
                            fechaprogramacion: inputFechaProgramacionLectura.current.value
                        })
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        // console.log(data);
                        if (data.success) {
                            settituloToast("Correcto");
                            settipoToast("success");
                            BuscarListaMicrobalances();
                            setmostrarModalReporgramarMicrobalance(false);
                        } else {
                            settituloToast("Alerta");
                            settipoToast("warning");
                        }
                        setmensajeToast(data.message)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    }).catch(async (error) => {
                        // console.log(error);
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    });
                }
            }
        }
    }

    const ReprogramarLecturaGrupo = () => {

        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarProgramacionLectura()) {

                if (window.confirm(`Al reprogramar un microbalance todos los cambios y/o avance realizado se perderan, ¿Estas seguro de reprogramar ${tipoProgramacionLectura} de esta programación de Microbalance?`)) {
                    setmostrarCircularProgress(true);
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let url = `${config.endPoint}api/Microbalance/ReprogramarMicroBalanceGrupo`;
                    // let url = `${config.endPoint}api/Microbalance/ReprogramarMicroBalanceGrupoV2`;
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify({
                            listaidmicrobalance: elementosSeleccionadosDataGrid.toString(),
                            fechaprogramacion: inputFechaProgramacionLectura.current.value,
                            // tipolectura: tipoProgramacionLectura
                        })
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        // console.log(data);
                        if (data.success) {
                            settituloToast("Correcto");
                            settipoToast("success");
                            BuscarListaMicrobalances();
                            setmostrarModalReporgramarMicrobalanceGrupo(false);
                        } else {
                            settituloToast("Alerta");
                            settipoToast("warning");
                        }
                        setmensajeToast(data.message)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    }).catch(async (error) => {
                        console.log(error);
                        settituloToast("Error");
                        settipoToast("error");
                        setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                        setabrirToast(true);
                        setmostrarCircularProgress(false);
                    });
                }
            }
        }
    }


    const KeyEnterInputBuscar = (ev) => {
        if (ev.key === "Enter") {
            BuscarListaMicrobalances();
        }
    }
    //Actualizar Cambios Filtros    
    const changeFiltroPeriodo = async (value) => {
        await setmatrizPeriodosSeleccionados(value);
        // let valorFiltros = ObtenerFiltros();
        // valorFiltros['periodo'] = value;
        // GuardarEnStorage(config.storageFiltros, valorFiltros);
        let matrizTemp = [];
        value.forEach(element => {
            matrizTemp.push(element.mes_proceso);
        });
        setcadenaPeriodosSeleccionados(matrizTemp);
    }
    const ChangeValorCambiarMicrobalance = (valor) => {
        setmostrarTodosEstadosMicrobalance(valor);
        // let valorFiltros = ObtenerFiltros();
        // valorFiltros['valor_mostrar_microbalances'] = valor;
        // GuardarEnStorage(config.storageFiltros, valorFiltros);
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>
            <Header titulo='Control de Perdidas / Balance / Microbalance'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Balance / Microbalance</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/balance') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4}>
                            <Stack direction='horizontal' >
                                <Form.Control ref={inputValorBuscar} onKeyDown={(ev) => { KeyEnterInputBuscar(ev) }} onChange={(ev) => { ActualizarInputBuscar() }} placeholder='Valor a buscar' size=''></Form.Control>
                                <IconButton onClick={() => { setmostrarModalAgregarSuministros(true) }}>
                                    <PlaylistAddIcon></PlaylistAddIcon>
                                </IconButton>
                            </Stack>
                        </Col>

                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button onClick={() => { BuscarListaMicrobalances() }} size='sm' variant='primary '>Buscar</Button>
                        </Col>

                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : 'text-end'} >
                            <Button variant='primary' size='sm' onClick={() => { setmostrarModalFiltro(true) }}>Filtros</Button>
                        </Col >
                    </Row>
                    <Row className='mt-1'>
                        <Col>
                            <small className='text-secondary'>
                                {
                                    `Filtros: ${matrizPeriodosSeleccionados.length > 0 ? `Periodo [${cadenaPeriodosSeleccionados.toString()}] ; ` : ''} 
                                    ${mostrarTodosEstadosMicrobalance ? `Todos los Estados` : 'Pendientes de Ejecución'}`
                                }
                            </small>
                        </Col>
                        <Col className='text-end'>
                            <ButtonMUI variant="text"
                                onClick={AbrirMenu}>
                                Opciones
                                <KeyboardArrowDownIcon />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={objetoMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(objetoMenu)}
                                onClose={CerrarMenu}
                            >
                                <MenuItem onClick={() => { CerrarMenu(); MostrarModalHabilitarLectura('LECTURA_1') }}>Habilitar Lectura 1</MenuItem>
                                <MenuItem onClick={() => { CerrarMenu(); MostrarModalHabilitarLectura('LECTURA_2') }}>Habilitar Lectura 2</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => { CerrarMenu(); AbrirModalAgregarTrabajadorGrupo() }}>Asignar Trabajador</MenuItem>
                                <MenuItem onClick={() => { CerrarMenu(); MostrarModalReporgramarLecturaGrupo() }}>Reprogramar Lectura 1</MenuItem>
                                <MenuItem onClick={() => { CerrarMenu(); MostrarModalReporgramarLectura2Grupo() }}>Reprogramar Lectura 2</MenuItem>
                            </Menu>
                        </Col>
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={listaMicrobalances}
                            columns={columns}
                            checkboxSelection
                            onSelectionModelChange={(values) => {
                                setelementosSeleccionadosDataGrid(values)
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </div>
            </div>



            {/* Modal Agregar Suministros */}
            <Modal
                show={mostrarModalAgregarSuministros}
                onHide={() => { setmostrarModalAgregarSuministros(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Lista
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <Form.Control ref={inputListaBuscar} as='textarea' rows={6} placeholder='Agregar Lista' size='sm'></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <Button variant='primary' onClick={() => { ListarvaloresBuscar() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal filtro */}
            <Modal
                show={mostrarModalFiltro}
                onHide={() => { setmostrarModalFiltro(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Seleccionar Criterios
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <small className='text-primary'>{`Periodo (${valorFiltroPeriodo.length})`}</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Autocomplete
                                    onChange={(event, value) => { changeFiltroPeriodo(value) }}
                                    isOptionEqualToValue={(option, value) => option.mes_proceso === value.mes_proceso}
                                    multiple
                                    size='small'
                                    options={valorFiltroPeriodo}
                                    // disableCloseOnSelect
                                    getOptionLabel={(option) => option.mes_proceso}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 4 }}
                                                checked={selected}
                                            />
                                            {option.mes_proceso}
                                        </li>
                                    )}
                                    style={{}}
                                    renderInput={(params) => (
                                        <TextField {...params} label="" placeholder="Periodo" />
                                    )}
                                    value={matrizPeriodosSeleccionados}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <small className='text-primary'>{`Estado Microbalances`}</small>
                            </Col>
                        </Row>

                        <Row className='mt-2'>
                            <Col>
                                <Form.Check size='sm' defaultChecked={mostrarTodosEstadosMicrobalance} ref={checkMostrarTodosMicrobalances} onChange={(ev) => { ChangeValorCambiarMicrobalance(ev.target.checked) }} type='checkbox' label='Mostrar Todos'></Form.Check>
                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col className='text-center'>
                                <Button onClick={() => { BuscarListaMicrobalances() }} className='w-50'>Filtrar</Button>
                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Trabajador */}
            <Modal
                show={mostrarModalAgregarTrabajador}
                onHide={() => { CerrarModalAgregarTrabajador() }}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Asignar Trabajador
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row >
                            <Col>
                                <small className='text-primary'>Trabajador</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Stack direction='horizontal' gap={2}>
                                    <Form.Control ref={inputNombreTrabajador} size='sm' placeholder='Trabajador' list='dataTrabajador'></Form.Control>
                                    <IconButton onClick={() => { AgregarTrabajadorMicrobalance() }}>
                                        <AddIcon></AddIcon>
                                    </IconButton>
                                </Stack>
                                <datalist id="dataTrabajador">
                                    {
                                        listaTrabajadoresActivos.map((row, index) => {
                                            return (
                                                <option key={index} value={row.trabajador_nombre} />
                                            )
                                        })
                                    }
                                </datalist>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <TableContainer style={{ maxHeight: 200, minWidth: '100%' }} >
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell style={{ backgroundColor: 'rgb(240,240,240)' }} className="header-colum-table">Accion</TableCell>
                                                <TableCell style={{ backgroundColor: 'rgb(240,240,240)' }} className="header-colum-table">ITEM</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                listaTrabajadoresMicrobalance.map((row) => {
                                                    return (
                                                        <TableRow key={row.id_trabajador_microbalance}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <Stack direction='horizontal' >
                                                                    <Form.Check
                                                                        onClick={() => {
                                                                            row.estado == "ACTIVO" ? ActualizarEstadoTrabajadorMicrobalance(row, 'INACTIVO') : ActualizarEstadoTrabajadorMicrobalance(row, 'ACTIVO')

                                                                        }}
                                                                        type="switch"
                                                                        className='mx-2' defaultChecked={row.estado == "ACTIVO" ? true : false}
                                                                    />

                                                                    <IconButton onClick={() => { EliminarTrabajadorMicrobalance(row) }} className='mx-2'>
                                                                        <DeleteIcon fontSize='small'></DeleteIcon>
                                                                    </IconButton>
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.trabajador_nombre}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className='text-end'>
                                <Button onClick={() => { CerrarModalAgregarTrabajador() }} size='sm' variant='outline-primary'>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Trabajador Grupo*/}
            <Modal
                show={mostrarModalAgregarTrabajadorGrupo}
                onHide={() => { CerrarModalAgregarTrabajadorGrupo() }}
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Asignar Trabajador Grupo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row >
                            <Col>
                                <small className='text-primary'>Trabajador</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Stack direction='horizontal' gap={2}>
                                    <Form.Control ref={inputNombreTrabajador} size='sm' placeholder='Trabajador' list='dataTrabajador'></Form.Control>
                                    <IconButton onClick={() => { AgregarTrabajadorMicrobalanceGrupo() }}>
                                        <AddIcon></AddIcon>
                                    </IconButton>
                                </Stack>
                                <datalist id="dataTrabajador">
                                    {
                                        listaTrabajadoresActivos.map((row, index) => {
                                            return (
                                                <option key={index} value={row.trabajador_nombre} />
                                            )
                                        })
                                    }
                                </datalist>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <TableContainer style={{ maxHeight: 200, minWidth: '100%' }} >
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell style={{ backgroundColor: 'rgb(240,240,240)' }} className="header-colum-table">Accion</TableCell>
                                                <TableCell style={{ backgroundColor: 'rgb(240,240,240)' }} className="header-colum-table">ITEM</TableCell>
                                                <TableCell style={{ backgroundColor: 'rgb(240,240,240)' }} className="header-colum-table">SED's Incluidas</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                listaTrabajadoresMicrobalance.map((row, index) => {

                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <Stack direction='horizontal' >
                                                                    {/* <Form.Check
                                                                        onClick={() => {
                                                                            row.estado == "ACTIVO" ? ActualizarEstadoTrabajadorMicrobalance(row, 'INACTIVO') : ActualizarEstadoTrabajadorMicrobalance(row, 'ACTIVO')

                                                                        }}
                                                                        type="switch"
                                                                        className='mx-2' defaultChecked={row.estado == "ACTIVO" ? true : false}
                                                                    /> */}
                                                                    <Tooltip title="Eliminar Trabajador">
                                                                        <IconButton onClick={() => { EliminarTrabajadorMicrobalanceGrupo(row) }} className='mx-2'>
                                                                            <DeleteIcon fontSize='small'></DeleteIcon>
                                                                        </IconButton>

                                                                    </Tooltip>

                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.usuario}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.sed}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small>{`${listaTrabajadoresMicrobalance.length} registros.`}</small>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className='text-end'>
                                <Button onClick={() => { CerrarModalAgregarTrabajadorGrupo() }} size='sm' variant='outline-primary'>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Habilitar Lecturas */}
            <Modal
                show={mostrarModalHabilitarLectura}
                onHide={() => { setmostrarModalHabilitarLectura(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Habilitar Lectura
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <small className='text-primary'>Tipo Lectura</small>
                                <Form.Control size='sm' disabled defaultValue={tipoProgramacionLectura}></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className='text-primary'>Elegir Fecha Lectura</small>
                                <Form.Control ref={inputFechaProgramacionLectura} size='sm' type='date'></Form.Control>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button variant='outline-primary' size='sm' onClick={() => { setmostrarModalHabilitarLectura(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button variant='primary' size='sm' onClick={() => { ProgramarLectura() }}>Aceptar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Reporgramar Microbalance */}
            <Modal
                show={mostrarModalReporgramarMicrobalance}
                onHide={() => { setmostrarModalReporgramarMicrobalance(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Reprogramar Lectura
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <small className='text-primary'>Tipo Lectura</small>
                                <Form.Control size='sm' disabled defaultValue={tipoProgramacionLectura}></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className='text-primary'>Elegir Fecha Lectura</small>
                                <Form.Control ref={inputFechaProgramacionLectura} size='sm' type='date'></Form.Control>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button variant='outline-primary' size='sm' onClick={() => { setmostrarModalReporgramarMicrobalance(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button variant='primary' size='sm' onClick={() => { ReprogramarLectura(true) }}>Aceptar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


            {/* Modal Reporgramar Microbalance Grupo */}
            <Modal
                show={mostrarModalReporgramarMicrobalanceGrupo}
                onHide={() => { setmostrarModalReporgramarMicrobalanceGrupo(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Reprogramar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <small className='text-secondary'>{`${elementosSeleccionadosDataGrid.length} Elementos seleccionados.`}</small>
                                {/* <Form.Control size='sm' disabled defaultValue={tipoProgramacionLectura}></Form.Control> */}
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col>
                                <small className='text-primary'>Tipo Lectura</small>
                                <Form.Control size='sm' disabled defaultValue={tipoProgramacionLectura}></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className='text-primary'>Elegir Fecha Lectura</small>
                                <Form.Control ref={inputFechaProgramacionLectura} size='sm' type='date'></Form.Control>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col>
                                <small className='text-secondary'>{`Obs.: Solo se reprogramarán los microbalance que tengan habilitado la Lectura 1, todos los elementos que tengan programado lectura 2 seran omitidos al ejecutar esta acción.`}</small>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button variant='outline-primary' size='sm' onClick={() => { setmostrarModalReporgramarMicrobalanceGrupo(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button variant='primary' size='sm' onClick={() => { ReprogramarLecturaGrupo() }}>Aceptar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


        </Fragment>
    );
}

export default Microbalances;