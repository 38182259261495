import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Card, FloatingLabel, Form, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { Stack, Button, Snackbar, Alert, AlertTitle } from '@mui/material'
import fondo from '../../assets/fondo.jpg'


import { config, BorrarStorage, ObtenerDetalleToken, GuardarEnStorage, listarPermisos, ValidarPermisos, LIST_SCREENS, ValidarUsuario } from '../../config/utils'


function Login(props) {

    const navigate = useNavigate();
    const [tituloToast,settituloToast]=useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipotoast] = useState("error");
    const [mensajetoast, setmensajetoast] = useState("");
    const [mostrarSpinner, setmostrarSpinner] = useState(false);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    // Referencias
    const usuarioRef = useRef();
    const contraRef = useRef();

    useEffect(()=>{
        ValidarAccesos();
    },[]);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {      
            BorrarStorage(config.storageName);    
            BorrarStorage(config.filter);    
            navigate('/');
        }else{
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_DASHBOARD, permisos)) {
                    navigate('/dashboard');             
                } else {
                    BorrarStorage(config.storageName);                    
                    BorrarStorage(config.filter);
                    settituloToast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);                    
                }
            });
        }
    }


    const validarFormulario = () => {
        let error = 0;
        if (usuarioRef.current.value.trim() === "") {
            usuarioRef.current.classList.add("is-invalid")
            error++;
        } else {
            usuarioRef.current.classList.remove("is-invalid");
        }
        if (contraRef.current.value.trim() === "") {
            contraRef.current.classList.add("is-invalid");
            error++;
        }
        else {
            contraRef.current.classList.remove("is-invalid");
        }
        if (error > 0) {
            return false;
        }
        else {
            return true;
        }

    }
    const IniciarSesion = () => {
        // setmostrarSpinner(true);
        // setTimeout(() => {
        //     navigate("/");
        // }, 2000);
        if (validarFormulario()) {
            setmostrarSpinner(true);
            // Creando Headers
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Usuario: usuarioRef.current.value,
                    Password: contraRef.current.value
                })
            };

            fetch(`${config.endPoint}api/usuario/login`, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {               

                setmostrarSpinner(false);
                if (!data.success) {
                    BorrarStorage(config.storageName);
                    BorrarStorage(config.storageFiltros);                    
                    
                    settituloToast("Error")
                    settipotoast("error")
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }
                else {

                    let detalleToken = JSON.parse(ObtenerDetalleToken(data.content));
                    let trabajador = {
                        id: detalleToken.id,
                        nombretrabajador: detalleToken.nombres,
                        token: data.content
                    };
                    GuardarEnStorage(config.storageName, trabajador);                    
                    listarPermisos().then((permisos) => {                
                        setlistapermisos(permisos);
                        if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_LOGIN, permisos)) {
                            navigate('/dashboard');             
                        } else {
                            BorrarStorage(config.storageName);     
                            BorrarStorage(config.storageFiltros);       
                            settituloToast("Alerta");
                            settipotoast("warning");
                            setmensajetoast("No tiene acceso a este recurso.");
                            setabrirToast(true);                    
                        }
                    });
                }
            }).catch((error) => {
                // console.log(error);
                setmensajetoast("Error Interno en el servidor, revisa el estado de la conexion.");
                setabrirToast(true);
                setmostrarSpinner(false);
            });
        }
    }

    const submitLogin = (ev) => {
        if (ev.key === "Enter") {
            IniciarSesion();
        }
    }

    return (
        <Fragment>
            {/* Alerta */}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>
            <Container fluid style={{position:'absolute',backgroundColor:'red',height:'100%',backgroundImage:`url(${fondo})`,filter:'grayscale(80%)',backgroundSize:'cover'}} >                
            </Container>            
            <Container fluid className='bg-secondary'  >
                <Row className='vh-100 justify-content-center align-items-center'>
                    <Col sm='9' md="8" lg="7" xl='4'>
                        <Card >
                            {/* <Card.Header className='text-center'>
                                <h3 className='text-secondary'>INICIAR SESIÓN</h3>
                            </Card.Header> */}
                            <Card.Body>
                            <div className='text-center'>
                            <img src='./img/logo_seal2.png' height={100}></img>
                            </div>
                                <Stack spacing={3} sx={{ mx: 3, mt: 2 }}>
                                    <FloatingLabel label="Usuario">
                                        <Form.Control ref={usuarioRef} type="text" placeholder='Usuario' onKeyPress={(ev) => { submitLogin(ev) }} />
                                    </FloatingLabel>

                                    <FloatingLabel label="Contraseña">
                                        <Form.Control ref={contraRef} type="password" placeholder='Contraseña' onKeyPress={(ev) => { submitLogin(ev) }} />
                                    </FloatingLabel>

                                    <Button
                                        variant="contained"
                                        onClick={() => { IniciarSesion() }}
                                    >
                                        {
                                            mostrarSpinner ?
                                                <Spinner animation="border" variant="light" size='sm' className='mx-2' />
                                                : <></>
                                        }
                                        Iniciar Sesión
                                    </Button>
                                    <small className='text-secondary'>{config.aplicationVersion}</small>

                                </Stack>

                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default Login;