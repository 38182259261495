import React, { Fragment, useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';

import { Container, Row, Col, Button, Modal, Accordion, FormControl } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { PinRojo, PinVerde, PinAzul, PinAmarillo, PinNego } from '../../config/iconos';
import { ValidarUsuario, config, ObtenerUsuario, useWindowSize,listarPermisos,ValidarPermisos,LIST_SCREENS } from '../../config/utils';

function ConfiguracionVariables() {
    const navigate = useNavigate();
    const size = useWindowSize();

    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_VARIABLES, permisos)) {
                    
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }       
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración / Pantallas'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Configuración / Variables</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/configuracion') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Accordion>
                            <Accordion.Item>
                                <Accordion.Header>Colores Pines de Mapa</Accordion.Header>
                                <Accordion.Body>
                                    <Container>

                                        <Row className='mt-2'>
                                            <Col>
                                                <img src='/img/pin3.svg' height={25}></img>
                                                <span className='text-secondary mx-1'>Pin Verde</span>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Desde' defaultValue={'0%'}></FormControl>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Hasta' defaultValue={'7%'}></FormControl>
                                            </Col>
                                        </Row>

                                        <Row className='mt-2'>
                                            <Col>
                                                <img src='/img/pin5.svg' height={25}></img>
                                                <span className='text-secondary mx-1'>Pin Amarillo</span>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Desde' defaultValue={'7%'}></FormControl>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Hasta' defaultValue={'12%'}></FormControl>
                                            </Col>
                                        </Row>

                                        <Row className='mt-2'>
                                            <Col>
                                                <img src='/img/pin1.svg' height={25}></img>
                                                <span className='text-secondary mx-1'>Pin Rojo</span>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Desde' defaultValue={'12%'}></FormControl>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Hasta' defaultValue={'>12%'}></FormControl>
                                            </Col>
                                        </Row>

                                        <Row className='mt-2'>
                                            <Col>
                                                <img src='/img/pin14.svg' height={25}></img>
                                                <span className='text-secondary mx-1'>Pin Azul</span>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Desde' defaultValue={'NEGATIVOS'}></FormControl>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Hasta' defaultValue={'NEGATIVOS'}></FormControl>
                                            </Col>
                                        </Row>

                                        <Row className='mt-2'>
                                            <Col>
                                                <img src='/img/pin34.svg' height={25}></img>
                                                <span className='text-secondary mx-1'>Pin Negro</span>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Desde' defaultValue={'S/TOTA'}></FormControl>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Hasta' defaultValue={'S/TOTA'}></FormControl>
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col sm={12} md={4}>
                                                <Button size='sm' variant='primary'>Guardar</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row className='mt-1'>
                        <Accordion>
                            <Accordion.Item>
                                <Accordion.Header>Calculo Variación Consumo</Accordion.Header>
                                <Accordion.Body>
                                    <Container>
                                        <Row>
                                            <Col sm={4}>
                                                <span className='text-secondary'>(%)Procentaje de variación</span>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Tiempo en horas.' defaultValue={50}></FormControl>
                                                <small className='text-info'>El valor de la variacion es en %</small>
                                            </Col>
                                        </Row>                                        

                                        <Row className='mt-2'>
                                            <Col sm={12} md={4}>
                                                <Button size='sm' variant='primary'>Guardar</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row className='mt-1'>
                        <Accordion>
                            <Accordion.Item>
                                <Accordion.Header>Usuario</Accordion.Header>
                                <Accordion.Body>
                                    <Container>
                                        <Row>
                                            <Col sm={4}>
                                                <span className='text-secondary'>Validez de Token Sesión Web</span>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Tiempo en horas.' defaultValue={3}></FormControl>
                                                <small className='text-info'>El tiempo de validez del token en horas</small>
                                            </Col>
                                        </Row>

                                        <Row className=' mt-2'>
                                            <Col sm={4}>
                                                <span className='text-secondary'>Validez de Token Sesión Movil</span>
                                            </Col>
                                            <Col>
                                                <FormControl size='sm' placeholder='Tiempo en horas.' defaultValue={12}></FormControl>
                                                <small className='text-info'>El tiempo de validez del token en horas</small>
                                            </Col>
                                        </Row>

                                        <Row className='mt-2'>
                                            <Col sm={12} md={4}>
                                                <Button size='sm' variant='primary'>Guardar</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>

                </Container>

                {/* <div className='my-1'>
                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        density='compact'
                        rows={dataPantallas}
                        columns={columns}
                        getCellClassName={(params) => {
                            if (params.colDef.field == "estado") {
                                if (params.row.estado == "ACTIVO") {
                                    return 'text-primary';
                                } else {
                                    return 'text-danger';
                                }
                            }
                        }}
                    />
                </div>
            </div> */}
            </div>



        </Fragment>
    )
}
export default ConfiguracionVariables;