import * as XLSX from 'xlsx';
import { useEffect, useState } from 'react';
export const config = {
    // endPoint: 'https://www.balancebtseal.somee.com/',    
    endPoint: 'https://serviciosseal.seal.com.pe/wsBalances/',
    // endPoint: 'https://192.168.10.15:5095/',
    // endPoint: 'https://localhost:7095/',
    // endPoint: 'http://localhost:4300/',  
    

    MessageErrorServer: 'Error en el Servidor,ERROR_CONNECTION ',
    aplicationVersion: '1.0.5 Pruebas',
    storageName: 'sisbalancedata',
    storageFiltros: 'sisbalancefiltro',
    storageidBalance:'sisbalanceidb'
}
export var ValidarUsuario = () => {
    let usuarioLocalstorage = ObtenerUsuario()
    //console.log(usuarioLocalstorage);
    if (usuarioLocalstorage != null) {
        usuarioLocalstorage = JSON.parse(usuarioLocalstorage);
        let ahora = Date.now() / 1000;
        // console.log(usuarioLocalstorage);
        if (usuarioLocalstorage.token !== null) {
            let userDetails = ObtenerDetalleToken(usuarioLocalstorage.token);
            //console.log(JSON.parse(userDetails));
            if (userDetails) {
                if (JSON.parse(userDetails).exp > ahora) {
                    return true;
                } else {
                    localStorage.removeItem(config.storageName);
                }
            }
        } else {
            if (usuarioLocalstorage.exp > ahora) {
                return true;
            } else {
                localStorage.removeItem(config.storageName);
            }
        }
    }
    return false;
}
export var ObtenerUsuario = () => {
    let usuarioLocalstorage = localStorage.getItem(config.storageName)
    if (usuarioLocalstorage) {
        return usuarioLocalstorage
    } else {
        return null
    }
}
export var ObtenerFiltros = () => {
    let usuarioLocalstorage = localStorage.getItem(config.storageFiltros)
    if (usuarioLocalstorage) {
        return JSON.parse(usuarioLocalstorage)
    } else {
        return {
            filtro: [],
            periodo: [],
            set: [],
            alimentador: [],
            sed: [],
            filtro_porc_perdida: '',
            valor_por_perdida: null,
            filtro_kwh_perdida: '',
            valor_kwh_perdida: null,
            valor_mostrar_microbalances:false
        }
    }
}
export var ObtenerDetalleToken = (token) => {
    if (token) {
        let centro = token.split(".")[1];
        return window.atob(centro);
    }
    return null;
}
export var GuardarEnStorage = (storageName, data) => {
    localStorage.setItem(storageName, JSON.stringify(data));
}
export var BorrarStorage = (storageName) => {
    // console.log('nombreStorage '+storageName);
    localStorage.removeItem(storageName)
    return storageName;
}
export var LEER_EXCEL_DE_ARCHIVO = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer', cellDates: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });

}
export var LEER_EXCEL = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: 'binary', cellDates: true });
            console.log(wb);

            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });

}
export var CONVERTIR_JSON_A_EXCEL = (data, nombreArchivo, nombrehoja) => {
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, nombrehoja);
    XLSX.writeFile(wb, `${nombreArchivo}.xls`);
}
export var listarPermisos = () => {

    // let usuarioLocalstorage = localStorage.getItem(config.storageName)
    // usuarioLocalstorage = JSON.parse(usuarioLocalstorage);

    return new Promise((resolve, reject) => {
        try {
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Permiso/ListarPermisos`;

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };            
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.success) {
                    resolve(data.content);
                }
                else {
                    resolve(null);
                }
            }).catch(() => {
                reject(null);
            });

        } catch (error) {
            reject(null);
        }        
    });
}
export var ValidarPermisos = (VISTA, permisos) => {    
    let _permisoEncontrado = permisos.find(x => x.pantalla_nombre === VISTA);
    if (_permisoEncontrado) {
        return true;
    } else {
        return false;
    }
}
export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}
export const LIST_SCREENS = {
    ACCESS_SCREEN_DASHBOARD: 'DASHBOARD',
    ACCESS_SCREEN_LOGIN: 'LOGIN',
    ACCESS_SCREEN_BALANCES: 'BALANCES',
    ACCESS_SCREEN_BALANCES_CARGAR_RPT: 'BALANCES_CARGAR_RPT',
    ACCESS_SCREEN_BALANCES_LISTA: 'BALANCES_LISTA',
    ACCESS_SCREEN_BALANCES_LISTA_MAPA: 'BALANCES_LISTA_MAPA',
    ACCESS_SCREEN_BALANCES_DETALLE: 'BALANCES_DETALLE',        
    ACCESS_SCREEN_BALANCES_MICROBALANCE: 'BALANCES_MICROBALANCE',
    ACCESS_SCREEN_BALANCES_MICROBALANCE_CALCULO: 'BALANCES_MICROBALANCE_CALCULO',
    ACCESS_SCREEN_DETECCIONES: 'DETECCIONES',
    ACCESS_SCREEN_DETECCIONES_LISTA: 'DETECCIONES_LISTA',
    ACCESS_SCREEN_DETECCIONES_CARGAR: 'DETECCIONES_CARGAR',
    ACCESS_SCREEN_RECUPEROS: 'RECUPEROS',
    ACCESS_SCREEN_RECUPEROS_LISTA: 'RECUPEROS_LISTA',
    ACCESS_SCREEN_RECUPEROS_CARGAR: 'RECUPEROS_CARGAR',
    ACCESS_SCREEN_SUMINISTRO: 'SUMINISTRO',
    ACCESS_SCREEN_ALERTAS_SUMINISTRO: 'ALERTA_SUMINISTRO',
    ACCESS_SCREEN_SUMINISTRO_ALERTA: 'SUMINISTRO_ALERTA',
    ACCESS_SCREEN_CONFIGURACION: 'CONFIGURACION',
    ACCESS_SCREEN_CONFIGURACION_PERSONAL: 'CONFIGURACION_PERSONAL',
    ACCESS_SCREEN_CONFIGURACION_PANTALLAS: 'CONFIGURACION_PANTALLAS',
    ACCESS_SCREEN_CONFIGURACION_TIPO_USUARIO: 'CONFIGURACION_TIPO_USUARIO',
    ACCESS_SCREEN_CONFIGURACION_AGRUPACIONES: 'CONFIGURACION_AGRUPACIONES',
    ACCESS_SCREEN_CONFIGURACION_VARIABLES: 'CONFIGURACION_VARIABLES',
    ACCESS_SCREEN_CONFIGURACION_BASE_SED: 'CONFIGURACION_BASE_SED',    
    ACCESS_SCREEN_CONFIGURACION_CODIGO_LECTURA: 'CONFIGURACION_CODIGO_LECTURA',    
    ACCESS_SCREEN_CONFIGURACION_DETALLE_VIVIENDA: 'CONFIGURACION_DETALLE_VIVIENDA',    
    ACCESS_SCREEN_MICROBALANCE: 'MICROBALANCE',
    ACCESS_SCREEN_MICROBALANCE_DETALLE: 'MICROBALANCE_DETALLE',
    ACCESS_SCREEN_MICROBALANCE_MENU_FINALIZAR: 'MICROBALANCE_MENU_FINALIZAR',
    ACCESS_SCREEN_MICROBALANCE_MENU_AGREGAR_TOTALIZADOR: 'MICROBALANCE_MENU_AGREGAR_TOTALIZADOR',
    ACCESS_SCREEN_MICROBALANCE_MENU_MOSTRAR_PROYECCION: 'MICROBALANCE_MENU_MOSTRAR_PROYECCION',
    ACCESS_SCREEN_SCRIPT: 'SCRIPT',    
}

