import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';

import { Container, Row, Col, Button, Modal, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Snackbar, Alert, AlertTitle, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DataGrid ,esES} from '@mui/x-data-grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import GradingIcon from '@mui/icons-material/Grading';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ValidarUsuario, config, ObtenerUsuario, useWindowSize,listarPermisos,ValidarPermisos,LIST_SCREENS } from '../../config/utils';

function ConfiguracionTipoUsuario(props) {
    const navigate = useNavigate();
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const size = useWindowSize();
    //Modales
    const [mostrarModaltipoUsuario, setmostrarModaltipoUsuario] = useState(false);
    const [mostrarModalPermisos, setmostrarModalPermisos] = useState(false);

    //
    const [dataTipoUsuario, setdataTipoUsuario] = useState([]);
    const [accionActual, setaccionActual] = useState("");
    const [tipoUsuarioActual, settipoUsuarioActual] = useState({});
    const [listaPermisosActual, setlistaPermisosActual] = useState([]);
    const [listaPantallaActivos, setlistaPantallaActivos] = useState([]);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const inputBuscar = useRef();
    const inputTipoUsuario = useRef();
    const selectEstado = useRef();
    const selectPantalla = useRef();

    const columns = [
        {

            field: 'accion', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {
                            AbrirModalTipoUsuario('VER', cellValues.row)
                        }}>
                            <EditIcon fontSize='small'></EditIcon>
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'tipo_usuario', headerName: 'Tipo de Usuario', flex: 1, headerClassName: 'header-colum-gray' },
        { field: 'estado', headerName: 'Estado', flex: 1, headerClassName: 'header-colum-gray' },
        {
            field: 'permisos', headerName: 'Permisos', flex: 1, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (
                    <IconButton onClick={() => {
                        AbrirModalPermisos(cellValues.row)
                    }}>
                        <GradingIcon fontSize='small' color='success'></GradingIcon>
                    </IconButton>
                )
            }
        },

    ];

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_TIPO_USUARIO, permisos)) {
                    BuscarListatiposUsuario();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }       
    }
    const BuscarListatiposUsuario = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/TipoUsuario/Listar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ valorBuscar: inputBuscar.current.value })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: elemento.id_tipo_usuario,
                            accion: '',
                            tipo_usuario: elemento.tipo_usuario,
                            estado: elemento.estado
                        }
                    );
                });
                setdataTipoUsuario(DataTemp);
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const AbrirModalTipoUsuario = async (accion, row) => {
        setaccionActual(accion);
        await setmostrarModaltipoUsuario(true);
        if (row) {
            settipoUsuarioActual(row);
            inputTipoUsuario.current.value = row.tipo_usuario;
            selectEstado.current.value = row.estado;
            inputTipoUsuario.current.disabled = true;
            selectEstado.current.disabled = true;
        }

    }
    const Guardar = () => {
        switch (accionActual) {
            case 'AGREGAR':
                console.log(accionActual);
                AgregarTipousuario();
                break;
            case 'MODIFICAR':
                ModificarTipousuario();
                break;
        }
    }
    const ValidarFormularioTipoUsuario = () => {
        let error = 0;
        if (inputTipoUsuario.current.value == '') {
            inputTipoUsuario.current.classList.add("is-invalid");
            error++;
        } else {
            inputTipoUsuario.current.classList.remove("is-invalid");
        }

        if (selectEstado.current.value == '') {
            selectEstado.current.classList.add("is-invalid");
            error++;
        } else {
            selectEstado.current.classList.remove("is-invalid");
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    const AgregarTipousuario = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarFormularioTipoUsuario()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/TipoUsuario/Agregar`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        Tiposuario: inputTipoUsuario.current.value,
                        Estado: selectEstado.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);

                    BuscarListatiposUsuario();
                    setmostrarModaltipoUsuario(false);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const ModificarTipousuario = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarFormularioTipoUsuario()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/TipoUsuario/ModificarTipoUsuario`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        IdTipoUsuario: tipoUsuarioActual.id,
                        Tiposuario: inputTipoUsuario.current.value,
                        Estado: selectEstado.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);

                    BuscarListatiposUsuario();
                    setmostrarModaltipoUsuario(false);
                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const HabilitarModificarTipoUsuario = () => {
        setaccionActual("MODIFICAR");
        inputTipoUsuario.current.disabled = false;
        selectEstado.current.disabled = false;
    }
    const ActualizarInputBuscar = () => {
        if (inputBuscar.current.value.trim() === '') {
            BuscarListatiposUsuario();
        }
    }

    //Modal Permisos
    const AbrirModalPermisos = async (row) => {
        settipoUsuarioActual(row);
        BuscarListaPantallasActivos();
        setlistaPermisosActual([]);
        await setmostrarModalPermisos(true);
        if (row) {
            inputTipoUsuario.current.value = row.tipo_usuario;
            selectEstado.current.value = row.estado
            BuscarPermisosTipoUsuario(row.id);
        }

    }

    const BuscarListaPantallasActivos = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            let url = "";
            url = `${config.endPoint}api/Pantalla/ListarActivos`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({})
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                setlistaPantallaActivos(data.content);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
            });
        }

    }

    const BuscarPermisosTipoUsuario = (idtipousuario) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/Permiso/BuscarIdTipoUsuario/${idtipousuario}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({})
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                setlistaPermisosActual(data.content);
                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const ValidarAgregarPermiso = () => {
        let error = 0;
        if (selectPantalla.current.value == '') {
            selectPantalla.current.classList.add('is-invalid');
            error++
        } else {
            selectPantalla.current.classList.remove('is-invalid');
        }
        if (error > 0) {
            return false
        }
        return true;
    }

    const AgregarPermnisoTipoUsuario = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarAgregarPermiso()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Permiso/AgregarPermiso`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        IdtipoUsuario: tipoUsuarioActual.id,
                        IdPantalla: selectPantalla.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        BuscarPermisosTipoUsuario(tipoUsuarioActual.id);
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message);
                    setabrirToast(true);

                    setmostrarCircularProgress(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    const CambiarEstadoPermiso = (estado, row) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/Permiso/CambiarEstadoPermiso`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    IdPermiso: row.id_permiso,
                    Estado: estado
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.success) {
                    settituloToast("Correcto");
                    settipoToast("success");
                    BuscarPermisosTipoUsuario(tipoUsuarioActual.id);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                }
                setmensajeToast(data.message);
                setabrirToast(true);

                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        }
    }

    const EliminarPermiso = (row) => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/Permiso/EliminarPermiso`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    IdPermiso: row.id_permiso
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.success) {
                    settituloToast("Correcto");
                    settipoToast("success");
                    BuscarPermisosTipoUsuario(tipoUsuarioActual.id);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                }
                setmensajeToast(data.message);
                setabrirToast(true);

                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración / Permisos'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Configuración / Permisos</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/configuracion') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4} className=''>
                            <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputBuscar} placeholder='Valor a buscar' size='sm'></Form.Control>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button onClick={() => { BuscarListatiposUsuario() }} variant='primary' size='sm'>
                                Buscar
                            </Button>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : 'text-end'}>
                            <Button variant='primary' size='sm' onClick={() => { AbrirModalTipoUsuario("AGREGAR", null) }}>Agregar <AddIcon fontSize='small'></AddIcon></Button>
                        </Col>
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={dataTipoUsuario}
                            columns={columns}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            getCellClassName={(params) => {
                                if (params.colDef.field == "estado") {
                                    if (params.row.estado == "ACTIVO") {
                                        return 'text-primary';
                                    } else {
                                        return 'text-danger';
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>



            {/* Modales  tipo usuario*/}
            <Modal
                show={mostrarModaltipoUsuario}
                onHide={() => { setmostrarModaltipoUsuario(false) }}
                keyboard={false}
                size="md"
            >
                <Modal.Header>
                    <Modal.Title>
                        Tipo Usuario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Tipo Usuario</small>
                                    <Form.Control ref={inputTipoUsuario} type="text" placeholder='Ingrese Tipo Usuario' ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <select ref={selectEstado} className="form-select" >
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                </Form.Group>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <Button size='sm' variant="outline-primary" onClick={() => { }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end '>
                                {
                                    accionActual == 'VER' ?
                                        <Button size='sm' onClick={() => { HabilitarModificarTipoUsuario() }} variant="primary">Modificar</Button>
                                        : <Button size='sm' onClick={() => { Guardar() }} variant="primary">Guardar</Button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {/* Modal Permisos */}
            <Modal
                show={mostrarModalPermisos}
                onHide={() => { setmostrarModalPermisos(false) }}
            >
                <Modal.Header>
                    <Modal.Title>Permisos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <small className="text-primary">Tipo de Usuario</small>
                                <Form.Control ref={inputTipoUsuario} size='sm' disabled type="text"></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Estado</small>
                                <Form.Control ref={selectEstado} size='sm' disabled type="text"  ></Form.Control>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <small className="text-primary">Vista / Pantalla / Menu</small>
                                <Stack direction='horizontal' gap={2} >
                                    <select ref={selectPantalla} className="form-select form-select-sm" defaultValue="">
                                        <option value="" disabled>Seleccione Pantalla/Acceso</option>
                                        {
                                            listaPantallaActivos.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.id_pantalla}>{row.pantalla_nombre}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <Button size='sm' onClick={() => { AgregarPermnisoTipoUsuario() }} variant='primary'>Agregar</Button>
                                </Stack>
                            </Col>
                        </Row>
                    </Container>

                    <TableContainer style={{ maxHeight: '300px' }}>

                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Pantalla / Acceso</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    listaPermisosActual.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell >
                                                    <Container fluid>
                                                        <Row>
                                                            <Col>
                                                                <Stack gap={4} direction='horizontal'>
                                                                    <Tooltip title="Eliminar permiso">
                                                                        <IconButton onClick={() => { EliminarPermiso(row) }}>
                                                                            <DeleteForeverIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Cambiar estado">
                                                                        {
                                                                            row.estado === 'ACTIVO' ?
                                                                                <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                                    <input onClick={() => { CambiarEstadoPermiso("INACTIVO", row) }} className="form-check-input" type="checkbox" defaultChecked />
                                                                                </div> :
                                                                                <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                                    <input onClick={() => { CambiarEstadoPermiso("ACTIVO", row) }} className="form-check-input" type="checkbox" />
                                                                                </div>
                                                                        }

                                                                    </Tooltip>
                                                                </Stack>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </TableCell>
                                                <TableCell>
                                                    {row.pantalla_nombre}
                                                </TableCell>
                                            </TableRow>

                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col className='text-end'>
                                <Button size='sm' onClick={() => { setmostrarModalPermisos(false) }} variant="outline-primary">Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}

export default ConfiguracionTipoUsuario;