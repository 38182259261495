import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Button, Modal, Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { Snackbar, Alert, AlertTitle, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DataGrid,esES } from '@mui/x-data-grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import GradingIcon from '@mui/icons-material/Grading';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PasswordIcon from '@mui/icons-material/Password';

import { ValidarUsuario, config, ObtenerUsuario, useWindowSize,listarPermisos,ValidarPermisos,LIST_SCREENS } from '../../config/utils';
import Header from '../dashboard/Header';
import PaginaCarga from '../../config/PaginaCarga';
import { Title } from '@mui/icons-material';

function ConfiguracionTrabajador(props) {
    const navigate = useNavigate();
    //Toast
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const size = useWindowSize();

    //
    const [dataTrabajadores, setdataTrabajadores] = useState([]);
    const [dataTiposUsuario, setdataTiposUsuario] = useState([]);
    const [trabajadorActual, settrabajadorActual] = useState({});
    const [accionActual, setaccionActual] = useState('');

    //modales
    const [mostarModalUsuario, setmostarModalUsuario] = useState(false);
    const [mostrarcontra, setmostrarcontra] = useState(false);
    const [controlesusuarioreadonly, setcontrolesusuarioreadonly] = useState(false);
    const [mostrarModalDetallaTrabajador, setmostrarModalDetallaTrabajador] = useState(false);
    const [mostrarModalCambiarPassword, setmostrarModalCambiarPassword] = useState(false);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const inputBuscar = useRef();
    const inputNombreTrabajador = useRef();
    const inputUsuario = useRef();
    const inputPassword = useRef();
    const selectTipoUsuario = useRef();
    const selectEstado = useRef();


    const columns = [
        {

            field: 'accion', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Editar */}
                        <Tooltip title="Modificar Datos Trabajador">
                            <IconButton onClick={() => {
                                AbrirModalModificarTrabajador(cellValues)
                            }}>
                                <EditIcon fontSize='small'></EditIcon>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Cambiar Contraseña">
                            <IconButton onClick={() => {
                                AbrirModalCambiarPassword(cellValues);
                            }}>
                                <PasswordIcon fontSize='small'></PasswordIcon>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        },
        { field: 'trabajador_nombre', headerName: 'Nombres', flex: 1, headerClassName: 'header-colum-gray' },
        {
            field: 'usuario', headerName: 'Usuario', with: '100px', flex: 1, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Usuario">
                            <IconButton onClick={() => { MostrarModalUsuario(cellValues) }}>
                                <PersonIcon fontSize='inherit' color='primary'></PersonIcon>
                            </IconButton>
                        </Tooltip>
                            <span>{cellValues.row.usuario}</span>
                        
                    </>
                )
            }
        },
        { field: 'tipo_usuario', headerName: 'Tipo Usuario', flex: 1, headerClassName: 'header-colum-gray' },
        { field: 'trabajador_estado', headerName: 'Estado', flex: 1, headerClassName: 'header-colum-gray' },
    ];

    useEffect(() => {
        ValidarAccesos();
    }, [])

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_PERSONAL, permisos)) {
                    BuscarListaTrabajadores();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }       
    }

    const BuscarListaTrabajadores = () => {
        if (!ValidarUsuario()) {
            navigate("/")
        } else {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/usuario/ListarTrabajadores`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ valorBuscar: inputBuscar.current.value })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                // console.log(data);
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: index,
                            id_tipo_usuario: elemento.id_tipo_usuario,
                            usuario: elemento.usuario,
                            trabajador_nombre: elemento.trabajador_nombre,
                            tipo_usuario: elemento.tipo_usuario,
                            trabajador_estado: elemento.trabajador_estado
                        }
                    );
                });

                setdataTrabajadores(DataTemp);
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }

    const BuscarListaTiposUsuario = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            let url = "";
            url = `${config.endPoint}api/TipoUsuario/listaActivos`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({})
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                console.log(data);
                setdataTiposUsuario(data.content);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
            });
        }
    }

    const ValidarAgregarTrabajador = () => {
        let errores = 0;
        if (inputNombreTrabajador.current.value == '') {
            inputNombreTrabajador.current.classList.add('is-invalid');
            errores++;
        } else {
            inputNombreTrabajador.current.classList.remove('is-invalid');
        }

        if (inputUsuario.current.value == '') {
            inputUsuario.current.classList.add('is-invalid');
            errores++;
        } else {
            inputUsuario.current.classList.remove('is-invalid');
        }

        if (inputPassword.current.value == '') {
            inputPassword.current.classList.add('is-invalid');
            errores++;
        } else {
            inputPassword.current.classList.remove('is-invalid');
        }

        if (selectTipoUsuario.current.value == '') {
            selectTipoUsuario.current.classList.add('is-invalid');
            errores++;
        } else {
            selectTipoUsuario.current.classList.remove('is-invalid');
        }

        if (selectEstado.current.value == '') {
            selectEstado.current.classList.add('is-invalid');
            errores++;
        } else {
            selectEstado.current.classList.remove('is-invalid');
        }

        if (errores > 0) {
            return false;
        }
        return true;

    }

    const AbrirModalAgregarTrabajador = (accion) => {
        setmostrarcontra(false);
        BuscarListaTiposUsuario();
        setaccionActual(accion);
        setmostarModalUsuario(true);
//        setmostrarModalDetallaTrabajador(true);
    }

    const Guardar = () => {        
        switch (accionActual) {
            case 'AGREGAR':
                AgregarnuevoTrabajador();
                break;
            case 'ACTUALIZAR_DATOS_USUARIO':
                ActualizarDatosUsuario();
                break;
            case 'MODIFICAR_TRABAJADOR':
                ActualizarDatosTrabajador();
                break;
        }
    }

    const AgregarnuevoTrabajador = () => {
        if (ValidarAgregarTrabajador()) {
            if (VerificarUsuarioExistente()) {
                inputUsuario.current.classList.add("is-invalid");

                settituloToast("Alerta");
                settipoToast("warning");
                setmensajeToast(`Usuario ya existe.`)
                setabrirToast(true);

            } else {
                inputUsuario.current.classList.remove("is-invalid");
                let url = "";
                url = `${config.endPoint}api/usuario/creartrabajador`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        Usuario: inputUsuario.current.value,
                        IdTipoUsuario: selectTipoUsuario.current.value,
                        TrabajadorNombre: inputNombreTrabajador.current.value,
                        TrabajadorEstado: selectEstado.current.value,
                        Password: inputPassword.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                        setmensajeToast(`${data.message}`)
                        setabrirToast(true);
                        BuscarListaTrabajadores();
                        // setmostrarModalDetallaTrabajador(false);
                    }
                    setmostarModalUsuario(false);

                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                });

            }
        }
    }

    const ActualizarDatosUsuario = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            let url = "";
            url = `${config.endPoint}api/usuario/ActualizarDatosUsuario`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idtipousuario: selectTipoUsuario.current.value,
                    usuario: inputUsuario.current.value,
                    estado: selectEstado.current.value,
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.success) {
                    settituloToast("Correcto");
                    settipoToast("success");
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                }
                setmensajeToast(`${data.message}`)
                setabrirToast(true);

                BuscarListaTrabajadores();
                setmostarModalUsuario(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
            });
        }
    }

    const ValidarDatosTrabajador = () => {
        let errores = 0;
        if (inputNombreTrabajador.current.value == '') {
            inputNombreTrabajador.current.classList.add('is-invalid');
            errores++;
        } else {
            inputNombreTrabajador.current.classList.remove('is-invalid');
        }

        if (errores > 0) {
            return false;
        }
        return true;
    }

    const ActualizarDatosTrabajador = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarDatosTrabajador()) {
                let url = "";
                url = `${config.endPoint}api/usuario/ActualizarDatosTrabajador`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        usuario: inputUsuario.current.value,
                        nombres: inputNombreTrabajador.current.value,
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(`${data.message}`)
                    setabrirToast(true);

                    BuscarListaTrabajadores();
                    setmostrarModalDetallaTrabajador()

                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                });
            }
        }
    }

    const VerificarUsuarioExistente = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        }
        else {
            let url = "";
            url = `${config.endPoint}api/usuario/BuscarUsuarioExistente`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ usuario: inputUsuario.current.value })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                return false;
            });
        }
    }

    const HabilitarControles = (estado) => {
        inputNombreTrabajador.current.disabled = estado;
        inputUsuario.current.disabled = estado;
        inputPassword.current.disabled = estado;
        selectTipoUsuario.current.disabled = estado;
        selectEstado.current.disabled = estado;
    }

    const MostrarModalUsuario = async (actual) => {
        // settrabajadorActual(cellValues.row);
        BuscarListaTiposUsuario();
        setaccionActual("VER");
        await setmostarModalUsuario(true)
        //Deshabilitar Controles
        await HabilitarControles(true);

        inputNombreTrabajador.current.value = actual.row.trabajador_nombre;
        inputUsuario.current.value = actual.row.usuario;
        inputPassword.current.value = '********';
        selectTipoUsuario.current.value = actual.row.id_tipo_usuario;
        selectEstado.current.value = actual.row.trabajador_estado;
    }

    const ModificarUsuario = () => {
        setaccionActual("ACTUALIZAR_DATOS_USUARIO");
        selectTipoUsuario.current.disabled = false;
        selectEstado.current.disabled = false;
    }

    const AbrirModalCambiarPassword = async (actual) => {
        settrabajadorActual(actual.row);
        await setmostrarModalCambiarPassword(true);

        inputNombreTrabajador.current.value = actual.row.trabajador_nombre;
        inputNombreTrabajador.current.disabled = true;
        inputUsuario.current.value = actual.row.usuario;
        inputUsuario.current.disabled = true;
    }

    const ValidarModalCambiarContraseña = () => {
        let errores = 0;

        if (inputPassword.current.value.trim() == '') {
            inputPassword.current.classList.add('is-invalid');
            errores++;
        } else {
            inputPassword.current.classList.remove('is-invalid');
        }


        if (errores > 0) {
            return false;
        }
        return true;

    }

    const CambiarContraseña = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarModalCambiarContraseña()) {
                let url = "";
                url = `${config.endPoint}api/usuario/cambiarpassword`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        Usuario: inputUsuario.current.value,
                        Password: inputPassword.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("danger");
                    }
                    setmensajeToast(`${data.message}`)
                    setabrirToast(true);
                    setmostrarModalDetallaTrabajador(false);
                    setmostrarModalCambiarPassword(false);
                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                });
            }
        }
    }

    const ActualizarInputBuscar = () => {
        if (inputBuscar.current.value.trim() === '') {
            BuscarListaTrabajadores();
        }
    }

    const AbrirModalModificarTrabajador = async (actual) => {
        setaccionActual("VER");
        await setmostrarModalDetallaTrabajador(true);
        //Llenar Datos
        inputNombreTrabajador.current.value = actual.row.trabajador_nombre;
        inputNombreTrabajador.current.disabled = true;
        inputUsuario.current.value = actual.row.usuario;
        inputUsuario.current.disabled = true;
    }

    const ModificarDatosTrabajador = () => {
        setaccionActual("MODIFICAR_TRABAJADOR");
        inputNombreTrabajador.current.disabled = false;
    }

    const MostrarModalAgregarTrabajador = () => {
        setaccionActual("AGREGAR");
        setmostarModalUsuario("AGREGAR");
    }




    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración / Trabajador'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Configuración / Trabajador</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/configuracion') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>


            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2' : ''}>
                            <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputBuscar} placeholder='Valor a buscar' size='sm'></Form.Control>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button onClick={() => { BuscarListaTrabajadores() }} variant='primary' size='sm'>
                                Buscar
                            </Button>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : 'text-end'}>
                            <Button variant='primary' size='sm' onClick={() => { AbrirModalAgregarTrabajador('AGREGAR') }}>Agregar <AddIcon fontSize='inherit'></AddIcon></Button>
                        </Col>
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={dataTrabajadores}
                            columns={columns}
                            getCellClassName={(params) => {
                                if (params.colDef.field == "trabajador_estado") {
                                    if (params.row.trabajador_estado == "ACTIVO") {
                                        return 'text-primary';
                                    } else {
                                        return 'text-danger';
                                    }
                                }
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </div>
            </div>


            {/* Modal Usuario */}
            <Modal
                show={mostarModalUsuario}
                onHide={() => { setmostarModalUsuario(false) }}
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>
                        {`Usuario`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombres Trabajador</small>
                                    <Form.Control ref={inputNombreTrabajador} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Usuario</small>
                                <Stack gap={2} direction='horizontal'>
                                    <Form.Control ref={inputUsuario} type='text' placeholder='Ingrese Usuario'></Form.Control>
                                    <IconButton onClick={() => { }} title='Cambiar Usuario'>
                                        <DriveFileRenameOutlineIcon />
                                    </IconButton>
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Contraseña</small>
                                <Stack gap={2} direction='horizontal'>
                                    <Form.Control ref={inputPassword} type={!mostrarcontra ? 'password' : 'text'} placeholder='Ingrese Contraseña'></Form.Control>
                                    <IconButton onClick={() => { setmostrarcontra(!mostrarcontra) }} title="Mostrar/Ocultar Contraseña">
                                        {
                                            mostrarcontra ?
                                                <VisibilityOffIcon />
                                                :
                                                <VisibilityIcon />
                                        }
                                    </IconButton>
                                    {
                                        controlesusuarioreadonly ?
                                            <IconButton onClick={() => { }} title='Cambiar Contraseña'>
                                                <SyncLockIcon />
                                            </IconButton>
                                            : <></>
                                    }
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Tipo Usuario</small>
                                <Stack gap={2} direction='horizontal'>
                                    <select ref={selectTipoUsuario} className="form-select " defaultValue="">
                                        <option value="" disabled>Seleccione Tipo Usuario</option>
                                        {
                                            dataTiposUsuario.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.id_tipo_usuario}>{row.tipo_usuario}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Stack>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <select ref={selectEstado} className="form-select" defaultValue="">
                                        <option value="" disabled>Elija el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <Button size='sm' onClick={() => { setmostarModalUsuario(false) }} variant="outline-primary">Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                {
                                    accionActual == 'VER' ?
                                        <Button size='sm' onClick={() => { ModificarUsuario() }} variant="primary">Modificar</Button>
                                        :
                                        <Button size='sm' onClick={() => { Guardar() }} variant="primary">Guardar</Button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>


            {/* Modales  Trabajador*/}
            <Modal
                show={mostrarModalDetallaTrabajador}
                onHide={() => { setmostrarModalDetallaTrabajador(false) }}
                keyboard={false}
                size="md"
            >
                <Modal.Header>
                    <Modal.Title>
                        {`Trabajador`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombres Trabajador</small>
                                    <Form.Control ref={inputNombreTrabajador} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Usuario</small>
                                <Stack gap={2} direction='horizontal'>
                                    <Form.Control ref={inputUsuario} type='text' placeholder='Ingrese Usuario'></Form.Control>
                                    {/* <IconButton onClick={() => { }} title='Cambiar Usuario'>
                                        <DriveFileRenameOutlineIcon />
                                    </IconButton> */}
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <Button size='sm' variant="outline-primary" onClick={() => { setmostrarModalDetallaTrabajador(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end '>
                                {
                                    accionActual == 'VER' ?
                                        <Button size='sm' onClick={() => { ModificarDatosTrabajador() }} variant="primary">Modificar</Button>
                                        :
                                        <Button size='sm' onClick={() => { Guardar() }} variant="primary">Guardar</Button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {/* Modales Cambiar Contraseña*/}
            <Modal
                show={mostrarModalCambiarPassword}
                onHide={() => { setmostrarModalCambiarPassword(false) }}
                keyboard={false}
                size="md"
            >
                <Modal.Header>
                    <Modal.Title>
                        {`Cambiar Contraseña`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombres Trabajador</small>
                                    <Form.Control ref={inputNombreTrabajador} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Usuario</small>
                                <Stack gap={2} direction='horizontal'>
                                    <Form.Control ref={inputUsuario} type='text' placeholder='Ingrese Usuario'></Form.Control>
                                    {/* <IconButton onClick={() => { }} title='Cambiar Usuario'>
                                        <DriveFileRenameOutlineIcon />
                                    </IconButton> */}
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Contraseña</small>
                                <Stack gap={2} direction='horizontal'>
                                    <Form.Control ref={inputPassword} type={!mostrarcontra ? 'password' : 'text'} placeholder='Ingrese Contraseña'></Form.Control>
                                    <IconButton onClick={() => { setmostrarcontra(!mostrarcontra) }} title="Mostrar/Ocultar Contraseña">
                                        {
                                            !mostrarcontra ?
                                                <VisibilityOffIcon />
                                                :
                                                <VisibilityIcon />
                                        }
                                    </IconButton>
                                    {
                                        controlesusuarioreadonly ?
                                            <IconButton onClick={() => { }} title='Cambiar Contraseña'>
                                                <SyncLockIcon />
                                            </IconButton>
                                            : <></>
                                    }
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <Button size='sm' variant="outline-primary" onClick={() => { setmostrarModalCambiarPassword(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end '>
                                <Button size='sm' onClick={() => { CambiarContraseña() }} variant="primary">Guardar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}

export default ConfiguracionTrabajador;