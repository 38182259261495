import React, { Fragment, useEffect, useRef, useState } from 'react';
import Header from '../../dashboard/Header'

import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import TableB from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import moment from 'moment-timezone';
//Material UI

import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Badge, IconButton, Snackbar, Alert, AlertTitle, Tooltip } from '@mui/material';
import StackMU from '@mui/material/Stack';

import ButtonMUI from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PolicyIcon from '@mui/icons-material/Policy';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SmsIcon from '@mui/icons-material/Sms';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

//Autocomplete
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//Importaciones
import PaginaCarga from '../../../config/PaginaCarga';
import { ValidarUsuario, ObtenerUsuario, config,listarPermisos,LIST_SCREENS,ValidarPermisos, GuardarEnStorage } from '../../../config/utils';

//ChartJs
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

function DetalleBalance() {
    ChartJS.register(...registerables);
    const navigate = useNavigate();

    //toast y progress Bar
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [mostrarBarraProgreso, setmostrarBarraProgreso] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //Parametros
    const { idbalance } = useParams();

    //Variables
    const [objetoMenu, setobjetoMenu] = useState(null);
    const [balance, setbalance] = useState({});
    const [suministrosBalance, setsuministrosBalance] = useState();
    const [agrupaciones, setagrupaciones] = useState()
    const [listaTotalizadores, setlistaTotalizadores] = useState([]);
    const [listaSuministros, setlistaSuministros] = useState([]);
    const [detalleDeteccionesSuministro, setdetalleDeteccionesSuministro] = useState([]);
    const [detalleRecuperoSuministro, setdetalleRecuperoSuministro] = useState([]);
    const [detalleConsumosSuministro, setdetalleConsumosSuministro] = useState([]);
    const [dataChartsLectura, setdataChartsLectura] = useState(null);
    const [dataChartsConsumo, setdataChartsConsumo] = useState(null);
    const [suministroActual, setsuministroActual] = useState('');
    const [listaMicrobalancesProgramados, setlistaMicrobalancesProgramados] = useState([]);
    const [listaObservacionesBalance, setlistaObservacionesBalance] = useState([]);

    //Modales
    const [mostrarModalObservacion, setmostrarModalObservacion] = useState(false);
    const [mostrarModalProgramarMicrobalance, setmostrarModalProgramarMicrobalance] = useState(false);
    const [mostrarModalObservaciones, setmostrarModalObservaciones] = useState(false);
    const [mostrarModalModificarSuministro, setmostrarModalModificarSuministro] = useState(false);
    const [abrirModalDetalleDeteccion, setabrirModalDetalleDeteccion] = useState(false);
    const [abrirModalDetalleRecupero, setabrirModalDetalleRecupero] = useState(false);
    const [abrirModalResumeConsumo, setabrirModalResumeConsumo] = useState(false);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const selectVitaBalance = useRef();
    const inputObservacion = useRef();

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = async() => {
        await BuscarDetalleBalance().then();
                    BuscarListaMicrobalancesProgramados();
                    BuscarListaObservaciones();
        // if (!ValidarUsuario()) {
        //     navigate('/');
        // } else {
        //     //Validar Permisos
        //     listarPermisos().then(async(permisos) => {
        //         setlistapermisos(permisos);
        //         if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_DETALLE, permisos)) {
        //             await BuscarDetalleBalance().then();
        //             BuscarListaMicrobalancesProgramados();
        //             BuscarListaObservaciones();
        //         } else {
        //             settituloToast("Alerta");
        //             settipoToast("warning");
        //             setmensajeToast("No tiene permisos para acceder a este Recurso.");
        //             setabrirToast(true);
        //             setTimeout(() => {
        //                 navigate('/');
        //             }, 1000);
        //         }
        //     });
        // }
    }

    //Procedimientos

    const BuscarDetalleBalance = () => {
        return new Promise((resolve, reject) => {
            if (!ValidarUsuario()) {
                navigate("/");
            } else {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/balance/DetalleBalance`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({ idbalance: idbalance })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {

                        let balance = data.content.balance[0];
                        balance.consumoUsuario = balance.sumaAp + balance.sumaComun + balance.sumaGi;
                        balance.diferencia = balance.sumaTotalizador - balance.consumoUsuario;
                        if (isNaN(parseFloat(balance.porcentajePerdida))) {
                            balance.porcentajePerdida = balance.porcentajePerdida;
                        } else {
                            balance.porcentajePerdida = Math.round((parseFloat(balance.porcentajePerdida) + Number.EPSILON) * 100) / 100;
                        }
                        balance.perdidaXusuario = Math.round((parseFloat(balance.diferencia / balance.sedUsuarios) + Number.EPSILON) * 100) / 100;

                        setbalance(balance);
                        setsuministrosBalance(data.content.suministros);
                        setagrupaciones(data.content.agrupaciones);

                        //Buscar totalizadores y Valores
                        let _totalizador = data.content.agrupaciones.find(x => x.nombre_agrupacion == 'TOTALIZADOR');
                        if (_totalizador) {
                            let splitElementosTotalizador = _totalizador.elementos.split(',');

                            // buscar elementos que son TOTALIZADOR
                            let elementosTotalizador = [];
                            splitElementosTotalizador.forEach(splitElementos => {
                                let elemetosEncontrados = data.content.suministros.filter(y => y.tarifa == splitElementos);
                                elementosTotalizador = elemetosEncontrados;
                            });

                            setlistaTotalizadores(elementosTotalizador)
                            // console.log(elementosTotalizador);


                            //Filtrar elementos que no son TOTALIZADOR
                            let elementossuministros = [];
                            splitElementosTotalizador.forEach(splitElementos => {
                                let elemetosEncontrados = data.content.suministros.filter(y => y.tarifa !== splitElementos);
                                elementossuministros = elemetosEncontrados;
                            });

                            let _listaSuministros = [];
                            elementossuministros.forEach((suministro, index) => {
                                let nsuministro = {
                                    item: index + 1,
                                    id: suministro.id_base_suministro,
                                    sistema_electrico: suministro.sistema_electrico,
                                    zona_administrativa: suministro.zona_administrativa,
                                    codigo_ruta: suministro.codigo_ruta,
                                    codigo_suministro: suministro.codigo_suministro,
                                    nombre_suministro: suministro.nombre_suministro,
                                    direccion_suministro: suministro.direccion_suministro,
                                    tarifa: suministro.tarifa,
                                    sed_suministro: suministro.sed_suministro,
                                    set_suministro: suministro.set_suministro,
                                    amt_suministro: suministro.amt_suministro,
                                    direccion_electrica: suministro.direccion_electrica,
                                    cea_consumo: suministro.cea_consumo,
                                    periodo_comercial: suministro.periodo_comercial,
                                    fecha_lectura_mes_actual: suministro.fecha_lectura_mes_actual,
                                    codigo_lectura_mes_actual: suministro.codigo_lectura_mes_actual,
                                    fecha_lectura_mes_anterior: suministro.fecha_lectura_mes_anterior,
                                    codigo_lectura_mes_anterior: suministro.codigo_lectura_mes_anterior,
                                    factor_medicion: suministro.factor_medicion,
                                    promedio_6ult_meses: suministro.promedio_6ult_meses,
                                    dias_facturados: suministro.dias_facturados,
                                    intervencion: '0',
                                    deteccion: suministro.deteccion,
                                    recupero: suministro.recupero
                                };
                                _listaSuministros.push(nsuministro);
                            });
                            setlistaSuministros(_listaSuministros);
                        }
                    } else {
                        settituloToast("Alerta");
                        settipoToast("warning");
                        setmensajeToast(data.message)
                        setabrirToast(true);
                    }
                    setmostrarCircularProgress(false);
                    resolve(true);
                }).catch(async (error) => {
                    // console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                    reject(null);
                });
            }
        });
    }

    const columns = [
        // {
        //     field: 'accion', headerName: '*', minWidth: 40, flex: 0.3, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
        //         console.log(cellValues);
        //         return (
        //             <>
        //                 {/* Programacion Microbalance */}
        //                 <IconButton onClick={() => {
        //                     setmostrarModalModificarSuministro(true)
        //                 }}>
        //                     <EditIcon fontSize='small'></EditIcon>
        //                 </IconButton>
        //             </>
        //         );
        //     }
        // },
        {
            field: 'item', headerName: 'ITEM', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <ButtonMUI className='text-decoration-underline text-primary'>{cellValues.row.item}</ButtonMUI>
                )
            }
        },
        { field: 'codigo_suministro', headerName: 'Suministro', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'tarifa', headerName: 'Tarifa', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'factor_medicion', headerName: 'Factor Medicion', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'cea_consumo', headerName: 'Consumo', minWidth: 100, flex: 1, headerClassName: 'header-colum-table' },
        {
            field: 'intervencion', headerName: '*', minWidth: 100, flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                // console.log(cellValues);
                return (
                    <>
                        {
                            cellValues.row.intervencion === '1' ?
                                <IconButton>
                                    <LeaderboardIcon color='error' fontSize='small'></LeaderboardIcon>
                                </IconButton>
                                :
                                <Tooltip title='Resumen Consumos'>
                                    <IconButton onClick={() => { BuscarResumenConsumos(cellValues.row.codigo_suministro) }}>
                                        <LeaderboardIcon color='primary' fontSize='small'></LeaderboardIcon>
                                    </IconButton>
                                </Tooltip>
                        }
                        {
                            cellValues.row.deteccion > 0 ?
                                <Tooltip title="Detecciones">
                                    <IconButton onClick={() => { BuscarDetalleDeteccion(cellValues.row.codigo_suministro) }}>
                                        <Badge badgeContent={cellValues.row.deteccion}>
                                            <PolicyIcon color='error' fontSize='small'></PolicyIcon>
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                : <></>
                        }

                        {
                            cellValues.row.recupero > 0 ?
                                <Tooltip title="Recupero">
                                    <IconButton onClick={() => { BuscarDetalleRecupero(cellValues.row.codigo_suministro) }}>
                                        <Badge badgeContent={cellValues.row.recupero}>
                                            <CurrencyExchangeIcon color='error' fontSize='small'></CurrencyExchangeIcon>
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                : <></>
                        }
                    </>
                )
            }
        }
    ];

    const AbrirMenu = (event) => {
        setobjetoMenu(event.currentTarget);
    };

    const CerrarMenu = () => {
        setobjetoMenu(null);
    };

    const CambiarVistaBalance = (value) => {
        //Almacenar IdBalance en memoria
        GuardarEnStorage(config.storageidBalance,idbalance);
        // navigate(`/balance/detalle/${idbalance}/${value.target.value}`);
        navigate(`/balance/microbalance/detalle/${value.target.value}`)
    }

    //Buscar Resumentes
    const BuscarDetalleDeteccion = (suministro) => {
        setsuministroActual(suministro);
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/deteccion/buscar`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ suministro: suministro })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    setdetalleDeteccionesSuministro(data.content);
                    setabrirModalDetalleDeteccion(true);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${data.message}`)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                // console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const BuscarDetalleRecupero = (suministro) => {
        setsuministroActual(suministro);
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/recupero/buscar`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ suministro: suministro })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                console.log(data);
                if (data.success) {
                    setdetalleRecuperoSuministro(data.content);
                    setabrirModalDetalleRecupero(true);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${data.message}`)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                // console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const BuscarResumenConsumos = (suministro) => {
        setsuministroActual(suministro);
        setdataChartsLectura(null);
        setdataChartsConsumo(null);
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/consumosuministro/resumen`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ suministro: suministro })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {

                    let arrayLabels = [];
                    let arrayLecturas = [];
                    let arrayPromedio = []
                    let arrayConsumos = []

                    for (let x = data.content.length - 1; x >= 0; x--) {
                        arrayLabels.push(data.content[x].periodo_comercial);
                        arrayLecturas.push(data.content[x].utlima_lectura);
                        arrayConsumos.push(data.content[x].cea_consumo);
                        arrayPromedio.push(data.content[x].promedio);
                    }

                    let dataChartLecturaTemp = {
                        // labels: ['RECLAMOS', 'NUEVOS SUMINISTROS', 'MANTENIMINETOS', 'AP Y TOTALIZADRORES', 'CONTRASTES', 'ZONA PEDREGAL', 'ZONAL CAMANA'],
                        labels: arrayLabels,
                        datasets: [
                            {
                                type: 'line',
                                label: 'Lecturas',
                                backgroundColor: 'rgba(54, 162, 235,0.3)',
                                borderColor: 'rgba(54, 162, 235,0.9)',
                                borderWidth: 2,
                                fill: false,
                                data: arrayLecturas
                                // data: [85, 66, 45, 29, 37, 115, 96],
                            },
                        ],
                    };

                    let dataChartConsumosTemp = {
                        // labels: ['RECLAMOS', 'NUEVOS SUMINISTROS', 'MANTENIMINETOS', 'AP Y TOTALIZADRORES', 'CONTRASTES', 'ZONA PEDREGAL', 'ZONAL CAMANA'],
                        labels: arrayLabels,
                        datasets: [
                            {
                                type: 'bar',
                                label: 'Consumo',
                                backgroundColor: 'rgba(255, 99, 132,0.3)',
                                borderColor: 'rgba(255, 99, 132)',
                                borderWidth: 2,
                                data: arrayConsumos
                                // data: [86, 60, 20, 20, 37, 115, 83],
                                // borderColor: 'white',
                            },
                            {
                                type: 'line',
                                label: 'Promedio Consumo',
                                backgroundColor: 'rgba(54, 162, 235,0.3)',
                                borderColor: 'rgba(54, 162, 235,0.9)',
                                borderWidth: 2,
                                data: arrayPromedio
                                // data: [86, 60, 20, 20, 37, 115, 83],
                                // borderColor: 'white',
                            }
                        ],
                    };

                    setdataChartsLectura(dataChartLecturaTemp);
                    setdataChartsConsumo(dataChartConsumosTemp);


                    setdetalleConsumosSuministro(data.content);
                    setabrirModalResumeConsumo(true);
                } else {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${data.message}`)
                    setabrirToast(true);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                // console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }

    // Procedimientos Microbalance
    const ProgramarMicrobalance = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/crear`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idbalance: idbalance,
                    sed: balance.sedCodigo,
                    periodocomercial: balance.mesProceso
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    settituloToast("Correcto");
                    settipoToast("success");
                    setmostrarModalProgramarMicrobalance(false);
                    //Actualizar
                    BuscarListaMicrobalancesProgramados();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                }
                setmensajeToast(data.message)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    const BuscarListaMicrobalancesProgramados = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/Microbalance/BuscarMicrobalanceIdBalance/${idbalance}`;

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.success) {
                    setlistaMicrobalancesProgramados(data.content);
                }
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                // console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    //Observaciones
    const ValidarControlesObservacion = () => {
        let error = 0;
        if (inputObservacion.current.value == '') {
            inputObservacion.current.classList.add('is-invalid');
            error++;
        } else {
            inputObservacion.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;

    }
    const AgregarObservacionBalance = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarControlesObservacion()) {
                setmostrarCircularProgress(true);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = `${config.endPoint}api/ObservacionBalance/agregar`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        IdCalculoBalance: idbalance,
                        Observacion: inputObservacion.current.value,
                        Estado: 'REGISTRADO'
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        //actualizar
                        BuscarListaObservaciones();
                        setmostrarModalObservacion(false);
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }
                    setmensajeToast(data.message);
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                }).catch(async (error) => {
                    // console.log(error);
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }
    const BuscarListaObservaciones = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {

            setmostrarCircularProgress(true);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/ObservacionBalance/listarobservacion/${idbalance}`;

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                setlistaObservacionesBalance(data.content);
                setmostrarCircularProgress(false);
            }).catch(async (error) => {
                // console.log(error);
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer}; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Balance / Detalle'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Balance / Detalle</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/balance/lista') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>


            {/* Tabla de Datos Suministros */}
            <div className='container border rounded mt-2'>
                <Container className='mt-2'>
                    <Row className='mb-1'>
                        <Col md={4}>
                            <Container fluid>
                                <Row>
                                    <Col>

                                        <Form.Label>{`SED: ${balance.sedCodigo} ; Periodo: ${balance.mesProceso}`}</Form.Label>
                                        {/* Mensajes */}
                                        <IconButton onClick={() => { setmostrarModalObservaciones(true) }}>
                                            <Badge badgeContent={listaObservacionesBalance.length} color="secondary">
                                                <SmsIcon></SmsIcon>
                                            </Badge >
                                        </IconButton>

                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col className='d-grid'>
                                        <Button variant='outline-primary' size='sm' onClick={() => { setmostrarModalProgramarMicrobalance(true) }}>Programar Microbalance</Button>
                                    </Col>
                                </Row>
                                <Row className='my-1'>
                                    <Col>
                                        <Form.Select ref={selectVitaBalance} size='sm' onChange={(value) => { CambiarVistaBalance(value) }} defaultValue=''>
                                            <option value=''>{`Seleccione Elemento`}</option>
                                            {
                                                listaMicrobalancesProgramados.map((row, index) => {
                                                    return (
                                                        <option key={row.id_microbalance} value={row.id_microbalance}>{`MICROBALANCE_${index + 1}`}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                </Row>

                            </Container>
                        </Col>
                        <Col md={4} lg={3}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td className='tabletd'>CONS TOT</td>
                                        <td className='tabletd'>{balance.sumaTotalizador}</td>
                                        <td className='fw-bold tabletd'>KWH</td>
                                    </tr>
                                    <tr>
                                        <td className='tabletd'>CONS USU</td>
                                        <td className='tabletd'>{balance.consumoUsuario}</td>
                                        <td className='fw-bold tabletd'>KWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>DIF</td>
                                        <td className='tabletd'>{balance.diferencia}</td>
                                        <td className='fw-bold tabletd'>KWH</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>% PERD</td>
                                        <td style={{ color: 'red' }} className='tabletd'>{balance.porcentajePerdida}</td>
                                        <td className='fw-bold tabletd'>%</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>#SUM</td>
                                        <td className='tabletd'>{balance.cantidadSuministros}</td>
                                        <td className='fw-bold tabletd'>Sum</td>
                                    </tr>
                                    <tr style={{ backgroundColor: 'rgb(255,255,195)' }}>
                                        <td className='tabletd'>%PERD./USU</td>
                                        <td className='tabletd'>{balance.perdidaXusuario}</td>
                                        <td className='fw-bold tabletd'>Kwh/Sum</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>

                        <Col className='text-end'>
                            <ButtonMUI variant="text"
                                onClick={AbrirMenu}>
                                Opciones
                                <KeyboardArrowDownIcon />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={objetoMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(objetoMenu)}
                                onClose={CerrarMenu}
                            >
                                <MenuItem onClick={() => { CerrarMenu(); setmostrarModalObservacion(true) }}>Agregar Observación</MenuItem>
                                <MenuItem onClick={() => { CerrarMenu(); setmostrarModalProgramarMicrobalance(true) }}>Programar Microbalance</MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { CerrarMenu(); BuscarDetalleBalance() }}>Actualizar</MenuItem>
                            </Menu>
                        </Col>
                    </Row>

                </Container>

                <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650, minWidth: '100%' }}>
                    <Table size='small' >
                        <TableHead>
                            <TableRow >
                                {/* <TableCell style={{ width: 60 }} className="header-colum-table">*</TableCell> */}
                                <TableCell className="header-colum-table">ITEM</TableCell>
                                <TableCell className="header-colum-table">Suministro</TableCell>
                                <TableCell className="header-colum-table">Tarifa</TableCell>
                                <TableCell className="header-colum-table">Factor Medicion</TableCell>
                                <TableCell className="header-colum-table">Consumo</TableCell>
                                <TableCell className="header-colum-table">Prom. Consumo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                listaTotalizadores ?
                                    listaTotalizadores.map((row, index) => {

                                        return (
                                            <TableRow key={index}>
                                                {/* <TableCell style={{ fontSize: '0.70em' }}>
                                                    <IconButton onClick={() => { setmostrarModalModificarSuministro(true) }}>
                                                        <EditIcon fontSize='small'></EditIcon>
                                                    </IconButton>
                                                </TableCell> */}
                                                <TableCell style={{ fontSize: '0.70em', width: '100px' }}>
                                                    <ButtonMUI className='text-decoration-underline text-primary'  >TOT</ButtonMUI>
                                                </TableCell>
                                                <TableCell style={{ fontSize: '0.75em' }}>{row.codigo_suministro}</TableCell>
                                                <TableCell style={{ fontSize: '0.75em' }}>{row.tarifa}</TableCell>
                                                <TableCell style={{ fontSize: '0.75em' }}>{row.factor_medicion}</TableCell>
                                                <TableCell style={{ fontSize: '0.75em' }}>{row.cea_consumo}</TableCell>
                                                <TableCell style={{ fontSize: '0.75em' }}>{row.promedio_6ult_meses}</TableCell>
                                            </TableRow>
                                        )
                                    }) :
                                    <></>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className='my-1 mt-3'>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={listaSuministros}
                            columns={columns}

                        />
                    </div>
                </div>
            </div>


            {/* Modal Agregar Observacion */}
            <Modal
                show={mostrarModalObservacion}
                onHide={() => { setmostrarModalObservacion(false) }}
                keyboard={false}
                centered
            >

                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <Container>
                        <h5>Agregar Observacion</h5>
                        <hr></hr>
                        <Row>
                            <Col>
                                <small className='text-primary'>Observación</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control size='sm' ref={inputObservacion} as="textarea" rows={3} placeholder='Observación'></Form.Control>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Button onClick={() => { setmostrarModalObservacion(false) }} variant='outline-primary' size='sm' >Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button size='sm' onClick={() => { AgregarObservacionBalance() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Mensaje Confirmar Programar Microbalance */}
            <Modal
                show={mostrarModalProgramarMicrobalance}
                onHide={() => { setmostrarModalProgramarMicrobalance(false) }}
                keyboard={false}
            >
                <Modal.Body >
                    <Container>
                        <Row className='mb-4'>
                            <Form.Label>
                                {
                                    `¿Estas seguro de generar una programacion de Microbalance para la SED ${balance.sedCodigo}?`
                                }
                            </Form.Label>
                            {/* <small className='text-secondary'>
                                {
                                    `En la(s) SED(s) que tenga una programacion de microbalance activa no se generara una programacion mientras no se cambie o finalice la programacion existente.`
                                }
                            </small> */}
                        </Row>
                        <Row>
                            <Col>
                                <Button size='sm' variant='outline-primary' onClick={()=>{setmostrarModalProgramarMicrobalance(false)}}>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button size='sm' onClick={() => { ProgramarMicrobalance() }}>Aceptar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Ver Observaciones */}
            <Modal
                show={mostrarModalObservaciones}
                onHide={() => { setmostrarModalObservaciones(false) }}
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        Observaciones
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row >
                            <Col>
                                <small className='text-primary'>Observación</small>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <StackMU direction="row" gap={2}>
                                    <Form.Control size='sm' placeholder='Observación'></Form.Control>
                                    <IconButton onClick={() => { setmostrarModalObservacion(true) }}>
                                        <AddIcon></AddIcon>
                                    </IconButton>
                                </StackMU>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <TableContainer style={{ maxHeight: 200, minWidth: '100%' }} >
                                    <Table size='small' stickyHeader>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell style={{ width: '80px', backgroundColor: 'rgb(240,240,240)' }}>Accion</TableCell>
                                                <TableCell style={{ backgroundColor: 'rgb(240,240,240)' }}>Observación</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                listaObservacionesBalance.map((row, index) => {
                                                    return (
                                                        <TableRow key={row.id_observacion_balance}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <StackMU >
                                                                    <IconButton className='mx-2'>
                                                                        <EditIcon fontSize='small'></EditIcon>
                                                                    </IconButton>
                                                                </StackMU>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.observacion}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col className='text-end'>
                                <Button size='sm' variant='outline-primary' onClick={() => { setmostrarModalObservaciones(false) }}>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Modificar Suministro */}
            <Modal
                show={mostrarModalModificarSuministro}
                onHide={() => { setmostrarModalModificarSuministro(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Modificar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col md={4}>
                                <small className='text-primary'>Suministro</small>
                            </Col>
                            <Col>
                                <Form.Control defaultValue={'469365'} disabled size='sm'></Form.Control>
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col md={4}>
                                <small className='text-primary'>TC Primario</small>
                            </Col>
                            <Col>
                                <Form.Control size='sm'></Form.Control>
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col md={4}>
                                <small className='text-primary'>TC Secundario</small>
                            </Col>
                            <Col>
                                <Form.Control size='sm'></Form.Control>
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col md={4}>
                                <small className='text-primary'>Factor de Medicion</small>
                            </Col>
                            <Col>
                                <Form.Control size='sm'></Form.Control>
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col md={4}>
                                <small className='text-primary'>Tarifa</small>
                            </Col>
                            <Col>
                                <Form.Control defaultValue={'BT5H'} size='sm' disabled></Form.Control>
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col md={4}>
                                <small className='text-primary'>Lectura 1</small>
                            </Col>
                            <Col>
                                <Form.Control size='sm'></Form.Control>
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col md={4}>
                                <small className='text-primary'>Lectura 2</small>
                            </Col>
                            <Col>
                                <Form.Control size='sm'></Form.Control>
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col md={4}>
                                <small className='text-primary'>Observacion</small>
                            </Col>
                            <Col>
                                <Form.Control size='sm'></Form.Control>
                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col >
                                <Button variant='outline-primary' onClick={() => { setmostrarModalModificarSuministro(false) }}>Cerrar</Button>
                            </Col>
                            <Col className='text-end'>
                                <Button variant='primary' onClick={() => { setmostrarModalModificarSuministro(false) }}>Actualizar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Detecciones*/}
            <Modal
                show={abrirModalDetalleDeteccion}
                onHide={() => { setabrirModalDetalleDeteccion(false) }}
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Detalle Detecciones suministro ${suministroActual}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        {
                            detalleDeteccionesSuministro.map((row) => {
                                return (
                                    <div key={row.id_deteccion}>
                                        <Row >
                                            <Col>
                                                <Container fluid>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Suministro: </strong>
                                                            <Form.Text className='mx-2'>{row.suministro}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Zona: </strong>
                                                            <Form.Text className='mx-2'>{row.zona}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Sistema: </strong>
                                                            <Form.Text className='mx-2'>{row.sistema}</Form.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>SED: </strong>
                                                            <Form.Text className='mx-2'>{row.sed}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>SET: </strong>
                                                            <Form.Text className='mx-2'>{row.deteccion_set}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Distrito: </strong>
                                                            <Form.Text className='mx-2'>{row.distrito}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Alimentador: </strong>
                                                            <Form.Text className='mx-2'>{row.alimentador}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Fecha Intervención: </strong>
                                                            <Form.Text className='mx-2'>{row.fecha_intervencion}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Observaciones: </strong>
                                                            <Form.Text className='mx-2'>{row.observaciones}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Historia: </strong>
                                                        <Form.Text className='mx-2'>{row.historia}</Form.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Detalle: </strong>
                                                        <Form.Text className='mx-2'>{row.detalle}</Form.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Monto: </strong>
                                                        <Form.Text className='mx-2'>{row.monto}</Form.Text>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Kwh: </strong>
                                                        <Form.Text className='mx-2'>{row.kwh}</Form.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Mes Proceso: </strong>
                                                        <Form.Text className='mx-2'>{row.mes_proceso}</Form.Text>
                                                    </Col>
                                                </Row>



                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Guion: </strong>
                                                        <Form.Text className='mx-2'>{row.guion}</Form.Text>
                                                    </Col>
                                                </Row>




                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Cantidad: </strong>
                                                        <Form.Text className='mx-2'>{row.cantidad}</Form.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <strong className='text-muted'>Area Procedencia: </strong>
                                                        <Form.Text className='mx-2'>{row.area_procedencia}</Form.Text>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                        <hr></hr>
                                    </div>
                                )
                            })
                        }
                        <Row>
                            <Col className='text-end'>
                                <Button size='sm' variant='outline-primary' onClick={() => { setabrirModalDetalleDeteccion(false) }}>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Recuperos */}
            <Modal
                show={abrirModalDetalleRecupero}
                onHide={() => { setabrirModalDetalleRecupero(false) }}
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`Detalle Recupero suministro ${suministroActual}`}

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        {
                            detalleRecuperoSuministro.map((row, index) => {
                                return (
                                    <div key={index}>
                                        <Row>
                                            <Col>
                                                <Container>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Suministro: </strong>
                                                            <Form.Text className='mx-2'>{row.suministro}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Zona: </strong>
                                                            <Form.Text className='mx-2'>{row.zona}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Zonal: </strong>
                                                            <Form.Text className='mx-2'>{row.zonal}</Form.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>SED: </strong>
                                                            <Form.Text className='mx-2'>{row.recupero_sed}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Sistema Electrico: </strong>
                                                            <Form.Text className='mx-2'>{row.sistema_electrico}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>SET: </strong>
                                                            <Form.Text className='mx-2'>{row.recupero_set}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Distrito: </strong>
                                                            <Form.Text className='mx-2'>{row.distrito}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Alimentador: </strong>
                                                            <Form.Text className='mx-2'>{row.alimentador}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Fecha: </strong>
                                                            <Form.Text className='mx-2'>{row.fecha}</Form.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Observación: </strong>
                                                            <Form.Text className='mx-2'>{row.observacion}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Descripcion Irregularidad: </strong>
                                                            <Form.Text className='mx-2'>{row.descripcion_irregularidad}</Form.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Tipo: </strong>
                                                            <Form.Text className='mx-2'>{row.tipo}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                            <Col>
                                                <Container>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Semana: </strong>
                                                            <Form.Text className='mx-2'>{row.semana}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>SED programado de balance: </strong>
                                                            <Form.Text className='mx-2'>{row.sed_programada_de_balance}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Oficina: </strong>
                                                            <Form.Text className='mx-2'>{row.oficina}</Form.Text>
                                                        </Col>
                                                    </Row>



                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Monto: </strong>
                                                            <Form.Text className='mx-2'>{row.monto}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>kwh: </strong>
                                                            <Form.Text className='mx-2'>{row.kwh}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Mes Proceso: </strong>
                                                            <Form.Text className='mx-2'>{row.mes_proceso}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Mes Ejecución: </strong>
                                                            <Form.Text className='mx-2'>{row.mes_ejecucion}</Form.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Cod: </strong>
                                                            <Form.Text className='mx-2'>{row.cod}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Carta: </strong>
                                                            <Form.Text className='mx-2'>{row.carta}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Cantidad: </strong>
                                                            <Form.Text className='mx-2'>{row.cantidad}</Form.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Acta Intervención: </strong>
                                                            <Form.Text className='mx-2'>{row.acta_intervencion}</Form.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>JUO: </strong>
                                                            <Form.Text className='mx-2'>{row.juo}</Form.Text>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <strong className='text-muted'>Supervisor: </strong>
                                                            <Form.Text className='mx-2'>{row.supervisor}</Form.Text>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                    </div>
                                )
                            })
                        }
                        <Row>
                            <Col className='text-end'>
                                <Button size='sm' variant='outline-primary' onClick={() => { setabrirModalDetalleRecupero(false) }}>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Resumen Consumo */}
            <Modal
                show={abrirModalResumeConsumo}
                onHide={() => { setabrirModalResumeConsumo(false) }}
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            `Detalle Consumos suministro ${suministroActual}`
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        {
                            detalleConsumosSuministro.length > 0 ?
                                <Row className='mt-2'>
                                    <Col >

                                        <Container fluid>
                                            <Row>
                                                <TableContainer style={{ maxHeight: '300px' }}>
                                                    <Table size='small' stickyHeader>
                                                        <TableHead>
                                                            <TableRow >
                                                                <TableCell>Periodo</TableCell>
                                                                <TableCell>Lectura</TableCell>
                                                                <TableCell>Consumo</TableCell>
                                                                <TableCell>Fecha Lectura</TableCell>
                                                                <TableCell>Cod Lectura</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                detalleConsumosSuministro.map((row, index) => {
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <TableCell >
                                                                                {row.periodo_comercial}
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.utlima_lectura}
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {row.cea_consumo}
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                {moment(row.fecha_lectura_mes_actual).format("DD/MM/YYYY")}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {row.codigo_lectura_mes_actual}
                                                                            </TableCell>
                                                                        </TableRow>

                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </Row>
                                            <hr></hr>
                                            {
                                                dataChartsLectura ?
                                                    <Row>
                                                        <Col sm={12} >
                                                            <Line data={dataChartsLectura} options={
                                                                {
                                                                    responsive: true,
                                                                    maintainAspectRatio: false,
                                                                    plugins: {
                                                                        legend: {
                                                                            position: 'top',
                                                                        },
                                                                        title: {
                                                                            display: true,
                                                                            text: 'Resumen Lecturas',
                                                                        }
                                                                    }
                                                                }
                                                            } />
                                                        </Col>
                                                        <Col sm={12} >
                                                            <Bar data={dataChartsConsumo} options={
                                                                {
                                                                    responsive: true,
                                                                    maintainAspectRatio: false,
                                                                    plugins: {
                                                                        legend: {
                                                                            position: 'top',
                                                                        },
                                                                        title: {
                                                                            display: true,
                                                                            text: 'Resumen Consumos',
                                                                        }
                                                                    }
                                                                }
                                                            } />
                                                        </Col>
                                                    </Row> : <></>

                                            }
                                        </Container>
                                    </Col>
                                </Row>
                                : <></>
                        }
                        <hr></hr>
                        <Row>
                            <Col className='text-end'>
                                <Button size='sm' variant='outline-primary' onClick={() => { setabrirModalResumeConsumo(false) }}>Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </Fragment>
    );
}

export default DetalleBalance;