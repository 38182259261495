import { Icon } from 'leaflet';
import pin1 from '../assets/pin1.svg'
// export const IconoRojo = new Icon({
//   iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
//   iconRetinaUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
//   iconSize: [35, 35]
// });
export const PinRojo = new Icon({
  iconUrl: '/img/pin1.svg',  
  iconSize: [40, 40]
});

export const PinVerde = new Icon({
  iconUrl: '/img/pin3.svg',     
  iconSize: [40, 40]
});

export const PinAmarillo = new Icon({
  iconUrl: '/img/pin5.svg',  
  iconSize: [40, 40]
});

export const PinAzul = new Icon({
  iconUrl: '/img/pin14.svg',  
  iconSize: [40, 40]
});

export const PinNego = new Icon({
  iconUrl: '/img/pin34.svg',  
  iconSize: [40, 40]
});

// Pines Marker
export const Pin20 = new Icon({
  // iconUrl: '/img/pin20.svg',
  iconUrl: pin1,
  iconSize: [40, 40]
});

export const Pin19 = new Icon({
  iconUrl: '/img/pin19.svg',
  iconSize: [40, 40]
});

export const Pin18 = new Icon({
  iconUrl: '/img/pin18.svg',
  iconSize: [40, 40]
});

export const Pin17 = new Icon({
  iconUrl: '/img/pin17.svg',
  iconSize: [40, 40]
});

export const Pin16 = new Icon({
  iconUrl: '/img/pin16.svg',
  iconSize: [40, 40]
});

export const Pin15 = new Icon({
  iconUrl: '/img/pin15.svg',
  iconSize: [40, 40]
});

export const Pin14 = new Icon({
  iconUrl: '/img/pin14.svg',
  iconSize: [40, 40]
});

export const Pin13 = new Icon({
  iconUrl: '/img/pin13.svg',
  iconSize: [40, 40]
});

export const Pin12 = new Icon({
  iconUrl: '/img/pin12.svg',
  iconSize: [40, 40]
});

export const Pin11 = new Icon({
  iconUrl: '/img/pin11.svg',
  iconSize: [40, 40]
});

export const Pin10 = new Icon({
  iconUrl: '/img/pin10.svg',
  iconSize: [40, 40]
});

export const Pin9 = new Icon({
  iconUrl: '/img/pin9.svg',
  iconSize: [40, 40]
});

export const Pin8 = new Icon({
  iconUrl: '/img/pin8.svg',
  iconSize: [40, 40]
});

export const Pin7 = new Icon({
  iconUrl: '/img/pin7.svg',
  iconSize: [40, 40]
});

export const Pin6 = new Icon({
  iconUrl: '/img/pin6.svg',
  iconSize: [40, 40]
});

export const Pin5 = new Icon({
  iconUrl: '/img/pin5.svg',
  iconSize: [40, 40]
});

export const Pin4 = new Icon({
  iconUrl: '/img/pin4.svg',
  iconSize: [40, 40]
});

export const Pin3 = new Icon({
  iconUrl: '/img/pin3.svg',
  iconSize: [40, 40]
});

export const Pin2 = new Icon({
  iconUrl: '/img/pin2.svg',
  iconSize: [40, 40]
});

export const Pin1 = new Icon({
  iconUrl: '/img/pin1.svg',
  iconSize: [40, 40]
});