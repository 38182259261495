import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';

import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle } from '@mui/material';
import { DataGrid,esES } from '@mui/x-data-grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { ValidarUsuario, config, ObtenerUsuario, useWindowSize, ValidarPermisos, listarPermisos, LIST_SCREENS } from '../../config/utils';

function ConfiguracionCodigosLectura(props) {
    const navigate = useNavigate();
    const size = useWindowSize();

    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const [mostrarModalCodigoLectura, setmostrarModalCodigoLectura] = useState(false);
    const [dataCodigoLecturas, setdataCodigoLecturas] = useState([]);
    const [accionActual, setaccionActual] = useState('');
    const [elementoActual, setelementoActual] = useState({});

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const inputBuscar = useRef();
    const inputNombreCodigoLectura = useRef();
    const inputDescripcionCodigoLectura = useRef();
    const checkCalcularPromedio = useRef();
    const checkTomarLectura = useRef();
    const checkIngresarDatos = useRef();
    const checkElegirTotalizador = useRef();
    const checkAgregarLecturaFin=useRef();
    const selectEstadoCodigoLectura = useRef();

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_CODIGO_LECTURA, permisos)) {
                    BuscarCodigosLectura();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/configuracion');
                    }, 1000);
                }
            });
        }
    }

    const BuscarCodigosLectura = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/CodigoLectura/ListarCodigosLectura`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ valorbuscar: inputBuscar.current.value })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: elemento.id_codigo_lectura,
                            accion: '',
                            codigo: elemento.codigo,
                            descripcion: elemento.descripcion,
                            calcular_promedio: elemento.calcular_promedio,
                            tomar_lectura: elemento.tomar_lectura,
                            ingresar_datos: elemento.ingresar_datos,
                            elegir_totalizador: elemento.elegir_totalizador,
                            agregar_lectura_fin: elemento.agregar_lectura_fin,
                            estado: elemento.estado
                        }
                    );
                });
                setdataCodigoLecturas(DataTemp);
                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }

    const columns = [
        {

            field: 'accion', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {

                            AbrirModalCodigoLectura('VER', cellValues.row)
                        }}>
                            <EditIcon fontSize='small'></EditIcon>
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'codigo', headerName: 'Codigo', flex: 0.5, headerClassName: 'header-colum-gray' },
        { field: 'descripcion', headerName: 'Descripción', flex: 1.5, headerClassName: 'header-colum-gray' },
        {
            field: 'elegir_totalizador', headerName: 'Elegir Totalizador', flex: 0.75, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (

                    cellValues.row.elegir_totalizador ?
                        <span>{`Si`}</span>
                        : <span>{`No`}</span>
                )

            }
        },
        {
            field: 'calcular_promedio', headerName: 'Calcular Promedio', flex: 0.75, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (

                    cellValues.row.calcular_promedio ?
                        <span>{`Si`}</span>
                        : <span>{`No`}</span>
                )
            }
        },
        {
            field: 'tomar_lectura', headerName: 'Tomar Lectura', flex: 0.75, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (

                    cellValues.row.tomar_lectura ?
                        <span>{`Si`}</span>
                        : <span>{`No`}</span>
                )
            }
        },
        {
            field: 'ingresar_datos', headerName: 'Solicitar Datos', flex: 0.75, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (

                    cellValues.row.ingresar_datos ?
                        <span>{`Si`}</span>
                        : <span>{`No`}</span>
                )

            }
        },       
        {
            field: 'agregar_lectura_fin', headerName: 'Agregar Lectura Fin', flex: 0.75, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (

                    cellValues.row.agregar_lectura_fin ?
                        <span>{`Si`}</span>
                        : <span>{`No`}</span>
                )

            }
        },            
        { field: 'estado', headerName: 'Estado', flex: 1, headerClassName: 'header-colum-gray' },

    ];

    const AbrirModalCodigoLectura = async (accion, row) => {
        setaccionActual(accion);
        setelementoActual(row);
        await setmostrarModalCodigoLectura(true);
        if (row) {                   
            inputNombreCodigoLectura.current.value = row.codigo;
            inputDescripcionCodigoLectura.current.value = row.descripcion;
            checkCalcularPromedio.current.checked = row.calcular_promedio;
            checkTomarLectura.current.checked = row.tomar_lectura;
            checkIngresarDatos.current.checked = row.ingresar_datos;                 
            checkElegirTotalizador.current.checked = row.elegir_totalizador;
            checkAgregarLecturaFin.current.checked=row.agregar_lectura_fin;
            selectEstadoCodigoLectura.current.value = row.estado;

            inputNombreCodigoLectura.current.disabled = true
            inputDescripcionCodigoLectura.current.disabled = true
            checkCalcularPromedio.current.disabled = true
            checkTomarLectura.current.disabled = true
            checkIngresarDatos.current.disabled = true
            checkElegirTotalizador.current.disabled=true
            checkAgregarLecturaFin.current.disabled=true
            selectEstadoCodigoLectura.current.disabled = true
        }
    }

    const Guardar = () => {
        switch (accionActual) {
            case 'AGREGAR':
                AgregarCodigoLectura();
                break;
            case 'MODIFICAR':
                ActualizarCodigoLectura();
                break;
        }
    }

    const ValidarFormularioCodigoLectura = () => {
        let error = 0;
        if (inputNombreCodigoLectura.current.value == '') {
            inputNombreCodigoLectura.current.classList.add("is-invalid");
            error++;
        } else {
            inputNombreCodigoLectura.current.classList.remove("is-invalid");
        }

        if (inputDescripcionCodigoLectura.current.value == '') {
            inputDescripcionCodigoLectura.current.classList.add('is-invalid');
            error++;
        } else {
            inputDescripcionCodigoLectura.current.classList.remove('is-invalid');
        }

        if (selectEstadoCodigoLectura.current.value == '') {
            selectEstadoCodigoLectura.current.classList.add('is-invalid');
            error++;
        } else {
            selectEstadoCodigoLectura.current.classList.remove('is-invalid');
        }
        

        if (error > 0) {
            return false;
        }
        return true;
    }

    const AgregarCodigoLectura = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarFormularioCodigoLectura()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/CodigoLectura/AgregarCodigoLecturaV2`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        codigo: inputNombreCodigoLectura.current.value.toUpperCase(),
                        descripcion:inputDescripcionCodigoLectura.current.value.toUpperCase(),
                        calcularpronedio:checkCalcularPromedio.current.checked,
                        tomarlectura:checkTomarLectura.current.checked,
                        ingresardatos:checkIngresarDatos.current.checked,
                        elegirtotalizador:checkElegirTotalizador.current.checked,
                        agregarlecturafin:checkAgregarLecturaFin.current.checked,
                        estado: selectEstadoCodigoLectura.current.value.toUpperCase()
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    BuscarCodigosLectura();
                    setmostrarModalCodigoLectura(false);
                    setmostrarCircularProgress(false);

                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    const ActualizarCodigoLectura = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarFormularioCodigoLectura()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/CodigoLectura/ActualizarCodigoLecturav2`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idcodigolectura: elementoActual.id,
                        codigo: inputNombreCodigoLectura.current.value.toUpperCase(),
                        descripcion:inputDescripcionCodigoLectura.current.value.toUpperCase(),
                        calcularpronedio:checkCalcularPromedio.current.checked,
                        tomarlectura:checkTomarLectura.current.checked,
                        ingresardatos:checkIngresarDatos.current.checked,
                        elegirtotalizador:checkElegirTotalizador.current.checked,
                        agregarlecturafin:checkAgregarLecturaFin.current.checked,
                        estado: selectEstadoCodigoLectura.current.value.toUpperCase()
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    BuscarCodigosLectura();
                    setmostrarModalCodigoLectura(false);
                    setmostrarCircularProgress(false);

                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    const ModificarCodigoLectura = () => {
        inputNombreCodigoLectura.current.disabled = false;
        inputDescripcionCodigoLectura.current.disabled = false;
        checkCalcularPromedio.current.disabled = false;
        checkTomarLectura.current.disabled = false;
        checkIngresarDatos.current.disabled = false;
        checkElegirTotalizador.current.disabled=false;
        checkAgregarLecturaFin.current.disabled=false;
        selectEstadoCodigoLectura.current.disabled = false;
        setaccionActual("MODIFICAR");
    }

    const ActualizarInputBuscar = () => {
        if (inputBuscar.current.value.trim() === '') {
            BuscarCodigosLectura();
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración / CodigoLecturas'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Configuración / CodigoLecturas-Acceso</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/configuracion') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2' : ''}>
                            <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputBuscar} placeholder='Valor a buscar' size='sm'></Form.Control>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button onClick={() => { BuscarCodigosLectura() }} variant='primary' size='sm'>
                                Buscar
                            </Button>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : 'text-end'}>
                            <Button variant='primary' size='sm' onClick={() => { AbrirModalCodigoLectura('AGREGAR', null) }}>Agregar <AddIcon fontSize='small'></AddIcon></Button>
                        </Col>
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={dataCodigoLecturas}
                            columns={columns}
                            getCellClassName={(params) => {
                                if (params.colDef.field == "estado") {
                                    if (params.row.estado == "ACTIVO") {
                                        return 'text-primary';
                                    } else {
                                        return 'text-danger';
                                    }
                                }
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </div>
            </div>


            {/* Modal CodigoLectura */}

            <Modal
                show={mostrarModalCodigoLectura}
                onHide={() => { setmostrarModalCodigoLectura(false) }}
                keyboard={false}
                size="md"
            >
                <Modal.Header>
                    <Modal.Title>
                        Codigo Lectura
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo Lectura</small>
                                    <Form.Control size='sm' ref={inputNombreCodigoLectura} type="text" placeholder='Ingrese Codigo Lectura'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Descripción</small>
                                    <Form.Control ref={inputDescripcionCodigoLectura} size='sm' type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col>
                                <Form.Group>
                                    <Form.Check ref={checkCalcularPromedio} label='Calcular Promedio' title='Calcular Promedio'></Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col>
                                <Form.Group>
                                    <Form.Check ref={checkTomarLectura} label='Tomar Lectura' ></Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col>
                                <Form.Group>
                                    <Form.Check ref={checkIngresarDatos} label='Solicitar Datos' ></Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col>
                                <Form.Group>
                                    <Form.Check ref={checkElegirTotalizador} label='Elegir Totalizador' ></Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col>
                                <Form.Group>
                                    <Form.Check ref={checkAgregarLecturaFin} label='Agregar Lectura Fin' ></Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <select ref={selectEstadoCodigoLectura} className="form-select" defaultValue="">
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                </Form.Group>

                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <Button size='sm' variant="outline-primary" onClick={() => { setmostrarModalCodigoLectura(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                {
                                    accionActual == 'VER' ?
                                        <Button onClick={() => { ModificarCodigoLectura() }} size='sm' variant="primary">Modificar</Button>
                                        : <Button onClick={() => { Guardar() }} size='sm' variant="primary">Guardar</Button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}

export default ConfiguracionCodigosLectura;
