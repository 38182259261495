import React from 'react';
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import './App.css';

//Componentes
import Login from './components/inicio/login';
import Dashboard from './components/dashboard/Dashboard';
import MenuBalance from './components/balance/MenuBalance';
import CargarConsumos from './components/balance/cargar/cargarreporte';
import Detecciones from './components/detecciones/detecciones';
import Recuperos from './components/recuperos/recuperos';
import MenuConfiguracion from './components/configuracion/menuconfiguracion';
import ListaBalances from './components/balance/listas/listabalances';
import Microbalances from './components/balance/microbalance/microbalances';
import NotFound from './config/notfound';
import CargarDetecciones from './components/detecciones/cargardetecciones';
import CargarRecuperos from './components/recuperos/cargarrecuperos';
import ListaBalancesMapa from './components/balance/listas/listabalancemapa';
import DetalleBalance from './components/balance/listas/detallebalance';
import DetalleMicrobalance from './components/balance/microbalance/microbalancedetalle';
import CalculoDetalleMicrobalance from './components/balance/listas/calculodetallebalance';
import ConfiguracionTrabajador from './components/configuracion/configuraciontrabajador';
import ConfiguracionPantallas from './components/configuracion/configuracionpantallas';
import ConfiguracionPermisos from './components/configuracion/configuracionpermisos';
import ConfiguracionAgrupacion from './components/configuracion/configuracionagrupacion';
import BaseSed from './components/configuracion/configbasesed';
import Suministro from './components/suministro/suministro';
import ConfiguracionVariables from './components/configuracion/configuracionVariables';
import ListadoAlertas from './components/suministro/listaalertas';
import ConfiguracionCodigosLectura from './components/configuracion/configuracioncodigolectura';
import ConfiguracionDetalleVivienda from './components/configuracion/configuraciondetallevivienda';
import MicrobalancesAgrupacion from './components/balance/microbalance/microbalanceagrupacion';
import ScriptSQL from './components/script/script';

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route exact path='/' element={<Login/>} />
          <Route exact path='/dashboard' element={<Dashboard/>} />
          <Route exact path='/balance' element={<MenuBalance/>} />
          <Route exact path='/balance/cargar' element={<CargarConsumos/>} />
          <Route exact path='/balance/lista' element={<ListaBalances/>} />
          <Route exact path='/balance/listamapa' element={<ListaBalancesMapa/>} />
          <Route exact path='/balance/detalle/:idbalance' element={<DetalleBalance/>} />
          {/* <Route exact path='/balance/detalle/:idbalance/:idmicrobalance' element={<CalculoDetalleMicrobalance/>} /> */}
          <Route exact path='/balance/microbalance' element={<Microbalances/>} />
          <Route exact path='/balance/microbalanceagrupacion' element={<MicrobalancesAgrupacion/>} />
          <Route exact path='/balance/microbalance/detalle/:idmicrobalance' element={<DetalleMicrobalance/>} />
          <Route exact path='/detecciones' element={<Detecciones/>} />
          <Route exact path='/detecciones/cargar' element={<CargarDetecciones/>} />
          <Route exact path='/recuperos' element={<Recuperos/>} />
          <Route exact path='/recuperos/cargar' element={<CargarRecuperos/>} />
          <Route exact path='/configuracion' element={<MenuConfiguracion/>} />
          <Route exact path='/configuracion/trabajador' element={<ConfiguracionTrabajador/>} />              
          <Route exact path='/configuracion/pantallas' element={<ConfiguracionPantallas/>} /> 
          <Route exact path='/configuracion/permisos' element={<ConfiguracionPermisos/>} /> 
          <Route exact path='/configuracion/agrupaciones' element={<ConfiguracionAgrupacion/>} /> 
          <Route exact path='/configuracion/basesed' element={<BaseSed/>} /> 
          <Route exact path='/configuracion/variables' element={<ConfiguracionVariables/>} /> 
          <Route exact path='/configuracion/codigolectura' element={<ConfiguracionCodigosLectura/>} />       
          <Route exact path='/configuracion/detallevivienda' element={<ConfiguracionDetalleVivienda/>} />                 
          <Route exact path='/suministro' element={<Suministro/>} /> 
          <Route exact path='/alertas' element={<ListadoAlertas/>} /> 
          <Route exact path='/script' element={<ScriptSQL/>} /> 
          <Route path='*' element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>    
  );
}

export default App;

