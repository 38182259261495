import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../dashboard/Header';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import HouseIcon from '@mui/icons-material/House';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



import { ValidarPermisos, ValidarUsuario, listarPermisos, LIST_SCREENS } from '../../config/utils';

function MenuBalance() {
    const navigate = useNavigate();

    //toast y progress Bar        
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES, permisos)) {
                    
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }


    return (
        <Fragment>            

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>
            <Header titulo='Control de Perdidas / Balance '></Header>
            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Balances</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/dashboard') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <Container className='mt-4'>
                {/* <Row>
                    <Col className='text-end'>
                        <IconButton color='primary' onClick={() => { navigate('/') }} >
                            <ArrowBackIcon ></ArrowBackIcon>
                        </IconButton>
                    </Col>
                </Row> */}
                <Row>
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_CARGAR_RPT, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <CloudUploadIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></CloudUploadIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/balance/cargar') }}>Cargar RPT</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_LISTA, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <DynamicFormIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></DynamicFormIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/balance/lista') }}>Lista Balances</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }
                    {
                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BALANCES_MICROBALANCE, listapermisos) ?
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} xxl={2} className='my-2'>
                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 70, width: 70 }}>
                                        <HouseIcon style={{ color: 'rgb(230,83,97)', fontSize: '2.8em' }}></HouseIcon>
                                    </div>
                                    <div className='d-grid m-2 ' style={{ width: '170px' }}>
                                        <Button variant="outline-primary" onClick={() => { navigate('/balance/microbalance') }}>Microbalances</Button>
                                    </div>
                                </div>
                            </Col>
                            : <></>
                    }

                </Row>

            </Container>
        </Fragment>
    );
}

export default MenuBalance;