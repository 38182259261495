import React, { Fragment, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { Snackbar, Alert, AlertTitle } from '@mui/material';

export default function NotFound() {
    const navigate = useNavigate()

    const [abrirToast, setabrirToast] = useState(false);
    const [titulotoast, settitulotoast] = useState("");
    const [mensajetoast, setmensajetoast] = useState("");
    const [tipotoast, settipotoast] = useState("error");

    useEffect(() => {
        const redireccionarPagina = () => {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Pagina No encontrada");
            setabrirToast(true);
    
            setTimeout(() => {
                navigate("/");
            }, 3000);
        }
        redireccionarPagina();
    }, [navigate])
    return (
        <Fragment>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{titulotoast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>
            <Container>
                <Row>
                    <Col>
                        <img alt='Pagina no Encontrada' width='100%' srcSet='https://img.freepik.com/vector-premium/pagina-no-encontrada-pagina-metafora-error-404-pagina-destino_5379-926.jpg?w=1380' style={{ backgroundSize: 'cover', margin: 'auto' }}></img>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}