import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Modal,Stack } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle, CircularProgress } from '@mui/material';
import { DataGrid,esES } from '@mui/x-data-grid';

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

//Importaciones
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';
import { ObtenerUsuario, config, ValidarUsuario, useWindowSize, LEER_EXCEL_DE_ARCHIVO, ValidarPermisos, listarPermisos, LIST_SCREENS } from '../../config/utils'

import moment from 'moment';

function BaseSed(props) {
    const navigate = useNavigate();
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [mostrarBarraCarga, setmostrarBarraCarga] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //hook actualizacion tamaño de ventana
    const size = useWindowSize();

    //
    const [dataArchivoBaseSed, setdataArchivoBaseSed] = useState([]);
    const [elementoActual, setelementoActual] = useState(null);

    //Modales
    const [mostrarModalAgregarSuministros, setmostrarModalAgregarSuministros] = useState(false);
    const [mostrarModealDetalleSuministro, setmostrarModealDetalleSuministro] = useState(false);
    const [mostrarModalAgregarArchivo, setmostrarModalAgregarArchivo] = useState(false);

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //inputs 
    const inputListaBuscar = useRef();
    const inputBuscar = useRef();

    const inputFileBaseSed = useRef();
    const inputPeriodoComercial = useRef();


    //referencia detalle suministro    
    const inputSedCodigo = useRef();
    const inputAliNombre = useRef();
    const inputSetNombre = useRef();
    const inputSieNombre = useRef();
    const inputDisNombre = useRef();
    const inputSedDireccion = useRef();
    const inputSedParticular = useRef();
    const inputSedObservaciones = useRef();
    const inputSedTipo = useRef();
    const inputSedUsuarios = useRef();
    const inputSedEnergiau = useRef();
    const inputSedObservacionesvarias = useRef();

    const columns = [
        {
            field: 'accion', headerName: 'Acción', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {
                            //console.log(cellValues);
                            setelementoActual(cellValues);
                            MostrarDetalleSuministro(cellValues.row);
                        }}>
                            <WysiwygIcon></WysiwygIcon>
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'sed_codigo', headerName: 'Codigo Sed', minWidth: 100, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'sed_direccion', headerName: 'Dirección', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'ali_nombre', headerName: 'Alimentador', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'set_nombre', headerName: 'Set Nombre', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'sie_nombre', headerName: 'SIE Nombre', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'dis_nombre', headerName: 'Distrito', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'sed_fases', headerName: 'Fases', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
    ];

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_BASE_SED, permisos)) {
                    BuscarBaseSed();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }
    }

    const ValidarCargaArchivo = () => {
        let error = 0;
        if (inputFileBaseSed.current.files.length > 0) {
            inputFileBaseSed.current.classList.remove('is-invalid');
        } else {
            inputFileBaseSed.current.classList.add('is-invalid');
            settipoToast("Error");
            settipoToast("error");
            setmensajeToast("Archivo no encontrado o no se ha seleccionado el archivo.");
            setabrirToast(true);
            error++;
        }

        // if (inputPeriodoComercial.current.value.trim() != "") {
        //     inputPeriodoComercial.current.classList.remove('is-invalid');
        // } else {
        //     inputPeriodoComercial.current.classList.add('is-invalid');
        //     error++;
        // }

        if (error > 0) return false;

        return true;
    }

    const CargaArchivo = async () => {
        if (ValidarCargaArchivo()) {
            // setmostrarCircularProgress(true);
            setmostrarBarraCarga(true);
            await LEER_EXCEL_DE_ARCHIVO(inputFileBaseSed.current.files[0]).then(async (dataXls) => {
                if (dataXls.length <= 0) {
                    throw new Error("No se encontraron registros en el archivo .");
                }

                let dataEnviar = [];
                // console.log(dataXls.length);
                for (let x = 0; x < dataXls.length; x++) {
                    let nuevaFila = {};
                    //Normalizando Informacion
                    for (var clave in dataXls[x]) {
                        //Verificando Claves Existes
                        if (dataXls[x].hasOwnProperty(clave)) {
                            //Verificando si la Clave es Cabecera o Material 
                            switch (clave.toUpperCase()) {
                                case 'SED_CODIGO':
                                    nuevaFila.SedCodigo = dataXls[x][clave];
                                    break;
                                case 'ALI_NOMBRE':
                                    nuevaFila.AliNombre = dataXls[x][clave];
                                    break;
                                case 'SET_NOMBRE':
                                    nuevaFila.Setnombre = dataXls[x][clave];
                                    break;
                                case 'SIE_NOMBRE':
                                    nuevaFila.SieNombre = dataXls[x][clave];
                                    break;
                                case 'DIS_NOMBRE':
                                    nuevaFila.DisNombre = dataXls[x][clave];
                                    break;
                                case 'SI':
                                    nuevaFila.Si = dataXls[x][clave];
                                    break;
                                case 'SED_CENTROATENCION':
                                    nuevaFila.SedCentroatencion = dataXls[x][clave];
                                    break;
                                case 'SED_DIRECCION':
                                    nuevaFila.SedDireccion = dataXls[x][clave];
                                    break;
                                case 'SED_INEI':
                                    nuevaFila.SedInei = dataXls[x][clave];
                                    break;
                                case 'SED_NOMINALBT':
                                    nuevaFila.SedNominalbt = dataXls[x][clave];
                                    break;
                                case 'SED_NOMINALMT':
                                    nuevaFila.SedNominalmt = dataXls[x][clave];
                                    break;
                                case 'SED_POTENCIA':
                                    nuevaFila.SedPotencia = dataXls[x][clave];
                                    break;
                                case 'SED_UTMNORTE':
                                    nuevaFila.SedUtmnorte = dataXls[x][clave];
                                    break;
                                case 'SED_UTMESTE':
                                    nuevaFila.SedUtmeste = dataXls[x][clave];
                                    break;
                                // case 'ID_ALI':
                                // nuevaFila.item = dataXls[x][clave];
                                // break;
                                case 'SED_POSICION':
                                    nuevaFila.SedPosicion = dataXls[x][clave];
                                    break;
                                case 'SED_FASES':
                                    nuevaFila.SedFases = dataXls[x][clave];
                                    break;
                                case 'SED_PARTICULAR':
                                    nuevaFila.SedParticular = dataXls[x][clave];
                                    break;
                                case 'SED_OBSERVACIONES':
                                    nuevaFila.SedObservaciones = dataXls[x][clave];
                                    break;
                                case 'SED_TIPO':
                                    nuevaFila.SedTipo = dataXls[x][clave];
                                    break;
                                case 'SED_CUTOUT':
                                    nuevaFila.SedCutout = dataXls[x][clave];
                                    break;
                                case 'SED_ELECTRIFICACION':
                                    nuevaFila.SedElectrificacion = dataXls[x][clave];
                                    break;
                                case 'SED_MAXDEM':
                                    nuevaFila.SedMaxdem = dataXls[x][clave];
                                    break;
                                case 'SED_PUESTATIERRA_CARCAZA':
                                    nuevaFila.SedPuestatierraCarcaza = dataXls[x][clave];
                                    break;
                                case 'SED_PUESTATIERRA_NEUTRO':
                                    nuevaFila.SedPuestatierraNeutro = dataXls[x][clave];
                                    break;
                                case 'SED_SUMINISTROP':
                                    nuevaFila.SedSuministrop = dataXls[x][clave];
                                    break;
                                case 'SED_USUARIOS':
                                    nuevaFila.SedUsuarios = dataXls[x][clave];
                                    break;
                                case 'SED_ENERGIAU':
                                    nuevaFila.SedEnergiau = dataXls[x][clave];
                                    break;
                                case 'SED_FECHAINS':
                                    nuevaFila.SedFechains = dataXls[x][clave];
                                    break;
                                case 'SED_OBSERVACIONESVARIAS':
                                    nuevaFila.Sedobservacionesvarias = dataXls[x][clave];
                                    break;
                                case 'SED_FU':
                                    nuevaFila.SedFu = dataXls[x][clave];
                                    break;
                                case 'SED_FECHAMANTENIMIENTO':
                                    nuevaFila.SedFechamantenimiento = dataXls[x][clave];
                                    break;
                                // case 'ID_SED':
                                // nuevaFila.item = dataXls[x][clave];
                                // break;
                                case 'SED_NOPOSTEAT':
                                    nuevaFila.SedNoposteat = dataXls[x][clave];
                                    break;
                                case 'SED_POSTEATALT':
                                    nuevaFila.SedPosteatalt = dataXls[x][clave];
                                    break;
                                case 'SED_POSTEATMAT':
                                    nuevaFila.SedPosteatmat = dataXls[x][clave];
                                    break;
                                case 'SED_NOPOSTEBT':
                                    nuevaFila.SedNopostebt = dataXls[x][clave];
                                    break;
                                case 'SED_POSTEBTALT':
                                    nuevaFila.SedPostebtalt = dataXls[x][clave];
                                    break;
                                case 'SED_POSTEBTMAT':
                                    nuevaFila.SedPostebtmat = dataXls[x][clave];
                                    break;
                                case 'SED_OBSERVACIONESMT':
                                    nuevaFila.SedObservacionesmt = dataXls[x][clave];
                                    break;
                                case 'SED_OBSERVACIONESBT':
                                    nuevaFila.SedObservacionesbt = dataXls[x][clave];
                                    break;
                                case 'SED_OBSERVACIONESAP':
                                    nuevaFila.SedObservacionesap = dataXls[x][clave];
                                    break;
                                case 'SED_OBSERVACIONESTRAFO':
                                    nuevaFila.SedObservacionestrafo = dataXls[x][clave];
                                    break;
                                case 'SED_CKTAP':
                                    nuevaFila.SedCktap = dataXls[x][clave];
                                    break;
                                case 'SED_CKTBT':
                                    nuevaFila.SedCktbt = dataXls[x][clave];
                                    break;
                                case 'SED_FUSIBLEA':
                                    nuevaFila.SedFusiblea = dataXls[x][clave];
                                    break;
                                case 'SED_FUSIBLEB':
                                    nuevaFila.SedFusibleb = dataXls[x][clave];
                                    break;
                                case 'SED_FUSIBLEC':
                                    nuevaFila.SedFusiblec = dataXls[x][clave];
                                    break;
                                case 'AP_CONMEDIDOR':
                                    nuevaFila.ApConmedidor = dataXls[x][clave];
                                    break;
                                case 'AP_CONSUMINISTRO':
                                    nuevaFila.ApConsuministro = dataXls[x][clave];
                                    break;
                                case 'AP_NROSUMINISTRO':
                                    nuevaFila.ApNrosuministro = dataXls[x][clave];
                                    break;
                                case 'AP_FOTOSED':
                                    nuevaFila.ApFotosed = dataXls[x][clave];
                                    break;
                                case 'AP_FOTOPOSTE':
                                    nuevaFila.ApFotoposte = dataXls[x][clave];
                                    break;
                                case 'SED_LECMEDIDOR':
                                    nuevaFila.SedLecmedidor = dataXls[x][clave];
                                    break;
                                case 'SED_TABLEROBT':
                                    nuevaFila.SedTablerobt = dataXls[x][clave];
                                    break;
                                case 'AP_FECMANTENIMIENTO':
                                    nuevaFila.ApFecmantenimiento = dataXls[x][clave];
                                    break;
                                case 'AP_FECPUESTASERV':
                                    nuevaFila.ApFecpuestaserv = dataXls[x][clave];
                                    break;
                                case 'SED_PTOFIS':
                                    nuevaFila.SedPtofis = dataXls[x][clave];
                                    break;
                                case 'SED_ANTIGUEDAD':
                                    nuevaFila.SedAntiguedad = dataXls[x][clave];
                                    break;
                                case 'SED_CANDADO':
                                    nuevaFila.SedCandado = dataXls[x][clave];
                                    break;
                                case 'SED_GRUPO':
                                    nuevaFila.SedGrupo = dataXls[x][clave];
                                    break;
                                case 'SED_TOTALIZADOR':
                                    nuevaFila.SedTotalizador = dataXls[x][clave];
                                    break;
                                case 'AP_TIPOMED':
                                    nuevaFila.ApTipomed = dataXls[x][clave];
                                    break;
                                case 'SED_FASESCNX':
                                    nuevaFila.SedFasescnx = dataXls[x][clave];
                                    break;
                                case 'SED_POSTEBT':
                                    nuevaFila.SedPostebt = dataXls[x][clave];
                                    break;
                                case 'SED_POSTEMT':
                                    nuevaFila.SedPostemt = dataXls[x][clave];
                                    break;
                                case 'SED_FC':
                                    nuevaFila.SedFc = dataXls[x][clave];
                                    break;
                                case 'SED_DEMPROM':
                                    nuevaFila.SedDemprom = dataXls[x][clave];
                                    break;
                                case 'SED_ACTIVA':
                                    nuevaFila.SedActiva = dataXls[x][clave];
                                    break;
                                case 'SED_ZF':
                                    nuevaFila.SedZf = dataXls[x][clave];
                                    break;
                                case 'SED_IDVNR':
                                    nuevaFila.SedIdvnr = dataXls[x][clave];
                                    break;
                                case 'SED_SER':
                                    nuevaFila.SedSer = dataXls[x][clave];
                                    break;
                                case 'SED_CODSER':
                                    nuevaFila.SedCodser = dataXls[x][clave];
                                    break;
                                case 'SED_CODALISER':
                                    nuevaFila.SedCodaliser = dataXls[x][clave];
                                    break;
                                case 'SED_X':
                                    nuevaFila.Sed_x = dataXls[x][clave];
                                    break;
                                case 'SED_Y':
                                    nuevaFila.Sed_y = dataXls[x][clave];
                                    break;
                            }
                        }
                    }
                    dataEnviar.push(nuevaFila);
                }
                if (dataEnviar.length <= 0) {
                    throw new Error("No se encontraron datos para procesar.");
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                // let url = `${config.endPoint}api/basesed/archivos/subir`;
                let url = `${config.endPoint}api/basesed/archivos/subirv2`;
                

                let dataEnviarBaseSed = {                    
                    dataBaseSed: dataEnviar
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(dataEnviarBaseSed)
                };


                await fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.success) {
                        settipoToast("success");
                        settituloToast("Completado");
                    } else {
                        settipoToast("error");
                        settituloToast("Error");
                    }
                    setmensajeToast(data.message)
                    setabrirToast(true);
                    BuscarBaseSed();
                    setmostrarBarraCarga(false);
                    setmostrarModalAgregarArchivo(false);

                }).catch(async (error) => {
                    throw new Error(`${config.MessageErrorServer}; ${error.toString()}`);                    
                });

                setmostrarCircularProgress(false);
            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(error.toString())
                setabrirToast(true);
                setmostrarBarraCarga(false);
                // setmostrarCircularProgress(false);
            });
        }
    }

    const BuscarBaseSed = () => {
        if (ValidarUsuario()) {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/basesed/lista`;
            let listaBuscar = "";

            if (inputBuscar.current.value.trim() !== '') {
                listaBuscar = inputBuscar.current.value.trim();
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ listaBuscar: listaBuscar })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: elemento.id_base_sed,
                            sed_codigo: elemento.sed_codigo,
                            ali_nombre: elemento.ali_nombre,
                            set_nombre: elemento.set_nombre,
                            sie_nombre: elemento.sie_nombre,
                            dis_nombre: elemento.dis_nombre,
                            si: elemento.si,
                            sed_centroatencion: elemento.sed_centroatencion,
                            sed_direccion: elemento.sed_direccion,
                            sed_inei: elemento.sed_inei,
                            sed_nominalbt: elemento.sed_nominalbt,
                            sed_nominalmt: elemento.sed_nominalmt,
                            sed_potencia: elemento.sed_potencia,
                            sed_utmnorte: elemento.sed_utmnorte,
                            sed_utmeste: elemento.sed_utmeste,
                            sed_posicion: elemento.sed_posicion,
                            sed_fases: elemento.sed_fases,
                            sed_particular: elemento.sed_particular,
                            sed_observaciones: elemento.sed_observaciones,
                            sed_tipo: elemento.sed_tipo,
                            sed_cutout: elemento.sed_cutout,
                            sed_electrificacion: elemento.sed_electrificacion,
                            sed_maxdem: elemento.sed_maxdem,
                            sed_puestatierra_carcaza: elemento.sed_puestatierra_carcaza,
                            sed_puestatierra_neutro: elemento.sed_puestatierra_neutro,
                            sed_suministrop: elemento.sed_suministrop,
                            sed_usuarios: elemento.sed_usuarios,
                            sed_energiau: elemento.sed_energiau,
                            sed_fechains: elemento.sed_fechains,
                            sed_observacionesvarias: elemento.sed_observacionesvarias,
                            sed_fu: elemento.sed_fu,
                            sed_fechamantenimiento: elemento.sed_fechamantenimiento,
                            sed_noposteat: elemento.sed_noposteat,
                            sed_posteatalt: elemento.sed_posteatalt,
                            sed_posteatmat: elemento.sed_posteatmat,
                            sed_nopostebt: elemento.sed_nopostebt,
                            sed_postebtalt: elemento.sed_postebtalt,
                            sed_postebtmat: elemento.sed_postebtmat,
                            sed_observacionesmt: elemento.sed_observacionesmt,
                            sed_observacionesbt: elemento.sed_observacionesbt,
                            sed_observacionesap: elemento.sed_observacionesap,
                            sed_observacionestrafo: elemento.sed_observacionestrafo,
                            sed_cktap: elemento.sed_cktap,
                            sed_cktbt: elemento.sed_cktbt,
                            sed_fusiblea: elemento.sed_fusiblea,
                            sed_fusibleb: elemento.sed_fusibleb,
                            sed_fusiblec: elemento.sed_fusiblec,
                            ap_conmedidor: elemento.ap_conmedidor,
                            ap_consuministro: elemento.ap_consuministro,
                            ap_nrosuministro: elemento.ap_nrosuministro,
                            ap_fotosed: elemento.ap_fotosed,
                            ap_fotoposte: elemento.ap_fotoposte,
                            sed_lecmedidor: elemento.sed_lecmedidor,
                            sed_tablerobt: elemento.sed_tablerobt,
                            ap_fecmantenimiento: elemento.ap_fecmantenimiento,
                            ap_fecpuestaserv: elemento.ap_fecpuestaserv,
                            sed_ptofis: elemento.sed_ptofis,
                            sed_antiguedad: elemento.sed_antiguedad,
                            sed_candado: elemento.sed_candado,
                            sed_grupo: elemento.sed_grupo,
                            sed_totalizador: elemento.sed_totalizador,
                            ap_tipomed: elemento.ap_tipomed,
                            sed_fasescnx: elemento.sed_fasescnx,
                            sed_postebt: elemento.sed_postebt,
                            sed_postemt: elemento.sed_postemt,
                            sed_fc: elemento.sed_fc,
                            sed_demprom: elemento.sed_demprom,
                            sed_activa: elemento.sed_activa,
                            sed_zf: elemento.sed_zf,
                            sed_idvnr: elemento.sed_idvnr,
                            sed_ser: elemento.sed_ser,
                            sed_codser: elemento.sed_codser,
                            sed_codaliser: elemento.sed_codaliser,
                            sed_x: elemento.sed_x,
                            sed_y: elemento.sed_y,
                            periodo_comercial: elemento.periodo_comercial
                        }
                    );
                });

                setdataArchivoBaseSed(DataTemp);
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate("/");
        }
    }

    const ListarSuminstrosBuscar = () => {
        if (inputListaBuscar.current.value !== '') {
            inputListaBuscar.current.classList.remove('is-invalid');

            let arrayLista = inputListaBuscar.current.value.split(/\r\n|\r|\n/);
            let listaBuscar = "";

            arrayLista.forEach(elemento => {
                if (elemento.trim() !== '') {
                    listaBuscar = listaBuscar + elemento.trim() + ",";
                }
            });

            listaBuscar = listaBuscar.substring(1, listaBuscar.length - 1);
            inputBuscar.current.value = listaBuscar;

            setmostrarModalAgregarSuministros(false);
        } else {
            inputListaBuscar.current.clasList.add('is-invalid');
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`No se encontro cadena para realizar esta accion.`)
            setabrirToast(true);
        }
    }

    const MostrarDetalleSuministro = async (registro) => {
        await setmostrarModealDetalleSuministro(true);

        // inputSedCodigo.current.value = registro.sed_codigo;
        inputAliNombre.current.value = registro.ali_nombre;
        inputSetNombre.current.value = registro.set_nombre;
        inputSieNombre.current.value = registro.sie_nombre;
        inputDisNombre.current.value = registro.dis_nombre;
        inputSedDireccion.current.value = registro.sed_direccion;
        inputSedParticular.current.value = registro.sed_particular;
        inputSedObservaciones.current.value = registro.sed_observaciones;
        inputSedTipo.current.value = registro.sed_tipo;
        inputSedUsuarios.current.value = registro.sed_usuarios;
        inputSedEnergiau.current.value = registro.sed_energiau;
        inputSedObservacionesvarias.current.value = registro.sed_observacionesvarias;

        inputAliNombre.current.disabled = true;
        inputSetNombre.current.disabled = true;
        inputSieNombre.current.disabled = true;
        inputDisNombre.current.disabled = true;
        inputSedDireccion.current.disabled = true;
        inputSedParticular.current.disabled = true;
        inputSedObservaciones.current.disabled = true;
        inputSedTipo.current.disabled = true;
        inputSedUsuarios.current.disabled = true;
        inputSedEnergiau.current.disabled = true;
        inputSedObservacionesvarias.current.disabled = true;
    }

    const ActualizarInputBuscar = () => {
        if (inputBuscar.current.value.trim() === '') {
            BuscarBaseSed();
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>
            <Header></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Configuración / Base SED</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/configuracion') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4}>
                            <Stack direction='horizontal' >
                                <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputBuscar} placeholder='Valor a buscar' size=''></Form.Control>                                
                                <IconButton onClick={() => { setmostrarModalAgregarSuministros(true) }}>
                                    <PlaylistAddIcon></PlaylistAddIcon>
                                </IconButton>
                            </Stack>
                        </Col>
                        
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1 mt-md-0' : ''}>
                            <Button onClick={() => { BuscarBaseSed() }} variant='primary' size='sm'>
                                Buscar
                            </Button>
                        </Col>
                        <Col sm={12} md={4} className={size.width < 768 ? 'd-grid gap-2 mt-1' : 'text-end'}>
                            <Button variant='primary' size='sm' onClick={() => { setmostrarModalAgregarArchivo(true) }}>Cargar Archivo</Button>
                        </Col>
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid density='compact' rows={dataArchivoBaseSed} columns={columns}  localeText={esES.components.MuiDataGrid.defaultProps.localeText}/>
                    </div>
                </div>
            </div>


            {/* Modal Agregar Suministros */}
            <Modal
                show={mostrarModalAgregarSuministros}
                onHide={() => { setmostrarModalAgregarSuministros(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Lista
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <Form.Control ref={inputListaBuscar} as='textarea' rows={6} placeholder='Agregar Lista' size='sm'></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <Button variant='primary' onClick={() => { ListarSuminstrosBuscar() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


            {/* Modal Detalle Suministro */}
            <Modal
                show={mostrarModealDetalleSuministro}
                onHide={() => { setmostrarModealDetalleSuministro(false) }}
                keyboard={false}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            elementoActual ?
                                `SED ${elementoActual.row.sed_codigo}`
                                : <></>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >

                    <Container>
                        <Row className=''>
                            <Col >
                                <small className='text-primary'>Alimentador</small>
                                <Form.Control size="sm" ref={inputAliNombre} className=''></Form.Control>
                            </Col>
                            <Col  >
                                <small className='text-primary'>SET Nombre</small>
                                <Form.Control size="sm" ref={inputSetNombre} className=''></Form.Control>
                            </Col>
                        </Row>

                        <Row className=''>
                            <Col >
                                <small className='text-primary'>SIE Nombre</small>
                                <Form.Control size="sm" ref={inputSieNombre} className=''></Form.Control>
                            </Col>
                            <Col  >
                                <small className='text-primary'>Dist. Nombre</small>
                                <Form.Control size="sm" ref={inputDisNombre} className=''></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col  >
                                <small className='text-primary'>Sed Direccion</small>
                                <Form.Control size="sm" ref={inputSedDireccion} className=''></Form.Control>
                            </Col>
                        </Row>

                        <Row className=''>
                            <Col >
                                <small className='text-primary'>Sed Particular</small>
                                <Form.Control size="sm" ref={inputSedParticular} className=''></Form.Control>
                            </Col>
                        </Row>

                        <Row className=''>
                            <Col >
                                <small className='text-primary'>Observaciones</small>
                                <Form.Control size="sm" ref={inputSedObservaciones} className=''></Form.Control>
                            </Col>
                        </Row>

                        <Row className=''>

                            <Col >
                                <small className='text-primary'>Tipo</small>
                                <Form.Control size="sm" ref={inputSedTipo} className=''></Form.Control>
                            </Col>
                            <Col  >
                                <small className='text-primary'>Usuarios</small>
                                <Form.Control size="sm" ref={inputSedUsuarios} className=''></Form.Control>
                            </Col>
                            <Col  >
                                <small className='text-primary'>Energia U.</small>
                                <Form.Control size="sm" ref={inputSedEnergiau} className=''></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <small className='text-primary'>Observaciones Varias</small>
                                <Form.Control size="sm" ref={inputSedObservacionesvarias} className=''></Form.Control>
                            </Col>
                        </Row>

                        <hr></hr>
                        <Row>
                            <Col className='text-end'>
                                <Button onClick={() => { setmostrarModealDetalleSuministro(false) }} size="sm">Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar */}
            <Modal
                show={mostrarModalAgregarArchivo}
                onHide={() => { setmostrarModalAgregarArchivo(false) }}
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Agregar Base Sed
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-4'>
                            <Col>
                                <Form.Control ref={inputFileBaseSed} type="file" size='sm' />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col md={2}>
                                <Form.Label>Periodo:</Form.Label>
                            </Col>
                            <Col md={3}>
                                <Form.Control ref={inputPeriodoComercial} size='sm' placeholder='Ingrese Periodo'></Form.Control>
                            </Col>
                        </Row> */}
                        <Row className='mt-4'>
                            {
                                mostrarBarraCarga ?
                                    <Col className='text-center d-block'>
                                        <span>
                                            <CircularProgress />

                                            Cargando...
                                        </span>
                                    </Col>
                                    :
                                    <Col className='text-end'>
                                        <Button variant='primary' onClick={() => { CargaArchivo() }} size="sm"> Cargar Archivo</Button>
                                    </Col>
                            }
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </Fragment>
    );
}

export default BaseSed;


