import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle, CircularProgress } from '@mui/material';
import { DataGrid,esES } from '@mui/x-data-grid';

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

//Importaciones
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';
import { ObtenerUsuario, config, ValidarUsuario, useWindowSize,listarPermisos,ValidarPermisos,LIST_SCREENS } from '../../config/utils'

import moment from 'moment';

function Recuperos(props) {
    const navigate = useNavigate();
    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    //hook actualizacion tamaño de ventana
    const size = useWindowSize();

    //
    const [dataArchivoRecuperos, setdataArchivoRecuperos] = useState([]);
    const [elementoActual, setelementoActual] = useState(null);

    //Modales
    const [mostrarModalAgregarSuministros, setmostrarModalAgregarSuministros] = useState(false);
    const [mostrarModealDetalleSuministro, setmostrarModealDetalleSuministro] = useState(false);

    //inputs 
    const inputListaBuscar = useRef();
    const inputBuscar = useRef();

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //referencia detalle suministro    
    const inputFecha = useRef();
    const inputOficina = useRef();
    const inputSuministro = useRef();
    const inputZona = useRef();
    const inputActaIntervencion = useRef();
    const inputTipo = useRef();
    const inputCod = useRef();
    const inputDescripcionIrregularidad = useRef();
    const inputObservacion = useRef();
    const inputCarta = useRef();
    const inputKwh = useRef();
    const inputMonto = useRef();
    const inputMesProceso = useRef();
    const inputMesEjecucion = useRef();
    const inputSemana = useRef();
    const inputJuo = useRef();
    const inputSupervisor = useRef();
    const inputCantidad = useRef();
    const inputSedProgramadaDeBalance = useRef();
    const inputRecuperoSed = useRef();
    const inputSistemaElectrico = useRef();
    const inputDistrito = useRef();
    const inputZonal = useRef();
    const inputRecuperoSet = useRef();
    const inputAlimentador = useRef();

    const rows = [
        { id: 1, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },
        { id: 2, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },
        { id: 3, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },
        { id: 4, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },
        { id: 5, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },
        { id: 6, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },
        { id: 7, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },
        { id: 8, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },
        { id: 9, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },
        { id: 10, accion: '', suministro: '6194', nombres: 'NEIRA GUTIERREZ, SEBASTIANA YNES', direccion: 'ERIAZO L2-C2 ASENTAMIENTO C-02 FRENTE A LA PARCELA Nº 62', fechaintervencion: '28/10/2021', zona: 'PEDREGAL', detalle: 'Puente en la Bornera del Medidor' },

    ];
    const columns = [
        {
            field: 'accion', headerName: 'Acción', minWidth: 50, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {
                            //console.log(cellValues);
                            setelementoActual(cellValues);
                            MostrarDetalleSuministro(cellValues.row);
                        }}>
                            <WysiwygIcon></WysiwygIcon>
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'suministro', headerName: 'Suministro', minWidth: 100, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'fecha', headerName: 'Fecha', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'mes_proceso', headerName: 'Mes Proceso', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'zonal', headerName: 'Zonal', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
        { field: 'juo', headerName: 'JUO', minWidth: 150, flex: 0.5, headerClassName: 'header-colum-table' },
        { field: 'descripcion_irregularidad', headerName: 'Descripción', minWidth: 150, flex: 1, headerClassName: 'header-colum-table' },
    ];

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_RECUPEROS_LISTA, permisos)) {
                    BuscarRecuperos();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/recuperos');
                    }, 1000);
                }
            });
        }       
    }

    const BuscarRecuperos = () => {
        if (ValidarUsuario()) {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/recupero/lista`;
            let listaBuscar = "";

            if (inputBuscar.current.value.trim() !== '') {
                listaBuscar = inputBuscar.current.value.trim();
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ listaBuscar: listaBuscar })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: elemento.id_recupero,
                            accion: '',
                            fecha: moment(elemento.fecha).format("DD/MM/YYYY"),
                            oficina: elemento.oficina,
                            suministro: elemento.suministro,
                            zona: elemento.zona,
                            acta_intervencion: elemento.acta_intervencion,
                            tipo: elemento.tipo,
                            cod: elemento.cod,
                            descripcion_irregularidad: elemento.descripcion_irregularidad,
                            observacion: elemento.observacion,
                            carta: elemento.carta,
                            kwh: elemento.kwh,
                            monto: elemento.monto,
                            mes_proceso: elemento.mes_proceso,
                            mes_ejecucion: elemento.mes_ejecucion,
                            semana: elemento.semana,
                            juo: elemento.juo,
                            supervisor: elemento.supervisor,
                            cantidad: elemento.cantidad,
                            sed_programada_de_balance: elemento.sed_programada_de_balance,
                            recupero_sed: elemento.recupero_sed,
                            sistema_electrico: elemento.sistema_electrico,
                            distrito: elemento.distrito,
                            zonal: elemento.zonal,
                            recupero_set: elemento.recupero_set,
                            alimentador: elemento.alimentador
                        }
                    );
                });

                setdataArchivoRecuperos(DataTemp);
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });

        } else {
            navigate("/login");
        }
    }

    const ListarSuminstrosBuscar = () => {
        if (inputListaBuscar.current.value !== '') {
            inputListaBuscar.current.classList.remove('is-invalid');

            let arrayLista = inputListaBuscar.current.value.split(/\r\n|\r|\n/);
            let listaBuscar = "";

            arrayLista.forEach(elemento => {
                if (elemento.trim() !== '') {
                    listaBuscar = listaBuscar + elemento.trim() + ",";
                }
            });

            listaBuscar = listaBuscar.substring(1, listaBuscar.length - 1);
            inputBuscar.current.value = listaBuscar;

            setmostrarModalAgregarSuministros(false);
        } else {
            inputListaBuscar.current.clasList.add('is-invalid');
            settituloToast("Error");
            settipoToast("error");
            setmensajeToast(`No se encontro cadena para realizar esta accion.`)
            setabrirToast(true);
        }
    }

    const MostrarDetalleSuministro = async (registro) => {
        await setmostrarModealDetalleSuministro(true);

        inputFecha.current.value=registro.fecha;
        inputOficina.current.value=registro.oficina;
        inputSuministro.current.value=registro.suministro;
        inputZona.current.value=registro.zona;
        inputActaIntervencion.current.value=registro.acta_intervencion;
        inputTipo.current.value=registro.tipo;
        inputCod.current.value=registro.cod;
        inputDescripcionIrregularidad.current.value=registro.descripcion_irregularidad;
        inputObservacion.current.value=registro.observacion;
        inputCarta.current.value=registro.carta;
        inputKwh.current.value=registro.kwh;
        inputMonto.current.value=registro.monto;
        inputMesProceso.current.value=registro.mes_proceso;
        inputMesEjecucion.current.value=registro.mes_ejecucion;
        inputSemana.current.value=registro.semana;
        inputJuo.current.value=registro.juo;
        inputSupervisor.current.value=registro.supervisor;
        inputCantidad.current.value=registro.cantidad;
        inputSedProgramadaDeBalance.current.value=registro.sed_programada_de_balance;
        inputRecuperoSed.current.value=registro.recupero_sed;
        inputSistemaElectrico.current.value=registro.sistema_electrico;
        inputDistrito.current.value=registro.distrito;
        inputZonal.current.value=registro.zonal;
        inputRecuperoSet.current.value=registro.recupero_set;
        inputAlimentador.current.value=registro.alimentador;     


        inputFecha.current.disabled=true;
        inputOficina.current.disabled=true;
        inputSuministro.current.disabled=true;
        inputZona.current.disabled=true;
        inputActaIntervencion.current.disabled=true;
        inputTipo.current.disabled=true;
        inputCod.current.disabled=true;
        inputDescripcionIrregularidad.current.disabled=true;
        inputObservacion.current.disabled=true;
        inputCarta.current.disabled=true;
        inputKwh.current.disabled=true;
        inputMonto.current.disabled=true;
        inputMesProceso.current.disabled=true;
        inputMesEjecucion.current.disabled=true;
        inputSemana.current.disabled=true;
        inputJuo.current.disabled=true;
        inputSupervisor.current.disabled=true;
        inputCantidad.current.disabled=true;
        inputSedProgramadaDeBalance.current.disabled=true;
        inputRecuperoSed.current.disabled=true;
        inputSistemaElectrico.current.disabled=true;
        inputDistrito.current.disabled=true;
        inputZonal.current.disabled=true;
        inputRecuperoSet.current.disabled=true;
        inputAlimentador.current.disabled=true;        
    }

    const ActualizarInputBuscar = () => {
        if (inputBuscar.current.value.trim() === '') {
            BuscarRecuperos();
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>
            <Header></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Recuperos</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/dashboard') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4} className={size.width<768?'d-grid gap-2':''}>
                            <Form.Control onChange={() => { ActualizarInputBuscar() }} ref={inputBuscar} placeholder='Valor a buscar' size=''></Form.Control>
                        </Col>
                        <Col sm={12} md={4}  className= {size.width<768?'d-grid gap-2 mt-1 mt-md-0':''}>
                            <Button onClick={()=>{BuscarRecuperos()}} variant='primary' size='sm'>
                                Buscar
                            </Button>
                            <IconButton onClick={() => { setmostrarModalAgregarSuministros(true) }}>
                                <PlaylistAddIcon></PlaylistAddIcon>
                            </IconButton>
                        </Col>
                        <Col sm={12} md={4} className={size.width<768?'d-grid gap-2 mt-1':'text-end'}>
                            <Button variant='primary' size='sm' onClick={() => { navigate('/recuperos/cargar') }}>Cargar Archivo</Button>
                        </Col>                        
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid 
                            density='compact' 
                            rows={dataArchivoRecuperos} 
                            columns={columns} 
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </div>
            </div>


            {/* Modal Agregar Suministros */}
            <Modal
                show={mostrarModalAgregarSuministros}
                onHide={() => { setmostrarModalAgregarSuministros(false) }}
                keyboard={false}
                size='md'
            >
                <Modal.Header>
                    <Modal.Title>
                        Agregar Lista
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <Form.Control ref={inputListaBuscar} as='textarea' rows={6} placeholder='Agregar Lista' size='sm'></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <Button variant='primary' onClick={() => { ListarSuminstrosBuscar() }}>Agregar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Detalle Suministro */}
            <Modal
                show={mostrarModealDetalleSuministro}
                onHide={() => { setmostrarModealDetalleSuministro(false) }}
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header>
                    <Modal.Title>
                        {
                            elementoActual?
                            `Suministro ${elementoActual.row.suministro}`
                            :<></>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >        

                    <Container>
                        <Row className=''>
                            <Col sm={6} md={4} lg={3}>
                                <small className='text-primary'>Fecha</small>
                                <Form.Control size="sm" ref={inputFecha} className=''></Form.Control>
                            </Col>                            
                            <Col sm={6} md={4} lg={3}>
                                <small className='text-primary'>Oficina</small>
                                <Form.Control size="sm" ref={inputOficina}className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                <small className='text-primary'>suministro</small>
                                <Form.Control size="sm" ref={inputSuministro} className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Zona</small>
                                <Form.Control size="sm" ref={inputZona} className=''></Form.Control>
                            </Col>                            
                        </Row>

                        <Row className=''>
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Acta Intervención</small>
                                <Form.Control size="sm" ref={inputActaIntervencion}className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                <small className='text-primary'>Tipo</small>
                                <Form.Control size="sm" ref={inputTipo} className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Cod</small>
                                <Form.Control size="sm" ref={inputCod} className=''></Form.Control>
                            </Col>                            
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Carta</small>
                                <Form.Control size="sm" ref={inputCarta}className=''></Form.Control>
                            </Col>
                        </Row>

                        <Row className=''>
                            <Col sm={12} md={12} >
                                <small className='text-primary'>Descripcion de Irregularidad</small>
                                <Form.Control size="sm" ref={inputDescripcionIrregularidad} className=''></Form.Control>
                            </Col>                            
                        </Row>

                        <Row className=''>
                            <Col sm={12} md={12} >
                                <small className='text-primary'>Observación</small>
                                <Form.Control size="sm" ref={inputObservacion} className=''></Form.Control>
                            </Col>                            
                        </Row>                        

                        <Row className=''>
                            
                            <Col sm={6} md={4} lg={3}>
                                <small className='text-primary'>Kwh</small>
                                <Form.Control size="sm" ref={inputKwh} className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Monto</small>
                                <Form.Control size="sm" ref={inputMonto} className=''></Form.Control>
                            </Col>                            
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Mes Proceso</small>
                                <Form.Control size="sm" ref={inputMesProceso}className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                <small className='text-primary'>Mes Ejecución</small>
                                <Form.Control size="sm" ref={inputMesEjecucion} className=''></Form.Control>
                            </Col>
                        </Row>

                        <Row className=''>
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Semana</small>
                                <Form.Control size="sm" ref={inputSemana} className=''></Form.Control>
                            </Col>                            
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Cantidad</small>
                                <Form.Control size="sm" ref={inputCantidad}className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                <small className='text-primary'>Sed Programado Balance</small>
                                <Form.Control size="sm" ref={inputSedProgramadaDeBalance} className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>SED</small>
                                <Form.Control size="sm" ref={inputRecuperoSed} className=''></Form.Control>
                            </Col>                            
                        </Row>

                        <Row className=''>
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Sistema Electrico</small>
                                <Form.Control size="sm" ref={inputSistemaElectrico}className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                <small className='text-primary'>Distrito</small>
                                <Form.Control size="sm" ref={inputDistrito} className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Zonal</small>
                                <Form.Control size="sm" ref={inputZonal} className=''></Form.Control>
                            </Col>                            
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>SET</small>
                                <Form.Control size="sm" ref={inputRecuperoSet}className=''></Form.Control>
                            </Col>
                        </Row>

                        <Row className=''>
                            <Col sm={6} md={4} lg={3}>
                                <small className='text-primary'>Alimentador</small>
                                <Form.Control size="sm" ref={inputAlimentador} className=''></Form.Control>
                            </Col>
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>JUO</small>
                                <Form.Control size="sm" ref={inputJuo} className=''></Form.Control>
                            </Col>                            
                            <Col sm={6} md={4} lg={3} >
                                <small className='text-primary'>Supervisor</small>
                                <Form.Control size="sm" ref={inputSupervisor}className=''></Form.Control>
                            </Col>                      
                        </Row>         
                        <hr></hr>               
                        <Row>
                            <Col className='text-end'>
                                <Button onClick={()=>{setmostrarModealDetalleSuministro(false)}} size="sm">Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>                    
                </Modal.Body>
            </Modal>

        </Fragment>
    );
}

export default Recuperos;