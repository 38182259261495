import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../dashboard/Header'
import PaginaCarga from '../../config/PaginaCarga';

import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

//Material UI
import { IconButton, Snackbar, Alert, AlertTitle} from '@mui/material';
import { DataGrid,esES } from '@mui/x-data-grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { ValidarUsuario, config, ObtenerUsuario,useWindowSize,ValidarPermisos,listarPermisos,LIST_SCREENS } from '../../config/utils';

function ConfiguracionPantallas(props) {
    const navigate = useNavigate();
    const size = useWindowSize();

    const [mostrarCircularProgress, setmostrarCircularProgress] = useState(false);
    const [tituloToast, settituloToast] = useState("Alerta");
    const [abrirToast, setabrirToast] = useState(false);
    const [tipotoast, settipoToast] = useState("error");
    const [mensajetoast, setmensajeToast] = useState("");

    const [mostrarModalPantalla, setmostrarModalPantalla] = useState(false);
    const [dataPantallas, setdataPantallas] = useState([]);
    const [accionActual, setaccionActual] = useState('');
    const [elementoActual, setelementoActual] = useState({});

    //Permisos
    const [listapermisos, setlistapermisos] = useState([]);

    //Referencias
    const inputBuscar = useRef();
    const inputNombrePantalla = useRef();
    const selectEstadoPantalla = useRef();

    useEffect(() => {
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (!ValidarUsuario()) {
            navigate('/');
        } else {
            //Validar Permisos
            listarPermisos().then((permisos) => {                
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION_PANTALLAS, permisos)) {
                    BuscarListaPantallas();
                } else {
                    settituloToast("Alerta");
                    settipoToast("warning");
                    setmensajeToast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        }     
    }

    const BuscarListaPantallas = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            setmostrarCircularProgress(true);
            let url = "";
            url = `${config.endPoint}api/Pantalla/Listar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ valorBuscar: inputBuscar.current.value })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                let DataTemp = [];
                data.content.forEach((elemento, index) => {
                    DataTemp.push(
                        {
                            id: elemento.id_pantalla,
                            accion: '',
                            pantalla_nombre: elemento.pantalla_nombre,
                            estado: elemento.estado
                        }
                    );
                });
                setdataPantallas(DataTemp);                
                setmostrarCircularProgress(false);

            }).catch((error) => {
                settituloToast("Error");
                settipoToast("error");
                setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                setabrirToast(true);
                setmostrarCircularProgress(false);
            });
        }
    }
    
    const columns = [
        {

            field: 'accion', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-gray', renderCell: (cellValues) => {
                return (
                    <>
                        {/* Eliminar Archivo */}
                        <IconButton onClick={() => {
                            AbrirModalPantalla('VER', cellValues.row)
                        }}>
                            <EditIcon fontSize='small'></EditIcon>
                        </IconButton>
                    </>
                );
            }
        },
        { field: 'pantalla_nombre', headerName: 'Nombre Pantalla', flex: 1, headerClassName: 'header-colum-gray' },
        // { field: 'codigo', headerName: 'Codigo Pantalla', flex: 1, headerClassName: 'header-colum-gray' },
        { field: 'estado', headerName: 'Estado', flex: 1, headerClassName: 'header-colum-gray' },

    ];

    const AbrirModalPantalla = async (accion, row) => {
        setaccionActual(accion);
        setelementoActual(row);
        await setmostrarModalPantalla(true);        
        if (row) {
            inputNombrePantalla.current.value = row.pantalla_nombre;
            selectEstadoPantalla.current.value = row.estado;
            inputNombrePantalla.current.disabled = true
            selectEstadoPantalla.current.disabled = true
        }
    }

    const Guardar = () => {
        switch (accionActual) {
            case 'AGREGAR':
                AgregarPantalla();
                break;
            case 'MODIFICAR':
                ActualizarPantalla();
                break;
        }
    }

    const ValidarFormularioPantalla = () => {
        let error = 0;
        if (inputNombrePantalla.current.value == '') {
            inputNombrePantalla.current.classList.add("is-invalid");
            error++;
        } else {
            inputNombrePantalla.current.classList.remove("is-invalid");
        }

        if (selectEstadoPantalla.current.value == '') {
            selectEstadoPantalla.current.classList.add('is-invalid');
            error++;
        } else {
            selectEstadoPantalla.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }

    const AgregarPantalla = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarFormularioPantalla()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Pantalla/Agregar`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        PantallaNombre: inputNombrePantalla.current.value.toUpperCase(),
                        Estado: selectEstadoPantalla.current.value.toUpperCase()
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    BuscarListaPantallas();
                    setmostrarModalPantalla(false);
                    setmostrarCircularProgress(false);

                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    const ActualizarPantalla = () => {
        if (!ValidarUsuario()) {
            navigate("/");
        } else {
            if (ValidarFormularioPantalla()) {
                setmostrarCircularProgress(true);
                let url = "";
                url = `${config.endPoint}api/Pantalla/Actualizar`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        IdPantalla:elementoActual.id,
                        PantallaNombre: inputNombrePantalla.current.value.toUpperCase(),
                        Estado: selectEstadoPantalla.current.value.toUpperCase()
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.success) {
                        settituloToast("Correcto");
                        settipoToast("success");
                    } else {
                        settituloToast("Error");
                        settipoToast("error");
                    }

                    setmensajeToast(data.message)
                    setabrirToast(true);
                    BuscarListaPantallas();
                    setmostrarModalPantalla(false);
                    setmostrarCircularProgress(false);

                }).catch((error) => {
                    settituloToast("Error");
                    settipoToast("error");
                    setmensajeToast(`${config.MessageErrorServer} ; ${error.toString()}`)
                    setabrirToast(true);
                    setmostrarCircularProgress(false);
                });
            }
        }
    }

    const ModificarPantalla = () => {
        inputNombrePantalla.current.disabled = false;
        selectEstadoPantalla.current.disabled = false;
        setaccionActual("MODIFICAR");
    }

    const ActualizarInputBuscar=()=>{
        if(inputBuscar.current.value.trim()==='')
        {
            BuscarListaPantallas();
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={mostrarCircularProgress} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{tituloToast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header titulo='Control de Perdidas / Configuración / Pantallas'></Header>

            <Container className='mt-1' style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <Row>
                    <Col>
                        <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                        <span className="mx-1" style={{ color: 'aliceblue' }}>Control de Perdidas / Configuración / Pantallas-Acceso</span>
                    </Col>
                    <Col className='text-end'>
                        <IconButton onClick={() => { navigate('/configuracion') }} style={{ color: 'white' }} size='small'>
                            <ArrowBackIcon />
                        </IconButton>
                    </Col>
                </Row>
            </Container>

            <div className='container border rounded mt-2'>
                <Container className='my-3'>
                    <Row >
                        <Col sm={12} md={4} className={size.width<768?'d-grid gap-2':''}>
                            <Form.Control onChange={()=>{ActualizarInputBuscar()}} ref={inputBuscar} placeholder='Valor a buscar' size='sm'></Form.Control>
                        </Col>
                        <Col sm={12} md={4} className={size.width<768?'d-grid gap-2 mt-1':''}>
                            <Button onClick={() => { BuscarListaPantallas() }} variant='primary' size='sm'>
                                Buscar
                            </Button>
                        </Col>
                        <Col sm={12} md={4} className={size.width<768?'d-grid gap-2 mt-1':'text-end'}>
                            <Button variant='primary' size='sm' onClick={() => { AbrirModalPantalla('AGREGAR', null) }}>Agregar <AddIcon fontSize='small'></AddIcon></Button>
                        </Col>
                    </Row>
                </Container>

                <div className='my-1'>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={dataPantallas}
                            columns={columns}
                            getCellClassName={(params) => {
                                if (params.colDef.field == "estado") {
                                    if (params.row.estado == "ACTIVO") {
                                        return 'text-primary';
                                    } else {
                                        return 'text-danger';
                                    }
                                }
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </div>
            </div>


            {/* Modal Pantalla */}

            <Modal
                show={mostrarModalPantalla}
                onHide={() => { setmostrarModalPantalla(false) }}
                keyboard={false}
                size="md"
            >
                <Modal.Header>
                    <Modal.Title>
                        Pantalla-Acceso-Menú
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Pantalla</small>
                                    <Form.Control ref={inputNombrePantalla} type="text" placeholder='Ingrese Nombre Pantalla-Acceso-Menú'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo Pantalla</small>
                                    <Form.Control type="text" placeholder='Ingrese Codigo Pantalla'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <select ref={selectEstadoPantalla} className="form-select" defaultValue="">
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                </Form.Group>

                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col>
                                <Button size='sm' variant="outline-primary" onClick={() => { setmostrarModalPantalla(false) }}>Cancelar</Button>
                            </Col>
                            <Col className='text-end'>
                                {
                                    accionActual == 'VER' ?
                                        <Button onClick={() => { ModificarPantalla() }} size='sm' variant="primary">Modificar</Button>
                                        : <Button onClick={() => { Guardar() }} size='sm' variant="primary">Guardar</Button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}

export default ConfiguracionPantallas;
